import { Table, Spin } from "antd";
import { useEffect, useState } from "react";

interface DadosEmpresaProps {
  cppdId?: string;
  clientes?: any[]; // Clientes é opcional para que o componente possa ser usado em CPPD
  clienteData?: any; // Dados do cliente direto, para uso em ListaDeReunioes
}

const columns = [
  {
    title: "Razão social",
    dataIndex: "razao_social",
    key: "razao_social",
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Telefone",
    dataIndex: "telefone",
    key: "telefone",
  },
  {
    title: "Responsável legal",
    dataIndex: "representante",
    key: "representante",
  },
];

const DadosEmpresa: React.FC<DadosEmpresaProps> = ({
  cppdId,
  clientes,
  clienteData,
}) => {
  const [cliente, setCliente] = useState<any | null>(clienteData || null);
  const [loading, setLoading] = useState<boolean>(!clienteData);

  useEffect(() => {
    const fetchCliente = () => {
      if (clienteData) {
        setCliente(clienteData);
        setLoading(false);
        return;
      }

      if (clientes && cppdId) {
        const clienteEncontrado = clientes.find(
          (cliente: any) => cliente._id === cppdId
        );
        setCliente(clienteEncontrado || null);
        setLoading(false);
      }
    };

    fetchCliente();
  }, [cppdId, clientes, clienteData]);

  if (loading) {
    return <Spin tip="Carregando dados da empresa..." />;
  }

  return (
    <div className="mb-6">
      <h5>Dados da empresa</h5>
      <br />
      {cliente ? (
        <Table
          size="small"
          dataSource={[cliente]}
          columns={columns}
          pagination={false}
          bordered
        />
      ) : (
        <p>Nenhum dado encontrado para este cliente.</p>
      )}
    </div>
  );
};

export default DadosEmpresa;
