import React, { useState } from "react";
import "./styles.css";
import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Spin,
  message,
  Row,
  Col,
} from "antd";
import { GlobalContextType } from "../../../../../../@types/global";
import { GlobalContext } from "../../../../../../context/globalContext";
import InputMask from "react-input-mask";
import Anexos from "../../../../../../components/Anexos/Anexos";
import { EditFornecedoresTypes } from "../../../../../../@types/editFornecedores";

interface FormValues {
  nome: string;
  cnpj: string;
  email: string;
  telefone: string;
  type: string;
  files: any;
}

const EditFornecedor: React.FC<EditFornecedoresTypes> = ({
  showAddFornecedor,
  setShowAddFornecedor,
  clientId,
  titular,
  selectedDados,
}) => {
  const { postFornecedor, getFornecedores, moduloMapeamento } =
    React.useContext(GlobalContext) as GlobalContextType;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [mask, setMask] = useState("(99) 99999-9999");
  const [currentAnexos, setCurrentAnexos] = useState<any[]>([]);
  const [anexos, setAnexos] = useState<any[]>([]);
  const [maskCPF, setMaskCPF] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, "");
    // Verifique o tamanho do valor inserido
    if (value.length <= 11) {
      setMaskCPF("999.999.999-999"); // Máscara de CPF
    } else {
      setMaskCPF("99.999.999/9999-99"); // Máscara de CNPJ
    }
  };

  const novoFornecedor = async (values: FormValues) => {
    setLoading(true);

    const formData = new FormData();
    if (currentAnexos) {
      for (const arquivo of currentAnexos) {
        formData.append("files", arquivo);
      }
    }

    // if (editFornecedor) {
    // const { nome, email, cnpj, type } = values;
    // if (!nome && !email && !cnpj && !type) {
    //   message.error("Preencha todos os campos!");
    //   setLoading(false);
    // } else {
    //   postFornecedor(
    //     { ...values, _id: editFornecedor._id },
    //     currentAnexos.filter((anexos: any) => !anexos.url),
    //     clientId,
    //   )
    //     .then((r) => {
    //       form.resetFields();
    //       setCurrentAnexos([]);
    //       clientId && getFornecedores(clientId);
    //       showAddFornecedor();
    //     })
    //     .finally(() => {
    //       setLoading(false);
    //       form.resetFields();
    //     });
    // }
    // } else {
    let { nome, email, cnpj, type } = values;

    if (!type) {
      type = "vigente";
    }

    if (!nome && !email && !cnpj) {
      message.error("Preencha todos os campos!");
      setLoading(false);
    } else {
      try {
        console.log("salvando fornecedor");
        const r = await postFornecedor(values, currentAnexos, clientId);
        form.resetFields();
        setCurrentAnexos([]);
        console.log("fornecedor salvo:", r);

        console.log("atualizando lista de fornecedores");
        clientId && getFornecedores(clientId);
        const fornecedorId = r.fornecedor_id;

        let currentTitular = titular;

        selectedDados?.forEach((dado: any) => {
          const dadoIndex: number | undefined = currentTitular.dados?.findIndex(
            (d: any) => d.dado_id === dado.dado_id
          );

          if (dadoIndex !== undefined && currentTitular?.dados?.[dadoIndex]) {
            let currentExternos: any =
              currentTitular.dados[dadoIndex].externos || [];

            currentTitular.dados[dadoIndex] = {
              ...currentTitular.dados[dadoIndex],
              externos: [...currentExternos, { fornecedor_id: fornecedorId }],
            };
          }
        });

        const currentTitularesFixed = moduloMapeamento.currentTitulares?.[0]
          ? [
              ...moduloMapeamento.currentTitulares.filter(
                (t) => t.key !== titular.key
              ),
              currentTitular,
            ]
          : [currentTitular];

        console.log("salvando titulares");
        moduloMapeamento.saveTitulares(currentTitularesFixed);
        setShowAddFornecedor(false);
      } catch (err) {
        console.error(err);
        message.error("Erro ao salvar titular");
      } finally {
        setLoading(false);
      }
    }
    // }
  };

  // useEffect(() => {
  //   if (editFornecedor && showFornecedores) {
  //     form.setFieldsValue({
  //       nome: editFornecedor.nome,
  //       cnpj: editFornecedor.cnpj,
  //       telefone: editFornecedor.telefone,
  //       email: editFornecedor.email,
  //       type: editFornecedor.type,
  //     });

  //     setLoading(true);
  //     getAnexo(editFornecedor._id)
  //       .then((res) => {
  //         setAnexos(res);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [showFornecedores]);
  return (
    <Modal
      title={
        <p style={{ color: "#5F29CC", fontSize: 22, fontWeight: 600 }}>
          Cadastro de Pessoa Física ou Jurídica Externa
        </p>
      }
      open={showAddFornecedor}
      footer={[]}
      onCancel={() => setShowAddFornecedor(false)}
      width={700}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={novoFornecedor}
        autoComplete="off"
      >
        <br />
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              label={
                maskCPF === "999.999.999-999"
                  ? "CPF"
                  : maskCPF === "99.999.999/9999-99"
                  ? "CNPJ"
                  : "CPF ou CNPJ"
              }
              name="cnpj"
              required
            >
              <InputMask
                type="text"
                mask={maskCPF}
                onChange={handleInputChange}
              >
                <Input />
              </InputMask>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                maskCPF === "999.999.999-999"
                  ? "Nome"
                  : maskCPF === "99.999.999/9999-99"
                  ? "Razão Social"
                  : "Razão Social ou Nome"
              }
              name="nome"
              required
            >
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>

        {maskCPF === "99.999.999/9999-99" && (
          <Form.Item label="Nome do responsável" name="representante" required>
            <Input type="text" />
          </Form.Item>
        )}
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label="E-mail" name="email" required>
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Telefone" name="telefone">
              <InputMask
                type="text"
                mask={mask}
                onBlur={(e) => {
                  if (e.target.value.replace("_", "").length === 14) {
                    setMask("(99) 9999-9999");
                  }
                }}
                onFocus={(e) => {
                  if (e.target.value.replace("_", "").length === 14) {
                    setMask("(99) 99999-9999");
                  }
                }}
              >
                <Input />
              </InputMask>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Tipo" name="type" required>
          <Radio.Group
            options={[
              { label: "Novo", value: "novo" },
              { label: "Vigente", value: "vigente" },
            ]}
            optionType="button"
          />
        </Form.Item>
        <Form.Item name="files">
          {loading ? (
            <Spin tip="Carregando" size="small">
              <div className="content" />
            </Spin>
          ) : (
            <Anexos
              currentAnexos={[...currentAnexos, ...anexos]}
              onChange={(v) =>
                setCurrentAnexos(v.fileList.map((f: any) => f.originFileObj))
              }
              setCurrentAnexos={setCurrentAnexos}
              text="Adicionar evidências"
              textSecondary="Evidências do compartilhamento e/ou adequação à LGPD do parceiro"
            />
          )}
        </Form.Item>
        <Form.Item style={{ marginBottom: "-0.5rem" }}>
          <Button
            style={{ background: "#5F29CC", color: "#fff" }}
            type="primary"
            className="btn-success"
            loading={loading}
            htmlType="submit"
          >
            Salvar
          </Button>
          <Button
            style={{ marginLeft: "1rem" }}
            className="btn-success"
            loading={loading}
            onClick={() => setShowAddFornecedor(false)}
          >
            Cancelar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditFornecedor;
