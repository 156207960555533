import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import TaskList from "../../../../components/Tarefas/TaskList/TaskList";

const PlanoAcaoQuestCSA = () => {
  return (
    <div className="csa">
      <BackHeader title="Questionário de T.I." />
      <TaskList csaquest />
    </div>
  );
};

export default PlanoAcaoQuestCSA;
