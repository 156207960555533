import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  DownCircleOutlined,
  MinusCircleFilled,
  PlusCircleOutlined,
  RightCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { GlobalContextType } from "../../../../../../@types/global";
import { GlobalContext } from "../../../../../../context/globalContext";
import React, { ReactNode, useEffect, useState } from "react";
import { Button, Input, Radio, Select, Space, Switch, Tooltip } from "antd";
import { InfoCircle } from "iconsax-react";
import { Titular } from "../../../../../../@types/titular";
const { TextArea } = Input;

interface ArmazenamentoGuiaProps {
  titular: Titular;
  setSelectedDados: (
    v: any[] | null | ((prev: any[] | null) => any[] | null)
  ) => void;
  selectedDados: any[] | null;
  icon: ReactNode;
  setIcon: (icon: ReactNode) => void;
  loadingTitulares: boolean;
  setLoadingTitulares: (v: boolean) => void;
  activeTab: string;
}

const ArmazenamentoGuia: React.FC<ArmazenamentoGuiaProps> = ({
  titular,
  setSelectedDados,
  selectedDados,
  icon,
  setIcon,
  loadingTitulares,
  setLoadingTitulares,
  activeTab,
}) => {
  const { moduloMapeamento } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [showPrazo, setShowPrazo] = useState<boolean>(false);
  const [lastInputArmazenamento, setLastInputArmazenamento] = useState<
    string | null
  >(null);
  const uniqueDataIds = new Set();

  useEffect(() => {
    // Seleciona a div com a classe `ant-tabs-content-holder` e reseta o scroll
    const tabsContent = document.querySelector(".ant-tabs-content-holder");
    if (tabsContent) {
      tabsContent.scrollTop = 0;
    }
  }, [activeTab]);

  return (
    <>
      <h4>Armazenamento</h4>
      <hr />
      {/* Listagem de dos dados pessoais */}
      <>
        <>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            1. Selecione os dados para definir as informações de armazenamento.{" "}
            {/* <Tooltip title="Selecione os dados para definir as informações de processamento">
    <InfoCircle
      size={16}
      style={{
        marginLeft: "0.5rem",
        cursor: "pointer",
      }}
    />
  </Tooltip> */}
          </p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="Selecionar todos">
              <Switch
                unCheckedChildren="Não"
                checkedChildren="Sim"
                onClick={(v) => {
                  if (v && titular.dados) {
                    setSelectedDados(titular.dados);
                  } else {
                    setSelectedDados(null);
                  }
                }}
                checked={
                  selectedDados?.length === titular.dados?.length ? true : false
                }
              />
            </Tooltip>
            <p style={{ marginLeft: "0.5rem" }}>
              As informações de armazenamento se aplicam a todos os dados
              pessoais cadastrados?
            </p>
          </div>
          <div></div>
        </>
        <br />
        <>
          {titular.dados?.map((dado) => (
            <Button
              size="small"
              style={{
                background:
                  titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.frequencia &&
                  (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.bases_legais_comuns ||
                    titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                      ?.bases_legais_sensiveis)
                    ? selectedDados?.find((d) => d.dado_id === dado.dado_id)
                      ? "#5F29CC"
                      : "#32E6BE"
                    : "",
                color:
                  titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.frequencia &&
                  (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.bases_legais_comuns ||
                    titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                      ?.bases_legais_sensiveis)
                    ? selectedDados?.find(
                        (d: any) => d.dado_id === dado.dado_id
                      )
                      ? "#fff"
                      : "#333"
                    : selectedDados?.find(
                        (d: any) => d.dado_id === dado.dado_id
                      )
                    ? "#fff"
                    : "#333",
                marginRight: "0.5rem",
                marginBottom: "0.5rem",
                opacity:
                  selectedDados?.length === titular.dados?.length
                    ? titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                        ?.frequencia &&
                      (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                        ?.bases_legais_comuns ||
                        titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                          ?.bases_legais_sensiveis)
                      ? 1
                      : 0.4
                    : 1,
                cursor:
                  selectedDados?.length === titular.dados?.length
                    ? "default"
                    : "pointer",
              }}
              icon={
                titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                  ?.frequencia &&
                (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                  ?.bases_legais_comuns ||
                  titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.bases_legais_sensiveis) ? (
                  selectedDados?.find(
                    (d: any) => d.dado_id === dado.dado_id
                  ) ? (
                    <DownCircleOutlined />
                  ) : (
                    <CheckCircleOutlined />
                  )
                ) : selectedDados?.find(
                    (d: any) => d.dado_id === dado.dado_id
                  ) ? (
                  <RightCircleOutlined />
                ) : (
                  <PlusCircleOutlined />
                )
              }
              type={
                selectedDados?.find((d: any) => d.dado_id === dado.dado_id)
                  ? "primary"
                  : titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                      ?.frequencia &&
                    (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                      ?.bases_legais_comuns ||
                      titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                        ?.bases_legais_sensiveis)
                  ? "primary"
                  : "default"
              }
              onClick={() => {
                setSelectedDados((prev) => {
                  if (
                    selectedDados?.find((d: any) => d.dado_id === dado.dado_id)
                  ) {
                    // remove
                    if (prev) {
                      return [
                        ...prev.filter((d) => d.dado_id !== dado.dado_id),
                      ];
                    } else {
                      return null;
                    }
                  } else {
                    // adiciona
                    return [dado];
                  }
                });
              }}
            >
              {
                moduloMapeamento.dados?.find((d: any) => d._id === dado.dado_id)
                  ?.nome
              }
            </Button>
          ))}
        </>
        <div></div>
      </>
      <br />
      {/* Preenchimento das informações */}
      <>
        {selectedDados?.[0] && (
          <>
            {/* Local de armazenamento */}
            <>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                2. Local de armazenamento dos dados{" "}
                <Tooltip title="É o lugar onde os dados pessoais coletados ficam armazenados.">
                  <InfoCircle
                    size={16}
                    style={{
                      marginLeft: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  style={{ width: "30%" }}
                  onChange={(v) => setLastInputArmazenamento(v.target.value)}
                  placeholder="Adicionar local de armazenamento"
                  value={lastInputArmazenamento || ""}
                />
                <Button
                  onClick={() => {
                    moduloMapeamento.setCurrentTitulares(
                      (prevTitulares: Titular[]) => {
                        let currentTitular = titular;
                        selectedDados?.forEach((dado) => {
                          const dadoIndex: number | undefined =
                            currentTitular.dados?.findIndex(
                              (d) => d.dado_id === dado.dado_id
                            );

                          if (
                            dadoIndex !== undefined &&
                            currentTitular?.dados?.[dadoIndex]
                          ) {
                            let currentLocal: any = currentTitular.dados[
                              dadoIndex
                            ].local?.[0]
                              ? currentTitular.dados[dadoIndex].local
                              : [];

                            currentTitular.dados[dadoIndex] = {
                              ...currentTitular.dados[dadoIndex],
                              local: [...currentLocal, lastInputArmazenamento],
                            };
                          }
                        });
                        setLastInputArmazenamento(null);

                        return [
                          ...prevTitulares.filter((t) => t.key !== titular.key),
                          currentTitular,
                        ];
                      }
                    );
                  }}
                  style={{
                    marginLeft: "1rem",
                    background: "#5F29CC",
                  }}
                  type="primary"
                >
                  Adicionar
                </Button>

                <br />
              </div>
              <br />
              <>
                {titular?.dados
                  ?.filter((d) =>
                    selectedDados.find((dado) => dado.dado_id === d.dado_id)
                  )
                  ?.filter((d) => d.local?.[0])
                  ?.map((dado) =>
                    dado?.local
                      ?.filter((local) => {
                        if (!uniqueDataIds.has(local)) {
                          uniqueDataIds.add(local);
                          return true;
                        }
                        return false;
                      })
                      ?.map((local) => (
                        <Tooltip title="Remover">
                          <div>
                            <Button
                              size="small"
                              style={{
                                background: "#3333FF",
                                color: "#fff",
                                marginBottom: "0.5rem",
                              }}
                              icon={<MinusCircleFilled />}
                              type="primary"
                              onClick={() => {
                                // setSelectedLocal(local);
                                moduloMapeamento.setCurrentTitulares(
                                  (prevTitulares: Titular[]) => {
                                    let currentTitular = titular;
                                    selectedDados?.forEach((dado) => {
                                      const dadoIndex: number | undefined =
                                        currentTitular.dados?.findIndex(
                                          (d) => d.dado_id === dado.dado_id
                                        );
                                      if (
                                        dadoIndex !== undefined &&
                                        currentTitular?.dados?.[dadoIndex]
                                      ) {
                                        let currentLocal: any = currentTitular
                                          .dados[dadoIndex].local?.[0]
                                          ? currentTitular.dados[dadoIndex]
                                              .local
                                          : [];
                                        currentTitular.dados[dadoIndex] = {
                                          ...currentTitular.dados[dadoIndex],
                                          local: currentLocal.filter(
                                            (l: string) => l !== local
                                          ),
                                        };
                                      }
                                    });
                                    return [
                                      ...prevTitulares.filter(
                                        (t) => t.key !== titular.key
                                      ),
                                      currentTitular,
                                    ];
                                  }
                                );
                              }}
                            >
                              {local}
                            </Button>
                          </div>
                        </Tooltip>
                      ))
                  )}
              </>
            </>
            <br />
            {titular?.dados
              ?.filter((d) =>
                selectedDados.find((dado) => dado.dado_id === d.dado_id)
              )
              ?.filter((d) => d.local?.[0])?.[0] && (
              <>
                {/* Prazo de armazenamento */}
                <>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      marginBottom: "1rem",
                    }}
                  >
                    3. Prazo de armazenamento{" "}
                    <Tooltip title="É o periodo pelo qual os dados pessoais ficarão armaenados na sua organização.">
                      <InfoCircle
                        size={16}
                        style={{
                          marginLeft: "0.5rem",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </p>

                  <>
                    <Radio.Group
                      onChange={(value) => {
                        if (value.target.value === "definido") {
                          setShowPrazo(true);
                        }

                        if (value.target.value === "indefinido") {
                          setShowPrazo(false);

                          moduloMapeamento.setCurrentTitulares(
                            (prevTitulares: Titular[]) => {
                              let currentTitular = titular;
                              selectedDados?.forEach((dado) => {
                                const dadoIndex: number | undefined =
                                  currentTitular.dados?.findIndex(
                                    (d) => d.dado_id === dado.dado_id
                                  );

                                if (
                                  dadoIndex !== undefined &&
                                  currentTitular?.dados?.[dadoIndex]
                                ) {
                                  currentTitular.dados[dadoIndex].prazo =
                                    "Não definido";
                                }
                              });

                              return [
                                ...prevTitulares.filter(
                                  (t) => t.key !== titular.key
                                ),
                                currentTitular,
                              ];
                            }
                          );
                        }
                      }}
                      value={
                        showPrazo
                          ? "definido"
                          : titular?.dados.filter((dado: any) =>
                              selectedDados.find(
                                (d) => dado.dado_id === d.dado_id
                              )
                            )?.[0]?.prazo === "Não definido"
                          ? "indefinido"
                          : null
                      }
                    >
                      <Space direction="vertical">
                        <Radio value={"definido"}>
                          Prazo de armazenamento <b>definido</b>
                        </Radio>
                        <Radio value={"indefinido"}>
                          Prazo de armazenamento <b>indefinido</b>
                        </Radio>
                      </Space>
                    </Radio.Group>

                    {(showPrazo ||
                      (titular?.dados.filter((dado: any) =>
                        selectedDados.find((d) => dado.dado_id === d.dado_id)
                      )?.[0]?.prazo !== "Não definido" &&
                        titular?.dados.filter((dado: any) =>
                          selectedDados.find((d) => dado.dado_id === d.dado_id)
                        )?.[0]?.prazo)) && (
                      <>
                        <p
                          style={{
                            marginBottom: "0.5rem",
                            marginTop: "1rem",
                          }}
                        >
                          Selecione
                        </p>
                        <div>
                          <Select
                            style={{
                              width: "100%",
                            }}
                            placeholder="Período"
                            value={
                              titular?.dados.filter((dado: any) =>
                                selectedDados.find(
                                  (d) => dado.dado_id === d.dado_id
                                )
                              )?.[0]?.prazo
                            }
                            onChange={(value: string) => {
                              moduloMapeamento.setCurrentTitulares(
                                (prevTitulares: Titular[]) => {
                                  let currentTitular = titular;
                                  selectedDados?.forEach((dado) => {
                                    const dadoIndex: number | undefined =
                                      currentTitular.dados?.findIndex(
                                        (d) => d.dado_id === dado.dado_id
                                      );

                                    if (
                                      dadoIndex !== undefined &&
                                      currentTitular?.dados?.[dadoIndex]
                                    ) {
                                      const dado =
                                        currentTitular.dados[dadoIndex];
                                      dado.prazo = value;
                                    }
                                  });

                                  return [
                                    ...prevTitulares.filter(
                                      (t) => t.key !== titular.key
                                    ),
                                    currentTitular,
                                  ];
                                }
                              );
                            }}
                            options={[
                              {
                                value:
                                  "Por prazo de guarda definido em lei ou regulamento",
                                label:
                                  "Por prazo de guarda definido em lei ou regulamento",
                              },
                              {
                                value:
                                  "Por prazo de guarda definido, mas sem previsão em lei ou regulamento",
                                label:
                                  "Por prazo de guarda definido, mas sem previsão em lei ou regulamento",
                              },
                              {
                                value:
                                  "Sem prazo de guarda definido, mas com justificativa conhecida pela organização ",
                                label:
                                  "Sem prazo de guarda definido, mas com justificativa conhecida pela organização ",
                              },
                            ]}
                          />
                        </div>
                      </>
                    )}
                  </>
                  <div></div>
                </>
                <br />
                {/* Norma */}
                <>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      marginBottom: "1rem",
                    }}
                  >
                    4. Norma{" "}
                    <Tooltip title="Opcional">
                      <InfoCircle
                        size={16}
                        style={{
                          marginLeft: "0.5rem",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </p>

                  <Input
                    style={{ width: "50%" }}
                    onChange={(v) => {
                      moduloMapeamento.setCurrentTitulares(
                        (prevTitulares: Titular[]) => {
                          let currentTitular = titular;
                          selectedDados?.forEach((dado) => {
                            const dadoIndex: number | undefined =
                              currentTitular.dados?.findIndex(
                                (d) => d.dado_id === dado.dado_id
                              );

                            if (
                              dadoIndex !== undefined &&
                              currentTitular?.dados?.[dadoIndex]
                            ) {
                              currentTitular.dados[dadoIndex] = {
                                ...currentTitular.dados[dadoIndex],
                                norma: v.target.value,
                              };
                            }
                          });

                          return [
                            ...prevTitulares.filter(
                              (t) => t.key !== titular.key
                            ),
                            currentTitular,
                          ];
                        }
                      );
                    }}
                    placeholder="Digite aqui a norma utilizada..."
                    value={
                      titular?.dados?.filter((dado: any) =>
                        selectedDados.find((d) => dado.dado_id === d.dado_id)
                      )?.[0]?.norma
                    }
                  />
                  <div></div>
                </>
              </>
            )}
          </>
        )}
        <div></div>
      </>
      <br />
      {/* Finalidade */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          5. Finalidade e Necessidade{" "}
          <Tooltip title="Descrever por quais motivos os dados são armazenados e identificar quais dados pessoais são desnecessários para alcançar essa finalidade.">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <TextArea
          onChange={(value) => {
            moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
              let currentTitular = titular;
              currentTitular.finalidade_armazenamento = value.target.value;

              return [
                ...prevTitulares.filter((t) => t.key !== titular.key),
                currentTitular,
              ];
            });
          }}
          value={titular.finalidade_armazenamento}
          autoSize={{ minRows: 3 }}
          showCount
          maxLength={5000}
        />
        <div></div>
      </>
      <br />
      {/* Medidas de segurança */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          6. Medidas de Segurança{" "}
          <Tooltip title="Indicar quais medidas garantem maior proteção aos dados pessoais armazenados.">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <TextArea
          onChange={(value) => {
            moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
              let currentTitular = titular;
              currentTitular.medidas_armazenamento = value.target.value;

              return [
                ...prevTitulares.filter((t) => t.key !== titular.key),
                currentTitular,
              ];
            });
          }}
          value={titular.medidas_armazenamento}
          autoSize={{ minRows: 3 }}
          showCount
          maxLength={5000}
        />
        <div></div>
      </>
      <br />
      {/* Observações */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          7. Observações{" "}
          <Tooltip title="Caso esta etapa do processo tenha alguma particularidade, deixe-a aqui">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <TextArea
          onChange={(value) => {
            moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
              let currentTitular = titular;
              currentTitular.obs_armazenamento = value.target.value;

              return [
                ...prevTitulares.filter((t) => t.key !== titular.key),
                currentTitular,
              ];
            });
          }}
          value={titular.obs_armazenamento}
          // onChange={(e) => setValue(e.target.value)}
          autoSize={{ minRows: 3 }}
          showCount
          maxLength={5000}
        />
        <div></div>
      </>
      <br />
      {/* Salvar e avançar*/}
      <>
        <Button
          style={{
            background: "#32E6BE",
            color: "#333333",
            marginTop: "1rem",
          }}
          icon={icon}
          type="primary"
          loading={loadingTitulares}
          onClick={() => {
            setLoadingTitulares(true);
            setIcon(<SaveOutlined />);

            moduloMapeamento
              .saveTitulares(moduloMapeamento.currentTitulares)
              .then(() => {
                setIcon(<CheckOutlined />);
              })
              .catch(() => {
                setIcon(<CloseOutlined />);
              })
              .finally(() => {
                setLoadingTitulares(false);
              });
          }}
        >
          Salvar e finalizar
        </Button>
        <div></div>
        <br />
      </>
    </>
  );
};

export default ArmazenamentoGuia;
