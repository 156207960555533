import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import BackHeader from "../../../components/BackHeader/BackHeader";
import { GlobalContextType } from "../../../@types/global";
import { GlobalContext } from "../../../context/globalContext";
import {
  Select,
  Space,
  Form,
  Table,
  Button,
  Collapse,
  Tabs,
  Row,
  Col,
  Descriptions,
  Tooltip,
  Tag,
  Alert,
} from "antd";
import {
  CheckCircleOutlined,
  PrinterOutlined,
  RightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { AreaType } from "../../../context/processosContext";
import { Danger } from "iconsax-react";
import { Questionario } from "../../../context/mapeamentoContext";
import { Titular } from "../../../@types/titular";
import TimelineGeral from "../../../components/Timeline/TimelineGeral";
import ContainerFicha from "./components/ContainerFicha/ContainerFicha";
import PdfDownload from "../../../components/PdfDownload/PdfDownload";
import RelatorioROT from "./components/RelatorioROT/RelatorioROT";

interface Filtros {
  cliente?: string;
  mapeamento?: string;
  area?: string;
  processo?: string;
  subprocesso?: string;
}

interface FichasProps {
  filtrosFeitos?: Filtros;
  backHeaderFunction?: VoidFunction;
}

const Fichas: React.FC<FichasProps> = ({
  filtrosFeitos,
  backHeaderFunction,
}) => {
  const {
    clientes,
    moduloProcessos,
    moduloMapeamento,
    usuarios,
    fornecedores,
    getFornecedores,
    fetchClienteLogs,
    currentCliente,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const [form] = Form.useForm(); // Adicionei a declaração do form
  const printRef = useRef<HTMLDivElement>(null);

  const [filtros, setFiltros] = useState<any>(null);
  const [activeKey, setActiveKey] = useState<string>("1");
  const [logs, setLogs] = useState<any[] | null>(null);
  const [loadingLogs, setLoadingLogs] = useState<boolean>(true);

  const [currentMapeamento, setCurrentMapeamento] = useState<
    Questionario | null | undefined
  >(null);

  useEffect(() => {
    if (filtros?.cliente) {
      if (
        !moduloMapeamento?.questionarios?.find(
          (q) => q.cliente_id === filtros?.cliente
        ) &&
        clientes?.filter((c: any) => c._id === filtros?.cliente)?.[0]
          ?.razao_social
      ) {
        moduloMapeamento.getQuestionariosCliente(filtros?.cliente);
      }
    }
  }, [filtros?.cliente]);

  useEffect(() => {
    if (filtros?.mapeamento && !currentMapeamento) {
      setCurrentMapeamento(
        moduloMapeamento.questionarios?.find(
          (q) => q._id === filtros.mapeamento
        )
      );
    }
  }, [filtros?.mapeamento]);

  useEffect(() => {
    if (currentCliente?._id && !filtrosFeitos) {
      setFiltros({
        cliente: currentCliente?._id,
      });
    }
  }, [currentCliente]);

  useEffect(() => {
    if (filtrosFeitos?.cliente || filtros?.cliente) {
      if (filtrosFeitos?.cliente && !filtros?.cliente) {
        setFiltros(filtrosFeitos);
      }
    }
  }, [filtrosFeitos]);

  useEffect(() => {
    if (currentCliente?._id) {
      fetchClienteLogs(currentCliente._id)
        .then((r) => setLogs(r))
        .finally(() => setLoadingLogs(false));

      !fornecedores?.[0] && getFornecedores(currentCliente._id);
    }
  }, [currentCliente]);

  useEffect(() => {
    if (clientes?.length === 1 && !filtros?.cliente && !filtrosFeitos) {
      setFiltros((prev: any) => ({
        ...prev,
        cliente: clientes[0]?._id,
      }));
    }
  }, [clientes]);

  useEffect(() => {
    if (
      moduloMapeamento.questionarios?.filter(
        (quest: any) => quest.nome === "Assessment Process"
      )?.length === 1 &&
      !filtros?.mapeamento &&
      !filtrosFeitos
    ) {
      let mapeamentoAtual = moduloMapeamento.questionarios?.filter(
        (quest: any) => quest.nome === "Assessment Process"
      )?.[0];

      setFiltros((prev: any) => ({
        ...prev,
        cliente: mapeamentoAtual?.cliente_id,
        mapeamento: mapeamentoAtual?._id,
      }));

      setCurrentMapeamento(mapeamentoAtual);

      if (
        mapeamentoAtual.capitulos?.filter((proc: any) => !proc.dependencia)
          ?.length === 1
      ) {
        let filtro = {
          cliente: mapeamentoAtual?.cliente_id,
          mapeamento: mapeamentoAtual?._id,
          area: mapeamentoAtual.capitulos?.find(
            (proc: any) => !proc.dependencia
          )?.areas_id?.[0],
          processo: mapeamentoAtual.capitulos?.find(
            (proc: any) => !proc.dependencia
          )?._id,
        };

        if (
          mapeamentoAtual.capitulos?.filter((proc: any) => proc.dependencia)
            ?.length === 1
        ) {
          setFiltros({
            ...filtro,
            subprocesso: mapeamentoAtual.capitulos?.find(
              (proc: any) => proc.dependencia
            )?._id,
          });
        } else {
          setFiltros(filtro);
        }
      }
    }
  }, [moduloMapeamento.questionarios]);

  useEffect(() => {
    if (
      (moduloProcessos?.areas?.length === 1 ||
        currentMapeamento?.areas?.length === 1) &&
      !filtros?.area &&
      !filtrosFeitos
    ) {
      setFiltros((prev: any) => ({
        ...prev,
        area: moduloProcessos?.areas?.[0]._id,
      }));
    }
  }, [moduloProcessos.areas, currentMapeamento]);

  const getClientOrder = (): string =>
    (
      clientes.find((cli: any) => cli._id === filtros.cliente)?.ordem || ""
    ).toString();

  const getQuestionarioIndex = (mapeamento: string | undefined): string => {
    const filteredQuestionarios = moduloMapeamento.questionarios?.filter(
      (quest: any) => quest.cliente_id === filtros.cliente
    );
    const foundQuestionario = moduloMapeamento.questionarios.find(
      (q: any) => q._id === mapeamento
    );

    // Check if foundQuestionario is not undefined before using it
    if (foundQuestionario !== undefined) {
      const questionarioIndex =
        filteredQuestionarios?.indexOf(foundQuestionario);
      return questionarioIndex !== -1 ? (questionarioIndex + 1).toString() : "";
    } else {
      return ""; // or handle the case when the questionario is not found
    }
  };

  const getAreaIndex = (areaId: string | undefined): string => {
    const filteredAreas: string[] | undefined = currentMapeamento?.areas;

    const areaIndex = filteredAreas?.findIndex((aId: string) => aId === areaId);

    return areaIndex !== undefined && areaIndex !== -1
      ? (areaIndex + 1).toString()
      : "";
  };

  const getCapituloIndex = (processo: string | undefined): string => {
    const filteredCapitulos = currentMapeamento?.capitulos;

    const capituloIndex = filteredCapitulos?.findIndex(
      (c: AreaType) => c?._id === processo
    );

    return capituloIndex !== undefined ? (capituloIndex + 1).toString() : "";
  };

  const getSubprocessoIndex = (subprocesso: string | undefined): string => {
    let subprocessoIndex = currentMapeamento?.capitulos.filter(
      (processo: any) => processo.dependencia === subprocesso
    );

    return subprocessoIndex !== undefined
      ? (subprocessoIndex + 1).toString()
      : "";
  };

  const getPerguntaIndex = (): string => {
    const perguntaIndex = moduloProcessos.perguntas
      ?.filter((p: any) =>
        moduloMapeamento.questionarios
          .find((q: any) => q._id === filtros.mapeamento)
          ?.perguntasRelacao?.find(
            (pergRel: any) =>
              pergRel.pergunta_id === p._id &&
              pergRel.capitulo_id === filtros.subprocesso
          )
      )
      .findIndex((item: any) => item._id === filtros.pergunta);

    return perguntaIndex !== undefined ? perguntaIndex.toString() : "";
  };

  const handleFormChange = (changedValues: any, allValues: any) => {
    // Lógica para limpar campos abaixo quando cliente é alterado
    if ("cliente" in changedValues) {
      setFiltros({
        cliente: changedValues["cliente"],
      });
      form.resetFields([
        "mapeamento",
        "area",
        "processo",
        "subprocesso",
        "pergunta",
      ]);
    }
    // Lógica para limpar campos abaixo quando mapeamento é alterado
    if ("mapeamento" in changedValues) {
      setFiltros((prev: any) => ({
        cliente: prev.cliente,
        mapeamento: changedValues["mapeamento"],
      }));
      form.resetFields(["area", "processo", "subprocesso", "pergunta"]);
    }

    // Lógica para limpar campos abaixo quando área é alterada
    if ("area" in changedValues) {
      setFiltros((prev: any) => ({
        cliente: prev.cliente,
        mapeamento: prev.mapeamento,
        area: changedValues["area"],
      }));
      form.resetFields(["processo", "subprocesso", "pergunta"]);
    }

    // Lógica para limpar campos abaixo quando processo é alterado
    if ("processo" in changedValues) {
      setFiltros((prev: any) => ({
        cliente: prev.cliente,
        mapeamento: prev.mapeamento,
        area: prev.area,
        processo: changedValues["processo"],
      }));
      form.resetFields(["subprocesso", "pergunta"]);
    }

    // Lógica para limpar campos abaixo quando processo é alterado
    if ("subprocesso" in changedValues) {
      setFiltros((prev: any) => ({
        cliente: prev.cliente,
        mapeamento: prev.mapeamento,
        area: prev.area,
        processo: prev.processo,
        subprocesso: changedValues["subprocesso"],
      }));
      form.resetFields(["pergunta"]);
    }
  };

  interface IconeRiscoProps {
    value: any;
    text: any;
  }

  const IconeRisco: React.FC<IconeRiscoProps> = ({ value, text }) => {
    let title;
    let description = <p>descrição</p>;
    let icon;

    // ICONES:
    const extremo = (
      <Danger
        size="16"
        color="red"
        style={{ cursor: "pointer", marginLeft: "0.5rem" }}
      />
    );

    const baixo = (
      <CheckCircleOutlined
        style={{
          color: "green",
          cursor: "pointer",
          marginLeft: "0.5rem",
          fontSize: 16,
        }}
      />
    );

    const alto = (
      <Danger
        size="16"
        color="#fc7600"
        style={{ cursor: "pointer", marginLeft: "0.5rem" }}
      />
    );

    const medio = (
      <Danger
        style={{ cursor: "pointer", marginLeft: "0.5rem" }}
        size="16"
        color="#fce700"
      />
    );

    switch (value) {
      case "1 a 100":
        title = "Risco baixo";
        icon = baixo;
        break;
      case "100 a 500":
        title = "Risco médio";
        icon = medio;
        break;
      case "500 a 5000":
        title = "Risco alto";
        icon = alto;
        break;
      case "5000 a 10000":
      case "+10000":
        title = "Risco extremo";
        icon = extremo;
        break;
      default:
        if (!value?.tipo) {
          if (value?.includes("Crianças (até 12 anos incompletos)")) {
            title = "Risco extremo";
            icon = extremo;
          } else if (
            value?.includes("Idosos (+ de 60 anos)") ||
            value?.includes("Adolescentes (13 a 18 anos)")
          ) {
            title = "Risco alto";
            icon = alto;
          } else if (value?.includes("Adultos (18 a 60 anos)")) {
            title = "Risco baixo";
            icon = baixo;
          }
        }
    }

    if (value?.tipo === "frequencia") {
      value?.dados?.forEach((dado: any) => {
        switch (dado.frequencia) {
          case "Contínuo ou em tempo real":
            title = "Risco extremo";
            icon = extremo;
            break;
          case "Semanal":
            title = "Risco extremo";
            icon = extremo;
            break;
          case "Mensal":
            title = "Risco alto";
            icon = alto;
            break;
          case "Semestral ou Anual":
            title = "Risco médio";
            icon = medio;
            break;
          case "Uma única vez":
            title = "Risco baixo";
            icon = baixo;
            break;
          default:
            break;
        }
      });
    }

    if (value?.tipo === "paises") {
      if (value.dados?.find((dado: any) => dado.paises)) {
        title = "Risco médio";
        icon = medio;
      } else {
        title = "Risco baixo";
        icon = baixo;
      }
    }

    // if (!title) {
    return <>{text}</>;
    // }

    // return (
    //   // <Popconfirm
    //   //   title={title}
    //   //   icon={
    //   //     <span
    //   //       style={{
    //   //         marginRight: "0.5rem",
    //   //         verticalAlign: "-3px",
    //   //       }}
    //   //     >
    //   //       {icon}
    //   //     </span>
    //   //   }
    //   //   overlayStyle={{ width: "300px" }}
    //   //   description={description}
    //   //   okText="Ver no Plano de Ação"
    //   //   cancelText="Fechar"
    //   //   onConfirm={() => {
    //   //     setActiveKey("2");
    //   //   }}
    //   // >
    //   <div className="d-flex a-c risco-pop">
    //     {text}
    //     {icon}
    //   </div>
    //   // </Popconfirm>
    // );
  };

  const Titulares: React.FC<any> = () => {
    const processos = currentMapeamento?.capitulos?.find(
      (processo: any) =>
        processo?._id ===
        (filtros?.subprocesso ? filtros?.subprocesso : filtros?.processo)
    );

    const titulares = moduloMapeamento?.currentTitulares?.filter(
      (titular) => titular.relacao_id === processos?.questionarioCapitulo?._id
    );

    if (titulares && titulares.length > 0) {
      const defaultActiveKeys = titulares.map((titular: any) => titular._id);

      return (
        <div>
          {titulares.map((titular: Titular, index: number) => (
            <div key={titular._id}>
              <Collapse
                className="collapse_titular"
                size="large"
                bordered={false}
                defaultActiveKey={defaultActiveKeys}
                items={[
                  {
                    label: (
                      <h5 style={{ color: "#5F29CC", marginTop: "-2.5px" }}>
                        Titular {index + 1}
                        {/* :{" "} */}
                        {/* <span style={{ color: "#5F29CC" }}>
                          {titular.categoria?.toUpperCase()}
                        </span> */}
                      </h5>
                    ),
                    key: titular._id,
                    children: (
                      <div className="grid-container">
                        {/* Coleta */}
                        <Collapse
                          className="collapse_etapas"
                          defaultActiveKey={"1-coleta"}
                          items={[
                            {
                              key: "1-coleta",
                              label: (
                                <p
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  Coleta
                                </p>
                              ),
                              children: (
                                <div>
                                  <Descriptions
                                    column={1}
                                    layout="vertical"
                                    labelStyle={{
                                      fontWeight: "bold",
                                      color: "#000",
                                    }}
                                  >
                                    <Descriptions.Item label="Número Estimado">
                                      <IconeRisco
                                        value={titular.quantidade}
                                        text={titular.quantidade + " Titulares"}
                                      />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Faixa etária">
                                      <IconeRisco
                                        value={titular.faixa_etaria}
                                        text={titular.faixa_etaria?.join(" | ")}
                                      />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Dados Pessoais comuns">
                                      {titular.dados
                                        ?.map(
                                          (dado: any) =>
                                            moduloMapeamento.dados
                                              ?.filter(
                                                (d: any) =>
                                                  d.tipo !== "sensivel"
                                              )
                                              ?.find(
                                                (d: any) =>
                                                  d._id === dado.dado_id
                                              )?.nome
                                        )
                                        ?.join(" | ") || "-"}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Dados Pessoais sensíveis">
                                      {titular.dados
                                        ?.filter(
                                          (d: any) => d.tipo === "sensivel"
                                        )
                                        ?.map(
                                          (dado: any) =>
                                            moduloMapeamento.dados?.find(
                                              (d: any) => d._id === dado.dado_id
                                            )?.nome
                                        )
                                        ?.join(" | ") || "-"}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Origem dos Dados">
                                      <IconeRisco
                                        value={titular.dados}
                                        text={Array.from(
                                          new Set(
                                            titular.dados?.flatMap(
                                              (dado: any) => dado.origem
                                            )
                                          )
                                        )?.join(" | ")}
                                      />
                                    </Descriptions.Item>
                                    {titular.finalidade_coleta && (
                                      <Descriptions.Item label="Finalidade e Necessidade">
                                        {titular.finalidade_coleta}
                                      </Descriptions.Item>
                                    )}
                                    {titular.medidas_coleta && (
                                      <Descriptions.Item label="Medidas de Segurança">
                                        {titular.medidas_coleta}
                                      </Descriptions.Item>
                                    )}
                                    {titular.obs_coleta && (
                                      <Descriptions.Item label="Observações">
                                        {titular.obs_coleta}
                                      </Descriptions.Item>
                                    )}
                                  </Descriptions>
                                </div>
                              ),
                            },
                          ]}
                        />

                        {/* Processamento */}
                        <Collapse
                          className="collapse_etapas"
                          defaultActiveKey={"1-processamento"}
                          items={[
                            {
                              key: "1-processamento",
                              label: (
                                <p
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  Processamento
                                </p>
                              ),
                              children: (
                                <div>
                                  <Descriptions
                                    layout="vertical"
                                    labelStyle={{
                                      fontWeight: "bold",
                                      color: "#000",
                                    }}
                                    column={1}
                                  >
                                    <Descriptions.Item label="Frequência">
                                      <IconeRisco
                                        value={{
                                          tipo: "frequencia",
                                          dados: titular.dados,
                                        }}
                                        text={Array.from(
                                          new Set(
                                            titular.dados
                                              ?.flatMap(
                                                (dado: any) => dado.frequencia
                                              )
                                              .filter((f: any) => f)
                                          )
                                        )?.join(" | ")}
                                      />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Bases Legais">
                                      {(titular.dados &&
                                        Array.from(
                                          new Set([
                                            ...titular.dados?.flatMap(
                                              (dado: any) =>
                                                dado.bases_legais_comuns
                                            ),
                                            ...titular.dados?.flatMap(
                                              (dado: any) =>
                                                dado.bases_legais_sensiveis
                                            ),
                                          ])
                                        )
                                          ?.filter((i: any) => i)
                                          ?.join(" | ")) ||
                                        "-"}
                                    </Descriptions.Item>
                                    {titular.finalidade_processamento && (
                                      <Descriptions.Item label="Finalidade e Necessidade">
                                        {titular.finalidade_processamento}
                                      </Descriptions.Item>
                                    )}
                                    {titular.medidas_processamento && (
                                      <Descriptions.Item label="Medidas de Segurança">
                                        {titular.medidas_processamento}
                                      </Descriptions.Item>
                                    )}
                                    {titular.obs_processamento && (
                                      <Descriptions.Item label="Observações">
                                        {titular.obs_processamento}
                                      </Descriptions.Item>
                                    )}
                                  </Descriptions>
                                </div>
                              ),
                            },
                          ]}
                        />

                        {/* Compartilhamento */}
                        <Collapse
                          className="collapse_etapas"
                          defaultActiveKey={"1-compartilhamento"}
                          items={[
                            {
                              key: "1-compartilhamento",
                              label: (
                                <p
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  Compartilhamento
                                </p>
                              ),
                              children: (
                                <div>
                                  <Descriptions
                                    layout="vertical"
                                    column={1}
                                    labelStyle={{
                                      fontWeight: "bold",
                                      color: "#000",
                                    }}
                                  >
                                    <Descriptions.Item label="Departamentos">
                                      {Array.from(
                                        new Set(
                                          titular.dados?.flatMap((dado: any) =>
                                            dado.areas_compartilhadas?.map(
                                              (area: any) =>
                                                moduloProcessos.areas?.find(
                                                  (a: any) =>
                                                    a._id === area.area_id
                                                )?.description
                                            )
                                          )
                                        )
                                      ).join(" | ")}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Formas de compartilhamento">
                                      {Array.from(
                                        new Set(
                                          titular.dados?.flatMap((dado: any) =>
                                            dado.areas_compartilhadas?.map(
                                              (area: any) => area.forma
                                            )
                                          )
                                        )
                                      ).join(" | ")}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Externos">
                                      {Array.from(
                                        new Set(
                                          titular.dados?.flatMap((dado: any) =>
                                            dado.externos
                                              ?.map(
                                                (ext: any) =>
                                                  fornecedores?.find(
                                                    (f: any) =>
                                                      f._id ===
                                                      ext.fornecedor_id
                                                  )?.nome +
                                                  ": " +
                                                  ext.forma
                                              )
                                              .join(" | ")
                                          )
                                        )
                                      ).join(" | ") || "-"}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Transferência Internacional">
                                      <IconeRisco
                                        value={{
                                          tipo: "paises",
                                          dados: titular.dados,
                                        }}
                                        text={
                                          titular.dados?.find(
                                            (dado: any) => dado.paises
                                          )
                                            ? titular.dados
                                                .filter(
                                                  (dado: any) => dado.paises
                                                )
                                                .map((dado: any) => dado.paises)
                                                .join(" | ")
                                            : "Não"
                                        }
                                      />
                                    </Descriptions.Item>
                                    {titular.finalidade_compartilhamento && (
                                      <Descriptions.Item label="Finalidade e Necessidade">
                                        {titular.finalidade_compartilhamento}
                                      </Descriptions.Item>
                                    )}
                                    {titular.medidas_compartilhamento && (
                                      <Descriptions.Item label="Medidas de segurança">
                                        {titular.medidas_compartilhamento}
                                      </Descriptions.Item>
                                    )}
                                    {titular.obs_compartilhamento && (
                                      <Descriptions.Item label="Observações">
                                        {titular.obs_compartilhamento}
                                      </Descriptions.Item>
                                    )}
                                  </Descriptions>
                                </div>
                              ),
                            },
                          ]}
                        />

                        {/* Armazenamento e retenção */}
                        <Collapse
                          className="collapse_etapas"
                          defaultActiveKey={"1-armazenamento"}
                          items={[
                            {
                              key: "1-armazenamento",
                              label: (
                                <p
                                  style={{
                                    color: "#fff",
                                  }}
                                >
                                  Armazenamento e retenção
                                </p>
                              ),
                              children: (
                                <div className="grid-item">
                                  <Descriptions
                                    layout="vertical"
                                    column={1}
                                    labelStyle={{
                                      fontWeight: "bold",
                                      color: "#000",
                                    }}
                                  >
                                    <Descriptions.Item label="Local do Armazenamento">
                                      {Array.from(
                                        new Set(
                                          titular.dados?.flatMap((dado: any) =>
                                            dado.local
                                              ?.map((local: any) => local)
                                              .join(" | ")
                                          )
                                        )
                                      ).join(" | ")}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Prazo de guarda">
                                      {Array.from(
                                        new Set(
                                          titular.dados?.flatMap(
                                            (dado: any) => dado.prazo
                                          )
                                        )
                                      )?.join(" | ") || "Não definido"}
                                    </Descriptions.Item>
                                    {titular.finalidade_armazenamento && (
                                      <Descriptions.Item label="Finalidade e Necessidade">
                                        {titular.finalidade_armazenamento}
                                      </Descriptions.Item>
                                    )}
                                    {titular.medidas_armazenamento && (
                                      <Descriptions.Item label="Medidas de segurança">
                                        {titular.medidas_armazenamento}
                                      </Descriptions.Item>
                                    )}
                                    {titular.obs_armazenamento && (
                                      <Descriptions.Item label="Observações">
                                        {titular.obs_armazenamento}
                                      </Descriptions.Item>
                                    )}
                                  </Descriptions>
                                </div>
                              ),
                            },
                          ]}
                        />
                      </div>
                    ),
                  },
                ]}
              />
              <br />
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <Alert
          type="info"
          message="Nenhum titular encontrado, revise a seleção do processo."
          showIcon
        />
      );
    }
  };

  return (
    <div className="ficha">
      <BackHeader title="Ficha de processo" prevAction={backHeaderFunction} />
      {/* Filtros ficha */}
      {filtrosFeitos && <br />}
      {!filtrosFeitos && (
        <Form
          form={form}
          name="fichas"
          layout="vertical"
          onValuesChange={handleFormChange}
        >
          {!filtros?.cliente && (
            <Alert
              type="warning"
              message="Selecione um cliente para visualizar!"
              showIcon
            />
          )}
          <Space wrap>
            {filtros?.cliente && (
              <>
                {moduloMapeamento.questionarios?.filter(
                  (quest: any) => quest.nome === "Assessment Process"
                )?.length > 1 && (
                  <Form.Item name="mapeamento" label="Ficha">
                    <Select
                      loading={
                        moduloMapeamento.questionarios?.filter(
                          (quest: any) => quest.cliente_id === filtros.cliente
                        )?.[0]
                          ? false
                          : true
                      }
                      disabled={
                        moduloMapeamento.questionarios?.filter(
                          (quest: any) => quest.cliente_id === filtros.cliente
                        )?.[0]
                          ? false
                          : true
                      }
                      value={filtros?.mapeamento}
                      style={{ width: "24rem" }}
                      placeholder="Selecione um mapeamento"
                      options={moduloMapeamento.questionarios
                        ?.filter(
                          (quest: any) => quest.nome === "Assessment Process"
                        )
                        ?.filter(
                          (quest: any) => quest.cliente_id === filtros.cliente
                        )
                        ?.sort(
                          (a: any, b: any) =>
                            new Date(b.created_at).getTime() -
                            new Date(a.created_at).getTime()
                        )
                        .map((quest: any) => {
                          return {
                            key: quest._id,
                            value: quest._id,
                            label: (
                              <Tooltip title={quest.nome}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      width: "200px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {quest.nome.length > 25
                                      ? quest.nome.substring(0, 25) + "..."
                                      : quest.nome}
                                  </p>
                                  <Tag
                                    color="magenta"
                                    style={{ marginLeft: "1rem" }}
                                  >
                                    {moment(new Date(quest.created_at)).format(
                                      "DD/MM/YYYY HH:mm"
                                    )}
                                  </Tag>
                                </div>
                              </Tooltip>
                            ),
                          };
                        })}
                    />
                  </Form.Item>
                )}
              </>
            )}
            {filtros?.mapeamento && (
              <>
                {moduloMapeamento.questionarios?.filter(
                  (quest: any) => quest.nome === "Assessment Process"
                )?.length > 1 && <RightOutlined className="ficha__rightIcon" />}

                {moduloProcessos?.areas?.length !== 1 &&
                  currentMapeamento?.areas?.length !== 1 && (
                    <Form.Item name="area" label="Área">
                      <Select
                        value={filtros?.area}
                        style={{ minWidth: 300 }}
                        placeholder="Selecione uma área"
                        options={currentMapeamento?.areas
                          ?.filter((areaId: string) =>
                            moduloProcessos.areas?.find((a) => areaId === a._id)
                          )
                          ?.map((areaId: any) => {
                            return {
                              key: areaId,
                              value: areaId,
                              label: moduloProcessos.areas?.find(
                                (a) => areaId === a._id
                              )?.description,
                            };
                          })}
                      />
                    </Form.Item>
                  )}
              </>
            )}

            {filtros?.area && (
              <>
                {moduloProcessos?.areas?.length !== 1 &&
                  currentMapeamento?.areas?.length !== 1 && (
                    <RightOutlined className="ficha__rightIcon" />
                  )}
                {/* {currentMapeamento?.capitulos?.filter(
                  (proc: any) => !proc.dependencia
                )?.length !== 1 && ( */}
                <Form.Item name="processo" label="Processo">
                  <Select
                    value={filtros?.processo}
                    placeholder="Selecione o processo"
                    style={{ minWidth: 300 }}
                    options={currentMapeamento?.capitulos
                      ?.filter(
                        (cap: any) =>
                          cap.tags?.includes(filtros.area) && !cap.dependencia
                      )
                      ?.map((processo: any) => {
                        return {
                          key: processo._id,
                          value: processo._id,
                          label: processo.description,
                        };
                      })}
                  />
                </Form.Item>
                {/* // )} */}
              </>
            )}
            {filtros?.processo &&
              currentMapeamento?.capitulos?.filter(
                (cap: any) => cap.dependencia === filtros.processo
              )?.[0] && (
                <>
                  {currentMapeamento?.capitulos?.filter(
                    (proc: any) => !proc.dependencia
                  )?.length !== 1 && (
                    <RightOutlined className="ficha__rightIcon" />
                  )}

                  <Form.Item name="subprocesso" label="Subprocesso">
                    <Select
                      value={filtros?.subprocesso}
                      style={{ width: 240 }}
                      options={currentMapeamento?.capitulos
                        ?.filter(
                          (cap: any) => cap.dependencia === filtros.processo
                        )
                        ?.map((processo: any) => {
                          return {
                            key: processo._id,
                            value: processo._id,
                            label: processo.description,
                          };
                        })
                        ?.reduce((acc: any[], current: any) => {
                          // Verifica se já existe um objeto com a mesma key no acumulador
                          const exists = acc.find(
                            (item) => item.key === current.key
                          );

                          // Se não existir, adiciona o objeto ao acumulador
                          if (!exists) {
                            acc.push(current);
                          }

                          return acc;
                        }, [])}
                    />
                  </Form.Item>
                </>
              )}
          </Space>
        </Form>
      )}
      {filtros?.mapeamento && (
        <Tabs
          onChange={(key) => setActiveKey(key)}
          size="large"
          type="card"
          activeKey={activeKey}
          items={[
            {
              key: "1",
              label: "Ficha de processo",
              children: (
                <div style={{ background: "#fff", padding: 16 }}>
                  {/* imprimir */}
                  {filtros.processo && !loadingLogs && currentMapeamento ? (
                    <Tooltip title="Registro das Operações de Tratamento de Dados Pessoais">
                      <PdfDownload
                        title={"Imprimir ROT"}
                        content={
                          <RelatorioROT
                            logs={logs}
                            loadingLogs={loadingLogs}
                            filtros={filtros}
                            currentMapeamento={currentMapeamento}
                            codigoFicha={`${
                              filtros.cliente &&
                              clientes?.[0] &&
                              `${getClientOrder()}.`
                            }${
                              filtros.mapeamento &&
                              `${getQuestionarioIndex(filtros.mapeamento)}`
                            }${
                              filtros.area && `.${getAreaIndex(filtros.area)}`
                            }${
                              filtros.processo &&
                              `.${getCapituloIndex(filtros.processo)}`
                            }${
                              filtros.subprocesso &&
                              `.${getSubprocessoIndex(filtros.subprocesso)}`
                            }`}
                          />
                        }
                      />
                    </Tooltip>
                  ) : (
                    <Button
                      icon={<PrinterOutlined />}
                      type="primary"
                      loading={true}
                      disabled
                    >
                      Imprimir ROT
                    </Button>
                  )}
                  <br />
                  {/* Visualização ficha */}
                  {filtros.cliente && currentMapeamento && (
                    <div ref={printRef} className="print-container">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: " flex-end",
                        }}
                      >
                        <h2 style={{ color: "#5f29cc", fontWeight: "bold" }}>
                          {filtros.cliente &&
                            clientes?.[0] &&
                            `${getClientOrder()}.`}
                          {filtros.mapeamento &&
                            `${getQuestionarioIndex(filtros.mapeamento)}`}
                          {filtros.area && `.${getAreaIndex(filtros.area)}`}
                          {filtros.processo &&
                            `.${getCapituloIndex(filtros.processo)}`}
                          {filtros.subprocesso &&
                            `.${getSubprocessoIndex(filtros.subprocesso)}`}
                          {filtros.pergunta && `.${getPerguntaIndex()}`}
                        </h2>
                        <h2 style={{ fontSize: "1.2rem" }}>
                          {moment(
                            new Date(currentMapeamento?.updated_at)
                          ).format("DD/MM/YYYY")}
                        </h2>
                      </div>
                      <hr />
                      <br />
                      <h4 style={{ fontSize: "1.4rem", color: "#5F29CC" }}>
                        {
                          moduloMapeamento.ficha
                            ?.find((f: any) => f?._id === filtros?.mapeamento)
                            ?.areas?.find((a: any) => a?._id === filtros?.area)
                            ?.processos?.find(
                              (processo: any) =>
                                processo?._id ===
                                (filtros?.subprocesso
                                  ? filtros?.subprocesso
                                  : filtros?.processo)
                            )?.description
                        }
                      </h4>

                      <Row gutter={24}>
                        <Col span={12}>
                          {/* Dados da empresa */}
                          {clientes?.[0] && (
                            <ContainerFicha>
                              <p style={{ fontSize: 18 }}>
                                <b>Empresa: </b>
                                {
                                  clientes?.find(
                                    (cli: any) => cli._id === filtros?.cliente
                                  )?.razao_social
                                }
                              </p>
                              <p style={{ fontSize: 18, marginTop: "1rem" }}>
                                <b>CNPJ: </b>
                                {
                                  clientes?.find(
                                    (cli: any) => cli._id === filtros?.cliente
                                  )?.cnpj
                                }
                              </p>
                            </ContainerFicha>
                          )}
                        </Col>
                        <Col span={12}>
                          {/* Responsável e Local */}
                          {filtros.processo && (
                            <ContainerFicha>
                              <p style={{ fontSize: 18 }}>
                                <b>Responsável pelo processo: </b>
                                {
                                  usuarios?.find(
                                    (usr: any) =>
                                      // usr.grupos.find(
                                      //   (g: any) => g.name === "Líder de processo"
                                      // ) &&
                                      usr.funcoesDetalhadas.find(
                                        (fd: any) =>
                                          fd.reference_model_id ===
                                          filtros.mapeamento
                                      ) &&
                                      usr.funcoesDetalhadas.find(
                                        (fd: any) =>
                                          fd.reference_model_id ===
                                            filtros.processo ||
                                          fd.reference_model_id ===
                                            moduloProcessos.capitulos?.find(
                                              (c) => c._id === filtros.processo
                                            )?.dependencia
                                      )
                                  )?.name
                                }
                              </p>
                              <p style={{ fontSize: 18, marginTop: "1rem" }}>
                                <b>Local: </b>
                                {currentMapeamento && filtros?.area && (
                                  <>
                                    {moduloProcessos.areas?.find(
                                      (a) => a._id === filtros.area
                                    ) &&
                                      moduloProcessos.capitulos?.find(
                                        (c) => c._id === filtros.processo
                                      ) && (
                                        <>
                                          {
                                            moduloProcessos.areas?.find(
                                              (a) => a._id === filtros.area
                                            )?.description
                                          }{" "}
                                          {">"}{" "}
                                          {
                                            moduloProcessos.capitulos?.find(
                                              (c) => c._id === filtros.processo
                                            )?.description
                                          }{" "}
                                          {">"}{" "}
                                          {moduloProcessos.capitulos?.find(
                                            (c) => c._id === filtros.subprocesso
                                          ) &&
                                            moduloProcessos.capitulos?.find(
                                              (c) =>
                                                c._id === filtros.subprocesso
                                            )?.description}
                                        </>
                                      )}
                                  </>
                                )}
                              </p>
                            </ContainerFicha>
                          )}
                        </Col>
                      </Row>
                      <br />
                      <br />
                      {filtros.processo && (
                        <ContainerFicha>
                          <h2
                            style={{
                              pageBreakBefore: "always",
                            }}
                          >
                            Processo de tratamento de dados pessoais
                          </h2>
                          <hr />
                          <Titulares />
                        </ContainerFicha>
                      )}
                      <br />
                      <br />

                      {/* historico */}
                      <ContainerFicha>
                        <h2
                          style={{
                            pageBreakBefore: "always",
                          }}
                        >
                          Histórico
                        </h2>
                        <hr />
                        <TimelineGeral
                          logs={logs}
                          mapeamentoId={filtros.mapeamento}
                          loadingLogs={loadingLogs}
                        />
                      </ContainerFicha>

                      <style>{`
            @media print {
              .print-container {
                margin: 1cm; /* Adicione as margens desejadas aqui */
              }
            }
          `}</style>
                    </div>
                  )}
                </div>
              ),
            },
            // {
            //   key: "2",
            //   label: "Plano de Ação (12 itens)",
            //   children: <></>,
            // },
            // {
            //   key: "3",
            //   label: "Visão em árvore",
            //   children: (
            //     <div>
            //       <TreeHierarchyGraph
            //         treeData={[
            //           {
            //             name: "Nome da empresa",
            //             children: [
            //               {
            //                 name: "Tecnologia da Informação",
            //                 children: [
            //                   {
            //                     name: "Processo 1",
            //                     attributes: {
            //                       Department: "Production",
            //                       isFullTime: true,
            //                       weeklyHours: 80,
            //                       teste: () => <h1>teste</h1>,
            //                     },
            //                   },

            //                   {
            //                     name: "Processo 2",
            //                   },
            //                 ],
            //               },
            //               {
            //                 name: "Recursos Humanos",
            //                 children: [
            //                   {
            //                     name: "Processo 1",
            //                   },
            //                   {
            //                     name: "Processo 2",
            //                     children: [
            //                       {
            //                         name: "Subprocesso 1",
            //                       },
            //                       {
            //                         name: "Subprocesso 2",
            //                       },
            //                     ],
            //                   },
            //                 ],
            //               },
            //             ],
            //           },
            //         ]}
            //       />
            //     </div>
            //   ),
            // },
          ]}
        />
      )}
      {!moduloMapeamento.questionarios?.find(
        (quest: any) => quest.nome === "Assessment Process"
      ) && (
        <Alert type="warning" message="Nenhum mapeamento aplicado" showIcon />
      )}
    </div>
  );
};

export default Fichas;
