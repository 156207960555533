import React, { useState } from "react";
import "./styles.css";
import { Button, Collapse, Modal, Tag } from "antd";
import { GlobalContextType } from "../../../../../../@types/global";
import { GlobalContext } from "../../../../../../context/globalContext";

import { ModalImportFornecedoresTypes } from "../../../../../../@types/modalFornecedores";

const ModalImportar: React.FC<ModalImportFornecedoresTypes> = ({
  showImport,
  setShowImport,
  importFile,
  setImportFile,
  clientId,
  importQuestionario,
}) => {
  const { moduloMapeamento } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [loading, setLoading] = useState<boolean>(false);

  const salvaAreaImportada = async () => {
    console.log(importQuestionario);
    setLoading(true);
    moduloMapeamento
      .postImportTemplate(importQuestionario)
      .then((res) => {
        res.status === 200 && setShowImport(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Modal
        title="Importar fornecedores"
        open={showImport}
        footer={[
          <Button
            type="default"
            form="form-pergunta"
            onClick={() => setShowImport(false)}
          >
            Cancelar
          </Button>,
          <Button
            loading={loading}
            onClick={salvaAreaImportada}
            type="primary"
            // disabled={importFornecedoresType === null ? true : false}
          >
            Salvar
          </Button>,
        ]}
        width={"90%"}
        onCancel={() => setShowImport(false)}
      >
        {importQuestionario && (
          <Collapse
            items={[
              {
                key: importQuestionario[0].value + "area",
                label: importQuestionario[0].value
                  .replace(/^\d+\.\s/, "")
                  .split(" - ")[0],
                children: (
                  <Collapse
                    items={importQuestionario.map((cap: any, index: number) => {
                      return {
                        key: index + "area",
                        label: cap.value
                          .replace(/^\d+\.\s/, "")
                          .split(" - ")[1],
                        children: cap.perguntas.map(
                          (perg: any, index: number) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                                className="perguntas-opt"
                              >
                                <h4
                                  style={{ width: "calc(100% - 11.5rem)" }}
                                  className="d-flex j-b a-c "
                                >
                                  {perg.value.split("?")[0]}?
                                </h4>
                                <div
                                  style={{
                                    width: "11.5rem",
                                    paddingLeft: "2rem",
                                  }}
                                >
                                  {perg.valor.find(
                                    (v: any) =>
                                      v.cp === "Conhecimento de processo"
                                  ) ? (
                                    <Tag
                                      color={"#fcba03"}
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Conhecimento de processo
                                    </Tag>
                                  ) : (
                                    perg.valor.map((valor: any) => (
                                      <div
                                        style={{
                                          display: "flex",
                                        }}
                                      >
                                        <p>{valor.option}:</p>
                                        <p
                                          style={{
                                            color: valor.value.includes("Não")
                                              ? "red"
                                              : "green",
                                            fontWeight: "bold",
                                            marginLeft: "0.5rem",
                                          }}
                                        >
                                          {valor.value}
                                        </p>
                                      </div>
                                    ))
                                  )}
                                </div>
                              </div>
                            );
                          }
                        ),
                      };
                    })}
                  />
                ),
              },
            ]}
          />
        )}
      </Modal>
    </>
  );
};

export default ModalImportar;
