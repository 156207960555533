import * as React from "react";
import "./styles.css";

import { Button } from "antd";
import { PdfViwerTypes } from "../../@types/pdfDownload";
import { PrinterOutlined } from "@ant-design/icons";
import { renderToString } from "react-dom/server";
import ReactToPrint from "react-to-print";

const PdfDownload: React.FC<PdfViwerTypes> = ({ content, title }) => {
  const [conteudo, setConteudo] = React.useState<any>();
  const componentRef = React.useRef<any>();

  // const printDocument = async () => {
  //   let iframe = document.createElement("iframe");
  //   iframe.style.visibility = "hidden";
  //   document.body.appendChild(iframe);
  //   // document.title = title || "Relatório";
  //   // @ts-ignore
  //   let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
  //   iframedoc.body.innerHTML = `<!DOCTYPE html><body >${renderToString(
  //     content
  //   )}</body></html>`;

  //   setConteudo(iframedoc.body);
  // };

  // React.useEffect(() => {
  //   printDocument();
  // }, []);
  return (
    <div>
      <div style={{ display: "none" }}>
        <div ref={componentRef}>{content}</div>{" "}
        {/* Referência ao componente para impressão */}
      </div>
      <ReactToPrint
        trigger={() => (
          <Button type="primary" icon={<PrinterOutlined />}>
            {title}
          </Button>
        )}
        content={() => componentRef.current}
      />
    </div>
  );
};
export default PdfDownload;
