import {
  Alert,
  Button,
  Collapse,
  DatePicker,
  Divider,
  message,
  Modal,
  Radio,
  Select,
  Spin,
  TimePicker,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { GlobalContextType } from "../../../@types/global";
import BackHeader from "../../../components/BackHeader/BackHeader";
import ReunioesCPPD from "../../../components/CPPD/ComitePrivacidade/ReunioesCPPD/reunioesCPPD";
import DadosEmpresa from "../../../components/CPPD/dadosEmpresa/Dados-empresa";
import { GlobalContext } from "../../../context/globalContext";
import Usuarios from "../Usuarios/Usuarios";

const dateFormat = "DD-MM-YYYY";
const timeFormat = "HH:mm";
const { Option } = Select;

const daysOfWeek = [
  { label: "Segunda-feira", value: 1 },
  { label: "Terça-feira", value: 2 },
  { label: "Quarta-feira", value: 3 },
  { label: "Quinta-feira", value: 4 },
  { label: "Sexta-feira", value: 5 },
  { label: "Sábado", value: 6 },
  { label: "Domingo", value: 0 },
];

const CPPD = () => {
  const {
    verificarCPPD,
    criarCPPD,
    userInfo,
    clientes,
    moduloTask,
    usuarios,
    currentCliente,
  } = useContext(GlobalContext) as GlobalContextType;
  const [cppdData, setCppdData] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSelecionada, setDataSelecionada] = useState(dayjs());
  const [horarioSelecionado, setHorarioSelecionado] = useState(
    dayjs("15:00", timeFormat)
  );
  const [diaSelecionado, setDiaSelecionado] = useState<number>(1);
  const [intervaloSelecionado, setIntervaloSelecionado] = useState(15);

  const checkCPPD = async () => {
    if (currentCliente._id) {
      const cppd = await verificarCPPD(currentCliente._id);
      setCppdData(cppd || null);
    }
  };

  useEffect(() => {
    currentCliente?._id && checkCPPD();
  }, [currentCliente, verificarCPPD]);

  useEffect(() => {
    if (userInfo.name && currentCliente?._id) {
      moduloTask?.fetchClienteTasks(currentCliente?._id);
    }
  }, [currentCliente, userInfo]);

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  const handleInstaurarComite = async () => {
    setLoading(true);
    setIsModalOpen(false);

    try {
      // Usa a data e hora diretamente, sem conversão
      const dataPadrao = dataSelecionada
        .day(diaSelecionado)
        .hour(horarioSelecionado.hour())
        .minute(horarioSelecionado.minute());

      const dataPrimeiraReuniao = dataSelecionada
        .hour(horarioSelecionado.hour())
        .minute(horarioSelecionado.minute());

      const intervalo = intervaloSelecionado;
      const link = "http://default-link.com";

      const response = await criarCPPD(
        currentCliente?._id!,
        userInfo._id,
        dataPadrao,
        dataPrimeiraReuniao,
        intervalo,
        link
      );

      if (response) {
        checkCPPD();
        message.success("CPPD criado com sucesso");
        currentCliente?._id &&
          moduloTask?.fetchClienteTasks(currentCliente?._id);
      }
    } catch (error) {
      message.error("Erro ao instaurar comitê.");
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date: any) => setDataSelecionada(date);
  const handleTimeChange = (time: any) => setHorarioSelecionado(time);
  const handleDayChange = (value: number) => setDiaSelecionado(value);
  const handleIntervalChange = (e: any) => {
    const valor = e.target.value as
      | "quinzenal"
      | "mensal"
      | "bimestral"
      | "trimestral";
    const intervalos = {
      quinzenal: 15,
      mensal: 30,
      bimestral: 60,
      trimestral: 90,
    };
    setIntervaloSelecionado(intervalos[valor]);
  };

  const gruposDoComite = [
    "65f1a790ff2de399bce35a8d",
    "66aa6aa01386fb3c3b13bafd",
    "66c61fe39810d75998778418",
    "66c61ff79810d75998778419",
  ];

  return (
    <>
      <BackHeader title="CPPD" prev />

      {currentCliente?._id ? (
        <>
          {loading && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <Spin size="large" />
            </div>
          )}
          <DadosEmpresa cppdId={currentCliente._id!} clientes={clientes} />

          <h3 className="font-bold mb-5">Membros</h3>
          {currentCliente._id && (
            <Collapse
              items={[
                {
                  label: "Visualizar",
                  children: (
                    <Usuarios cppd cliente_id={currentCliente?._id} noHeader />
                  ),
                },
              ]}
            />
          )}

          {/* {usuarios?.find(
            (usr: any) =>
              usr.cliente_id === currentCliente._id &&
              usr.grupos
                ?.map((g: any) => g._id)
                .some((grupo: string) => gruposDoComite.includes(grupo))
          ) ? ( */}
          <>
            {!cppdData ? (
              <div>
                <Divider />
                {!usuarios?.find(
                  (usr: any) =>
                    usr.cliente_id === currentCliente._id &&
                    usr.grupos
                      ?.map((g: any) => g._id)
                      .some((grupo: string) => gruposDoComite.includes(grupo))
                ) && (
                  <Alert
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                    type="warning"
                    message="Atenção! O CPPD será instaurado sem membros padrões."
                    showIcon
                  />
                )}

                <Button
                  type="primary"
                  size="large"
                  className="bg-violet-700 hover:text-purple-500"
                  onClick={showModal}
                >
                  Instaurar CPPD
                </Button>
                <Modal
                  title="Instaurar comitê"
                  open={isModalOpen}
                  onCancel={handleCancel}
                  okText="Adicionar"
                  onOk={handleInstaurarComite}
                  cancelText="Cancelar"
                >
                  <div className="mt-5">
                    <Typography.Title level={5}>Dia da semana</Typography.Title>
                    <Select
                      onChange={handleDayChange}
                      className="w-full"
                      placeholder="Selecione o dia da semana padrão"
                    >
                      {daysOfWeek.map((day) => (
                        <Option key={day.value} value={day.value}>
                          {day.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="mt-5">
                    <Typography.Title level={5}>Data inicial</Typography.Title>
                    <DatePicker
                      format={dateFormat}
                      onChange={handleDateChange}
                      className="w-full"
                      disabledDate={(current) => {
                        // Desabilita datas anteriores a hoje
                        const today = dayjs().startOf("day");
                        // Verifica se a data selecionada é anterior a hoje
                        if (current && current < today) {
                          return true;
                        }
                        // Desabilita dias que não são o dia da semana selecionado
                        return current && current.day() !== diaSelecionado;
                      }}
                    />
                  </div>
                  <div className="mt-5">
                    <Typography.Title level={5}>
                      Horário padrão
                    </Typography.Title>
                    <TimePicker
                      defaultValue={horarioSelecionado}
                      format={timeFormat}
                      onChange={handleTimeChange}
                      className="w-full"
                    />
                  </div>
                  <div className="mt-5">
                    <Typography.Title level={5} className="mb-2">
                      Intervalo das reuniões
                    </Typography.Title>
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={handleIntervalChange}
                    >
                      <Radio.Button value="quinzenal">Quinzenal</Radio.Button>
                      <Radio.Button value="mensal">Mensal</Radio.Button>
                      <Radio.Button value="bimestral">Bimestral</Radio.Button>
                      <Radio.Button value="trimestral">Trimestral</Radio.Button>
                    </Radio.Group>
                  </div>
                  <br />
                  <Alert
                    type="info"
                    showIcon
                    message="Se desejar alterar a data de uma reunião específica, você poderá fazê-lo mais tarde."
                  />
                  {!usuarios?.find(
                    (usr: any) =>
                      usr.cliente_id === currentCliente._id &&
                      usr.grupos
                        ?.map((g: any) => g._id)
                        .some((grupo: string) => gruposDoComite.includes(grupo))
                  ) && (
                    <Alert
                      style={{ marginTop: "1rem" }}
                      type="warning"
                      message="Atenção! O CPPD será instaurado sem membros padrões."
                      showIcon
                    />
                  )}
                  <br />
                </Modal>
              </div>
            ) : (
              <div className="mb-10">
                <h3 className="font-bold mb-5 mt-5">Linha do tempo</h3>
                <ReunioesCPPD
                  dataCriacao={cppdData.createdAt}
                  cppdId={cppdData._id}
                />
              </div>
            )}
          </>
          {/* ) : (
             <Alert
               style={{ marginTop: "2rem" }}
               type="warning"
               message="É necessário ao menos um usuário membro do comitê para instaurar CPPD"
               showIcon
             />
           )} */}
        </>
      ) : (
        <Alert
          type="warning"
          message="Selecione um cliente para visualizar!"
          showIcon
        />
      )}
    </>
  );
};

export default CPPD;
