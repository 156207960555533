import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { Table, Input, Button, Tag } from "antd";
import BackHeader from "../../../components/BackHeader/BackHeader";
import { GlobalContextType } from "../../../@types/global";
import { GlobalContext } from "../../../context/globalContext";
import NewCliente from "../../../components/NewCliente/NewCliente";
import { PlusOutlined } from "@ant-design/icons";

const { Search } = Input;

const Clientes = () => {
  const { clientes, userInfo, windowWidth } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [search, setSearch] = useState<string>("");
  const [showModal, setShowModal] = React.useState(false);

  const navigate = useNavigate();

  const columns = [
    {
      title: "Nome",
      dataIndex: "razao_social",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      render: (tipoId: any) =>
        tipoId === "64ef8783276b0e19e13f748e" && (
          <Tag color="#fa9623">Assinante</Tag>
        ),
    },
  ];

  const columnsSub = [
    {
      title: "Nome",
      dataIndex: "razao_social",
    },
  ];

  const columnsMobile = [
    {
      title: "Nome",
      dataIndex: "razao_social",
    },
  ];

  const ordenarClientes = (clientes: any, busca: string) => {
    if (!busca) return clientes; // Se não há busca, retorna os clientes como estão

    return clientes
      .map((cliente: any) => ({
        ...cliente,
        key: cliente._id,
        searchIndex: cliente.razao_social
          .toUpperCase()
          .indexOf(busca.toUpperCase()),
      }))
      .sort((a: any, b: any) => {
        // Ordena primeiramente pelo índice da busca (resultados mais próximos primeiro)
        if (a.searchIndex !== b.searchIndex) {
          return a.searchIndex - b.searchIndex;
        }
        // Se necessário, adicione aqui mais critérios de desempate
        return a.razao_social.localeCompare(b.razao_social); // Como desempate, usa a razão social
      });
  };

  const onSearch = (value: string) => setSearch(value);

  return (
    <div className="clientes">
      <BackHeader title="Clientes" />

      <div className="clientes__actions">
        <Search
          placeholder="Buscar cliente"
          className="clientes__actions--search"
          allowClear
          enterButton="Buscar"
          onSearch={onSearch}
        />
        <Button
          className="clientes__actions--new"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setShowModal(true)}
          style={{ marginLeft: "1rem" }}
        >
          Novo Cliente
        </Button>
      </div>
      {userInfo?.grupos?.find((gp: any) => gp.name === "seusdados") ? (
        <Table
          className="clientes__list"
          dataSource={ordenarClientes(clientes, search)?.filter(
            (c: any) => c.tipo === "64ef8783276b0e19e13f748e"
          )}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                navigate("/clientes/editar/" + record._id);
              },
            };
          }}
          defaultExpandAllRows
          columns={windowWidth > 800 ? columns : columnsMobile}
          pagination={{ pageSize: 10 }}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                className="clientes__list"
                style={{ marginLeft: "3rem" }}
                size="small"
                showHeader={false}
                dataSource={clientes?.filter(
                  (c: any) =>
                    c.razao_social
                      .toUpperCase()
                      .includes(search.toUpperCase()) &&
                    c.cliente_sup === record._id
                )}
                pagination={false}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      navigate("/clientes/editar/" + record._id);
                    },
                  };
                }}
                columns={columnsSub}
              />
            ),
          }}
        />
      ) : (
        <Table
          className="clientes__list"
          dataSource={clientes?.filter(
            (c: any) =>
              c.razao_social.toUpperCase().includes(search.toUpperCase()) &&
              c.tipo !== "64ef8783276b0e19e13f748e"
          )}
          size="small"
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                navigate("/clientes/editar/" + record._id);
              },
            };
          }}
          columns={windowWidth > 800 ? columns : columnsMobile}
          pagination={{ pageSize: 10 }}
        />
      )}

      <NewCliente setShowModal={setShowModal} showModal={showModal} />
    </div>
  );
};

export default Clientes;
