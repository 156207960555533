import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import "./styles.css";
import { Alert, Button, Dropdown, Table, Upload } from "antd";
import Search from "antd/es/input/Search";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import {
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileAddFilled,
  FileDoneOutlined,
  FileExcelFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import ModalSelectQuestionario from "./components/ModalEditar/ModalSelectQuestionario";
import moment from "moment";
import { MenuProps, Spin } from "antd";
import * as XLSX from "xlsx";
import ModalImportarCve from "./components/ModalImportarCve/ModalImportarCve";

interface ListQuestioarioProps {
  editCliente?: boolean;
  editCPPD?: boolean;
  editRIPD?: boolean;
  editLIA?: boolean;
}

const ListQuestinarios: React.FC<ListQuestioarioProps> = ({
  editCliente,
  editCPPD,
  editRIPD,
  editLIA,
}) => {
  const {
    moduloMapeamento,
    windowWidth,
    pageSize,
    userInfo,
    moduloTask,
    currentCliente,
  } = React.useContext(GlobalContext) as GlobalContextType;
  const [search, setSearch] = React.useState<string>("");
  const [loagingPlanoAcao, setLoagingPlanoAcao] =
    React.useState<boolean>(false);
  const [importFile, setImportFile] = React.useState<any>(null);
  const [showImport, setShowImport] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [showSelectQuestionario, setShowSelectQuestionario] =
    React.useState<boolean>(false);

  const handleShowSelectQuestionario = () => {
    showSelectQuestionario
      ? setShowSelectQuestionario(false)
      : setShowSelectQuestionario(true);
  };
  const params = useParams();

  const navigate = useNavigate();

  const columns = [
    {
      title: "Nome",
      dataIndex: "nome",
      render: (name: string, record: any, index: number) =>
        name.replace("Assessment Process", "Mapeamento de processos"),
    },
    {
      title: "Data criação",
      dataIndex: "created_at",
      render: (date: any) => (
        <p style={{ fontWeight: "bold" }}>
          {moment(new Date(date)).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Ações",
      key: "_id",
      render: (text: any, record: any) =>
        record.nome === "Assessment Process" ? (
          <>
            <Button
              type="default"
              onClick={() =>
                navigate(
                  "/mapeamentos/" +
                    (params.id || currentCliente._id) +
                    "/" +
                    record._id
                )
              }
              icon={<EyeOutlined />}
              style={{ marginRight: "1rem" }}
            >
              Ver
            </Button>
          </>
        ) : (
          <div style={{ minWidth: windowWidth > 800 ? "12rem" : "0" }}>
            <Button
              type="default"
              onClick={() =>
                navigate("/clientes/editar/" + params.id + "/ver/" + record._id)
              }
              icon={<EyeOutlined />}
              style={{ marginRight: "1rem" }}
            >
              Ver
            </Button>
            {windowWidth > 800 && !editCPPD && !editRIPD && !editLIA && (
              <Button
                onClick={() => {
                  navigate(
                    "/clientes/editar/" +
                      params.id +
                      "/questionario/" +
                      record._id
                  );
                }}
                icon={<EditOutlined />}
              >
                Editar
              </Button>
            )}
            {!editCPPD && !editRIPD && !editLIA && (
              <>
                {userInfo?.grupos?.find((gp: any) => gp.name === "assinante") &&
                moduloTask.tasks
                  .filter(
                    (task: any) =>
                      task.title !==
                      "Inclua respondentes e líderes nas áreas correspondentes do questionário PAM"
                  )
                  .find(
                    (task: any) => task.modelo_referencia_id === record._id
                  ) ? (
                  <Button
                    onClick={() => {
                      navigate("/tarefas");
                    }}
                    style={{ marginLeft: "1rem" }}
                    icon={<FileDoneOutlined />}
                  >
                    Ver plano de ação
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setLoagingPlanoAcao(true);
                      moduloTask
                        .createQuestionarioTasks(record._id)
                        .finally(() => {
                          setLoagingPlanoAcao(false);
                        });
                    }}
                    loading={loagingPlanoAcao}
                    style={{ marginLeft: "1rem" }}
                    icon={<FileAddFilled />}
                    type="primary"
                  >
                    Liberar plano de ação
                  </Button>
                )}
              </>
            )}
          </div>
        ),
    },
  ];

  const onSearch = (value: string) => setSearch(value);

  const handleImport = (file: any) => {
    setShowImport(true);

    const reader = new FileReader();

    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      reader.readAsArrayBuffer(file);
      reader.onload = (e: any) => {
        const workbook: any = XLSX.read(e.target.result, { type: "buffer" });
        const worksheetName = "RELATÓRIO CSA";
        const worksheet = workbook.Sheets[worksheetName];
        const data: any = XLSX.utils.sheet_to_json(worksheet);
        let headerRow: any = data[1]; // Assume que a segunda linha contém os nomes das colunas

        // Verifica se o primeiro item do headerRow possui o formato "__EMPTY_" e ajusta se necessário
        if (!headerRow["__EMPTY"]) {
          headerRow = Object.keys(headerRow).reduce((acc: any, key: string) => {
            acc[`__EMPTY_${key}`] = headerRow[key];
            return acc;
          }, {});
        }

        const newData = data.slice(2).map((item: any) => {
          const newItem: any = {};
          for (const key in headerRow) {
            if (Object.prototype.hasOwnProperty.call(headerRow, key)) {
              const columnName = headerRow[key].replace(" ", "_").toUpperCase();
              newItem[columnName] = item[key] || item[`__EMPTY_${key}`];
            }
          }
          return newItem;
        });
        setImportFile({ nome: data[0]["__EMPTY_6"], data: newData });
      };
    }

    return false;
  };

  React.useEffect(() => {
    if (currentCliente?._id) {
      setLoading(true);
      moduloMapeamento
        ?.getQuestionariosCliente(currentCliente._id)
        .finally(() => setLoading(false));
    }
  }, [currentCliente]);

  const items: MenuProps["items"] = [
    {
      label: (
        <Button
          href="https://arquivos-portal-seusdados.nyc3.cdn.digitaloceanspaces.com/modelo_importar_parceiros.xlsx"
          style={{ marginLeft: "-0.5rem", color: "#1677ff" }}
          size="small"
          type="link"
          icon={<DownloadOutlined />}
        >
          Baixar modelo
        </Button>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Upload
          showUploadList={false}
          beforeUpload={(file: any) => handleImport(file)}
          accept="text/csv,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        >
          <Button
            size="small"
            style={{ marginLeft: "-2.4rem", color: "#000" }}
            type="link"
            icon={<FileExcelFilled style={{ color: "green" }} />}
          >
            Enviar XLSX
          </Button>
        </Upload>
      ),
      key: "1",
    },
  ];

  return (
    <div className="listQuestionarios">
      {!editCPPD && !editRIPD && !editLIA && (
        <BackHeader title="Mapeamentos aplicados" />
      )}
      {params.id || currentCliente ? (
        loading ? (
          <div
            style={{
              width: "100%",
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin tip="Verificando questionários..." />
          </div>
        ) : (
          <>
            <div style={{ display: "flex" }}>
              <Search
                placeholder={"Buscar questionário"}
                allowClear
                enterButton={<SearchOutlined />}
                onSearch={onSearch}
                style={{ marginBottom: "1rem" }}
              />
              <Dropdown menu={{ items }} trigger={["click"]}>
                <Button
                  style={{ marginLeft: "2rem" }}
                  icon={<FileExcelFilled style={{ color: "green" }} />}
                >
                  Importar CVE
                </Button>
              </Dropdown>
              <Button
                type="primary"
                style={{
                  background: "green",
                  marginLeft: windowWidth > 800 ? "2rem" : "1rem",
                }}
                icon={<PlusOutlined />}
                onClick={handleShowSelectQuestionario}
              >
                Mapeamento
              </Button>
            </div>
            <Table
              size="small"
              dataSource={moduloMapeamento.questionarios
                ?.sort(
                  (a: any, b: any) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )
                ?.filter((q: any) => !q.nome?.includes("EXECUÇÃO"))
                ?.filter((q: any) => {
                  if (editCPPD) {
                    return (
                      q.cliente_id &&
                      q.nome ===
                        "Monitoramento da governança em proteção de dados pessoais"
                    );
                  } else if (editRIPD) {
                    return (
                      q.cliente_id && q.nome.toLowerCase().includes("ripd")
                    );
                  } else if (editLIA) {
                    return (
                      q.cliente_id &&
                      q.nome.toLowerCase().includes("legítimo interesse")
                    );
                  } else {
                    return true;
                  }
                })
                ?.filter(
                  (q: any) =>
                    (q.cliente_id?.toString() === params.id ||
                      q.cliente_id?.toString() === currentCliente?._id ||
                      (!currentCliente?._id && true)) &&
                    q.nome.toUpperCase().includes(search.toUpperCase())
                )}
              pagination={{ pageSize }}
              columns={columns}
            />
            {(params.id || currentCliente?._id) && (
              <ModalSelectQuestionario
                showModal={showSelectQuestionario}
                handleShowModal={handleShowSelectQuestionario}
                clientId={params.id || currentCliente?._id}
              />
            )}
            <ModalImportarCve
              clientId={params.id}
              importFile={importFile}
              setImportFile={setImportFile}
              setShowImport={setShowImport}
              showImport={showImport}
            />
          </>
        )
      ) : (
        <Alert
          type="warning"
          message="Selecione um cliente para visualizar!"
          showIcon
        />
      )}
    </div>
  );
};

export default ListQuestinarios;
