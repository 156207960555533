import { useContext } from "react";
import api from "../services/api";
import { message } from "antd";
import { GlobalContext } from "./globalContext";

export type CsaContextType = {
  getResultadosCsa: (anexoId: string) => Promise<any>;
};

export const useCsaContext = (): CsaContextType => {
  const globalContext = useContext(GlobalContext);

  const getResultadosCsa = (anexoId: string) => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/resultados/csa/${anexoId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao buscar resultados!");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  return {
    getResultadosCsa,
  };
};
