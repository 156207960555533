import React from "react";
import "./styles.css";
import LogoSeusdados from "../../../../../../../assets/images/LogoSeusDados.png";
import { GlobalContextType } from "../../../../../../../@types/global";
import { GlobalContext } from "../../../../../../../context/globalContext";

import moment from "moment";
import { Scatter, ScatterChart, XAxis, YAxis } from "recharts";
import { Col, Row } from "antd";

interface RelatorioRIPDProps {
  questId: string;
  versaoId: string;
  pontuacaoMaxima: number;
  porcentagemConforme: number;
  matrizRisco: any;
  questionario: any;
  currentRespostas: any;
}

interface TabelaProps {
  numeroColunas: number;
  numeroLinhas: number;
}

const RelatorioRIPD: React.FC<RelatorioRIPDProps> = ({
  questId,
  versaoId,
  pontuacaoMaxima,
  porcentagemConforme,
  matrizRisco,
  questionario,
  currentRespostas,
}) => {
  const { usuarios } = React.useContext(GlobalContext) as GlobalContextType;

  const GridMatrizrisco: React.FC<TabelaProps> = ({
    numeroColunas,
    numeroLinhas,
  }) => {
    // Função para verificar se a célula deve ter background verde
    const shouldHaveGreenBackground = (
      linha: number,
      coluna: number
    ): boolean => {
      // Defina aqui as coordenadas das células que devem ter background verde
      const celulasVerdes = [
        { linha: 2, coluna: 1 },
        { linha: 3, coluna: 1 },
        { linha: 3, coluna: 2 },
        { linha: 4, coluna: 1 },
        { linha: 4, coluna: 2 },
        { linha: 4, coluna: 3 },
      ];
      return celulasVerdes.some(
        (c) => c.linha === linha && c.coluna === coluna
      );
    };

    // Função para verificar se a célula deve ter background laranja
    const shouldHaveOrangeBackground = (
      linha: number,
      coluna: number
    ): boolean => {
      // Defina aqui as coordenadas das células que devem ter background laranja
      const celulasLaranjas = [
        { linha: 1, coluna: 1 },
        { linha: 2, coluna: 2 },
        { linha: 3, coluna: 3 },
        { linha: 4, coluna: 4 },
      ];
      return celulasLaranjas.some(
        (c) => c.linha === linha && c.coluna === coluna
      );
    };

    // Função para criar as células da tabela
    const renderCelulas = () => {
      let celulas: JSX.Element[] = [];
      for (let i = 0; i < numeroLinhas; i++) {
        let linha: JSX.Element[] = [];

        for (let j = 0; j < numeroColunas; j++) {
          const isCelulaVerde = shouldHaveGreenBackground(i + 1, j + 1); // Linha e coluna baseadas em 1-index
          const isCelulaLaranja = shouldHaveOrangeBackground(i + 1, j + 1); // Linha e coluna baseadas em 1-index

          linha.push(
            <div
              key={`celula-${i}-${j}`}
              className="celula"
              style={{
                backgroundColor: isCelulaVerde
                  ? "#33CCFF"
                  : isCelulaLaranja
                  ? "#4C72E3"
                  : "#5F29CC",
              }}
            />
          );
        }
        celulas.push(
          <div key={`linha-${i}`} className="linha">
            {linha}
          </div>
        );
      }
      return celulas;
    };

    return <div className="tabela">{renderCelulas()}</div>;
  };

  // Função para formatar os ticks e exibir apenas inteiros nos rótulos dos eixos
  const formatTick = (tick: any) => {
    if (tick % 1 === 0) {
      // Exibir apenas inteiros
      return tick.toString();
    }
    return ""; // Ocultar valores fracionários
  };

  const truncateDescription = (description: any, maxLength = 15) => {
    if (description?.length > maxLength) {
      return description.slice(0, maxLength) + "...";
    }
    return description;
  };

  function encontrarIndicePorValor(valorProcurado: any) {
    const capitulosQuestionario = questionario.capitulos?.map(
      (c: any) => c._id
    );

    // Contador global de índice
    let contadorGlobal = 0;

    const indicesPorCapituloEncontrados = capitulosQuestionario.map(
      (capId: string) => {
        const indicesEncontrados = questionario?.perguntasRelacao
          ?.filter(
            (r: any) => r.pergunta?.description && r?.capitulo_id === capId
          )
          ?.sort((a: any, b: any) => {
            // Se ambos 'ordem' estão definidos, ordena por 'ordem'
            if (a.pergunta.ordem && b.pergunta.ordem) {
              return a.pergunta.ordem - b.pergunta.ordem;
            }

            // Se 'ordem' não está definido, ordena por 'created_at'
            return (
              new Date(a.pergunta.created_at).getTime() -
              new Date(b.pergunta.created_at).getTime()
            );
          })
          ?.map((objeto: any, indice: number) => {
            contadorGlobal++; // Incrementa o contador global

            if (objeto === valorProcurado) {
              return contadorGlobal;
            }
            return null;
          });

        return indicesEncontrados;
      }
    );

    const indicesValidos = indicesPorCapituloEncontrados
      ?.flat()
      .filter((indice: number) => indice !== null);

    return indicesValidos;
  }

  const CustomizedShape = (props: any) => {
    const { cx, cy, payload } = props;
    const { risco } = payload;

    const itensMatriz = matrizRisco
      ?.filter((item: any) => item.risco === risco)
      ?.filter(
        (item: any, index: number, self: any) =>
          index ===
          self.findIndex((i: any) => i.pergunta._id === item.pergunta._id)
      )
      ?.map((item: any) =>
        encontrarIndicePorValor(
          questionario?.perguntasRelacao?.find(
            (r: any) => r.pergunta._id === item.pergunta._id
          )
        )
      );

    return (
      <g>
        <g transform={`translate(${cx},${cy})`}>
          <text
            onClick={() => console.log(payload)}
            x={0}
            y={5}
            dy={0}
            textAnchor="middle"
            fontSize={10}
          >
            {itensMatriz.length > 7 ? (
              <>
                <tspan x={0} y={-5} dy={0}>
                  {itensMatriz
                    .slice(0, Math.ceil(itensMatriz.length / 2))
                    .join(", ")}
                  ,
                </tspan>
                <tspan x={0} y={10} dy={0}>
                  {itensMatriz
                    .slice(Math.ceil(itensMatriz.length / 2))
                    .join(", ")}
                </tspan>
              </>
            ) : (
              itensMatriz.join(", ")
            )}
          </text>
        </g>
      </g>
    );
  };

  return (
    <div className="relatorio_ripd">
      <div className="capa" style={{ pageBreakAfter: "always" }}>
        <div className="relatorio__ripd-title">
          <h1>RIPD</h1>
          <h3>EXTRATO</h3>
        </div>
        <div className="bloco_info">
          <div className="bloco_info--box">
            <Row>
              <Col span="16">
                <p>
                  GESTOR DO PROJETO:{" "}
                  <span>
                    {
                      usuarios?.find((user: any) =>
                        user?.funcoesDetalhadas?.find(
                          (funcao: any) =>
                            funcao?.funcao_id.name ===
                              "responder-questionario" &&
                            funcao?.reference_model_id === questionario._id
                        )
                      )?.name
                    }
                  </span>
                </p>
              </Col>
              <Col span="6">
                <p>
                  DATA RIPD:{" "}
                  <span>
                    {moment(new Date(questionario.created_at)).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <br />
        <div className="bloco_info">
          <h5>RESUMO DO PROJETO</h5>
          <div className="bloco_info--box" style={{ height: "8rem" }}>
            <p>
              {
                currentRespostas.find(
                  (r: any) => r.pergunta_id === "66f1b63c1293aaa882fa8ada" // Pergunta: Descreva o projeto
                )?.value?.[0]
              }
            </p>
          </div>
        </div>
        <br />
        <Row>
          <Col span={12}>
            <div className="bloco_info">
              <h5>ÁREAS ENVOLVIDAS</h5>
              <div className="bloco_info--box" style={{ height: "14rem" }}>
                <ul>
                  {currentRespostas
                    .find(
                      (r: any) =>
                        r.pergunta_id === "66f1b9f51293aaa882fa9d8d" ||
                        r.pergunta_id === "66f1bc4882d6e6625581476f" // Pergunta: Quais são as áreas da sua organização envolvidas no projeto? (Educação ou Saúde)
                    )
                    ?.respostas?.map((r: string) => (
                      <li>{r}</li>
                    ))}
                </ul>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="bloco_info">
              <h5>FLUXO DE DESENVOLVIMENTO</h5>
              <div className="bloco_info--box" style={{ height: "14rem" }}>
                <p>
                  {" "}
                  {
                    currentRespostas.find(
                      (r: any) => r.pergunta_id === "66f1bcaf82d6e66255814773" // Pergunta: Fluxo de desenvolvimento
                    )?.value?.[0]
                  }
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <div
              className="rows_chart--col2__nivel"
              style={{
                transform: "scale(1) translateY(20px)",
                width: 50,
                height: 200,
                marginLeft: "1rem",
                background:
                  "rgb(0,255,8) linear-gradient(180deg, #5F29CC 0%, #4C72E3 50%,  #33CCFF 100%)",
              }}
            >
              <p
                style={{
                  position: "relative",
                  top: 90 + "px",
                  marginTop: "-29px",
                  left: "78px",
                  color:
                    porcentagemConforme > 33 && porcentagemConforme < 66
                      ? "#4C72E3"
                      : porcentagemConforme >= 66
                      ? "#33CCFF"
                      : "#5F29CC",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  width: "8rem",
                }}
                translate="no"
              >
                {porcentagemConforme >= 66 && "ALTA"}
                {porcentagemConforme > 33 &&
                  porcentagemConforme < 66 &&
                  "MÉDIO"}
                {porcentagemConforme <= 33 && "BAIXA"}
                <span
                  style={{
                    position: "relative",
                    fontSize: "0.8rem",
                  }}
                  translate="no"
                >
                  <br />
                  {porcentagemConforme.toFixed(2)} pontos
                </span>
              </p>
              <p
                style={{
                  marginTop: "-82px",
                  marginLeft: "-10px",
                }}
                translate="no"
              >
                Maturidade
              </p>
              <div
                style={{
                  width: "70px",
                  height: "2px",
                  marginTop: "0px",
                  background: "#000",
                  opacity: 0.3,
                  marginLeft: "-10px",
                }}
              />
              <div
                style={{
                  width: "70px",
                  height: "2px",
                  background: "#000",
                  marginLeft: "-10px",
                  marginTop: `calc(${100 - porcentagemConforme}px*2)`,
                }}
              >
                <div className="arrow right" style={{ top: "-3.5px" }} />
                <div
                  className="arrow left"
                  style={{ top: "-12.9px", left: "63px" }}
                />
              </div>
            </div>
          </Col>
          <Col span={18}>
            <div
              style={{
                transform: "scale(0.9) translateY(-100px) translateX(-10px)",
              }}
            >
              <div
                style={{
                  width: "600px",
                  height: "300px",
                  position: "relative",
                  marginTop: "-30%",
                  // bottom: "-50%",
                  // left: "50%",
                  transform: "translate(0, 106.5%)",
                  zIndex: 0,
                }}
              >
                <GridMatrizrisco numeroColunas={4} numeroLinhas={4} />
              </div>
              <ScatterChart
                width={600}
                height={300}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <XAxis
                  label={{
                    value: "Probabilidade",
                    position: "bottom",
                    offset: 0,
                  }}
                  type="number"
                  dataKey="probabilidade"
                  name="Probabilidade"
                  ticks={[-0.5, 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5]}
                  domain={[0, 3]}
                  tickFormatter={formatTick}
                />
                <YAxis
                  label={{
                    value: "Impacto",
                    angle: -90,
                    position: "left",
                  }}
                  type="number"
                  dataKey="impacto"
                  ticks={[-0.5, 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5]}
                  domain={[0, 3]}
                  name="Impacto"
                  tickFormatter={formatTick}
                />
                <Scatter
                  fill="#000"
                  name="Matriz de Risco"
                  data={matrizRisco}
                  shape={<CustomizedShape />}
                  isAnimationActive={false}
                />
              </ScatterChart>
            </div>
          </Col>
        </Row>
        <img
          className="relatorio__seusdados-logo"
          src={LogoSeusdados}
          alt="logo"
        />
      </div>

      <div className="page2" style={{ pageBreakAfter: "always" }}>
        <div className="relatorio__ripd-title">
          <h1>RIPD</h1>
          <h3>EXTRATO</h3>
        </div>
        <div className="bloco_info">
          <h5>TRATAMENTO DE DADOS</h5>
          <div className="bloco_info--box" style={{ height: "25rem" }}>
            <ul>
              {currentRespostas
                .find(
                  (r: any) => r.pergunta_id === "66f2ffbd82d6e66255814790" // Pergunta: 25. Assinale todos os dados pessoais comuns que poderão ser tratados nesse desenvolvimento:
                )
                ?.respostas?.map((r: string) => (
                  <li>{r}</li>
                ))}
            </ul>
          </div>
        </div>
        <br />
        <div className="bloco_info">
          <h5>TRATAMENTO ALTO RISCO</h5>
          <div className="bloco_info--box" style={{ height: "25rem" }}>
            <ul>
              {currentRespostas
                .find(
                  (r: any) => r.pergunta_id === "66f3003e82d6e66255814794" // 26. Identifique todos os dados sensíveis que poderão ser tratados nesse desenvolvimento:
                )
                ?.respostas?.map((r: string) => (
                  <li>{r}</li>
                ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="page3" style={{ pageBreakAfter: "always" }}>
        <div className="relatorio__ripd-title">
          <h1>RIPD</h1>
          <h3>EXTRATO</h3>
        </div>
        <Row>
          <Col span={12}>
            <div className="bloco_info">
              <h5>RISCOS DE PRIVACIDADE</h5>
              <div className="bloco_info--box" style={{ height: "25rem" }}>
                <p>
                  {
                    currentRespostas.find(
                      (r: any) => r.pergunta_id === "66f6c7061293aaa8821e90ec" // Pergunta: Todos os dados tratados são estritamente indispensáveis
                    )?.value?.[0]
                  }
                </p>
                <br />
                <p>
                  {
                    currentRespostas.find(
                      (r: any) => r.pergunta_id === "66f6c77f1293aaa8821e9414" // Pergunta:  Identifique quais dos dados a serem tratados poderiam ser considerados dispensáveis para o objetivo que se pretende atingir?
                    )?.value?.[0]
                  }
                </p>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="bloco_info">
              <h5>RISCOS LEGAIS</h5>
              <div className="bloco_info--box" style={{ height: "25rem" }}>
                <p>Pergunta 44:</p>
                <ul style={{ marginLeft: "1rem" }}>
                  {currentRespostas
                    .find(
                      (r: any) => r.pergunta_id === "66f6f0241293aaa88220a144" // Pergunta 44
                    )
                    ?.value?.map((v: string) => (
                      <li>{truncateDescription(v, 50)}</li>
                    ))}
                </ul>
                <br />
                <p>Pergunta 45:</p>
                <ul style={{ marginLeft: "1rem" }}>
                  {currentRespostas
                    .find(
                      (r: any) => r.pergunta_id === "66f6f0ef1293aaa88220de99" // Pergunta 45
                    )
                    ?.value?.map((v: string) => (
                      <li>{truncateDescription(v, 50)}</li>
                    ))}
                </ul>
                <br />
                <p>Pergunta 46:</p>
                <ul style={{ marginLeft: "1rem" }}>
                  {currentRespostas
                    .find(
                      (r: any) => r.pergunta_id === "66f6f20f1293aaa88221f5be" // Pergunta 46
                    )
                    ?.value?.map((v: string) => (
                      <li>{truncateDescription(v, 50)}</li>
                    ))}
                </ul>
                <br />
                <p>Pergunta 47:</p>
                <ul style={{ marginLeft: "1rem" }}>
                  {currentRespostas
                    .find(
                      (r: any) => r.pergunta_id === "66f6f2641293aaa882226aa2" // Pergunta 47
                    )
                    ?.value?.map((v: string) => (
                      <li>{truncateDescription(v, 50)}</li>
                    ))}
                </ul>
                <br />
                {currentRespostas.find(
                  (r: any) => r.pergunta_id === "66f6f2e31293aaa882226e02" // Pergunta 48
                )?.value?.[0] && (
                  <>
                    <p>Pergunta 48:</p>
                    <ul style={{ marginLeft: "1rem" }}>
                      {currentRespostas
                        .find(
                          (r: any) =>
                            r.pergunta_id === "66f6f2e31293aaa882226e02" // Pergunta 48
                        )
                        ?.value?.map((v: string) => (
                          <li>{truncateDescription(v, 50)}</li>
                        ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <div className="bloco_info">
          <h5 style={{ width: "100%" }}>RISCOS DE SEGURANÇA</h5>
          <div className="bloco_info--box" style={{ height: "25rem" }}>
            <ol>
              <li>
                {currentRespostas
                  .find(
                    (r: any) => r.pergunta_id === "66fadd141293aaa8822df0b1" // Pergunta 49
                  )
                  ?.pergunta?.description?.replace(
                    "(sendo 0 = conforme; 1 = baixo risco; 2= médio risco e 3 alto risco)",
                    ""
                  )
                  ?.replace(
                    "(sendo 0 = conforme, 1 = baixo risco, 2 médio risco, 3 alto risco)",
                    ""
                  )}
                <ul style={{ marginLeft: "1rem" }}>
                  {currentRespostas
                    .find(
                      (r: any) => r.pergunta_id === "66fadd141293aaa8822df0b1" // Pergunta 49
                    )
                    ?.value?.map((v: string) => (
                      <li>{truncateDescription(v, 50)}</li>
                    ))}
                </ul>
              </li>
              <li>
                {currentRespostas
                  .find(
                    (r: any) => r.pergunta_id === "66fadd7c1293aaa8822df443" // Pergunta 50
                  )
                  ?.pergunta?.description?.replace(
                    "(sendo 0 = conforme; 1 = baixo risco; 2= médio risco e 3 alto risco)",
                    ""
                  )
                  ?.replace(
                    "(sendo 0 = conforme, 1 = baixo risco, 2 médio risco, 3 alto risco)",
                    ""
                  )}
                <ul style={{ marginLeft: "1rem" }}>
                  {currentRespostas
                    .find(
                      (r: any) => r.pergunta_id === "66fadd7c1293aaa8822df443" // Pergunta 50
                    )
                    ?.value?.map((v: string) => (
                      <li>{truncateDescription(v, 50)}</li>
                    ))}
                </ul>
              </li>
              <li>
                {currentRespostas
                  .find(
                    (r: any) => r.pergunta_id === "66fadde91293aaa8822df7d9" // Pergunta 51
                  )
                  ?.pergunta?.description?.replace(
                    "(sendo 0 = conforme; 1 = baixo risco; 2= médio risco e 3 alto risco)",
                    ""
                  )
                  ?.replace(
                    "(sendo 0 = conforme, 1 = baixo risco, 2 médio risco, 3 alto risco)",
                    ""
                  )}
                <ul style={{ marginLeft: "1rem" }}>
                  {currentRespostas
                    .find(
                      (r: any) => r.pergunta_id === "66fadde91293aaa8822df7d9" // Pergunta 51
                    )
                    ?.value?.map((v: string) => (
                      <li>{truncateDescription(v, 50)}</li>
                    ))}
                </ul>
              </li>
              <li>
                {currentRespostas
                  .find(
                    (r: any) => r.pergunta_id === "66fade481293aaa8822e26a2" // Pergunta 52
                  )
                  ?.pergunta?.description?.replace(
                    "(sendo 0 = conforme; 1 = baixo risco; 2= médio risco e 3 alto risco)",
                    ""
                  )
                  ?.replace(
                    "(sendo 0 = conforme, 1 = baixo risco, 2 médio risco, 3 alto risco)",
                    ""
                  )}
                <ul style={{ marginLeft: "1rem" }}>
                  {currentRespostas
                    .find(
                      (r: any) => r.pergunta_id === "66fade481293aaa8822e26a2" // Pergunta 52
                    )
                    ?.value?.map((v: string) => (
                      <li>{truncateDescription(v, 50)}</li>
                    ))}
                </ul>
              </li>
              <li>
                {currentRespostas
                  .find(
                    (r: any) => r.pergunta_id === "66faded01293aaa8822e2a40" // Pergunta 53
                  )
                  ?.pergunta?.description?.replace(
                    "(sendo 0 = conforme; 1 = baixo risco; 2= médio risco e 3 alto risco)",
                    ""
                  )
                  ?.replace(
                    "(sendo 0 = conforme, 1 = baixo risco, 2 médio risco, 3 alto risco)",
                    ""
                  )}
                <ul style={{ marginLeft: "1rem" }}>
                  {currentRespostas
                    .find(
                      (r: any) => r.pergunta_id === "66faded01293aaa8822e2a40" // Pergunta 53
                    )
                    ?.value?.map((v: string) => (
                      <li>{truncateDescription(v, 50)}</li>
                    ))}
                </ul>
              </li>
              <li>
                {currentRespostas
                  .find(
                    (r: any) => r.pergunta_id === "66fadf3f1293aaa8822e2de2" // Pergunta 54
                  )
                  ?.pergunta?.description?.replace(
                    "(sendo 0 = conforme; 1 = baixo risco; 2= médio risco e 3 alto risco)",
                    ""
                  )
                  ?.replace(
                    "(sendo 0 = conforme, 1 = baixo risco, 2 médio risco, 3 alto risco)",
                    ""
                  )}
                <ul style={{ marginLeft: "1rem" }}>
                  {currentRespostas
                    .find(
                      (r: any) => r.pergunta_id === "66fadf3f1293aaa8822e2de2" // Pergunta 54
                    )
                    ?.value?.map((v: string) => (
                      <li>{truncateDescription(v, 50)}</li>
                    ))}
                </ul>
              </li>
              <li>
                {currentRespostas
                  .find(
                    (r: any) => r.pergunta_id === "66fadffa1293aaa8822e3188" // Pergunta 55
                  )
                  ?.pergunta?.description?.replace(
                    "(sendo 0 = conforme; 1 = baixo risco; 2= médio risco e 3 alto risco)",
                    ""
                  )
                  ?.replace(
                    "(sendo 0 = conforme, 1 = baixo risco, 2 médio risco, 3 alto risco)",
                    ""
                  )}
                <ul style={{ marginLeft: "1rem" }}>
                  {currentRespostas
                    .find(
                      (r: any) => r.pergunta_id === "66fadffa1293aaa8822e3188" // Pergunta 55
                    )
                    ?.value?.map((v: string) => (
                      <li>{truncateDescription(v, 50)}</li>
                    ))}
                </ul>
              </li>
            </ol>
            <br />
          </div>
        </div>
      </div>

      <div className="page4" style={{ pageBreakAfter: "always" }}>
        <div className="relatorio__ripd-title">
          <h1>RIPD</h1>
          <h3>EXTRATO</h3>
        </div>
        <div className="bloco_info">
          <h5>PLANOS DE AÇÃO</h5>
          <div className="bloco_info--box" style={{ height: "8rem" }}></div>
        </div>
        <br />
        <div className="bloco_info">
          <h5>PARECER DPO</h5>
          <div className="bloco_info--box" style={{ height: "8rem" }}></div>
        </div>
      </div>
    </div>
  );
};

export default RelatorioRIPD;
