import React, { useEffect } from "react";

import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import { useParams } from "react-router-dom";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import OWASP from "../OWASP/OWASP";

const OwaspCliente = () => {
  const { getAnexosOwasp } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const params = useParams();

  useEffect(() => {
    params.id && getAnexosOwasp(params.id);
  }, [params.id]);

  return (
    <div>
      <OWASP />
    </div>
  );
};

export default OwaspCliente;
