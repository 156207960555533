import { useParams, useNavigate } from "react-router-dom";
import "./styles.css";
import { Button } from "antd";
import intro3 from "../../../../../assets/images/intro3.png";
import { ArrowRightOutlined } from "@ant-design/icons";

const Introducao3 = () => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div className="intro">
      <img src={intro3} alt="Seusdados" />
      <Button
        icon={<ArrowRightOutlined />}
        className="btn-next"
        onClick={() =>
          navigate(
            `/formulario/4/${params.id}/${params.fornecedorid}/${params.questionarioid}`
          )
        }
      >
        Avançar
      </Button>
    </div>
  );
};

export default Introducao3;
