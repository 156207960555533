import React, { useState } from "react";
import { Modal, Button, DatePicker, Alert, Form, Input } from "antd";
import moment from "moment";

interface CriarReuniaoExtraModalProps {
  isVisible: boolean;
  onClose: () => void;
  onCreate: (selectedDate: any, title: string) => void;
  loading: boolean; // Adicionei o loading aqui para ser passado como prop
}

const CriarReuniaoExtraModal: React.FC<CriarReuniaoExtraModalProps> = ({
  isVisible,
  onClose,
  onCreate,
  loading,
}) => {
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [title, setTitle] = useState<string>("");

  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };
  return (
    <Modal
      title="Criar Reunião Extraordinária"
      open={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          disabled={selectedDate && title ? false : true}
          key="submit"
          type="primary"
          onClick={() => onCreate(selectedDate, title)}
          loading={loading}
        >
          Criar agendamento
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <br />
        <Form.Item label="Título da reunião">
          <Input onChange={(v) => setTitle(v.target.value)} />
        </Form.Item>
        <Form.Item label="Data/Horário do agendamento">
          <DatePicker
            format="DD/MM/YYYY HH:mm"
            showTime
            onChange={(dt) => {
              setSelectedDate(dt);
            }}
            style={{ width: 200 }}
            value={selectedDate}
            disabledDate={disabledDate}
            placeholder="Selecione..."
          />
        </Form.Item>
      </Form>
      <div />
      <br />
      <Alert
        type="info"
        message="Os participantes podem ser adicionado posteriormente."
        showIcon
      />
      <br />
    </Modal>
  );
};

export default CriarReuniaoExtraModal;
