import BackHeader from "../../../components/BackHeader/BackHeader";
import ListQuestinarios from "../../Apps/Questionarios/ListQuestionarios/ListQuestionarios";

const QuestionariosLIA = () => {
  return (
    <div className="questionarios_LIA">
      <BackHeader title="Aplicações Balanceamento do Legítimo Interesse (LIA)" />
      <ListQuestinarios editLIA />
    </div>
  );
};

export default QuestionariosLIA;
