import React from "react";
import "./styles.css";
import CapaRelBlock from "../../../../../../../assets/images/CapaRelBlock.jpeg";
import LogoSeusdados from "../../../../../../../assets/images/LogoSeusDados.png";
import NotaMaturidade from "../../../../../../../assets/images/nota_maturidade.png";
import NotaMaturidadeExemplo from "../../../../../../../assets/images/nota_maturidade_exemplo.png";
import { GlobalContextType } from "../../../../../../../@types/global";
import { GlobalContext } from "../../../../../../../context/globalContext";
import {
  CompassOutlined,
  MailOutlined,
  PhoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Scatter, ScatterChart, XAxis, YAxis } from "recharts";
import { Checkbox, Space } from "antd";

interface RelatorioMaturidadeProps {
  questId: string;
  versaoId: string;
  pontuacaoMaxima: number;
  porcentagemConforme: number;
  matrizRisco: any;
  questionario: any;
  currentRespostas: any;
}

interface TabelaProps {
  numeroColunas: number;
  numeroLinhas: number;
}

const RelatorioMaturidade: React.FC<RelatorioMaturidadeProps> = ({
  questId,
  versaoId,
  pontuacaoMaxima,
  porcentagemConforme,
  matrizRisco,
  questionario,
  currentRespostas,
}) => {
  const { moduloMapeamento, moduloProcessos, clientes } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const GridMatrizrisco: React.FC<TabelaProps> = ({
    numeroColunas,
    numeroLinhas,
  }) => {
    // Função para verificar se a célula deve ter background verde
    const shouldHaveGreenBackground = (
      linha: number,
      coluna: number
    ): boolean => {
      // Defina aqui as coordenadas das células que devem ter background verde
      const celulasVerdes = [
        { linha: 2, coluna: 1 },
        { linha: 3, coluna: 1 },
        { linha: 3, coluna: 2 },
        { linha: 4, coluna: 1 },
        { linha: 4, coluna: 2 },
        { linha: 4, coluna: 3 },
      ];
      return celulasVerdes.some(
        (c) => c.linha === linha && c.coluna === coluna
      );
    };

    // Função para verificar se a célula deve ter background laranja
    const shouldHaveOrangeBackground = (
      linha: number,
      coluna: number
    ): boolean => {
      // Defina aqui as coordenadas das células que devem ter background laranja
      const celulasLaranjas = [
        { linha: 1, coluna: 1 },
        { linha: 2, coluna: 2 },
        { linha: 3, coluna: 3 },
        { linha: 4, coluna: 4 },
      ];
      return celulasLaranjas.some(
        (c) => c.linha === linha && c.coluna === coluna
      );
    };

    // Função para criar as células da tabela
    const renderCelulas = () => {
      let celulas: JSX.Element[] = [];
      for (let i = 0; i < numeroLinhas; i++) {
        let linha: JSX.Element[] = [];

        for (let j = 0; j < numeroColunas; j++) {
          const isCelulaVerde = shouldHaveGreenBackground(i + 1, j + 1); // Linha e coluna baseadas em 1-index
          const isCelulaLaranja = shouldHaveOrangeBackground(i + 1, j + 1); // Linha e coluna baseadas em 1-index

          linha.push(
            <div
              key={`celula-${i}-${j}`}
              className="celula"
              style={{
                backgroundColor: isCelulaVerde
                  ? "#33CCFF"
                  : isCelulaLaranja
                  ? "#4C72E3"
                  : "#5F29CC",
              }}
            />
          );
        }
        celulas.push(
          <div key={`linha-${i}`} className="linha">
            {linha}
          </div>
        );
      }
      return celulas;
    };

    return <div className="tabela">{renderCelulas()}</div>;
  };

  // Função para formatar os ticks e exibir apenas inteiros nos rótulos dos eixos
  const formatTick = (tick: any) => {
    if (tick % 1 === 0) {
      // Exibir apenas inteiros
      return tick.toString();
    }
    return ""; // Ocultar valores fracionários
  };

  function encontrarIndicePorValor(valorProcurado: any) {
    // Utilize a função map para percorrer o array
    // A função map retorna um novo array com os índices dos objetos que correspondem ao valor procurado
    const indicesEncontrados = questionario.perguntasRelacao
      ?.filter((r: any) => r.pergunta?.description)
      .sort(
        (a: any, b: any) =>
          new Date(a.pergunta.created_at).getTime() -
          new Date(b.pergunta.created_at).getTime()
      )
      .map((objeto: any, indice: number) => {
        if (objeto === valorProcurado) {
          return indice + 1;
        }
        return null; // Retorna null para os objetos que não correspondem ao valor procurado
      });

    // Filtra os índices válidos (diferentes de null)
    const indicesValidos = indicesEncontrados.filter(
      (indice: number) => indice !== null
    );

    return indicesValidos;
  }

  const CustomizedShape = (props: any) => {
    const { cx, cy, payload } = props;
    const { risco } = payload;

    const itensMatriz = matrizRisco
      ?.filter((item: any) => item.risco === risco)
      ?.map(
        (item: any) =>
          questionario?.perguntasRelacao
            ?.sort(
              (a: any, b: any) =>
                new Date(a.pergunta.created_at).getTime() -
                new Date(b.pergunta.created_at).getTime()
            )
            ?.findIndex((r: any) => r.pergunta._id === item.pergunta._id) + 1
      );
    return (
      <g>
        {/* <Dot cx={cx} cy={cy} r={5} width={3} height={3} fill={fill} /> */}
        <g transform={`translate(${cx},${cy})`}>
          <text
            onClick={() => console.log(payload)}
            x={0}
            y={5}
            dy={0}
            textAnchor="middle"
            fontSize={10}
          >
            {itensMatriz.length > 7 ? (
              <>
                <tspan x={0} y={-5} dy={0}>
                  {itensMatriz
                    .slice(0, Math.ceil(itensMatriz.length / 2))
                    .join(", ")}
                  ,
                </tspan>
                <tspan x={0} y={10} dy={0}>
                  {itensMatriz
                    .slice(Math.ceil(itensMatriz.length / 2))
                    .join(", ")}
                </tspan>
              </>
            ) : (
              itensMatriz.join(", ")
            )}
          </text>
        </g>
      </g>
    );
  };

  return (
    <div className="relatorio_questionario">
      <div className="capa" style={{ pageBreakAfter: "always" }}>
        <h3>{"relatório de diagnóstico".toUpperCase()}</h3>
        <div className="linha_capa" />
        <h4 style={{ fontSize: "2.5rem", marginTop: "2rem" }}>
          Maturidade da governança em proteção de dados
        </h4>
        <h1 className="capa__title">
          <b>CPPD</b>
        </h1>
        <img alt="capa" src={CapaRelBlock} />
        <p>
          {moment(
            new Date(
              moduloMapeamento.questionarios
                ?.find((q) => q._id === questId)
                ?.versoes?.find((v: any) => v._id === versaoId).updated_at
            )
          ).format("YY")}
        </p>
      </div>

      <div className="page2" style={{ pageBreakAfter: "always" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
        <h1 className="title2">
          Para
          <br />
          <b>Começar</b>
        </h1>
        <div className="rows">
          <div className="col1">
            <p className="col1_title">PREPARADO POR:</p>
            <b>
              {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
            </b>
            <p className="col1_title">RESPONSÁVEL:</p>
            <b>MARCELO FATTORI</b>
            <img
              alt="capa"
              className="relatorio__seusdados-logo"
              src={LogoSeusdados}
            />
          </div>
          <div className="col2">
            <h4>Contexto:</h4>
            <p>
              A Lei Geral de Proteção de Dados Pessoais (LGPD) é uma lei federal
              que regulamenta o tratamento de dados pessoais de pessoas físicas
              no Brasil. Estar em conformidade com a LGPD é importante por três
              motivos:
            </p>
            <ul>
              <li>
                Responsabilidade legal: empresas que não cumprem a LGPD podem
                ser multadas em até 2% do faturamento anual, limitadas a R$ 50
                milhões.
              </li>
              <li>
                Ética e transparência: as empresas têm a responsabilidade de
                proteger a privacidade e a segurança das pessoas ao coletar e
                processar dados pessoais.
              </li>
              <li>
                Imagem e reputação: empresas que demonstram compromisso com a
                proteção de dados pessoais ganham a confiança de seus clientes e
                parceiros.
              </li>
            </ul>
            <p>
              Para que a organização esteja em conformidade com a LGPD
              necessária a adequação de processos (atividades) e modificação da
              cultura organizacional sobre tratamentos de dados, o que permitirá
              o gerenciamento da atividade empresarial a partir da lógica da
              proteçao de dados por padrão, com observância das regras legais e
              normas internas por todas as partes relacionadas com a empresa.
            </p>
            <br />
            <p>
              <b>
                Não basta, portanto, criação de politicas, modelos de documentos
                ou aquisição de softwares, mas, sim, verdadeira transformação
                cultural, a partir da alta gestão, com observância de boas
                práticas e governança da proteção de dados pessoais.
              </b>
            </p>
            <br />
            <p>
              <b>
                Para desenvolver e garantir esse ambiente de governança em
                proteção de dados pessoais é criado o CPPD
              </b>{" "}
              - Comitê de Privacidade e Proteção de Dados Pessoais.
            </p>
            <p
              style={{
                fontSize: "16px",
                marginTop: "1rem",
              }}
            >
              Palavras-chave: LGPD, conformidade, plano de continuidade de
              negócios, diagnóstico
            </p>
          </div>
        </div>
      </div>
      <div className="page3" style={{ pageBreakAfter: "always" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
        <h1 className="title2">
          Porque isso é
          <br />
          <b>importante</b>
        </h1>
        <div className="rows">
          <div className="col1">
            <p className="col1_title">PREPARADO POR:</p>
            <b>
              {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
            </b>
            <p className="col1_title">RESPONSÁVEL:</p>
            <b>MARCELO FATTORI</b>
            <img
              alt="logo"
              className="relatorio__seusdados-logo"
              src={LogoSeusdados}
            />
          </div>
          <div className="col2">
            <p>
              A governança em proteção de dados pessoais é{" "}
              <b>
                essencial para cumprir as exigências legais da LGPD e, também,
                para mitigar riscos, evitar penalidades severas e obter
                condições favoráveis à conitnuidade do negócio.
              </b>
            </p>
            <br />
            <p>
              A <b>Resolução CD/ANPD nº 4</b>, de 24 de fevereiro de 2023,
              enfatiza a importância da implementação de boas práticas de
              governança,{" "}
              <b>
                bonificando a organização diligente com uma atenuante de multas
                e outras penalidades em casos de violações.
              </b>
            </p>
            <br />
            <p>
              A ausência de um sistema de governança implementado e efetivo
              poderá se tornar, ainda, fator de{" "}
              <b>agravamento da pena pela conduta negligente</b> da organização.
            </p>
            <br />
            <p>
              Além disso, a governança robusta em proteção de dados
              <b>
                fortalece a confiança dos titulares de dados, melhora a
                reputação da organização
              </b>{" "}
              e pode ser um diferencial competitivo no mercado.
            </p>
            <p
              style={{
                fontSize: "16px",
                marginTop: "1rem",
              }}
            >
              Palavras-chave: LGPD, conformidade, plano de continuidade de
              negócios, diagnóstico, governança, proteção de dados, segurança da
              informação.
            </p>
          </div>
        </div>
      </div>
      <div className="page4" style={{ pageBreakAfter: "always" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
        <h1 className="title2">
          A atuação do
          <br />
          <b>CPPD</b>
        </h1>
        <div className="rows">
          <div className="col1">
            <p className="col1_title">PREPARADO POR:</p>
            <b>
              {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
            </b>
            <p className="col1_title">RESPONSÁVEL:</p>
            <b>MARCELO FATTORI</b>
            <img
              alt="logo"
              className="relatorio__seusdados-logo"
              src={LogoSeusdados}
            />
          </div>
          <div className="col2">
            <h4>Objetivo e Composição:</h4>
            <p>
              O Comitê de Proteção de Dados (CPPD) da{" "}
              <b>
                {
                  clientes.find(
                    (c: any) =>
                      c._id ===
                      moduloMapeamento.questionarios?.find(
                        (q) => q._id === questId
                      )?.cliente_id
                  )?.razao_social
                }
              </b>{" "}
              é um órgão consultivo criado para assegurar a conformidade com a
              LGPD na organização. A sua composição inclui:
            </p>
            <ul>
              <li>Coordenador</li>
              <li>Secretário</li>
              <li>Representantes das diversas áreas</li>
              <li>Convidados indicados conforme necessidade</li>
            </ul>
            <h4>Indicação e Mandato</h4>
            <p>
              Os membros são indicados pela diretoria ou gestores de áreas e
              devem possuir conhecimento sólido sobre a LGPD e os riscos
              associados, participando de forma ativa e diligente nas reuniões.
            </p>
            <h4>Funcionamento</h4>
            <p>
              O CPPD se reúne ordinariamente mensalmente e extraordinariamente
              conforme necessário. As pautas são organizadas pelo Coordenador,
              com possibilidade de sugestões dos membros. As discussões são
              registradas em atas e devem embasar decisões da Diretoria
              Executiva.
            </p>
          </div>
        </div>
      </div>
      <div className="page5" style={{ pageBreakAfter: "always" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
        <h1 className="title2">
          A atuação do
          <br />
          <b>CPPD</b>
        </h1>
        <div className="rows">
          <div className="col1">
            <p className="col1_title">PREPARADO POR:</p>
            <b>
              {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
            </b>
            <p className="col1_title">RESPONSÁVEL:</p>
            <b>MARCELO FATTORI</b>
            <img
              alt="logo"
              className="relatorio__seusdados-logo"
              src={LogoSeusdados}
            />
          </div>
          <div className="col2">
            <h4>Principais atribuições</h4>
            <ul>
              <li>Apoiar o Encarregado de Proteção de Dados (DPO)</li>
              <li>Monitorar a conformidade com a LGPD</li>
              <li>Implementar e atualizar o programa de proteção de dados</li>
              <li>Identificar e mitigar riscos de privacidade</li>
              <li>
                Garantir a eficácia dos controles de segurança e privacidade
              </li>
              <li>Promover a cultura de proteção de dados na organização</li>
            </ul>
            <h4>Responsabilidades</h4>
            <p>
              Participar das reuniões e discussões, analisar informações
              pertinentes e contribuir com sugestões, sobretudo apoiar as áreas
              internas na continuidade do processo de conformidade com a LGPD.
            </p>
          </div>
        </div>
      </div>
      <div className="page6" style={{ pageBreakAfter: "always" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
        <h1 className="title2">
          <b>Objetivos</b>
        </h1>
        <div className="rows">
          <div className="col1">
            <p className="col1_title">PREPARADO POR:</p>
            <b>
              {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
            </b>
            <p className="col1_title">RESPONSÁVEL:</p>
            <b>MARCELO FATTORI</b>
            <img
              alt="logo"
              className="relatorio__seusdados-logo"
              src={LogoSeusdados}
            />
          </div>
          <div className="col2">
            <h4>Objetivo desta análise:</h4>

            <p>
              Este relatório tem como objetivo avaliar o grau de maturidade da
              organização em relação à governança de proteção de dados pessoais,
              conforme as diretrizes da Lei Geral de Proteção de Dados (LGPD) e
              as normas internacionais relevantes.
            </p>
            <br />
            <p>
              Através de um questionário aplicado aos Comitês de Privacidade e
              Proteção de Dados (CPPD) dos nossos clientes, buscamos identificar
              pontos fortes e áreas de melhoria para garantir a conformidade e a
              segurança dos dados pessoais tratados pela organização.
            </p>
            <p
              style={{
                fontSize: "16px",
                marginTop: "1rem",
              }}
            >
              Palavras-chave: LGPD, conformidade, plano de continuidade de
              negócios, diagnóstico, governança, proteção de dados, segurança da
              informação.
            </p>
          </div>
        </div>
      </div>
      <div className="page7" style={{ pageBreakAfter: "always" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
        <h1 className="title2">
          <b>Fundamentos</b>
        </h1>
        <div className="rows">
          <div className="col1">
            <p className="col1_title">PREPARADO POR:</p>
            <b>
              {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
            </b>
            <p className="col1_title">RESPONSÁVEL:</p>
            <b>MARCELO FATTORI</b>
            <img
              alt="logo"
              className="relatorio__seusdados-logo"
              src={LogoSeusdados}
            />
          </div>
          <div className="col2">
            <h4>Legais:</h4>
            <ul>
              <li>
                Lei 13.709 de 14 de agosto de 2018 - LGPD
                <ul>
                  <li>artigo 50, § 1º</li>
                  <li>artigo 52, § 1º, incisos IX, X</li>
                </ul>
              </li>
              <li>
                Resolução n. 4/2023 do Conselho Diretor da ANPD - Autoridade
                Nacional de Proteção de Dados
                <ul>
                  <li>artigo 7</li>
                  <li>artigo 13, inciso II</li>
                </ul>
              </li>
            </ul>
            <h4>Técnicos:</h4>
            <ul>
              <li>
                ABNT. NBR <b>ISO/IEC 27001:2022</b> - Tecnologia da Informação -
                Técnicas de segurança - Sistemas de gestão de segurança da
                informação - Requisitos. Rio de Janeiro: ABNT, 2022.;
              </li>
              <li>
                RABNT. NBR <b>ISO/IEC 27002:2023</b> - Tecnologia da Informação
                - Técnicas de segurança - Código de prática para controles de
                segurança da informação. Rio de Janeiro: ABNT, 2023.;
              </li>
              <li>
                ABNT. NBR <b>ISO/IEC 27701:2022</b> - Tecnologia da Informação -
                Técnicas de segurança - Extensão à ISO/IEC 27001 e ISO/IEC 27002
                para gestão de privacidade da informação - Requisitos e
                diretrizes. Rio de Janeiro: ABNT, 2022.;
              </li>
              <li>
                <b>
                  NIST. Framework for Improving Critical Infrastructure
                  Cybersecurity
                </b>
                , Version 1.1. Gaithersburg, MD: National Institute of Standards
                and Technology, 2018. Disponível em:
                <br />
                <a href="https://nvlpubs.nist.gov/nistpubs/CSWP/NIST.CSWP.04162018.pdf">
                  https://nvlpubs.nist.gov/nistpubs/CSWP/NIST.CSWP.04162018.pdf
                </a>
              </li>
              <li>
                <b>
                  NIST. NIST Privacy Framework: A Tool for Improving Privacy
                </b>{" "}
                through Enterprise Risk Management, Version 1.0. Gaithersburg,
                MD: National Institute of Standards and Technology, 2020.
                Disponível em:
                <br />
                <a href="https://www.nist.gov/system/files/documents/2020/01/16/NIST%20Privacy%20Framework_V1.0.pdf">
                  https://www.nist.gov/system/files/documents
                  /2020/01/16/NIST%20Privacy%20Framework_V1.0.pdf
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="page8" style={{ pageBreakAfter: "always" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
        <h1 className="title2">
          <b>Metodologia</b>
        </h1>
        <div className="rows">
          <div className="col1">
            <p className="col1_title">PREPARADO POR:</p>
            <b>
              {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
            </b>
            <p className="col1_title">RESPONSÁVEL:</p>
            <b>MARCELO FATTORI</b>
            <img
              alt="logo"
              className="relatorio__seusdados-logo"
              src={LogoSeusdados}
            />
          </div>
          <div className="col2">
            <h4>Introdução:</h4>
            <p>
              O relatório apresenta os resultados do diagnóstico do nível de
              maturidade da governança em proteção de dados pessoais do CPPD -
              Comitê de Privacidade e Proteção de Dados da{" "}
              <b>
                {" "}
                {
                  clientes.find(
                    (c: any) =>
                      c._id ===
                      moduloMapeamento.questionarios?.find(
                        (q) => q._id === questId
                      )?.cliente_id
                  )?.razao_social
                }
              </b>
              , pela <b>SEUSDADOS CONSULTORIA EM PROTEÇÃO DE DADOS LIMITADA</b>{" "}
              entre{" "}
              <b>
                {moment(
                  new Date(
                    moduloMapeamento.questionarios
                      ?.find((q) => q._id === questId)
                      ?.versoes?.find((v: any) => v._id === versaoId).created_at
                  )
                ).format("DD [de] MMMM")}
              </b>{" "}
              e{" "}
              <b>
                {" "}
                {moment(
                  new Date(
                    moduloMapeamento.questionarios
                      ?.find((q) => q._id === questId)
                      ?.versoes?.find((v: any) => v._id === versaoId).updated_at
                  )
                ).format("DD [de] MMMM")}
              </b>
              , referente ao ano/exercício de{" "}
              <b>
                {moment(
                  new Date(
                    moduloMapeamento.questionarios
                      ?.find((q) => q._id === questId)
                      ?.versoes?.find((v: any) => v._id === versaoId).updated_at
                  )
                ).format("YYYY")}
              </b>
              .
            </p>
            <h4>Metodologia:</h4>
            <ul>
              <li>
                Estrutura das Perguntas e Respostas
                <ul>
                  <li>Alternativa A: Resposta totalmente errada (0 pontos)</li>
                  <li>Alternativa B: Baixo grau de maturidade (1 ponto)</li>
                  <li>Alternativa C: Médio grau de maturidade (2 pontos)</li>
                  <li>Alternativa D: Alto grau de maturidade (3 pontos)</li>
                </ul>
              </li>
              <li>
                Pontuação Total
                <ul>
                  <li>Cada pergunta tem um valor máximo de 3 pontos.</li>
                  <li>
                    O questionário completo tem um valor máximo de 90 pontos (30
                    perguntas x 3 pontos).
                  </li>
                </ul>
              </li>
              <li>
                Conversão de Pontuação para Nota de Maturidade
                <ul>
                  <li>
                    Para converter a pontuação total em uma nota de maturidade
                    que varia de 0 a 100, utilizaremos a seguinte fórmula:
                    <img
                      alt="nota"
                      style={{ height: "2rem", marginTop: "1rem" }}
                      src={NotaMaturidade}
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="page9" style={{ pageBreakAfter: "always" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
        <h1 className="title2">
          <b>Metodologia</b>
        </h1>
        <div className="rows">
          <div className="col1">
            <p className="col1_title">PREPARADO POR:</p>
            <b>
              {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
            </b>
            <p className="col1_title">RESPONSÁVEL:</p>
            <b>MARCELO FATTORI</b>
            <img
              alt="logo"
              className="relatorio__seusdados-logo"
              src={LogoSeusdados}
            />
          </div>
          <div className="col2">
            <ul>
              <li>
                Exemplo de Pergunta e Alternativas:
                <ul>
                  <li>
                    Pergunta: A sua organização possui um Data Protection
                    Officer (DPO) nomeado conforme os requisitos da LGPD?
                    <ul>
                      <li>
                        Alternativa A: Não temos um DPO nomeado. (0 pontos)
                      </li>
                      <li>
                        Alternativa B: Temos uma pessoa responsável pela
                        privacidade, mas sem um título oficial de DPO. (1 ponto)
                      </li>
                      <li>
                        Alternativa C: Temos um DPO nomeado, mas ele não possui
                        treinamento específico em LGPD. (2 pontos)
                      </li>
                      <li>
                        Alternativa D: Temos um DPO nomeado com treinamento
                        completo em LGPD e envolvimento ativo na gestão de
                        privacidade. (3 pontos)
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                Aplicação da Metodologia
                <ul>
                  <li>
                    Administração do Questionário: Cada organização responde às
                    30 perguntas, selecionando uma alternativa para cada
                    pergunta.
                  </li>
                  <li>
                    Cálculo da Pontuação Total: Soma-se a pontuação de todas as
                    respostas.
                  </li>
                  <li>
                    Conversão da Pontuação Total para Nota de Maturidade:
                    Aplica-se a fórmula para obter a nota final.
                  </li>
                </ul>
              </li>
              <li>
                Exemplo de Cálculo
                <ul>
                  <li>Pontuação Total Obtida: 75 pontos</li>
                  <li>
                    Pontuação Máxima: 90 pontos
                    <img
                      style={{ height: "2rem", marginTop: "1rem" }}
                      alt="exemplo nota"
                      src={NotaMaturidadeExemplo}
                    />
                  </li>
                </ul>
              </li>
              <li>
                Considerações finais sobre a metodologia
                <ul>
                  <li>
                    Este método oferece uma forma estruturada e objetiva de
                    avaliar o grau de maturidade de uma organização em relação à
                    LGPD. As perguntas cobrem aspectos chave da conformidade,
                    como governança, gestão de dados, segurança da informação,
                    entre outros. Essa abordagem permite identificar o quanto
                    efetivo vem sendo a atuação do CPPD e do modelo de
                    governança adotado na organização.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="page10" style={{ pageBreakAfter: "always" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
        <h1 className="title2">
          Nível de
          <br />
          <b>conformidade</b>
        </h1>
        <div className="rows">
          <div className="col1">
            <p className="col1_title">PREPARADO POR:</p>
            <b>
              {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
            </b>
            <p className="col1_title">RESPONSÁVEL:</p>
            <b>MARCELO FATTORI</b>
            <img
              className="relatorio__seusdados-logo"
              src={LogoSeusdados}
              alt="logo"
            />
          </div>
          <div className="col2">
            <h4>Interpretação dos Resultados</h4>
            <ul>
              <li>
                Nota de 0 a 30: Maturidade baixa (Organização em estágio inicial
                de conformidade com a LGPD)
              </li>
              <li>
                Nota de 40 a 60: Maturidade média (Organização com práticas
                básicas de conformidade, mas com várias áreas a melhorar)
              </li>
              <li>
                Nota de 70 a 80: Maturidade alta (Organização com boas práticas
                de conformidade, faltando apenas alguns ajustes)
              </li>
              <li>
                Nota de 90 a 100: Maturidade total (Organização em plena
                conformidade com a LGPD, com processos bem estabelecidos e
                monitorados)
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="page11" style={{ pageBreakAfter: "always" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
        <h1 className="title2">
          Pilares &
          <br />
          <b>categorias</b>
        </h1>
        <div className="rows">
          <div className="col1">
            <p className="col1_title">PREPARADO POR:</p>
            <b>
              {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
            </b>
            <p className="col1_title">RESPONSÁVEL:</p>
            <b>MARCELO FATTORI</b>
            <img
              className="relatorio__seusdados-logo"
              src={LogoSeusdados}
              alt="logo"
            />
          </div>
          <div className="col2">
            <p>A Análise está estruturada em 4 pilares:</p>
            <ul>
              <li>Cultura Organizacional</li>
              <li>Processos de negócios</li>
              <li>Gestão de TI</li>
              <li>Segurança da Informação</li>
            </ul>
            <p>
              E cada um deles possuem categorias específicas, onde são tratadas
              pormenorizadamente cada um dos controles aplicáveis.
            </p>
          </div>
        </div>
      </div>

      {pontuacaoMaxima && (
        <div className="page12" style={{ pageBreakBefore: "always" }}>
          <h1 className="title2">
            Resultados &
            <br />
            <b>conformidade</b>
          </h1>

          <div className="rows_chart">
            <div className="rows_chart--col1">
              <p>Baseado na análise das respostas apresentadas pelo CPPD:</p>
              <div style={{ marginTop: "2rem" }}>
                <p
                  style={{
                    fontSize: "14px !important",
                    textAlign: "left",
                    color: "#ed722f !important",
                  }}
                >
                  BAIXO = Risco alto de incidentes e penalidades
                </p>
                <p
                  style={{
                    fontSize: "14px !important",
                    textAlign: "left",
                    color: "#ed722f !important",
                  }}
                >
                  MÉDIO = Risco considerável de incidentes e penalidades
                </p>
                <p
                  style={{
                    fontSize: "14px !important",
                    textAlign: "left",
                    color: "#ed722f !important",
                  }}
                >
                  ALTO = Baixo risco de incidentes e penalidades
                </p>
              </div>
            </div>
            <div className="rows_chart--col2">
              <div
                className="rows_chart--col2__nivel"
                style={{
                  width: 50,
                  height: 200,
                  background:
                    "rgb(0,255,8) linear-gradient(180deg, #5F29CC 0%, #4C72E3 50%,  #33CCFF 100%)",
                }}
              >
                <p
                  style={{
                    position: "relative",
                    top: 90 + "px",
                    marginTop: "-29px",
                    left: "78px",
                    color:
                      porcentagemConforme > 33 && porcentagemConforme < 66
                        ? "#4C72E3"
                        : porcentagemConforme >= 66
                        ? "#33CCFF"
                        : "#5F29CC",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    width: "8rem",
                  }}
                  translate="no"
                >
                  {porcentagemConforme >= 66 && "ALTA"}
                  {porcentagemConforme > 33 &&
                    porcentagemConforme < 66 &&
                    "MÉDIO"}
                  {porcentagemConforme <= 33 && "BAIXA"}
                  <span
                    style={{
                      position: "relative",
                      fontSize: "0.8rem",
                    }}
                    translate="no"
                  >
                    <br />
                    {porcentagemConforme.toFixed(2)} pontos
                  </span>
                </p>
                <p
                  style={{
                    marginTop: "-82px",
                    marginLeft: "-20px",
                  }}
                  translate="no"
                >
                  Maturidade
                </p>
                <div
                  style={{
                    width: "70px",
                    height: "2px",
                    marginTop: "-2px",
                    background: "#000",
                    opacity: 0.3,
                    marginLeft: "-10px",
                  }}
                />
                <div
                  style={{
                    width: "70px",
                    height: "2px",
                    background: "#000",
                    marginLeft: "-10px",
                    marginTop: `calc(${100 - porcentagemConforme}px*2)`,
                  }}
                >
                  <div className="arrow right" style={{ top: "-3.5px" }} />
                  <div
                    className="arrow left"
                    style={{ top: "-12.9px", left: "63px" }}
                  />
                </div>
                <div
                  style={{
                    width: "70px",
                    height: "2px",
                    background: "#000",
                    opacity: 0.3,
                    marginLeft: "-10px",
                    marginTop: `calc(196px - calc(${porcentagemConforme}px*2))`,
                  }}
                />
              </div>
            </div>
          </div>

          <p
            style={{
              fontSize: "24px",
              textAlign: "center",
              marginTop: "6rem",
            }}
          >
            O nível de maturidade da governança de proteção de dados é avaliado
            em uma escala de 0 a 100, com base nas respostas fornecidas no
            questionário. Cada resposta contribui com uma pontuação específica,
            que é somada para determinar a nota final. Esta nota reflete a
            posição da organização em relação às melhores práticas de proteção
            de dados e ao cumprimento das exigências legais.
          </p>
          <img
            className="relatorio__seusdados-logo"
            src={LogoSeusdados}
            alt="logo"
            style={{
              bottom: "0",
              marginTop: "12rem",
            }}
          />
        </div>
      )}

      <div className="page7" style={{ pageBreakAfter: "always" }}>
        <h1
          className="title2"
          style={{
            paddingTop: "1.25rem !important",
          }}
        >
          Análises &
          <br />
          <b>plano de ação</b>
        </h1>
        <div
          style={{
            width: "600px",
            height: "300px",
            position: "relative",
            marginTop: "-30%",
            // bottom: "-50%",
            // left: "50%",
            transform: "translate(0, 106.5%)",
            zIndex: 0,
          }}
        >
          <GridMatrizrisco numeroColunas={4} numeroLinhas={4} />
        </div>
        <ScatterChart
          width={600}
          height={300}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <XAxis
            label={{
              value: "Probabilidade",
              position: "bottom",
              offset: 0,
            }}
            type="number"
            dataKey="probabilidade"
            name="Probabilidade"
            ticks={[-0.5, 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5]}
            domain={[0, 3]}
            tickFormatter={formatTick}
          />
          <YAxis
            label={{
              value: "Impacto",
              angle: -90,
              position: "left",
            }}
            type="number"
            dataKey="impacto"
            ticks={[-0.5, 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5]}
            domain={[0, 3]}
            name="Impacto"
            tickFormatter={formatTick}
          />
          <Scatter
            fill="#000"
            name="Matriz de Risco"
            data={matrizRisco}
            shape={<CustomizedShape />}
            isAnimationActive={false}
          />
        </ScatterChart>

        <div
          style={{
            marginLeft: "5rem",
          }}
        >
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                backgroundColor: "#33CCFF",
              }}
            />
            <h5 style={{ margin: "0" }}>
              Risco Baixo:{" "}
              <span style={{ fontSize: "0.8rem" }}>
                {matrizRisco
                  ?.filter((item: any) => item.risco <= 0.33)
                  ?.map(
                    (item: any) =>
                      questionario.perguntasRelacao
                        .sort(
                          (a: any, b: any) =>
                            new Date(a.pergunta.created_at).getTime() -
                            new Date(b.pergunta.created_at).getTime()
                        )
                        .findIndex(
                          (r: any) => r.pergunta._id === item.pergunta._id
                        ) + 1
                  )
                  .join(", ")}
              </span>
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                backgroundColor: "#4C72E3",
              }}
            ></div>
            <h5 style={{ margin: "0" }}>
              Risco Médio:{" "}
              <span style={{ fontSize: "0.8rem" }}>
                {matrizRisco
                  ?.filter(
                    (item: any) => item.risco > 0.33 && item.risco <= 0.66
                  )
                  ?.map(
                    (item: any) =>
                      questionario.perguntasRelacao
                        .sort(
                          (a: any, b: any) =>
                            new Date(a.pergunta.created_at).getTime() -
                            new Date(b.pergunta.created_at).getTime()
                        )
                        .findIndex(
                          (r: any) => r.pergunta._id === item.pergunta._id
                        ) + 1
                  )
                  .join(", ")}
              </span>
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                backgroundColor: "#5F29CC",
              }}
            ></div>
            <h5 style={{ margin: "0" }}>
              Risco Alto:{" "}
              <span style={{ fontSize: "0.8rem" }}>
                {matrizRisco
                  ?.filter((item: any) => item.risco > 0.66)
                  ?.map(
                    (item: any) =>
                      questionario.perguntasRelacao
                        .sort(
                          (a: any, b: any) =>
                            new Date(a.pergunta.created_at).getTime() -
                            new Date(b.pergunta.created_at).getTime()
                        )
                        .findIndex(
                          (r: any) => r.pergunta._id === item.pergunta._id
                        ) + 1
                  )
                  .join(", ")}
              </span>
            </h5>
          </div>
        </div>

        <div style={{ height: "3rem" }} />
        <div className="rows">
          <div className="col1">
            <p className="col1_title">PREPARADO POR:</p>
            <b>
              {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
            </b>
            <p className="col1_title">RESPONSÁVEL:</p>
            <b>MARCELO FATTORI</b>
            <img
              className="relatorio__seusdados-logo"
              src={LogoSeusdados}
              alt="logo"
            />
          </div>
          <div className="col2">
            <p style={{ paddingTop: "4.2rem" }}>
              Com base nos resultados obtidos, propomos um plano de ação para
              aprimorar a governança de proteção de dados pessoais na
              organização. Este plano inclui medidas práticas e recomendações
              específicas para abordar as áreas de melhoria identificadas,
              implementação de novos controles de segurança, programas de
              treinamento e conscientização para colaboradores, dentre outras
              medidas.
            </p>
          </div>
        </div>
      </div>
      <div>
        {questionario.perguntasRelacao
          ?.map((r: any) => r.capitulo_id)
          ?.filter((id: any, index: number) => {
            return (
              questionario.perguntasRelacao
                ?.map((r: any) => r.capitulo_id)
                ?.indexOf(id) === index
            );
          })
          ?.map((idCap: any) => (
            <div style={{ pageBreakAfter: "always" }}>
              <div className="relatorio__headerCap">
                <h2>
                  {
                    moduloProcessos.capitulos?.find(
                      (cap: any) => cap._id === idCap
                    )?.description
                  }
                </h2>
              </div>
              <div className="">
                {questionario.perguntasRelacao
                  ?.filter(
                    (r: any) =>
                      r.capitulo_id === idCap && r.pergunta?.description
                  )
                  ?.sort(
                    (a: any, b: any) =>
                      new Date(a.pergunta.created_at).getTime() -
                      new Date(b.pergunta.created_at).getTime()
                  )
                  .map((relPergunta: any) => {
                    const currentResposta = currentRespostas.filter(
                      (r: any) => r.pergunta_id === relPergunta.pergunta_id
                    );
                    return (
                      <div key={relPergunta._id + "analiseMultiResp"}>
                        <div
                          className="respostas__pergunta--label"
                          style={{ fontSize: 16 }}
                        >
                          <b>{encontrarIndicePorValor(relPergunta)}.</b>{" "}
                          {relPergunta.pergunta.description}{" "}
                        </div>
                        <div style={{ marginLeft: "1rem" }}>
                          <Space direction="vertical">
                            {relPergunta.pergunta.tipo !== "input" ? (
                              relPergunta.pergunta.opcoes.map((opt: any) => {
                                let allRespostas: any = [];
                                currentResposta.forEach((r: any) =>
                                  allRespostas.push(...r.value)
                                );

                                const currentGrau =
                                  currentResposta?.[0]?.graus?.filter(
                                    (g: any) =>
                                      g.alternativa?.includes(opt?.alternativa)
                                  )?.[0];

                                return (
                                  <p>
                                    <Checkbox
                                      checked={
                                        currentResposta.filter((resp: any) =>
                                          resp.respostas.includes(
                                            opt.alternativa
                                          )
                                        )?.[0]
                                          ? true
                                          : false
                                      }
                                    >
                                      {opt.alternativa}{" "}
                                      {currentResposta.filter((resp: any) =>
                                        resp.respostas.includes(opt.alternativa)
                                      ).length > 1 && (
                                        <>
                                          {" "}
                                          -{" "}
                                          <b
                                            className={
                                              currentGrau.grau_descricao ===
                                                "Conforme" ||
                                              currentGrau.grau_descricao ===
                                                "Baixo risco"
                                                ? "respostas__pergunta--label__conforme"
                                                : currentGrau.grau_descricao ===
                                                    "Não conforme" ||
                                                  currentGrau.grau_descricao ===
                                                    "Alto risco"
                                                ? "respostas__pergunta--label__inconforme"
                                                : currentGrau.grau_descricao ===
                                                  "Médio risco"
                                                ? "respostas__pergunta--label__orange"
                                                : "respostas__pergunta--label__blue"
                                            }
                                          >
                                            {currentResposta.filter(
                                              (resp: any) =>
                                                resp.respostas.includes(
                                                  opt.alternativa
                                                )
                                            ).length !== 1 && (
                                              <>
                                                {
                                                  currentResposta.filter(
                                                    (resp: any) =>
                                                      resp.respostas.includes(
                                                        opt.alternativa
                                                      )
                                                  ).length
                                                }{" "}
                                                resposta
                                                {currentResposta.filter(
                                                  (resp: any) =>
                                                    resp.respostas.includes(
                                                      opt.alternativa
                                                    )
                                                ).length !== 1 && "s"}{" "}
                                              </>
                                            )}

                                            {currentGrau.grau_descricao && (
                                              <span
                                                style={{
                                                  fontSize: "0.6rem",
                                                }}
                                              >
                                                ({currentGrau.grau_descricao})
                                              </span>
                                            )}
                                          </b>
                                        </>
                                      )}
                                    </Checkbox>
                                  </p>
                                );
                              })
                            ) : (
                              <p className="respostas__pergunta--label__blue">
                                {/* Conhecimento de processo */}
                                {currentResposta[0]?.value[0]}
                              </p>
                            )}
                          </Space>
                        </div>
                        <br />
                        <h4>Boas práticas</h4>
                        <br />

                        <div
                          style={{ marginLeft: "1rem" }}
                          dangerouslySetInnerHTML={{
                            __html: relPergunta.pergunta.recomendacao,
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          ))}
      </div>

      <div className="page14" style={{ pageBreakAfter: "always" }}>
        <h1 className="title2">
          Um pouco
          <br />
          <b>sobre nós</b>
        </h1>
        <div style={{ marginTop: "4rem" }}>
          <p>
            Na Seusdados Consultoria, acreditamos que a proteção de dados é mais
            do que uma obrigação legal; é uma missão transformadora. Somos uma
            consultoria multidisciplinar que combina expertise jurídica,
            tecnológica, de segurança da informação, gestão de processos e
            comunicação para criar soluções inovadoras e disruptivas em
            conformidade com a Lei Geral de Proteção de Dados (LGPD).
          </p>
          <br />
          <h4>Nossa Visão</h4>
          <p>
            Somos pioneiros na proteção de dados no Brasil, impulsionando a
            transformação digital com segurança e privacidade. Nosso compromisso
            é não apenas assegurar a conformidade legal, mas também promover uma
            cultura de proteção de dados que fortaleça a confiança e a reputação
            de nossos clientes.
          </p>
          <br />

          <h4>Nossa Missão</h4>
          <p>
            Capacitar empresas e organizações a navegarem pelo complexo cenário
            de proteção de dados, oferecendo soluções personalizadas que
            garantam a conformidade com a LGPD e a segurança dos dados pessoais.
            Atuamos como DPO as a service, proporcionando suporte técnico e
            estratégico contínuo para a gestão de dados pessoais.
          </p>
          <br />

          <h4>Nossos Valores</h4>
          <ul>
            <li>
              <b>Inovação:</b> Utilizamos as mais recentes tecnologias e
              metodologias para oferecer soluções eficientes e eficazes.
            </li>
            <li>
              <b>Transparência: </b>Mantemos uma comunicação clara e aberta com
              nossos clientes, garantindo que todas as etapas do processo de
              conformidade sejam compreendidas e acompanhadas de perto.
            </li>
            <li>
              <b>Multidisciplinaridade:</b> Nossa equipe é composta por
              profissionais de diversas áreas, garantindo uma abordagem
              abrangente e integrada.
            </li>
            <li>
              <b>Excelência:</b> Comprometemo-nos com a qualidade e a excelência
              em todos os nossos serviços, buscando sempre superar as
              expectativas dos nossos clientes.
            </li>
          </ul>
          <br />
          <h4>Nossa Abordagem</h4>
          <p>
            Nossa consultoria segue uma lógica de atuação que passa pela análise
            completa e transversal das necessidades de cada cliente.
            Consideramos todos os aspectos envolvidos na proteção de dados
            pessoais, desde a análise jurídica e regulatória até a implementação
            de medidas técnicas de segurança da informação.
          </p>
          <br />
        </div>
        <img
          className="relatorio__seusdados-logo"
          src={LogoSeusdados}
          alt="logo"
          style={{
            bottom: "0",
            marginTop: "2rem",
          }}
        />
      </div>
      <div className="page15" style={{ pageBreakAfter: "always" }}>
        <h1 className="title2">
          Um pouco
          <br />
          <b>sobre nós</b>
        </h1>
        <div style={{ marginTop: "4rem" }}>
          <h4>Nossos Serviços</h4>
          <ul>
            <li>
              <b>Adequação à LGPD:</b> Realizamos auditorias e implementamos
              medidas para garantir a conformidade com a LGPD.
            </li>
            <li>
              <b>DPO as a Service: </b>Assumimos a função de Encarregado de
              Proteção de Dados Pessoais, oferecendo suporte contínuo.
            </li>
            <li>
              <b>Treinamentos e Capacitações: </b>Oferecemos programas de
              treinamento para capacitar equipes na gestão e proteção de dados.
            </li>
            <li>
              <b>Avaliação de Impacto:</b> Conduzimos avaliações de impacto à
              proteção de dados (DPIA) para identificar e mitigar riscos.
            </li>
          </ul>
          <br />
          <h4>Nossa Equipe</h4>
          <p>
            Nossa equipe é formada por especialistas em direito, tecnologia,
            segurança da informação, gestão de processos e comunicação, todos
            comprometidos com a missão de proteger os dados pessoais de nossos
            clientes. Com uma abordagem colaborativa e integrada, estamos
            preparados para enfrentar os desafios mais complexos e proporcionar
            soluções eficazes e inovadoras.
          </p>
        </div>
        <img
          className="relatorio__seusdados-logo"
          src={LogoSeusdados}
          alt="logo"
          style={{
            bottom: "0",
            marginTop: "26rem",
          }}
        />
      </div>
      <div className="page16" style={{ pageBreakAfter: "always" }}>
        <h1 className="title2">
          Próximos
          <br />
          <b>passos:</b>
        </h1>
        <div className="rows">
          <div className="col1"></div>
          <div className="col2">
            <p>
              A <b>continuidade do negócio</b>, do ponto de vista da{" "}
              <b>gestão estratégica de riscos</b> da organização, passa pela
              conformidade com a <b>Lei Geral de Proteção de Dados</b>. Dentre
              outras, a obtenção e evidências de que parceiros comerciais e
              fornecedores estejam em conformidade com a LGPD, é{" "}
              <b>obrigatória</b> para garantir a sua conformidade.
            </p>
            <div className="proximos-passos__containner">
              <p>
                01<b>Avaliar o resultado</b>
              </p>
              <div className="proximos-passos__hr" />
              <p>
                02<b>Adequar o plano de ação </b>
              </p>
              <div className="proximos-passos__hr" />
              <p>
                03<b>Estruturar</b>
              </p>
              <div className="proximos-passos__hr" />
              <p>
                04<b>Implementar </b>
              </p>
              <div className="proximos-passos__hr" />
              <p>
                05<b>Monitorar </b>
              </p>
              <div className="proximos-passos__hr" />
            </div>
          </div>
        </div>
        <img
          className="relatorio__seusdados-logo"
          alt="logo"
          src={LogoSeusdados}
          style={{
            bottom: "0",
            marginTop: "-2rem",
            // paddingBottom: "2rem",
          }}
        />
      </div>
      <div className="page17" style={{ pageBreakAfter: "always" }}>
        <h4 className="title2">Obrigado</h4>
        <div style={{ marginTop: "2rem" }}>
          <br />
          <br />
          <h4>
            O time da <b>Seusdados Consultoria</b> está a sua disposição para
            esclarecer dúvidas e também ajudar, não deixe de nos chamar.
          </h4>
          <div className="address-info">
            <p>
              <CompassOutlined className="address-info--icon" />
              <span>Rua Afonso Arinos, 24, Vila Nova Espéria,</span>
              <br />
              <span style={{ marginLeft: "5.6rem" }}>
                Jundiaí-SP, 13.211-030
              </span>
            </p>
            <p>
              <PhoneOutlined className="address-info--icon" />
              <span>+55 11 4040 5552</span>
            </p>
            <p>
              <MailOutlined className="address-info--icon" />
              <span>marcelo@seusdados.com</span>
            </p>
            <p>
              <SearchOutlined className="address-info--icon" />
              <span>
                <a href="www.seusdados.com">www.seusdados.com</a>
              </span>
            </p>
          </div>
        </div>
        <img
          className="relatorio__seusdados-logo"
          alt="logo"
          src={LogoSeusdados}
          style={{
            marginTop: "24rem",
            // paddingBottom: "2rem",
          }}
        />
      </div>
    </div>
  );
};

export default RelatorioMaturidade;
