import React, { useState } from "react";
import "./styles.css";
import { Button, Form, Input, Col, Row, Alert, Tabs } from "antd";
import BackHeader from "../../../components/BackHeader/BackHeader";

import { GlobalContext } from "../../../context/globalContext";
import { GlobalContextType } from "../../../@types/global";
import { SaveOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const EditClientes = () => {
  const { saveCliente, windowWidth, currentCliente, setCurrentCliente } =
    React.useContext(GlobalContext) as GlobalContextType;

  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: any) => {
    setLoading(true);
    saveCliente(
      {
        razao_social: values.cliente.razaoSocial,
        representante: values.cliente.representante,
        email: values.cliente.email,
        telefone: values.cliente.telefone,
        obs: values.cliente.obs,
        cnpj: values.cliente.cnpj,
      },
      currentCliente?._id
    )
      .then((res) => setCurrentCliente(res))
      .finally(() => setLoading(false));
  };

  const FormCliente = () => {
    return (
      <Form layout="vertical" name="edit-clientes-form" onFinish={onFinish}>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: "1",
              label: "Dados gerais",
              children: (
                <div style={{ overflowX: "hidden" }}>
                  <Row gutter={16}>
                    <Col span={windowWidth > 800 ? 12 : 24}>
                      <Form.Item
                        name={["cliente", "razaoSocial"]}
                        label="Razão Social"
                      >
                        <Input
                          value={currentCliente?.razao_social}
                          defaultValue={currentCliente?.razao_social}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={windowWidth > 800 ? 6 : 24}>
                      <Form.Item name={["cliente", "cnpj"]} label="CNPJ">
                        <Input
                          value={currentCliente?.cnpj}
                          defaultValue={currentCliente?.cnpj}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={windowWidth > 800 ? 6 : 24}>
                      <Form.Item name={["cliente", "email"]} label="E-mail">
                        <Input
                          value={currentCliente?.email}
                          defaultValue={currentCliente?.email}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={windowWidth > 800 ? 12 : 24}>
                      <Form.Item
                        name={["cliente", "telefone"]}
                        label="Telefone / Celular"
                      >
                        <Input
                          value={currentCliente?.telefone}
                          defaultValue={currentCliente?.telefone}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={windowWidth > 800 ? 12 : 24}>
                      <Form.Item
                        name={["cliente", "representante"]}
                        label="Representante legal"
                      >
                        <Input
                          value={currentCliente?.representante}
                          defaultValue={currentCliente?.representante}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item name={["cliente", "obs"]} label="Observações">
                        <TextArea
                          rows={6}
                          value={currentCliente?.obs}
                          defaultValue={currentCliente?.obs}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ),
            },
          ]}
        />
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: "1",
              label: "CSA",
              children: (
                <>
                  <Alert
                    type="info"
                    message="Função em desenvolvimento"
                    showIcon
                  />
                  {/* <Col span={windowWidth > 800 ? 12 : 24}>
                    <Form.Item
                      name={["cliente", "urls"]}
                      label="URLs para análisar"
                    >
                      <Input
                        value={currentCliente?.urls}
                        defaultValue={currentCliente?.urls}
                      />
                    </Form.Item>
                  </Col> */}
                  <br />
                </>
              ),
            },
          ]}
        />
        <Form.Item>
          <Button
            loading={loading}
            icon={<SaveOutlined />}
            type="primary"
            className="btn-success"
            htmlType="submit"
          >
            Salvar cliente
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className="editClientes">
      <BackHeader title={"Editar Cliente"} />
      {currentCliente?._id && <FormCliente />}
      {!currentCliente?._id && (
        <Alert
          type="warning"
          message="Selecione um cliente para visualizar!"
          showIcon
        />
      )}
    </div>
  );
};

export default EditClientes;
