import React, { useEffect, useState } from "react";
import { Timeline, Spin, Button } from "antd";
import moment from "moment";
import { GlobalContext } from "../../context/globalContext";
import { GlobalContextType } from "../../@types/global";

interface Log {
  _id: string;
  title: string;
  description?: string;
  created_at: string;
  user_id: string;
  local?: {
    reference_model_name: string;
    reference_model_id: string[];
  }[];
}

interface TimelineGeralProps {
  mapeamentoId?: string;
  logs?: any[] | null;
  loadingLogs?: boolean;
  filter?: string | null;
}

const TimelineGeral: React.FC<TimelineGeralProps> = ({
  mapeamentoId,
  logs,
  loadingLogs,
  filter,
}) => {
  const { usuarios } = React.useContext(GlobalContext) as GlobalContextType;

  const [visibleLogs, setVisibleLogs] = useState(6);
  const [filteredLogs, setFilteredLogs] = useState<any[] | null>(null);
  const [verMais, setVerMais] = useState<string | null>(null);

  const handleLoadMore = () => {
    setVisibleLogs((prevVisibleLogs) => prevVisibleLogs + 10); // Carregar mais 10 logs
  };

  useEffect(() => {
    if (logs && mapeamentoId) {
      setFilteredLogs(
        logs
          .sort(
            (a: Log, b: Log) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .filter((log: Log) =>
            log.local?.find(
              (local) => local.reference_model_name === "questionario"
            )
              ? log.local?.find(
                  (local) => local.reference_model_name === "questionario"
                )?.reference_model_id?.[0] === mapeamentoId
              : true
          )
          .filter((log: Log) =>
            log.local?.find(
              (local) => local.reference_model_name === "responder-questionario"
            )
              ? log.local?.find(
                  (local) =>
                    local.reference_model_name === "responder-questionario"
                )?.reference_model_id?.[0] === mapeamentoId
              : true
          )
      );
    }

    if (logs && mapeamentoId) {
      setFilteredLogs(
        logs
          .sort(
            (a: Log, b: Log) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .filter((log: Log) =>
            log.local?.find(
              (local) => local.reference_model_name === "questionario"
            )
              ? log.local?.find(
                  (local) => local.reference_model_name === "questionario"
                )?.reference_model_id?.[0] === mapeamentoId
              : true
          )
          .filter((log: Log) =>
            log.local?.find(
              (local) => local.reference_model_name === "responder-questionario"
            )
              ? log.local?.find(
                  (local) =>
                    local.reference_model_name === "responder-questionario"
                )?.reference_model_id?.[0] === mapeamentoId
              : true
          )
      );
    } else if (logs && !mapeamentoId) {
      setFilteredLogs(
        logs
          ?.filter((log) => (filter ? log.title.includes(filter) : true))
          ?.sort(
            (a: Log, b: Log) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
      );
    }
  }, [logs, mapeamentoId, filter]);

  const truncateDescription = (description: any, maxLength = 15) => {
    if (description?.length > maxLength) {
      return description.slice(0, maxLength) + "...";
    }
    return description;
  };

  return (
    <div>
      {/* historico */}
      {loadingLogs && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Spin size="small" />
          <p style={{ marginLeft: "0.5rem", color: "#b3b3b3" }}>
            Verificando logs...
          </p>
        </div>
      )}
      {logs && (
        <div
          style={{
            marginTop: "2rem",
          }}
        >
          <Timeline style={{ marginLeft: "1rem" }}>
            {filteredLogs?.slice(0, visibleLogs).map((log: Log) => (
              <Timeline.Item key={log._id}>
                <div className="ficha__log">
                  <p className="ficha__log--date">
                    {moment(new Date(log.created_at)).format(
                      "DD/MM/YYYY HH:mm"
                    )}
                  </p>
                  <p className="ficha__log--title">
                    {log.title}{" "}
                    {usuarios?.find((usr: any) => usr._id === log.user_id) && (
                      <span className="ficha__log--user">
                        por{" "}
                        <span>
                          {
                            usuarios?.find(
                              (usr: any) => usr._id === log.user_id
                            ).name
                          }
                        </span>
                      </span>
                    )}
                  </p>
                </div>
                <p className="ficha__log--desc">
                  {log.description && log.description.length > 200 ? (
                    <>
                      {verMais === log._id
                        ? log.description
                        : truncateDescription(log.description, 200)}
                      {verMais === log._id ? (
                        <Button
                          type="link"
                          onClick={() => {
                            setVerMais(null);
                          }}
                        >
                          Ver menos
                        </Button>
                      ) : (
                        <Button
                          type="link"
                          onClick={() => {
                            setVerMais(log._id);
                          }}
                        >
                          Ver mensagem completa
                        </Button>
                      )}
                    </>
                  ) : (
                    log.description
                  )}
                </p>
              </Timeline.Item>
            ))}
          </Timeline>
          {filteredLogs && visibleLogs < filteredLogs?.length && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1rem 0",
              }}
            >
              <Button onClick={handleLoadMore} type="primary">
                Mais registros
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TimelineGeral;
