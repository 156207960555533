import { useContext, useState, Dispatch, SetStateAction } from "react";
import { GlobalContext } from "./globalContext";
import { message } from "antd";
import api from "../services/api";

interface Departamento {
  _id: string;
  nome: string;
}

interface Categoria {
  _id: string;
  nome: string;
}

interface TaskDependencia {
  _id: string;
  titulo: string;
}

interface TaskModeloReferencia {
  _id: string;
  nome: string;
  versao: string;
}

// Enumerações para tipos específicos
enum TaskTipoDependencia {
  Normal = "normal",
  Checklist = "checklist",
}

enum TaskStatus {
  Pendente = "Pendente",
  EmAndamento = "Em andamento",
  Concluida = "Concluída",
}

export interface TaskType extends Document {
  _id: string;
  title: string;
  description: string;
  owner_id: string;
  assignment_id: string;
  dependencia: TaskDependencia;
  tipo_dependencia: TaskTipoDependencia;
  status: TaskStatus;
  created_at: Date;
  updated_at: Date;
  tipo: string;
  modelo_referencia_id: any;
  id_chamado_tomticket: string;
  departamento: Departamento;
  categoria: Categoria;
  nome_conjunto: string;
  data_conclusao: Date;
  prazo_conclusao: number;
  modelo_info?: any; // nao esta no modelo, adicionado pela rota
  assignment_name?: string; // nao esta no modelo, adicionado pela rota
  owner_name?: string; // nao esta no modelo, adicionado pela rota
}

export type TaskContextType = {
  criarTarefa: (tipo: string, tarefa: any) => Promise<any>;
  updateTaskAssignment: (taskId: string, assignment_id: string) => Promise<any>;
  getCategorias: () => Promise<Categoria[]>;
  getDepartamentos: () => Promise<Departamento[]>;
  postNewTask: (newTask: any) => Promise<any>;
  inativarTask: (taskid: any) => Promise<any>;
  fetchTasks: () => void;
  fetchClienteTasks: (clienteId: string) => void;
  createQuestionarioTasks: (questionarioId: any) => Promise<any>;
  tasks: TaskType[];

  tasksCliente: TaskType[];
  setTasksCliente: Dispatch<SetStateAction<TaskType[]>>;
  setTasks: Dispatch<SetStateAction<TaskType[]>>;
  selectedTask: TaskType | null;
  setSelectedTask: Dispatch<SetStateAction<TaskType | null>>;
  updateTask: (task: TaskType) => Promise<any>;
  setComments: (v: any) => any;
  comments: any;
  getComments: (
    taskid: string | undefined | null,
    clienteId?: string
  ) => Promise<any>;
  // createMultipleSubTasks: (
  //   tasks: any[],
  //   cliente_id: string,
  //   sponsor: string
  // ) => Promise<any>;
};

export const useTaskContext = (): TaskContextType => {
  const globalContext = useContext(GlobalContext);

  const [tasks, setTasks] = useState<TaskType[]>([]);
  const [selectedTask, setSelectedTask] = useState<TaskType | null>(null);
  const [tasksCliente, setTasksCliente] = useState<TaskType[]>([]);
  const [comments, setComments] = useState<any[]>([]);

  // Função para obter a lista de comentários relacionados ao ticket
  const getComments = (
    taskid: string | undefined | null,
    clienteId?: string
  ): Promise<any[]> => {
    return new Promise(async (resolve, reject) => {
      try {
        let response: any;
        if (taskid) {
          response = await api.get(`/comments/${taskid}`);
        } else {
          response = await api.get(`/comments/cliente/${clienteId}`);
        }

        setComments((prevComments) => {
          const newComments = response.data.filter(
            (newComment: any) =>
              !prevComments.some((comment) => comment._id === newComment._id)
          );

          const updatedComments = [...prevComments, ...newComments];
          resolve(updatedComments); // Resolve a Promise com os comentários atualizados

          return updatedComments;
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const fetchTasks = async () => {
    globalContext?.incrementLoadingCount();
    try {
      const response = await api.get<TaskType[]>("/tasks");
      setTasks(response.data);
    } catch (error) {
      console.error("Erro ao buscar tarefas:", error);
    } finally {
      globalContext?.decrementLoadingCount();
    }
  };

  const fetchClienteTasks = async (clienteId: string) => {
    globalContext?.incrementLoadingCount();
    try {
      const response = await api.get<TaskType[]>("/tasks/cliente/" + clienteId);
      setTasksCliente(response.data);
    } catch (error) {
      console.error("Erro ao buscar tarefas:", error);
    } finally {
      globalContext?.decrementLoadingCount();
    }
  };

  const criarTarefa = async (tipo: string, tarefa: any) => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/criar-tarefa`, {
          tipo: tipo,
          tarefa: tarefa,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao criar tarefa!");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const postNewTask = async (newTask: any) => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/tasks`, newTask)
        .then((response) => {
          message.success("Nova tarefa cadastrada com sucesso!");
          fetchTasks();
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao cadastrar nova tarefa.");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const inativarTask = async (taskId: string) => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .put(`/tasks/${taskId}/inactivate`)
        .then((response) => {
          message.success("Item removido com sucesso!");
          fetchTasks();
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao cadastrar nova tarefa.");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  // const createMultipleSubTasks = async (
  //   tasks: any[],
  //   cliente_id: string,
  //   sponsor: string
  // ) => {
  //   globalContext?.incrementLoadingCount();
  //   return new Promise(async (resolve, reject) => {
  //     await api
  //       .post(`/create-multiple-sub-tasks`, {
  //         tasks,
  //         cliente_id,
  //         sponsor,
  //       })
  //       .then((response) => {
  //         resolve(response.data);
  //       })
  //       .catch((err) => {
  //         message.error("Erro ao criar múltiplas sub tarefas!");
  //         reject(err);
  //       })
  //       .finally(() => globalContext?.decrementLoadingCount());
  //   });
  // };

  const getDepartamentos = async () => {
    globalContext?.incrementLoadingCount();
    return new Promise<Departamento[]>(async (resolve, reject) => {
      try {
        const response = await api.get("/departamentos"); // Substitua pelo endpoint correto
        resolve(response.data);
      } catch (error) {
        message.error("Erro ao buscar departamentos!");
        reject(error);
      } finally {
        globalContext?.decrementLoadingCount();
      }
    });
  };

  const getCategorias = async () => {
    globalContext?.incrementLoadingCount();
    return new Promise<Categoria[]>(async (resolve, reject) => {
      try {
        const response = await api.get("/categorias"); // Substitua pelo endpoint correto
        resolve(response.data);
      } catch (error) {
        message.error("Erro ao buscar categorias!");
        reject(error);
      } finally {
        globalContext?.decrementLoadingCount();
      }
    });
  };

  const updateTaskAssignment = (taskId: string, assignment_id: string) => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .put(`/tasks/${taskId}/update-assignment`, {
          assignment_id: assignment_id,
        })
        .then((response) => {
          const updatedTask = response?.data;
          const updatedTaskId = updatedTask?._id;

          // Atualizando o state `tasks`
          if (tasks?.find((task) => task._id === updatedTaskId)) {
            setTasks((prevTasks) =>
              prevTasks.map((task) =>
                task._id === updatedTaskId ? { ...task, ...updatedTask } : task
              )
            );
          }

          // Atualizando o state `tasksCliente`
          if (tasksCliente?.find((task) => task._id === updatedTaskId)) {
            setTasksCliente((prevTasksCliente) =>
              prevTasksCliente.map((task) =>
                task._id === updatedTaskId ? { ...task, ...updatedTask } : task
              )
            );
          }

          message.success("Atribuição atualizada com sucesso!");
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao atualizar tarefa!");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const updateTask = (task: TaskType) => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .put(`/tasks/${task._id}`, task)
        .then((response) => {
          const updatedTask = response?.data;
          const updatedTaskId = updatedTask?._id;

          // Atualizando o state `tasks`
          if (tasks?.find((task) => task._id === updatedTaskId)) {
            setTasks((prevTasks) =>
              prevTasks.map((task) =>
                task._id === updatedTaskId ? { ...task, ...updatedTask } : task
              )
            );
          }

          // Atualizando o state `tasksCliente`
          if (tasksCliente?.find((task) => task._id === updatedTaskId)) {
            setTasksCliente((prevTasksCliente) =>
              prevTasksCliente.map((task) =>
                task._id === updatedTaskId ? { ...task, ...updatedTask } : task
              )
            );
          }

          setSelectedTask(updatedTask);

          message.success("Item atualizado com sucesso!");
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao atualizar item!");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const createQuestionarioTasks = (questionarioId: any) => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .post("createquestionariotasks", {
          questionario_id: questionarioId,
        })
        .then((response) => {
          resolve(response.data);
          fetchTasks();
          message.success("Plano de ação criado!");
        })
        .catch((err) => {
          message.error("Ocorreu um erro ao criar plano de ação!");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };
  return {
    criarTarefa,
    updateTaskAssignment,
    getCategorias,
    getDepartamentos,
    postNewTask,
    fetchTasks,
    tasks,
    setTasks,
    selectedTask,
    setSelectedTask,
    createQuestionarioTasks,
    fetchClienteTasks,
    setTasksCliente,
    tasksCliente,
    inativarTask,
    updateTask,
    comments,
    setComments,
    getComments,
    // createMultipleSubTasks,
  };
};
