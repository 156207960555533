import React, { useEffect, useState } from "react";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import TimelineGeral from "../../../../components/Timeline/TimelineGeral";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Input,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { RadioChangeEvent } from "antd/lib";
const { Option } = Select;
const { TextArea } = Input;

const ProgramaPrivacidade = () => {
  const { currentCliente, fetchClienteLogs, postLog } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [logs, setLogs] = useState<any[] | null>(null);
  const [loadingLogs, setLoadingLogs] = useState<boolean>(true);
  const [loadingPostLog, setLoadingPostLog] = useState<boolean>(false);
  const [value, setValue] = useState(1);
  const [tipoHistorico, setTipoHistorico] = useState(false);
  const [filtro, setFiltro] = useState<string | null>(null);

  const onChangeFiltros = (e: RadioChangeEvent) => {
    setFiltro(e.target.value);
  };

  const onChangeHistorico = (e: RadioChangeEvent) => {
    setTipoHistorico(e.target.checked);
  };

  useEffect(() => {
    if (currentCliente?._id) {
      fetchClienteLogs(currentCliente._id)
        .then((r) => setLogs(r))
        .finally(() => setLoadingLogs(false));
    }
  }, [currentCliente]);

  const [activity, setActivity] = useState(null);
  const [method, setMethod] = useState(null);
  const [object, setObject] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [sentence, setSentence] = useState("");

  const updateSentence = () => {
    if (activity && method && object && origin && destination) {
      const newSentence = `Realizada uma ${activity} (${method}) sobre ${object}, com origem em ${origin} e destino ${destination}.`;
      setSentence(newSentence);
    }
  };

  // Atualiza a frase sempre que qualquer seleção muda
  React.useEffect(updateSentence, [
    activity,
    method,
    object,
    origin,
    destination,
  ]);

  const handleFinish = () => {
    setLoadingPostLog(true);
    postLog(
      tipoHistorico ? "Histórico adicionado" : "Registro adicionado",
      sentence,
      currentCliente._id
    )
      .then(() =>
        fetchClienteLogs(currentCliente._id)
          .then((r) => setLogs(r))
          .finally(() => setLoadingLogs(false))
      )
      .finally(() => {
        setLoadingPostLog(false);

        setSentence("");
      });
  };

  const atividades = [
    "ligação",
    "mensagem",
    "reunião",
    "análise",
    "resposta",
    "notificação",
    "manifestação",
  ];

  const meios = [
    "telefone",
    "Whatsapp",
    "Teams",
    "Workplace",
    "online",
    "presencial",
    "e-mail",
    "chat",
    "processo",
    "Meudpo",
    "CPPD",
  ];

  const objetos = [
    "RIPD",
    "LIA",
    "ROT",
    "Mapeamento de fluxos",
    "Mapeamento de dados",
    "treinamento",
    "Workshop",
    "processo administrativo fiscalizador",
    "processo administrativo sancionatório",
    "não cumprimento de atividade do Programa de Privacidade",
    "exercício de direito pelo titular de dados",
    "governança do programa de privacidade",
    "prestação de contas do programa de privacidade",
    "consulta Meudpo",
    "contrato",
  ];

  const origens = [
    "ANPD",
    "titular de dados",
    "cliente",
    "usuário",
    "Poder Judiciário",
    "Procon",
    "Senacon",
    "Fornecedor",
    "Parceiro comercial",
    "MeResponda",
    "Canal LGPD",
    "Seusdados",
    "Meudpo",
  ];

  const destinos = [
    "cliente",
    "Seusdados",
    "Titular de Dados",
    "ANPD",
    "Procon",
    "Parceiro comercial",
    "Fornecedor",
  ];

  return (
    <div className="programa-privaciade">
      <BackHeader title="Programa de Privacidade" prev />
      {currentCliente?._id ? (
        <>
          <h4>Linha do tempo</h4>
          <br />
          <p style={{ marginBottom: 10 }}>Montagem da descrição (opcional)</p>
          <Row gutter={12}>
            <Col span={3}>
              <Select
                placeholder="Atividade"
                onChange={(value) => setActivity(value)}
                style={{ width: "100%", marginBottom: 8 }}
              >
                {atividades.map((a) => (
                  <Option value={a}>{a}</Option>
                ))}
              </Select>
            </Col>
            <Col span={3}>
              <Select
                placeholder="Meio"
                onChange={(value) => setMethod(value)}
                style={{ width: "100%", marginBottom: 8 }}
              >
                {meios.map((m) => (
                  <Option value={m}>{m}</Option>
                ))}
              </Select>
            </Col>
            <Col span={3}>
              <Select
                placeholder="Objeto"
                onChange={(value) => setObject(value)}
                style={{ width: "100%", marginBottom: 8 }}
              >
                {objetos.map((o) => (
                  <Option value={o}>{o}</Option>
                ))}
              </Select>
            </Col>
            <Col span={3}>
              <Select
                placeholder="Origem"
                onChange={(value) => setOrigin(value)}
                style={{ width: "100%", marginBottom: 8 }}
              >
                {origens.map((o) => (
                  <Option value={o}>{o}</Option>
                ))}
              </Select>
            </Col>
            <Col span={3}>
              <Select
                placeholder="Destino"
                onChange={(value) => setDestination(value)}
                style={{ width: "100%", marginBottom: 8 }}
              >
                {destinos.map((d) => (
                  <Option value={d}>{d}</Option>
                ))}
              </Select>
            </Col>
          </Row>
          <p style={{ marginBottom: 10 }}>Descrição do registro</p>
          <Row gutter={12}>
            <Col span={15}>
              <TextArea
                autoSize
                value={sentence}
                showCount
                onChange={(e) => setSentence(e.target.value)} // Permite a edição manual da frase
                placeholder="Descreva..."
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Button
            loading={loadingPostLog}
            type="primary"
            style={{ marginTop: "1rem" }}
            icon={<PlusOutlined />}
            onClick={handleFinish}
          >
            Adicionar registro
          </Button>
          <Tooltip title="Adicionar registros como histórico">
            <Checkbox
              onChange={onChangeHistorico}
              style={{ marginLeft: "1rem" }}
            >
              Histórico
            </Checkbox>
          </Tooltip>
          <br />
          <br />
          <hr style={{ opacity: 1, borderColor: "#000000 !important" }} />
          <Radio.Group onChange={onChangeFiltros} value={filtro}>
            <Radio value={null}>Todos os registros</Radio>
            <Radio value={"Registro adicionado"}>
              Apenas registro de consultores
            </Radio>
            <Radio value={"Histórico adicionado"}>Apenas histórico</Radio>
          </Radio.Group>
          <TimelineGeral
            logs={logs}
            loadingLogs={loadingLogs}
            filter={filtro}
          />
        </>
      ) : (
        <Alert
          type="warning"
          message="Selecione um cliente para visualizar!"
          showIcon
        />
      )}
    </div>
  );
};

export default ProgramaPrivacidade;
