import BackHeader from "../../../components/BackHeader/BackHeader";
import ListQuestinarios from "../../Apps/Questionarios/ListQuestionarios/ListQuestionarios";

const QuestionariosRIPD = () => {
  return (
    <div className="questionarios_RIPD">
      <BackHeader title="Aplicações RIPD" />
      <ListQuestinarios editRIPD />
    </div>
  );
};

export default QuestionariosRIPD;
