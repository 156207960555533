import {
  CheckCircleFilled,
  CheckOutlined,
  CloseOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { GlobalContextType } from "../../../../../../@types/global";
import { GlobalContext } from "../../../../../../context/globalContext";
import React, { ReactNode, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { InfoCircle } from "iconsax-react";
import { Titular } from "../../../../../../@types/titular";
const { TextArea } = Input;

interface GuiaColetaProps {
  titular: Titular;
  categoriasUnicas: string[] | any;
  icon: ReactNode;
  setIcon: (icon: ReactNode) => void;
  loadingTitulares: boolean;
  setLoadingTitulares: (v: boolean) => void;
  activeTab: string;
  setActiveTab: (v: string) => void;
}

const ColetaGuia: React.FC<GuiaColetaProps> = ({
  titular,
  categoriasUnicas,
  icon,
  setIcon,
  loadingTitulares,
  setLoadingTitulares,
  activeTab,
  setActiveTab,
}) => {
  const { moduloMapeamento } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const categoriasPermitidas = [
    "Candidato",
    "Cliente",
    "Colaborador",
    "Lead (cliente em potencial)",
    "Conselheiro",
    "Diretor",
    "Estagiário",
    "Prestador de Serviço",
    "Servidor Público",
    "Voluntário",
    "Acionista",
    "Visitante",
    "Aluno",
    "Paciente",
  ];

  useEffect(() => {
    // Seleciona a div com a classe `ant-tabs-content-holder` e reseta o scroll
    const tabsContent = document.querySelector(".ant-tabs-content-holder");
    if (tabsContent) {
      tabsContent.scrollTop = 0;
    }
  }, [activeTab]);

  return (
    <>
      {/* Categoria dos titulares */}
      <>
        <h4>Coleta</h4>
        <hr />
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          1. Qual a categoria do titular de dados?{" "}
          <Tooltip title="É o tipo de relação que as pessoas têm com sua empresa">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <Radio.Group
          onChange={(value) => {
            moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
              let currentTitular = titular;
              currentTitular.categoria = value.target.value;

              return [
                ...prevTitulares.filter(
                  (t) => t.key !== titular.key && t?._id !== titular?._id
                ),
                currentTitular,
              ];
            });
          }}
          style={{ width: "100%" }}
          value={titular.categoria}
        >
          <Row>
            <Col span={8}>
              <Space direction="vertical">
                <Tooltip title="Pessoa que se aplica a uma vaga de emprego ou posição na sua empresa">
                  <Radio value={"Candidato"}>
                    Candidato <InfoCircle size={15} />
                  </Radio>
                </Tooltip>
                <Tooltip title="Indivíduo que contrata serviços ou adquire mercadorias mediante pagamento">
                  <Radio value={"Cliente"}>
                    Cliente{" "}
                    <InfoCircle
                      style={{
                        marginBottom: -2,
                      }}
                      size={15}
                    />
                  </Radio>
                </Tooltip>

                <Radio value={"Colaborador"}>Colaborador</Radio>
                <Tooltip title="Pessoa que demonstrou interesse em seus produtos ou serviços e pode se tornar um cliente no futuro">
                  <Radio value={"Lead (cliente em potencial)"}>
                    Lead (cliente em potencial){" "}
                    <InfoCircle
                      style={{
                        marginBottom: -2,
                      }}
                      size={15}
                    />
                  </Radio>
                </Tooltip>
                <Tooltip title="Indivíduo que fornece orientação ou conselhos para a sua empresa, geralmente como parte de um conselho consultivo ou de administração">
                  <Radio value={"Conselheiro"}>
                    Conselheiro{" "}
                    <InfoCircle
                      style={{
                        marginBottom: -2,
                      }}
                      size={15}
                    />
                  </Radio>
                </Tooltip>
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical">
                <Tooltip title="Membro da alta gestão da empresa, responsável por tomar decisões estratégicas e liderar setores/organização como um todo">
                  <Radio value={"Diretor"}>
                    Diretor{" "}
                    <InfoCircle
                      style={{
                        marginBottom: -2,
                      }}
                      size={15}
                    />
                  </Radio>
                </Tooltip>
                <Tooltip title="Pessoa contratada sob o regime de contratação de estágio">
                  <Radio value={"Estagiário"}>
                    Estagiário{" "}
                    <InfoCircle
                      style={{
                        marginBottom: -2,
                      }}
                      size={15}
                    />
                  </Radio>
                </Tooltip>
                <Tooltip title="Pessoa contratada para realizar serviços específicos, sem ser um empregado direto">
                  <Radio value={"Prestador de Serviço"}>
                    Prestador de Serviço{" "}
                    <InfoCircle
                      style={{
                        marginBottom: -2,
                      }}
                      size={15}
                    />
                  </Radio>
                </Tooltip>
                <Tooltip title="Pessoa que trabalha em alguma capacidade governamental ou para uma entidade do setor público">
                  <Radio value={"Servidor Público"}>
                    Servidor Público{" "}
                    <InfoCircle
                      style={{
                        marginBottom: -2,
                      }}
                      size={15}
                    />
                  </Radio>
                </Tooltip>
                <Tooltip title="Pessoa que oferece seu tempo e habilidades sem receber pagamento">
                  <Radio value={"Voluntário"}>
                    Voluntário{" "}
                    <InfoCircle
                      style={{
                        marginBottom: -2,
                      }}
                      size={15}
                    />
                  </Radio>
                </Tooltip>
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical">
                <Tooltip title="Indivíduo  que possui uma parte do capital social da sua empresa, tendo interesse direto no seu desempenho financeiro.">
                  <Radio value={"Acionista"}>
                    Acionista{" "}
                    <InfoCircle
                      style={{
                        marginBottom: -2,
                      }}
                      size={15}
                    />
                  </Radio>
                </Tooltip>
                <Tooltip title="Pessoa que visita as instalações da sua empresa, podendo ser por motivos profissionais, comerciais ou como convidado.">
                  <Radio value={"Visitante"}>
                    Visitante{" "}
                    <InfoCircle
                      style={{
                        marginBottom: -2,
                      }}
                      size={15}
                    />
                  </Radio>
                </Tooltip>
                <Tooltip title="Indivíduo matriculado para estudar em instituições de ensino associadas ou oferecidas pela sua organização">
                  <Radio value={"Aluno"}>
                    Aluno{" "}
                    <InfoCircle
                      style={{
                        marginBottom: -2,
                      }}
                      size={15}
                    />
                  </Radio>
                </Tooltip>
                <Tooltip title="Indivíduo que recebe cuidados ou tratamentos médicos dos serviços de saúde associados à sua empresa.">
                  <Radio value={"Paciente"}>
                    Paciente{" "}
                    <InfoCircle
                      style={{
                        marginBottom: -2,
                      }}
                      size={15}
                    />
                  </Radio>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </Radio.Group>
        <div style={{ height: "0.4rem" }} />
        <Space>
          <Radio
            checked={
              categoriasPermitidas.find((c: any) => c === titular.categoria)
                ? false
                : true
            }
          >
            <Input
              size="small"
              placeholder="Outro"
              maxLength={500}
              showCount
              style={{ width: 250 }}
              onChange={(value) => {
                moduloMapeamento.setCurrentTitulares(
                  (prevTitulares: Titular[]) => {
                    let currentTitular = titular;
                    currentTitular.categoria = value.target.value;

                    return [
                      ...prevTitulares.filter((t) => t.key !== titular.key),
                      currentTitular,
                    ];
                  }
                );
              }}
              value={
                categoriasPermitidas.find((c: any) => c === titular.categoria)
                  ? ""
                  : titular.categoria
              }
            />
          </Radio>
        </Space>
        <div></div>
      </>
      <br />
      {/* Quantidade */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          2. Número estimado de titulares{" "}
          <Tooltip title="É a quantidade aproximada de pessoas cujos dados pessoais são tratados neste subprocesso">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <Radio.Group
          onChange={(value) => {
            moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
              let currentTitular = titular;
              currentTitular.quantidade = value.target.value;

              return [
                ...prevTitulares.filter((t) => t.key !== titular.key),
                currentTitular,
              ];
            });
          }}
          value={titular.quantidade}
        >
          <Space direction="vertical">
            <Radio value={"1 a 99 pessoas"}>1 a 99 pessoas</Radio>
            <Radio value={"100 a 999 pessoas"}>100 a 999 pessoas</Radio>
            <Radio value={"1.000 a 9.999 pessoas"}>1.000 a 9.999 pessoas</Radio>
            <Radio value={"10.000 a 99.999 pessoas"}>
              10.000 a 99.999 pessoas
            </Radio>
            <Radio value={"100.000 a 999.999 pessoas"}>
              100.000 a 999.999 pessoas
            </Radio>
            <Radio value={"+ 1.000.000 pessoas"}>+ 1.000.000 pessoas</Radio>
            <Radio value={"Não é possivel estimar"}>
              Não é possivel estimar
            </Radio>
          </Space>
        </Radio.Group>
        <div></div>
      </>
      <br />
      {/* Faixa etária */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          3. Faixa etária dos titulares{" "}
          <Tooltip title="É a classificação das pessoas (titulares) de acordo com a sua idade">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <Checkbox.Group
          onChange={(value) => {
            moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
              let currentTitular = titular;
              currentTitular.faixa_etaria = value;

              return [
                ...prevTitulares.filter((t) => t.key !== titular.key),
                currentTitular,
              ];
            });
          }}
          value={titular.faixa_etaria}
        >
          <Space direction="vertical">
            <Checkbox value={"Crianças (até 12 anos incompletos)"}>
              Crianças (até 12 anos incompletos)
            </Checkbox>
            <Checkbox value={"Adolescentes (13 a 18 anos)"}>
              Adolescentes (13 a 18 anos)
            </Checkbox>
            <Checkbox value={"Adultos (18 a 60 anos)"}>
              Adultos (18 a 60 anos)
            </Checkbox>
            <Checkbox value={"Idosos (+ de 60 anos)"}>
              Idosos (+ de 60 anos)
            </Checkbox>
          </Space>
        </Checkbox.Group>
        <div></div>
      </>
      <br />
      {/* Dados pessoais coletados */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          4. Tipos de dados pessoais coletados{" "}
          <Tooltip title="São todos os dados pessoais que você coleta ou trata neste subprocesso">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <>
          {categoriasUnicas?.map((categoria: any) => (
            <Collapse
              className="collapse_ghost"
              ghost
              items={[
                {
                  key: "4",
                  label: categoria,
                  children: moduloMapeamento?.dados
                    ?.filter((dado: any) => dado.categoria === categoria)
                    ?.map((dado: any) => (
                      <div
                        style={{
                          marginBottom: "0.5rem",
                        }}
                      >
                        <Button
                          size="small"
                          icon={
                            titular?.dados?.find(
                              (d) => d.dado_id === dado._id
                            ) ? (
                              <CheckCircleFilled
                                style={{
                                  color: "#5F29CC",
                                }}
                              />
                            ) : (
                              <PlusCircleOutlined />
                            )
                          }
                          onClick={() => {
                            moduloMapeamento.setCurrentTitulares(
                              (prevTitulares: Titular[]) => {
                                let currentTitular = titular;
                                if (
                                  titular?.dados?.find(
                                    (d) => d.dado_id === dado._id
                                  )
                                ) {
                                  // remove
                                  currentTitular.dados =
                                    currentTitular.dados?.filter(
                                      (d) => d.dado_id !== dado._id
                                    );
                                } else {
                                  // Adiciona
                                  currentTitular.dados = currentTitular.dados
                                    ? [
                                        ...currentTitular.dados.filter(
                                          (d) => d.dado_id !== dado._id
                                        ),
                                        {
                                          dado_id: dado._id,
                                        },
                                      ]
                                    : [
                                        {
                                          dado_id: dado._id,
                                        },
                                      ];
                                }
                                return [
                                  ...prevTitulares.filter(
                                    (t) => t.key !== titular.key
                                  ),
                                  currentTitular,
                                ];
                              }
                            );
                          }}
                        >
                          {dado.nome}
                        </Button>
                        {titular?.dados?.find(
                          (d) => d.dado_id === dado._id
                        ) && (
                          <>
                            <Tooltip
                              placement="right"
                              title="Origem do Dado é onde as informações pessoais são coletadas pela primeira vez"
                            >
                              <Select
                                size="small"
                                style={{
                                  width: 150,
                                  marginLeft: "1rem",
                                }}
                                placeholder="Origem do Dado"
                                value={
                                  titular?.dados?.find(
                                    (d) => d.dado_id === dado._id
                                  )?.origem
                                }
                                onChange={(value: string) => {
                                  moduloMapeamento.setCurrentTitulares(
                                    (prevTitulares: Titular[]) => {
                                      let currentTitular = titular;

                                      if (currentTitular.dados) {
                                        // Check if the dado exists in the array
                                        const existingDadoIndex =
                                          currentTitular.dados.findIndex(
                                            (d) => d.dado_id === dado._id
                                          );

                                        if (existingDadoIndex !== -1) {
                                          // se dado existe, update origem
                                          currentTitular.dados[
                                            existingDadoIndex
                                          ].origem = value;
                                        }
                                      }
                                      return [
                                        ...prevTitulares.filter(
                                          (t) => t.key !== titular.key
                                        ),
                                        currentTitular,
                                      ];
                                    }
                                  );
                                }}
                                options={[
                                  {
                                    value: "Fornecido pelo titular de dados",
                                    label:
                                      "Fornecido pelo " +
                                      (titular?.categoria ||
                                        "titular de dados"),
                                  },
                                  {
                                    value: "Fornecidos por terceiros",
                                    label: "Fornecidos por terceiros",
                                  },
                                  {
                                    value:
                                      "Fornecidos por outro departamento ou área interna",
                                    label:
                                      "Fornecidos por outro departamento ou área interna",
                                  },
                                ]}
                              />
                            </Tooltip>
                            {titular.dados.find((d) => d.dado_id === dado._id)
                              ?.origem === "Fornecidos por terceiros" && (
                              <Input
                                style={{
                                  width: "100%",
                                  marginTop: "0.25rem",
                                }}
                                maxLength={500}
                                placeholder="Informe o nome do departamento interno"
                                value={
                                  titular?.dados?.find(
                                    (d) => d.dado_id === dado._id
                                  )?.nome_dep_interno
                                }
                                onChange={(value) => {
                                  moduloMapeamento.setCurrentTitulares(
                                    (prevTitulares: Titular[]) => {
                                      let currentTitular = titular;

                                      if (currentTitular.dados) {
                                        // Check if the dado exists in the array
                                        const existingDadoIndex =
                                          currentTitular.dados.findIndex(
                                            (d) => d.dado_id === dado._id
                                          );

                                        if (existingDadoIndex !== -1) {
                                          // se dado existe, update origem
                                          currentTitular.dados[
                                            existingDadoIndex
                                          ].nome_dep_interno =
                                            value.target.value;
                                        }
                                      }
                                      return [
                                        ...prevTitulares.filter(
                                          (t) => t.key !== titular.key
                                        ),
                                        currentTitular,
                                      ];
                                    }
                                  );
                                }}
                              />
                            )}
                            {titular.dados.find((d) => d.dado_id === dado._id)
                              ?.origem ===
                              "Fornecido pelo titular de dados" && (
                              <Select
                                style={{
                                  width: "100%",
                                  marginTop: "0.25rem",
                                }}
                                placeholder="Selecione"
                                value={
                                  titular?.dados?.find(
                                    (d) => d.dado_id === dado._id
                                  )?.consentimento_titular
                                }
                                onChange={(value: string) => {
                                  moduloMapeamento.setCurrentTitulares(
                                    (prevTitulares: Titular[]) => {
                                      let currentTitular = titular;

                                      if (currentTitular.dados) {
                                        // Check if the dado exists in the array
                                        const existingDadoIndex =
                                          currentTitular.dados.findIndex(
                                            (d) => d.dado_id === dado._id
                                          );

                                        if (existingDadoIndex !== -1) {
                                          // se dado existe, update origem
                                          currentTitular.dados[
                                            existingDadoIndex
                                          ].consentimento_titular = value;
                                        }
                                      }
                                      return [
                                        ...prevTitulares.filter(
                                          (t) => t.key !== titular.key
                                        ),
                                        currentTitular,
                                      ];
                                    }
                                  );
                                }}
                                options={[
                                  {
                                    value:
                                      "O Titular não sabe que seus dados estão sendo usados",
                                    label:
                                      "O Titular não sabe que seus dados estão sendo usados",
                                  },
                                  {
                                    value:
                                      "O Titular sabe, mas não autorizou o uso",
                                    label:
                                      "O Titular sabe, mas não autorizou o uso",
                                  },
                                  {
                                    value:
                                      "O Titular sabe e autorizou o uso dos dados",
                                    label:
                                      "O Titular sabe e autorizou o uso dos dados",
                                  },
                                ]}
                              />
                            )}
                          </>
                        )}

                        {titular?.dados?.find(
                          (d) =>
                            d.dado_id === dado._id && dado.tipo === "sensivel"
                        ) && (
                          <Input
                            style={{
                              marginTop: "0.25rem",
                            }}
                            placeholder="Adicione o motivo deste dado ser indispensável"
                            value={
                              titular?.dados?.find(
                                (d) => d.dado_id === dado._id
                              )?.motivo
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              moduloMapeamento.setCurrentTitulares(
                                (prevTitulares: Titular[]) => {
                                  let currentTitular = titular;

                                  if (currentTitular.dados) {
                                    // Check if the dado exists in the array
                                    const existingDadoIndex =
                                      currentTitular.dados.findIndex(
                                        (d) => d.dado_id === dado._id
                                      );

                                    if (existingDadoIndex !== -1) {
                                      // se dado existe, update motivo
                                      currentTitular.dados[
                                        existingDadoIndex
                                      ].motivo = event.target.value;
                                    }
                                  }
                                  return [
                                    ...prevTitulares.filter(
                                      (t) => t.key !== titular.key
                                    ),
                                    currentTitular,
                                  ];
                                }
                              );
                            }}
                          />
                        )}
                      </div>
                    )),
                },
              ]}
            />
          ))}
        </>
      </>
      <br />
      {/* Finalidade */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          5. Finalidade e Necessidade{" "}
          <Tooltip title="Descrever por quais motivos os dados são coletados e identificar quais dados pessoais são desnecessários para alcançar essa finalidade.">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <TextArea
          onChange={(value) => {
            moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
              let currentTitular = titular;
              currentTitular.finalidade_coleta = value.target.value;

              return [
                ...prevTitulares.filter((t) => t.key !== titular.key),
                currentTitular,
              ];
            });
          }}
          value={titular.finalidade_coleta}
          autoSize={{ minRows: 3 }}
          showCount
          maxLength={5000}
        />
        <div></div>
      </>
      <br />
      {/* Medidas de segurança */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          6. Medidas de Segurança{" "}
          <Tooltip title="Indicar quais medidas garantem maior proteção aos dados pessoais coletados.">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <TextArea
          onChange={(value) => {
            moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
              let currentTitular = titular;
              currentTitular.medidas_coleta = value.target.value;

              return [
                ...prevTitulares.filter((t) => t.key !== titular.key),
                currentTitular,
              ];
            });
          }}
          value={titular.medidas_coleta}
          autoSize={{ minRows: 3 }}
          showCount
          maxLength={5000}
        />
        <div></div>
      </>
      <br />
      {/* Observações */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          7. Observações{" "}
          <Tooltip title="Caso esta etapa do processo tenha alguma particularidade, deixe-a aqui">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <TextArea
          onChange={(value) => {
            moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
              let currentTitular = titular;
              currentTitular.obs_coleta = value.target.value;

              return [
                ...prevTitulares.filter((t) => t.key !== titular.key),
                currentTitular,
              ];
            });
          }}
          value={titular.obs_coleta}
          // onChange={(e) => setValue(e.target.value)}
          autoSize={{ minRows: 3 }}
          showCount
          maxLength={5000}
        />
        <div></div>
      </>
      <br />
      {/* Salvar e avançar*/}
      <>
        <Button
          style={{
            background: "#32E6BE",
            color: "#333333",
            marginTop: "1rem",
          }}
          icon={icon}
          type="primary"
          loading={loadingTitulares}
          onClick={() => {
            setLoadingTitulares(true);
            setIcon(<SaveOutlined />);

            moduloMapeamento
              .saveTitulares(moduloMapeamento.currentTitulares)
              .then(() => {
                setIcon(<CheckOutlined />);
              })
              .catch(() => {
                setIcon(<CloseOutlined />);
              })
              .finally(() => {
                setLoadingTitulares(false);
                setActiveTab("Processamento");
              });
          }}
        >
          Salvar e avançar
        </Button>
        <div></div>
        <br />
      </>
    </>
  );
};

export default ColetaGuia;
