import React, { useState } from "react";
import { Modal, Form, DatePicker, message, Button, Alert } from "antd";
import dayjs, { Dayjs } from "dayjs";

interface EditDateModalProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  currentReuniaoId: string | null;
  onUpdateDate: (reuniaoId: string, date: Dayjs) => Promise<void>;
}

const EditDateModal: React.FC<EditDateModalProps> = ({
  isVisible,
  setIsVisible,
  currentReuniaoId,
  onUpdateDate,
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [loading, setLoading] = useState<boolean>(false);

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  const handleUpdateDate = async () => {
    if (selectedDate && currentReuniaoId) {
      setLoading(true);
      try {
        await onUpdateDate(currentReuniaoId, selectedDate);
        message.success("Data da reunião atualizada com sucesso!");
        setIsVisible(false);
      } catch (error) {
        message.error("Erro ao atualizar a data da reunião.");
      } finally {
        setLoading(false);
      }
    } else {
      message.warning("Por favor, selecione uma data.");
    }
  };

  return (
    <Modal
      title="Editar data da reunião"
      open={isVisible}
      onOk={handleUpdateDate}
      onCancel={() => setIsVisible(false)}
      footer={[
        <Button key="cancel" onClick={() => setIsVisible(false)}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleUpdateDate}
        >
          Ok
        </Button>,
      ]}
      width={800}
    >
      <Form layout="vertical">
        <Form.Item label="Data da reunião">
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            showTime
            format="HH:mm DD/MM/YYYY"
            placeholder="Selecione uma data"
          />
          {/* data_agendamento é anterior ao dia de hoje */}
          {selectedDate &&
            selectedDate.toDate().setHours(0, 0, 0, 0) <
              new Date().setHours(0, 0, 0, 0) && (
              <div>
                <br />
                <Alert
                  type="warning"
                  message="A data de agendamento é anterior à data atual. O convite do Teams não será atualizado, e nenhuma notificação será enviada."
                  showIcon
                />
              </div>
            )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditDateModal;
