import React, { useEffect, useState } from "react";
import "./styles.css";
import { Tooltip, BarChart, CartesianGrid, YAxis, Bar, XAxis } from "recharts";
import { GlobalContextType } from "../../../../../../../@types/global";
import { GlobalContext } from "../../../../../../../context/globalContext";
import api from "../../../../../../../services/api";
import { useParams } from "react-router-dom";
interface Response {
  questao_id: string;
  resposta: string[];
}

interface Questao {
  _id: string; // Make sure this matches the actual type from your API
  questao: string;
  ordem: number;
  tipo: string;
}
const Metricas = () => {
  const { grauRiscos, windowWidth, getGrauRiscos } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const params = useParams();

  const [dataAll, setDataAll] = useState<any>([]);
  const [dataBar, setDataBar] = useState<any>([]);
  const [porcentagemConforme, setPorcentagemConforme] = useState<any>(0);

  const [allRespostas, setAllRespostas] = useState<any>([]);
  const [metrica, setMetrica] = useState<any>(null);

  const [mergedData, setMergedData] = useState<
    { questao: string; ordem: number; respostas: string[] }[]
  >([]);

  useEffect(() => {
    async function fetchData() {
      if (grauRiscos[0]) {
        try {
          const response = await api.get(
            `/respostasforn/${params.questionarioid}`
          );

          if (response.data.metrica) {
            setMetrica(response.data.metrica);
          }

          // setMetrica()
          const questoesResponse = await api.get("/questoes");
          const questoes: Questao[] = questoesResponse.data;

          const mergedDataArray = response.data.respostas.map(
            (resposta: Response) => {
              const questao = questoes.find(
                (q: Questao) => q._id === resposta.questao_id
              );

              return {
                ...questao,
                respostas: resposta.resposta,
                graus: grauRiscos.filter(
                  (g: any) =>
                    g.questao_id.toString() === questao?._id.toString()
                ),
              };
            }
          );

          // const sortedMergedData = mergedDataArray
          //   .filter((q: any) => q.tipo !== "input" && q.graus[0])
          //   .sort((a: any, b: any) => a.ordem - b.ordem);

          const sortedMergedData = mergedDataArray.sort(
            (a: any, b: any) => a.ordem - b.ordem
          );

          let allRespData: any = [];

          sortedMergedData.forEach((questao: any) => {
            if (questao.respostas && questao.respostas[0]) {
              questao.respostas.map((item: any) => {
                if (
                  allRespData.filter(
                    (item: any) =>
                      item._id.toString() === questao._id.toString()
                  ).length !== 0
                ) {
                  return;
                }
                return allRespData.push({
                  ...questao,
                  resposta: item,
                  grau: grauRiscos
                    .filter(
                      (gr: any) =>
                        gr.questao_id.toString() === questao._id.toString()
                    )
                    .filter((gr: any) => gr.value.includes(item))[0]?.grau,
                });
              });
            }
          });

          setAllRespostas(allRespData);
          // setRespostasInconformes();
          setMergedData(sortedMergedData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
    fetchData();
  }, [params.questionarioid, grauRiscos]);

  useEffect(() => {
    getGrauRiscos();
  }, []);

  useEffect(() => {
    let meio =
      allRespostas.filter(
        (r: any) =>
          (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
          r._id !== "64ee165a41736da1927a46b7" && // 12
          r._id !== "64ee165a41736da1927a46c7" // 28
      ).length / 2;

    // acrescentar calculo da pergunta 12 (meio ponto para cada alternativa assinalada)
    if (allRespostas.find((r: any) => r._id === "64ee165a41736da1927a46b7")) {
      meio =
        meio +
        allRespostas
          .find((r: any) => r._id === "64ee165a41736da1927a46b7")
          ?.respostas?.filter((r: any) => r !== "Não sei informar")?.length /
          2;
    }

    // acrescentar calculo da pergunta 28 (meio ponto para cada alternativa assinalada)
    if (allRespostas.find((r: any) => r._id === "64ee165a41736da1927a46c7")) {
      meio =
        meio +
        allRespostas
          .find((r: any) => r._id === "64ee165a41736da1927a46c7")
          ?.respostas?.filter((r: any) => r !== "Nenhuma Ferramenta")?.length /
          2;
    }

    const conforme = allRespostas.filter((r: any) => r.grau === "sim").length;

    const somaMeioConforme = meio + conforme;

    const pontuacaopergunta7 =
      mergedData.find((data: any) => data?._id === "64ee165a41736da1927a46b2")
        ?.respostas?.length || 0;

    setPorcentagemConforme(
      metrica ? metrica : (somaMeioConforme / (21 + pontuacaopergunta7)) * 100
    );

    setDataAll([
      {
        name: "Conforme",
        value: allRespostas.filter((r: any) => r.grau === "sim").length,
        fill: "#43CB03",
      },
      {
        name: "Não Conforme",
        value: allRespostas.filter((r: any) => r.grau === "nao").length,
        fill: "#FE0C0C",
      },
      {
        name: "Pontos de Atenção",
        value: allRespostas.filter(
          (r: any) => r.grau === "meio" || r.grau === "Ponto de Atenção"
        ).length,
        fill: "blue",
      },
    ]);

    setDataBar([
      {
        categoria: "Informações gerais da empresa",
        Conformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "sim" && r.categoria === "Informações gerais da empresa"
        ).length,
        Inconformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "nao" && r.categoria === "Informações gerais da empresa"
        ).length,
      },
      {
        categoria: "Encarregado de Proteção de dados (DPO)",
        Conformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "sim" &&
            r.categoria === "Encarregado de Proteção de dados (DPO)"
        ).length,
        Inconformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "nao" &&
            r.categoria === "Encarregado de Proteção de dados (DPO)"
        ).length,
        "Pontos de Atenção": allRespostas.filter(
          (r: any) =>
            (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
            r.categoria === "Encarregado de Proteção de dados (DPO)"
        ).length,
      },
      {
        categoria: "Identificação dos tratamentos de dados pessoais",
        Conformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "sim" &&
            r.categoria === "Identificação dos tratamentos de dados pessoais"
        ).length,
        Inconformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "nao" &&
            r.categoria === "Identificação dos tratamentos de dados pessoais"
        ).length,
        "Pontos de Atenção": allRespostas.filter(
          (r: any) =>
            (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
            r.categoria === "Identificação dos tratamentos de dados pessoais"
        ).length,
      },
      {
        categoria: "Conformidade à Privacidade e Proteção de dados (LGPD)",
        Conformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "sim" &&
            r.categoria ===
              "Conformidade à Privacidade e Proteção de dados (LGPD)"
        ).length,
        Inconformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "nao" &&
            r.categoria ===
              "Conformidade à Privacidade e Proteção de dados (LGPD)"
        ).length,
        "Pontos de Atenção": allRespostas.filter(
          (r: any) =>
            (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
            r.categoria ===
              "Conformidade à Privacidade e Proteção de dados (LGPD)"
        ).length,
      },
      {
        categoria: "Atendimento aos direitos do titular de dados",
        Conformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "sim" &&
            r.categoria === "Atendimento aos direitos do titular de dados"
        ).length,
        Inconformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "nao" &&
            r.categoria === "Atendimento aos direitos do titular de dados"
        ).length,
        "Pontos de Atenção": allRespostas.filter(
          (r: any) =>
            (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
            r.categoria === "Atendimento aos direitos do titular de dados"
        ).length,
      },
      {
        categoria: "Tecnologia da Informação",
        Conformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "sim" && r.categoria === "Tecnologia da Informação"
        ).length,
        Inconformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "nao" && r.categoria === "Tecnologia da Informação"
        ).length,
        "Pontos de Atenção": allRespostas.filter(
          (r: any) =>
            (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
            r.categoria === "Tecnologia da Informação"
        ).length,
      },
      {
        categoria: "Segurança da Informação",
        Conformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "sim" && r.categoria === "Segurança da Informação"
        ).length,
        Inconformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "nao" && r.categoria === "Segurança da Informação"
        ).length,
        "Pontos de Atenção": allRespostas.filter(
          (r: any) =>
            (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
            r.categoria === "Segurança da Informação"
        ).length,
      },
      {
        categoria:
          "Histórico de incidentes de segurança envolvendo dados pessoais",
        Conformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "sim" &&
            r.categoria ===
              "Histórico de incidentes de segurança envolvendo dados pessoais"
        ).length,
        Inconformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "nao" &&
            r.categoria ===
              "Histórico de incidentes de segurança envolvendo dados pessoais"
        ).length,
        "Pontos de Atenção": allRespostas.filter(
          (r: any) =>
            (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
            r.categoria ===
              "Histórico de incidentes de segurança envolvendo dados pessoais"
        ).length,
      },
      {
        categoria: "Cultura de Segurança da informação",
        Conformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "sim" &&
            r.categoria === "Cultura de Segurança da informação"
        ).length,
        Inconformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "nao" &&
            r.categoria === "Cultura de Segurança da informação"
        ).length,
        "Pontos de Atenção": allRespostas.filter(
          (r: any) =>
            (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
            r.categoria === "Cultura de Segurança da informação"
        ).length,
      },
      {
        categoria: "Treinamento e conscientização",
        Conformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "sim" && r.categoria === "Treinamento e conscientização"
        ).length,
        Inconformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "nao" && r.categoria === "Treinamento e conscientização"
        ).length,
        "Pontos de Atenção": allRespostas.filter(
          (r: any) =>
            (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
            r.categoria === "Treinamento e conscientização"
        ).length,
      },
      {
        categoria: "Gerenciamento de Banco de dados",
        Conformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "sim" &&
            r.categoria === "Gerenciamento de Banco de dados"
        ).length,
        Inconformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "nao" &&
            r.categoria === "Gerenciamento de Banco de dados"
        ).length,
        "Pontos de Atenção": allRespostas.filter(
          (r: any) =>
            (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
            r.categoria === "Gerenciamento de Banco de dados"
        ).length,
      },
      {
        categoria: "Transferência internacional de dados",
        Conformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "sim" &&
            r.categoria === "Transferência internacional de dados"
        ).length,
        Inconformidades: allRespostas.filter(
          (r: any) =>
            r.grau === "nao" &&
            r.categoria === "Transferência internacional de dados"
        ).length,
        "Pontos de Atenção": allRespostas.filter(
          (r: any) =>
            (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
            r.categoria === "Transferência internacional de dados"
        ).length,
      },
    ]);
  }, [allRespostas]);

  return (
    <div className="analise__metrics">
      {/* MATURIDADE */}
      <div
        style={{
          width: 50,
          height: 200,
          marginRight: windowWidth > 800 ? "4rem" : "1rem",
          background:
            "rgb(0,255,8) linear-gradient(180deg,  rgba(0,255,8,1)0%, rgba(255,188,0,1) 50%,  rgba(255,0,0,1)100%)",
        }}
      >
        <p
          style={{
            marginTop: "-21px",
            marginLeft: "-11px",
          }}
          translate="no"
        >
          Maturidade
        </p>
        <div
          style={{
            width: "70px",
            height: "2px",
            marginTop: "-2px",
            background: "#000",
            opacity: 0.3,
            marginLeft: "-10px",
          }}
        />
        <div
          style={{
            width: "70px",
            height: "2px",
            background: "#000",
            marginLeft: "-10px",
            marginTop: `calc(${100 - porcentagemConforme}px*2)`,
          }}
        >
          <div className="arrow right" style={{ top: "-3.5px" }}>
            <div
              style={{
                transform: "rotate(-180deg)",
                right: "5rem",
                top: "-0.5rem",
                position: "relative",
              }}
              translate="no"
            >
              <p
                style={{
                  color:
                    porcentagemConforme > 33 && porcentagemConforme < 66
                      ? "rgba(255,188,0,1)"
                      : porcentagemConforme >= 66
                      ? "#5dcf00"
                      : "rgba(255,0,0,1)",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
                translate="no"
              >
                {porcentagemConforme >= 66 && "ALTA"}
                {porcentagemConforme > 33 &&
                  porcentagemConforme < 66 &&
                  "MÉDIA"}
                {porcentagemConforme <= 33 && "BAIXA"}
                <p
                  style={{
                    position: "absolute",
                    fontSize: "0.8rem",
                    width: "6rem",
                    marginLeft: "-0.4rem",
                    marginTop: "-0.4rem",
                  }}
                  translate="no"
                >
                  {porcentagemConforme.toFixed(2)} pontos
                </p>
              </p>
            </div>
          </div>
          <div
            className="arrow left"
            style={{ top: "-12.9px", left: "63px" }}
          />
        </div>
        <div
          style={{
            width: "70px",
            height: "2px",
            background: "#000",
            opacity: 0.3,
            marginLeft: "-10px",
            marginTop: `calc(196px - calc(${100 - porcentagemConforme}px*2))`,
          }}
        />
      </div>

      {/* RISCO */}
      <div
        style={{
          width: 50,
          height: 200,
          marginRight: windowWidth > 800 ? "4rem" : "1rem",
          background:
            "rgb(0,255,8) linear-gradient(180deg, rgba(255,0,0,1) 0%, rgba(255,188,0,1) 50%,  rgba(0,255,8,1)100%)",
        }}
      >
        <p
          style={{
            marginTop: "-21px",
            marginLeft: "8px",
          }}
        >
          Risco
        </p>
        <div
          style={{
            width: "70px",
            height: "2px",
            marginTop: "-2px",
            background: "#000",
            opacity: 0.3,
            marginLeft: "-10px",
          }}
        />
        <div
          style={{
            width: "70px",
            height: "2px",
            background: "#000",
            marginLeft: "-10px",
            marginTop: `calc(${porcentagemConforme}px*2)`,
          }}
        >
          <div className="arrow right" style={{ top: "-3.5px" }}>
            <div
              style={{
                transform: "rotate(-180deg)",
                right: "5rem",
                top: "-0.5rem",
                position: "relative",
              }}
              translate="no"
            >
              <p
                style={{
                  color:
                    porcentagemConforme > 33 && porcentagemConforme < 66
                      ? "rgba(255,188,0,1)"
                      : porcentagemConforme >= 66
                      ? "#5dcf00"
                      : "rgba(255,0,0,1)",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
                translate="no"
              >
                {porcentagemConforme >= 66 && "BAIXO"}
                {porcentagemConforme > 33 &&
                  porcentagemConforme < 66 &&
                  "MÉDIO"}
                {porcentagemConforme <= 33 && "ALTO"}
                <p
                  style={{
                    position: "absolute",
                    fontSize: "0.8rem",
                    width: "6rem",
                    marginLeft: "-0.4rem",
                    marginTop: "-0.4rem",
                  }}
                  translate="no"
                >
                  {100 - porcentagemConforme.toFixed(2)} pontos
                </p>
              </p>
            </div>
          </div>
          <div
            className="arrow left"
            style={{ top: "-12.9px", left: "63px" }}
          />
        </div>
        <div
          style={{
            width: "70px",
            height: "2px",
            background: "#000",
            opacity: 0.3,
            marginLeft: "-10px",
            marginTop: `calc(196px - calc(${porcentagemConforme}px*2))`,
          }}
        />
      </div>

      <BarChart
        style={windowWidth > 800 ? {} : { marginTop: "2rem" }}
        width={350}
        height={200}
        data={dataBar.filter(
          (data: any) => data.Inconformidades + data.Conformidades !== 0
        )}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis />
        <XAxis
          dataKey="categoria"
          axisLine={true}
          tickLine={false}
          interval={0}
          height={1}
        />
        <Tooltip />
        <Bar dataKey="Conformidades" fill="#43CB03" />
        <Bar dataKey="Inconformidades" fill="#FE0C0C" />
        <Bar dataKey="Pontos de Atenção" fill="#ffa600" />
      </BarChart>
    </div>
  );
};

export default Metricas;
