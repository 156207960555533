import { useNavigate } from "react-router-dom";
import * as React from "react";
import "./styles.css";
import {
  Table,
  Input,
  Button,
  Tag,
  Tabs,
  Switch,
  Tooltip,
  Upload,
  Dropdown,
} from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContextType } from "../../../../@types/global";
import {
  DownloadOutlined,
  FileExcelFilled,
  FileExcelOutlined,
} from "@ant-design/icons";
import * as XLSX from "xlsx";
import { GlobalContext } from "../../../../context/globalContext";
import {
  EditOutlined,
  EnterOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { AreaType } from "../../../../context/processosContext";
import ModalImportar from "./components/ModalImportar/ModalImportar";
import { MenuProps } from "antd/lib";
import moment from "moment";

const { Search } = Input;

const Capitulos = () => {
  const { moduloProcessos } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [search, setSearch] = React.useState<string>("");
  const [showNovaArea, setShowNovaArea] = React.useState(false);
  const [novaArea, setNovaArea] = React.useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [showImport, setShowImport] = React.useState<boolean>(false);
  const [importFile, setImportFile] = React.useState<any>(null);
  const [importQuestionario, setImportQuestionario] = React.useState<any>(null);

  const handleSwitchChange = async (
    areaId: string,
    description: string | null,
    padrao: boolean
  ) => {
    setLoading(true);

    moduloProcessos
      .updateArea(areaId, description, padrao)
      .finally(() => setLoading(false));
  };

  const columnsArea = [
    {
      title: "Área",
      dataIndex: "description",
    },
    {
      title: (
        <Tooltip
          placement="topLeft"
          title="Deseja exibir como áreas padrão no módulo Assessment Process"
        >
          <div>
            <span>Exibir em Assessment Process? </span>
            <InfoCircleOutlined />
          </div>
        </Tooltip>
      ),
      dataIndex: "padrao",
      render: (padrao: any, record: AreaType) => (
        <Switch
          checked={padrao}
          checkedChildren="Exibir"
          unCheckedChildren="Não"
          loading={loading}
          onChange={(checked: boolean) =>
            handleSwitchChange(record._id, null, checked)
          }
        />
      ),
    },
    {
      title: "Data de criação",
      dataIndex: "created_at",
      render: (data: any) => moment(new Date(data)).format("DD/MM/YYYY HH:mm"),
    },
  ];

  const columnsCap = [
    {
      title: "Processo",
      dataIndex: "description",
    },
    {
      title: "Data de criação",
      dataIndex: "created_at",
      render: (data: any) => moment(new Date(data)).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Ação",
      dataIndex: "_id",
      render: (id: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => navigate("/capitulos/editar/" + id)}
          >
            Editar processo
          </Button>
        </div>
      ),
    },
  ];

  const handleImport = (file: any) => {
    setShowImport(true);

    const reader = new FileReader();

    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      reader.readAsArrayBuffer(file);
      reader.onload = (e: any) => {
        const workbook = XLSX.read(e.target.result, { type: "buffer" });
        const worksheetName = workbook.SheetNames[0];

        const worksheet: any = workbook.Sheets[worksheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);

        // Extrair itens da coluna A
        const itensColunaA: any = [];
        const itensColunaB: any = [];
        const itensColunaC: any = [];
        const itensColunaE: any = [];

        const range = XLSX.utils.decode_range(worksheet["!ref"]);
        for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
          const cellAddressA = { r: rowNum, c: 0 }; // Coluna A (c: 0)
          const cellRefA = XLSX.utils.encode_cell(cellAddressA);
          const cellA = worksheet[cellRefA];

          if (cellA) {
            const cellValue = cellA.v;
            const cellLocation = XLSX.utils.encode_cell({ r: rowNum, c: 0 });
            itensColunaA.push({ value: cellValue, location: cellLocation });
          }

          const cellAddressB = { r: rowNum, c: 1 }; // Coluna B (c: 1)
          const cellRefB = XLSX.utils.encode_cell(cellAddressB);
          const cellB = worksheet[cellRefB];

          if (cellB) {
            const cellValue = cellB.v;
            const cellLocation = XLSX.utils.encode_cell({ r: rowNum, c: 1 });
            itensColunaB.push({ value: cellValue, location: cellLocation });
          }

          const cellAddressC = { r: rowNum, c: 2 }; // Coluna C (c: 2)
          const cellRefC = XLSX.utils.encode_cell(cellAddressC);
          const cellC = worksheet[cellRefC];

          if (cellC) {
            const cellValue = cellC.v;
            const cellLocation = XLSX.utils.encode_cell({ r: rowNum, c: 2 });
            itensColunaC.push({ value: cellValue, location: cellLocation });
          }

          const cellAddressE = { r: rowNum, c: 4 }; // Coluna E (c: 42)
          const cellRefE = XLSX.utils.encode_cell(cellAddressE);
          const cellE = worksheet[cellRefE];

          if (cellE) {
            const cellValue = cellE.v;
            const cellLocation = XLSX.utils.encode_cell({ r: rowNum, c: 4 });
            itensColunaE.push({ value: cellValue, location: cellLocation });
          }
        }

        const perguntasUnicas: any = {};

        itensColunaB.forEach((pergunta: any) => {
          const perguntaSplit = pergunta.value.split("?");
          const textoPergunta = perguntaSplit[0];

          if (!perguntasUnicas[textoPergunta]) {
            perguntasUnicas[textoPergunta] = {
              value: pergunta.value,
              location: pergunta.location,
              valor: itensColunaC
                .filter(
                  (grau: any) =>
                    Number(grau.location.split("C")[1]) ===
                    Number(pergunta.location.split("B")[1])
                )
                .map((grau: any) => {
                  return {
                    ...grau,
                    option: perguntaSplit[1],
                    cp: itensColunaE.filter(
                      (tipo: any) =>
                        Number(grau.location.split("C")[1]) ===
                        Number(tipo.location.split("E")[1])
                    )?.[0]?.value,
                  };
                }),
            };
          } else {
            perguntasUnicas[textoPergunta].valor.push({
              ...itensColunaC.filter(
                (grau: any) =>
                  Number(grau.location.split("C")[1]) ===
                  Number(pergunta.location.split("B")[1])
              )[0],
              option: perguntaSplit[1],
            });
          }
        });

        // Formatar o array final no formato desejado
        const perguntas = Object.values(perguntasUnicas)
          .map((item: any) => {
            return {
              value: item.value,
              location: item.location,
              valor: item.valor,
            };
          })
          .filter((item: any) => item.value !== "" && item.value !== " ");
        let areaMap = itensColunaA.map((cap: any, index: number) => {
          return {
            ...cap,
            perguntas: perguntas.filter(
              (perguntas: any) =>
                Number(perguntas.location.split("B")[1]) >
                  Number(cap.location.split("A")[1]) &&
                Number(perguntas.location.split("B")[1]) <
                  Number(itensColunaA[index + 1]?.location.split("A")[1])
              // ? //    &&
              //   // itensColunaA.length - 1 === index
              //   true
              // : Number(perguntas.location.split("B")[1]) <
              //   Number(itensColunaA[index + 1]?.location.split("A")[1])
            ),
          };
        });

        setImportQuestionario(areaMap);
        // Verificar as células mescladas
        // if (worksheet["!merges"]) {
        //   worksheet["!merges"].forEach((merge) => {
        //     // Obter as coordenadas iniciais e finais das células mescladas
        //     const startCell = XLSX.utils.encode_cell(merge.s);
        //     const endCell = XLSX.utils.encode_cell(merge.e);

        //     // Extrair valor da célula mesclada
        //     const startCellRef = worksheet[startCell];
        //     const mergedValue = startCellRef ? startCellRef.v : "";

        //     // Armazenar o valor na estrutura de células mescladas da coluna A
        //     for (let row = merge.s.r; row <= merge.e.r; row++) {
        //       const cellAddress = XLSX.utils.encode_cell({ r: row, c: 0 });
        //       areasCaps[cellAddress] = mergedValue;
        //     }
        //   });
        //   console.log(areasCaps);
        // }
        // console.log(data);
        // console.log(data.filter((item: any) => item["__EMPTY_1"]));
        // Transforma o nome das propriedades para maiúsculas
        const newData = data.map((item: any) => {
          const newItem: any = {};
          for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
              newItem[key.toUpperCase()] = item[key];
            }
          }
          return newItem;
        });

        setImportFile(newData);
      };
    }

    return false;
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <Button
          href="https://arquivos-portal-seusdados.nyc3.cdn.digitaloceanspaces.com/modelo_importar_parceiros.xlsx"
          style={{ marginLeft: "-0.5rem", color: "#1677ff" }}
          size="small"
          type="link"
          icon={<DownloadOutlined />}
        >
          Baixar modelo
        </Button>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Upload
          showUploadList={false}
          beforeUpload={(file: any) => handleImport(file)}
          accept="text/csv,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        >
          <Button
            size="small"
            style={{ marginLeft: "-2.4rem", color: "#000" }}
            type="link"
            icon={<FileExcelFilled style={{ color: "green" }} />}
          >
            Enviar XLSX
          </Button>
        </Upload>
      ),
      key: "1",
    },
  ];
  const onSearchAreaCapitulo = (value: string) => setSearch(value);

  return (
    <div className="clientes">
      <BackHeader title={"Áreas / Processos / Perguntas"} />

      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "Geral",
            children: (
              <>
                {/* Busca áreas */}
                <div className="d-flex" style={{ marginBottom: "1rem" }}>
                  <Search
                    placeholder="Buscar áreas / questionários"
                    className="clientes__actions--search"
                    allowClear
                    enterButton="Buscar"
                    onSearch={onSearchAreaCapitulo}
                  />
                  <div style={{ marginLeft: "auto" }}>
                    {/* <Tooltip
                      title={
                        <>
                          Importar conjunto completo de Áreas, Processos e
                          Perguntas.
                          <br /> <br />
                          *Para importar somente perguntas, selecione um dos
                          processos.
                        </>
                      }
                    >
                      <Dropdown menu={{ items }} trigger={["click"]}>
                        <Button
                          style={{
                            background: "green",
                          }}
                          type="primary"
                          icon={<FileExcelFilled />}
                        >
                          Importar XLSX
                        </Button>
                      </Dropdown>
                    </Tooltip> */}
                    <Button
                      style={{ marginLeft: "1rem" }}
                      type="primary"
                      onClick={() => setShowNovaArea(true)}
                    >
                      Nova área
                    </Button>
                    <Button
                      style={{ marginLeft: "1rem" }}
                      type="primary"
                      onClick={() => navigate("/areas/editar")}
                    >
                      Novo processo
                    </Button>
                  </div>
                </div>
                {showNovaArea && (
                  <div className="clientes__actions">
                    <Input
                      placeholder="Digite o nome da nova área"
                      onChange={(v: any) => setNovaArea(v.target.value)}
                    />
                    <Button
                      className="clientes__actions--new"
                      type="primary"
                      disabled={novaArea.length > 1 ? false : true}
                      onClick={() => {
                        novaArea.length > 1 && setShowNovaArea(false);
                        novaArea.length > 1 && setNovaArea("");
                        novaArea.length > 1 &&
                          moduloProcessos.postArea(novaArea);
                      }}
                    >
                      Salvar
                    </Button>
                  </div>
                )}

                {/* tabelas area > cap > processo > sub-processo > pergunta */}
                {/* Área */}
                <Table
                  size="small"
                  dataSource={moduloProcessos.areas
                    ?.filter((a: any) => !a.cliente_id)
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(b.created_at).getTime() -
                        new Date(a.created_at).getTime()
                    )
                    .filter((c: any) =>
                      c.description
                        ?.toUpperCase()
                        .includes(search?.toUpperCase())
                    )}
                  columns={columnsArea}
                  rowKey={(record) => record._id}
                  expandable={{
                    expandedRowRender: (recordArea) => (
                      <>
                        <EnterOutlined
                          style={{
                            transform: "scaleX(-1)",
                            color: "#A3A3A3",
                            fontSize: 22,
                            marginLeft: "0.8rem",
                          }}
                        />
                        {/* Processo */}
                        <Table
                          pagination={false}
                          className="tabela_capitulos"
                          dataSource={moduloProcessos.capitulos
                            ?.sort(
                              (a: any, b: any) =>
                                new Date(a.created_at).getTime() -
                                new Date(b.created_at).getTime()
                            )
                            ?.filter(
                              (c: any) =>
                                !c.dependencia &&
                                c.tags.includes(recordArea._id)
                            )}
                          size="small"
                          rowKey={(recordSub) => recordSub._id}
                          expandable={{
                            expandedRowRender: (recordSub) => (
                              <>
                                <EnterOutlined
                                  style={{
                                    transform: "scaleX(-1)",
                                    color: "#A3A3A3",
                                    fontSize: 22,
                                    marginLeft: "0.8rem",
                                  }}
                                />
                                {/* Subprocesso */}
                                <Table
                                  className="tabela_capitulos"
                                  pagination={false}
                                  size="small"
                                  dataSource={moduloProcessos.capitulos
                                    ?.sort(
                                      (a: any, b: any) =>
                                        new Date(a.created_at).getTime() -
                                        new Date(b.created_at).getTime()
                                    )
                                    ?.filter(
                                      (c: any) =>
                                        c.dependencia &&
                                        c.dependencia === recordSub._id &&
                                        !c.cliente_id
                                    )}
                                  rowKey={(recordSub) => recordSub._id}
                                  columns={[
                                    {
                                      title: "Subprocesso",
                                      dataIndex: "description",
                                    },
                                    {
                                      title: "Ação",
                                      dataIndex: "_id",
                                      render: (id: any) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "right",
                                          }}
                                        >
                                          <Button
                                            type="link"
                                            size="small"
                                            icon={<EditOutlined />}
                                            onClick={() =>
                                              navigate(
                                                "/capitulos/editar/" + id
                                              )
                                            }
                                          >
                                            Editar subprocesso
                                          </Button>
                                        </div>
                                      ),
                                    },
                                  ]}
                                />
                              </>
                            ),
                            rowExpandable: (recordSub) =>
                              moduloProcessos.capitulos?.filter(
                                (c: any) =>
                                  !c.dependencia &&
                                  c.tags.includes(recordArea._id)
                              )?.[0]
                                ? true
                                : false,
                          }}
                          columns={columnsCap}
                        />
                      </>
                    ),
                    rowExpandable: (recordArea) =>
                      moduloProcessos.capitulos?.filter(
                        (c: any) =>
                          !c.dependencia && c.tags?.includes(recordArea._id)
                      )?.[0]
                        ? true
                        : false,
                  }}
                />
              </>
            ),
          },
          {
            key: "2",
            label: "Cadastrado por clientes",
            children: (
              <>
                {/* Busca áreas */}
                <Search
                  placeholder="Buscar áreas cadastradas por clientes"
                  style={{ marginBottom: "1rem" }}
                  className="clientes__actions--search"
                  allowClear
                  enterButton="Buscar"
                  onSearch={onSearchAreaCapitulo}
                />

                {/* Área */}
                <Table
                  size="small"
                  dataSource={moduloProcessos.areas
                    ?.filter((a: any) => a.cliente_id)
                    .filter((c: any) =>
                      c.description.toUpperCase().includes(search.toUpperCase())
                    )}
                  columns={columnsArea}
                  pagination={{ pageSize: 4 }}
                />
                {/* Busca processos */}
                <Search
                  placeholder="Buscar processos cadastrados por clientes"
                  style={{ marginBottom: "1rem" }}
                  className="clientes__actions--search"
                  allowClear
                  enterButton="Buscar"
                  onSearch={onSearchAreaCapitulo}
                />
                <Table
                  pagination={{ pageSize: 4 }}
                  dataSource={moduloProcessos.capitulos?.filter(
                    (c: any) => c.cliente_id
                  )}
                  size="small"
                  rowKey={(recordSub) => recordSub._id}
                  columns={columnsCap}
                />
              </>
            ),
          },
        ]}
      />
      <ModalImportar
        importFile={importFile}
        setImportFile={setImportFile}
        setShowImport={setShowImport}
        showImport={showImport}
        importQuestionario={importQuestionario}
      />
    </div>
  );
};

export default Capitulos;
