import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Alert,
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { GlobalContext } from "../../../../../context/globalContext";
import { GlobalContextType } from "../../../../../@types/global";
import { ModalEditPerguntaTypes } from "../../../../../@types/ModalEditPergunta";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";

const { TextArea } = Input;

const ModalEditPergunta: React.FC<ModalEditPerguntaTypes> = ({
  showModal,
  handleShowModal,
  capId,
  perguntasListadas,
  selectedPergunta,
}) => {
  const { moduloProcessos } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [form] = Form.useForm();

  const [opcoes, setOpcoes] = useState<any>();
  const [tipo, setTipo] = useState<string>("");
  const [dependencia, setDependencia] = useState<any>(null);
  const [dependencias, setDependencias] = useState<any>(null);
  const [editor, setEditor] = useState<any>({
    norma: "",
    recomendacao: "",
    obs: "",
  });

  useEffect(() => {
    if (selectedPergunta) {
      form.setFieldsValue({
        description: selectedPergunta.description,
        required: selectedPergunta.required,
        dependencia: selectedPergunta.dependencia?.[0]?.pergunta_id,
        tipo: selectedPergunta.tipo,
        placeholder: selectedPergunta.placeholder,
      });
      // if (selectedPergunta.dependencia) {
      //   setDependencia(selectedPergunta.dependencia || null);
      // }
      setTipo(selectedPergunta.tipo);

      // Preencher valores para as opções caso existam
      if (selectedPergunta.opcoes && selectedPergunta.opcoes.length > 0) {
        const opcoesValues = selectedPergunta.opcoes.map((opcao) => ({
          alternativa: opcao.alternativa,
          grau_descricao: opcao.grau_descricao,
          grau: opcao.grau,
          probabilidade: opcao.probabilidade,
          impacto: opcao.impacto,
        }));
        form.setFieldsValue({ opcoes: opcoesValues });
      }

      // Preencher valores para as normas se existirem
      setEditor({
        norma: selectedPergunta.norma || "",
        recomendacao: selectedPergunta.recomendacao || "",
        obs: selectedPergunta.obs || "",
      });

      setDependencias(selectedPergunta.dependencia);
    }
  }, [selectedPergunta]);

  const onFinish = (values: any) => {
    if (!selectedPergunta?._id) {
      moduloProcessos?.postPergunta(
        { ...values, dependencia: dependencias, ...editor },
        capId
      );
      message.success("Pergunta adicionada com sucesso!");
    } else {
      moduloProcessos?.patchPergunta({
        _id: selectedPergunta._id,
        ...values,
        dependencia: dependencias,
        ...editor,
      });
      message.success("Pergunta atualizada com sucesso!");
    }
    form.resetFields();
    setEditor({
      norma: "",
      recomendacao: "",
      obs: "",
    });
    handleShowModal();
  };

  const handleAddDependencia = () => {
    if (dependencia) {
      setDependencias((prev: any) =>
        prev ? [...prev, dependencia] : [dependencia]
      );
      setDependencia(null);
      form.setFieldsValue({
        dependencias: "",
      });
    } else {
      form.setFieldsValue({
        dependencias: "",
      });
    }
  };

  const handleDeleteDependencia = (dependencia: any) => {
    setDependencias((prev: any) => [
      ...prev?.filter(
        (dep: any) => dep.pergunta_id !== dependencia.pergunta_id
      ),
    ]);
  };
  const items = [
    {
      key: "1",
      label: "Normas",
      children: (
        <ReactQuill
          placeholder="Escreva a norma"
          // value={
          //   capitulos.filter((c: any) => c._id === params.id)[0]
          //     .recomendacao || newCap.recomendacao
          // }
          value={editor.norma}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
          ]}
          modules={{
            toolbar: [
              [{ font: [] }],
              [{ size: [] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link", "image"],
              ["clean"],
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
            // imageResize: {
            //   parchment: Quill.import("parchment"),
            //   modules: ["Resize", "DisplaySize"],
            // },
          }}
          // defaultValue={
          //   params.id
          //     ? capitulos.filter((c: any) => c._id === params.id)[0]
          //         ?.recomendacao || newCap.recomendacao
          //     : newCap.recomendacao
          // }
          onChange={(v: any) =>
            setEditor((prev: any) => ({ ...prev, norma: v }))
          }
        />
      ),
    },
    {
      key: "2",
      label: "Boas práticas",
      children: (
        <ReactQuill
          placeholder="Escreva a recomendação"
          // value={
          //   capitulos.filter((c: any) => c._id === params.id)[0]
          //     .recomendacao || newCap.recomendacao
          // }

          value={editor.recomendacao}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
          ]}
          modules={{
            toolbar: [
              [{ font: [] }],
              [{ size: [] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link", "image"],
              ["clean"],
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
            // imageResize: {
            //   parchment: Quill.import("parchment"),
            //   modules: ["Resize", "DisplaySize"],
            // },
          }}
          // defaultValue={
          //   params.id
          //     ? capitulos.filter((c: any) => c._id === params.id)[0]
          //         ?.recomendacao || newCap.recomendacao
          //     : newCap.recomendacao
          // }
          onChange={(v: any) =>
            setEditor((prev: any) => ({ ...prev, recomendacao: v }))
          }
        />
      ),
    },
    {
      key: "3",
      label: "Observações",
      children: (
        <ReactQuill
          placeholder="Escreva ima observação"
          value={editor.obs}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
          ]}
          modules={{
            toolbar: [
              [{ font: [] }],
              [{ size: [] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link", "image"],
              ["clean"],
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
            // imageResize: {
            //   parchment: Quill.import("parchment"),
            //   modules: ["Resize", "DisplaySize"],
            // },
          }}
          // defaultValue={
          //   params.id
          //     ? capitulos.filter((c: any) => c._id === params.id)[0]
          //         ?.recomendacao || newCap.recomendacao
          //     : newCap.recomendacao
          // }
          onChange={(v: any) => setEditor((prev: any) => ({ ...prev, obs: v }))}
        />
      ),
    },
  ];

  return (
    <Modal
      title="Adicionar pergunta"
      width={"95%"}
      open={showModal}
      footer={[
        <Button type="default" form="form-pergunta" onClick={handleShowModal}>
          Cancelar
        </Button>,
        <Button
          type="primary"
          form="form-pergunta"
          key="submit"
          htmlType="submit"
        >
          Salvar
        </Button>,
      ]}
      onCancel={handleShowModal}
    >
      <Form
        form={form}
        name="form-pergunta"
        onFinish={onFinish}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Descrição"
          name="description"
          rules={[{ required: true, message: "Campo obrigatório!" }]}
        >
          <TextArea placeholder="Título da pergunta" autoSize />
        </Form.Item>

        <Form.Item name="required" valuePropName="checked">
          <Checkbox>Obrigatória</Checkbox>
        </Form.Item>

        <Form.Item name={"dependencias"} label="Dependências">
          <Alert
            type="info"
            message="A pergunta atual só será exibida se uma das dependências forem atendidas."
            showIcon
          />
          <br />
          <Select
            placeholder="Selecione uma pergunta para criar dependência"
            onChange={(v) =>
              setDependencia({
                pergunta_id: v,
                value: null,
                tipo: perguntasListadas?.find((perg: any) => perg._id === v)
                  .tipo,
              })
            }
            style={{ width: "50%" }}
            options={perguntasListadas
              ?.filter(
                (p: any) =>
                  p._id && p.tipo !== "input" && p._id !== selectedPergunta?._id
              )
              // Retirar perguntas ja cadastradas
              ?.filter(
                (p: any) =>
                  !selectedPergunta?.dependencia?.find(
                    (d) => d.pergunta_id === p._id
                  )
              )
              .map((perg: any) => {
                return {
                  value: perg?._id,
                  label: perg?.description,
                };
              })}
          />
          <Button
            type="primary"
            style={{ marginLeft: "1rem" }}
            icon={<PlusOutlined />}
            onClick={handleAddDependencia}
          >
            Adicionar dependência
          </Button>
          {(selectedPergunta?.dependencia || dependencias) && (
            <>
              <br />
              <br />
              <Row gutter={[24, 24]}>
                {dependencias
                  ?.reduce((acc: any[], current: any) => {
                    const x = acc.find(
                      (item) => item.pergunta_id === current.pergunta_id
                    );
                    if (!x) {
                      acc.push(current);
                    }
                    return acc;
                  }, [])
                  ?.map((dependencia: any) => (
                    <Col span={6}>
                      <Card
                        size="small"
                        title={
                          <Tooltip
                            placement="topLeft"
                            title={
                              perguntasListadas.find(
                                (perg: any) =>
                                  perg._id === dependencia?.pergunta_id
                              )?.description
                            }
                          >
                            {
                              perguntasListadas.find(
                                (perg: any) =>
                                  perg._id === dependencia?.pergunta_id
                              )?.description
                            }
                          </Tooltip>
                        }
                        actions={[
                          <Button
                            size="small"
                            type="link"
                            style={{ color: "red" }}
                            onClick={() => handleDeleteDependencia(dependencia)}
                            icon={<DeleteOutlined />}
                          >
                            Excluir
                          </Button>,
                        ]}
                      >
                        <p style={{ opacity: 0.4 }}>
                          *Clique nas alternativas para editar
                        </p>
                        <br />
                        <Select
                          onChange={(v) => {
                            if (v) {
                              const dependenciaAtual = dependencia;

                              const novasDependencias = v.map(
                                (value: string) => {
                                  return {
                                    ...dependenciaAtual,
                                    value: value,
                                  };
                                }
                              );

                              setDependencias((prev: any) => [
                                ...prev.filter(
                                  (d: any) =>
                                    d.pergunta_id !==
                                    dependenciaAtual.pergunta_id
                                ),
                                ...novasDependencias,
                              ]);
                            }
                          }}
                          placeholder="Escolha as opções de resposta"
                          mode="multiple"
                          maxTagCount={3}
                          maxTagTextLength={10}
                          options={perguntasListadas
                            .find(
                              (perg: any) =>
                                perg._id === dependencia.pergunta_id
                            )
                            ?.opcoes?.map((opt: any) => {
                              return {
                                value: opt?.alternativa,
                                label: opt?.alternativa,
                              };
                            })}
                          defaultValue={selectedPergunta?.dependencia
                            ?.filter(
                              (d) => d?.pergunta_id === dependencia?.pergunta_id
                            )
                            ?.map((d) => d.value)}
                        />
                        <Space direction="vertical">
                          {/* {perguntasListadas
                          .find(
                            (perg: any) => perg._id === dependencia.pergunta_id
                          )
                          ?.opcoes?.map((opt: any) => (
                            <Radio
                              checked={
                                selectedPergunta.dependencia?.find(
                                  (d) =>
                                    d.value === opt.alternativa &&
                                    d.pergunta_id === dependencia.pergunta_id
                                )
                                  ? true
                                  : false
                              }
                            >
                              {opt?.alternativa}
                            </Radio>
                          ))} */}
                        </Space>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </>
          )}
        </Form.Item>

        <Form.Item
          label="Tipo"
          name="tipo"
          rules={[{ required: true, message: "Campo obrigatório!" }]}
        >
          <Select
            style={{ width: "50%" }}
            onChange={(v) => setTipo(v)}
            options={[
              { value: "input", label: "Texto" },
              { value: "dropdown", label: "Alternativa" },
              { value: "checkbox", label: "Múltipla escolha" },
              { value: "file", label: "Anexo", disabled: true },
              { value: "textarea", label: "Texto longo", disabled: true },
            ]}
          />
        </Form.Item>

        {(tipo === "dropdown" || tipo === "checkbox") && (
          <Collapse
            size="small"
            accordion
            items={[
              {
                key: "1",
                label: "Opções",
                children: (
                  <Form.List
                    name="opcoes"
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names || names.length < 2) {
                            return Promise.reject(
                              new Error("Pelo menos 2 opções")
                            );
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <Row gutter={[16, 0]}>
                            <Col span={fields.length <= 1 ? "15" : "14"}>
                              <Form.Item
                                label={index === 0 ? "Descrição" : ""}
                                required={false}
                                key={field.key}
                              >
                                <Form.Item
                                  {...field}
                                  validateTrigger={["onChange", "onBlur"]}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                    },
                                  ]}
                                  noStyle
                                  name={[field.name, "alternativa"]}
                                >
                                  <TextArea
                                    placeholder="Nome da opção"
                                    autoSize
                                    // onChange={(v) => setOpcoes(field)}
                                  />
                                </Form.Item>
                              </Form.Item>
                            </Col>
                            <Col span="3">
                              <Form.Item
                                label={index === 0 ? "Descrição do grau" : ""}
                                key={field.key + "descgrau"}
                                name={[field.name, "grau_descricao"]}
                              >
                                <AutoComplete
                                  options={[
                                    { value: "Conforme" },
                                    { value: "Não conforme" },
                                    { value: "Conhecimento de Processo" },
                                    { value: "Comum" },
                                    { value: "Sensível" },
                                    { value: "Alto risco" },
                                    { value: "Médio risco" },
                                    { value: "Baixo risco" },
                                  ]}
                                  filterOption={(inputValue, option: any) =>
                                    option.value
                                      .toLowerCase()
                                      .includes(inputValue.toLowerCase())
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col span="2">
                              <Form.Item
                                label={index === 0 ? "Grau" : ""}
                                required={false}
                                key={field.key + "grau"}
                              >
                                <Form.Item
                                  {...field}
                                  validateTrigger={["onChange", "onBlur"]}
                                  name={[field.name, "grau"]}
                                  noStyle
                                >
                                  <Select
                                    options={[
                                      { value: 0, label: "0" },
                                      { value: 0.25, label: "0.25" },
                                      { value: 0.5, label: "0.5" },
                                      { value: 0.75, label: "0.75" },
                                      { value: 1, label: "1" },
                                      { value: 1.25, label: "1.25" },
                                      { value: 1.5, label: "1.5" },
                                      { value: 1.75, label: "1.75" },
                                      { value: 2, label: "2" },
                                      { value: 2.25, label: "2.25" },
                                      { value: 2.5, label: "2.5" },
                                      { value: 2.75, label: "2.75" },
                                      { value: 3, label: "3" },
                                      // { value: 3.25, label: "3.25" },
                                      // { value: 3.5, label: "3.5" },
                                      // { value: 3.75, label: "3.75" },
                                      // { value: 4, label: "4" },
                                      // { value: 4.25, label: "4.25" },
                                      // { value: 4.5, label: "4.5" },
                                      // { value: 4.75, label: "4.75" },
                                      // { value: 5, label: "5" },
                                    ]}
                                  />
                                </Form.Item>
                              </Form.Item>
                            </Col>
                            <Col span="2">
                              <Form.Item
                                label={index === 0 ? "Probabilidade" : ""}
                                required={false}
                                key={field.key + "probabilidade"}
                              >
                                <Form.Item
                                  {...field}
                                  validateTrigger={["onChange", "onBlur"]}
                                  name={[field.name, "probabilidade"]}
                                  noStyle
                                >
                                  <Select
                                    options={[
                                      { value: 0, label: "0" },
                                      { value: 0.25, label: "0.25" },
                                      { value: 0.5, label: "0.5" },
                                      { value: 0.75, label: "0.75" },
                                      { value: 1, label: "1" },
                                      { value: 1.25, label: "1.25" },
                                      { value: 1.5, label: "1.5" },
                                      { value: 1.75, label: "1.75" },
                                      { value: 2, label: "2" },
                                      { value: 2.25, label: "2.25" },
                                      { value: 2.5, label: "2.5" },
                                      { value: 2.75, label: "2.75" },
                                      { value: 3, label: "3" },
                                      // { value: 3.25, label: "3.25" },
                                      // { value: 3.5, label: "3.5" },
                                      // { value: 3.75, label: "3.75" },
                                      // { value: 4, label: "4" },
                                      // { value: 4.25, label: "4.25" },
                                      // { value: 4.5, label: "4.5" },
                                      // { value: 4.75, label: "4.75" },
                                      // { value: 5, label: "5" },
                                    ]}
                                  />
                                </Form.Item>
                              </Form.Item>
                            </Col>
                            <Col span="2">
                              <Form.Item
                                label={index === 0 ? "Impacto" : ""}
                                required={false}
                                key={field.key + "impacto"}
                              >
                                <Form.Item
                                  {...field}
                                  validateTrigger={["onChange", "onBlur"]}
                                  name={[field.name, "impacto"]}
                                  noStyle
                                >
                                  <Select
                                    options={[
                                      { value: 0, label: "0" },
                                      { value: 0.25, label: "0.25" },
                                      { value: 0.5, label: "0.5" },
                                      { value: 0.75, label: "0.75" },
                                      { value: 1, label: "1" },
                                      { value: 1.25, label: "1.25" },
                                      { value: 1.5, label: "1.5" },
                                      { value: 1.75, label: "1.75" },
                                      { value: 2, label: "2" },
                                      { value: 2.25, label: "2.25" },
                                      { value: 2.5, label: "2.5" },
                                      { value: 2.75, label: "2.75" },
                                      { value: 3, label: "3" },
                                      { value: 3.25, label: "3.25" },
                                      { value: 3.5, label: "3.5" },
                                      { value: 3.75, label: "3.75" },
                                      { value: 4, label: "4" },
                                      { value: 4.25, label: "4.25" },
                                      { value: 4.5, label: "4.5" },
                                      { value: 4.75, label: "4.75" },
                                      { value: 5, label: "5" },
                                    ]}
                                  />
                                </Form.Item>
                              </Form.Item>
                            </Col>
                            {fields.length > 1 && (
                              <>
                                <Col span="1">
                                  <Form.Item
                                    label={index === 0 ? " " : ""}
                                    required={false}
                                    key={field.key + "acao"}
                                  >
                                    <Form.Item {...field} noStyle>
                                      {fields.length > 1 ? (
                                        <DeleteOutlined
                                          onClick={() => remove(field.name)}
                                          style={{
                                            color: "red",
                                            fontSize: 18,
                                          }}
                                        />
                                      ) : null}
                                    </Form.Item>
                                  </Form.Item>
                                </Col>
                              </>
                            )}
                          </Row>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                              setOpcoes(fields);
                            }}
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                          >
                            Adicionar opção
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                ),
              },
            ]}
            defaultActiveKey={["1"]}
          />
        )}

        {tipo === "input" && (
          <Form.Item label="Placeholder" name="placeholder">
            <TextArea
              placeholder="Isto é um placeholder"
              autoSize
              style={{ width: "50%" }}
            />
          </Form.Item>
        )}

        <Collapse
          style={{ marginTop: "2rem" }}
          size="small"
          ghost
          items={items}
          defaultActiveKey={["1", "2"]}
        />
      </Form>
    </Modal>
  );
};

export default ModalEditPergunta;
