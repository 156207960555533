import React, { ReactNode, useState } from "react";
import { Button, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";

interface CustomTooltipProps {
  text: string;
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  visibleByDefault?: boolean;
  children: ReactNode;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  text,
  children,
  visibleByDefault = false,
  placement = "top",
}) => {
  const [visible, setVisible] = useState(visibleByDefault);

  const closeTooltip = () => {
    setVisible(false);
  };

  const title = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        color: "#fff",
      }}
    >
      <b style={{ marginBottom: "0.5rem" }}>Dica</b>
      <div>{text}</div>
      <Button
        size="small"
        onClick={closeTooltip}
        style={{ marginLeft: "auto", color: "#5f29cc", marginTop: "0.5rem" }}
      >
        Entendi
      </Button>
    </div>
  );

  return (
    <Tooltip
      title={title}
      color="#5f29cc"
      open={visible}
      onOpenChange={setVisible}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
