import React from "react";
import "./styles.css";
import LogoSeusdados from "../../../../../assets/images/LogoSeusDados.png";
import { EnterOutlined } from "@ant-design/icons";
import { Col, Progress, Row } from "antd";
import TimelineFicha from "../../../../../components/Timeline/TimelineGeral";
import { GlobalContextType } from "../../../../../@types/global";
import { GlobalContext } from "../../../../../context/globalContext";
import { Questionario } from "../../../../../context/mapeamentoContext";
import moment from "moment";

interface RelatorioROTProps {
  logs: any;
  currentMapeamento: Questionario | null | undefined;
  loadingLogs: boolean;
  filtros: any;
  codigoFicha: string;
}

const RelatorioROT: React.FC<RelatorioROTProps> = ({
  logs,
  currentMapeamento,
  loadingLogs,
  filtros,
  codigoFicha,
}) => {
  const {
    clientes,
    usuarios,
    moduloProcessos,
    moduloMapeamento,
    fornecedores,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const data = [
    { description: "Faixa etária", status: "pendente" },
    { description: "Dados tratados sem consentimento", status: "concluido" },
    { description: "Outros", status: "em-andamento" },
  ];

  const processos = currentMapeamento?.capitulos?.find(
    (processo: any) =>
      processo?._id ===
      (filtros?.subprocesso ? filtros?.subprocesso : filtros?.processo)
  );

  const titulares = moduloMapeamento?.currentTitulares?.filter(
    (titular) => titular.relacao_id === processos?.questionarioCapitulo?._id
  );

  return (
    <div className="relatorio_rot">
      <div className="capa" style={{ pageBreakAfter: "always" }}>
        {/* Cabeçalho comum em todas as páginas */}
        <div className="relatorio__rot-title">
          <h1>{codigoFicha.replace(" ", "").replace("undefined", "")}</h1>
          <h3>CÓDIGO IDENTIFICADOR</h3>
        </div>
        {/* Cabeçalho comum em todas as páginas */}

        {/* Rodapé comum em todas as páginas */}
        <div className="relatorio__footer">
          <div className="relatorio__footer-content">
            <span className="relatorio__seusdados-date">
              {moment(new Date(currentMapeamento!?.updated_at)).format(
                "DD/MM/YYYY"
              )}
            </span>
            <img
              className="relatorio__seusdados-logorot"
              src={LogoSeusdados}
              alt="Logo"
            />
            <span style={{ width: "4rem" }}>
              {/* Página <span style={{ marginLeft: "1rem" }}>| 7</span>{" "} */}
            </span>
          </div>
        </div>
        {/* Rodapé comum em todas as páginas */}

        <center>
          <h1
            style={{
              fontSize: "22px",
              textAlign: "center",
              width: "333px",
            }}
          >
            Registro das Operações de Tratamento de Dados Pessoais
          </h1>
        </center>
        <br />
        <h1 className="relatorio__rot--itemtitle">1. Dados da empresa</h1>
        <br />
        <Row gutter={24}>
          <Col span={12}>
            <div className="relatorio__rot--bloco">
              <p className="bloco_title">Razão social</p>
              <p className="bloco_content">
                {
                  clientes?.find((cli: any) => cli._id === filtros?.cliente)
                    ?.razao_social
                }
              </p>
            </div>
          </Col>
          <Col span={12}>
            <div className="relatorio__rot--bloco">
              <p className="bloco_title">CNPJ</p>
              <p className="bloco_content">
                {
                  clientes?.find((cli: any) => cli._id === filtros?.cliente)
                    ?.cnpj
                }
              </p>
            </div>
          </Col>
        </Row>
        <br />
        <hr />
        <br />
        <h1 className="relatorio__rot--itemtitle">2. Dados do processo</h1>
        <br />
        {/* Arvore do local */}
        <div className="local_processo_container">
          <p className="local_processo_container__areatitle">Área:</p>
          <div className="local_processo_container__tree">
            <div className="local_processo_container__tree--area">
              <span className="texto_bloco">
                {moduloProcessos.areas?.find((a) => a._id === filtros.area)
                  ?.description || "Selecione uma área"}
              </span>
            </div>
            <div className="local_processo_container__tree--processo">
              <EnterOutlined
                style={{
                  transform: "scaleX(-1)",
                  color: "#A3A3A3",
                  fontSize: 22,
                }}
              />
              <span className="texto_bloco">
                {moduloProcessos.capitulos?.find(
                  (c) => c._id === filtros.processo
                )?.description || "Selecione um processo"}
              </span>
            </div>
            <div className="local_processo_container__tree--subprocesso ">
              <EnterOutlined
                style={{
                  transform: "scaleX(-1)",
                  color: "#A3A3A3",
                  fontSize: 22,
                }}
                className="local_processo__active--icon"
              />
              <span className="texto_bloco local_processo__active--span">
                {moduloProcessos.capitulos?.find(
                  (c) => c._id === filtros.subprocesso
                )?.description || "Selecione um processo"}
              </span>
            </div>
          </div>
        </div>
        <br />
        {/* <p style={{ fontWeight: "bold", fontSize: 12 }}>
          Descrição do processo:
        </p>
        <p style={{ fontSize: 10, color: "#333333" }}>
          O processo de recrutamento em RH começa com a identificação da vaga e
          criação da descrição do cargo. Em seguida, divulgamos a oportunidade,
          filtramos currículos e realizamos triagens iniciais. Os candidatos
          mais adequados passam por entrevistas com o RH e gestores. Após
          avaliação, o melhor candidato é selecionado, feita a oferta de emprego
          e, se aceita, inicia-se a integração.
        </p>
        <br /> */}
        <hr />
        <br />
        <h1 className="relatorio__rot--itemtitle">3. Gestor(a) do processo</h1>
        <br />
        <Row gutter={24}>
          <Col span={12}>
            <div className="relatorio__rot--bloco">
              <p className="bloco_title">Nome / Função</p>
              <p className="bloco_content">
                {usuarios?.find(
                  (usr: any) =>
                    // usr.grupos.find(
                    //   (g: any) => g.name === "Líder de processo"
                    // ) &&
                    usr.funcoesDetalhadas.find(
                      (fd: any) => fd.reference_model_id === filtros.mapeamento
                    ) &&
                    usr.funcoesDetalhadas.find(
                      (fd: any) =>
                        fd.reference_model_id === filtros.processo ||
                        fd.reference_model_id ===
                          moduloProcessos.capitulos?.find(
                            (c) => c._id === filtros.processo
                          )?.dependencia
                    )
                )?.name || "Nenhum responsável encontrado"}
              </p>
              <p className="bloco_content">
                {usuarios?.find(
                  (usr: any) =>
                    // usr.grupos.find(
                    //   (g: any) => g.name === "Líder de processo"
                    // ) &&
                    usr.funcoesDetalhadas.find(
                      (fd: any) => fd.reference_model_id === filtros.mapeamento
                    ) &&
                    usr.funcoesDetalhadas.find(
                      (fd: any) =>
                        fd.reference_model_id === filtros.processo ||
                        fd.reference_model_id ===
                          moduloProcessos.capitulos?.find(
                            (c) => c._id === filtros.processo
                          )?.dependencia
                    )
                )?.cargo || "Sem cargo definido"}
              </p>
            </div>
          </Col>
          <Col span={12}>
            <div className="relatorio__rot--bloco">
              <p className="bloco_title">E-mail / Telefone</p>
              <p className="bloco_content">
                {usuarios?.find(
                  (usr: any) =>
                    // usr.grupos.find(
                    //   (g: any) => g.name === "Líder de processo"
                    // ) &&
                    usr.funcoesDetalhadas.find(
                      (fd: any) => fd.reference_model_id === filtros.mapeamento
                    ) &&
                    usr.funcoesDetalhadas.find(
                      (fd: any) =>
                        fd.reference_model_id === filtros.processo ||
                        fd.reference_model_id ===
                          moduloProcessos.capitulos?.find(
                            (c) => c._id === filtros.processo
                          )?.dependencia
                    )
                )?.email || "E-mail não encontrado"}
              </p>
              <p className="bloco_content">
                {usuarios?.find(
                  (usr: any) =>
                    // usr.grupos.find(
                    //   (g: any) => g.name === "Líder de processo"
                    // ) &&
                    usr.funcoesDetalhadas.find(
                      (fd: any) => fd.reference_model_id === filtros.mapeamento
                    ) &&
                    usr.funcoesDetalhadas.find(
                      (fd: any) =>
                        fd.reference_model_id === filtros.processo ||
                        fd.reference_model_id ===
                          moduloProcessos.capitulos?.find(
                            (c) => c._id === filtros.processo
                          )?.dependencia
                    )
                )?.telefone || "Telefone não encontrado"}
              </p>
            </div>
          </Col>
        </Row>
        {/* <br />
        <p style={{ fontSize: 10, color: "#333333" }}>
          *Texto explicativo sobre o código identificador da ficha. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Mauris condimentum
          gravida malesuada. Nam at porttitor massa, ut pellentesque sapien.
          Cras volutpat porttitor aliquam. Praesent scelerisque dolor vitae quam
          finibus dictum. Vivamus hendrerit faucibus ante, eget mollis odio
          gravida eget.
        </p> */}
        {/* número da página */}
        {/* <div className="relatorio__seusdados-page">1</div> */}
      </div>

      <div className="page2" style={{ pageBreakAfter: "always" }}>
        <h1 className="relatorio__rot--itemtitle">1. COLETA</h1>
        <Progress
          size={[181, 6]}
          steps={4}
          showInfo={false}
          percent={25}
          strokeColor={["#5F29CC", "#33CCFF"]}
        />
        {titulares?.map((titular) => (
          <div className="relatorio__rot--content">
            <p className="content__titular">
              {titular.categoria?.toUpperCase()}
            </p>
            <hr className="content__hr" />
            <p className="content__label">Número estimado</p>
            <p className="content__text">{titular.quantidade}</p>
            <br />
            <p className="content__label">Faixa etária</p>
            <p className="content__text">{titular.faixa_etaria?.join(" | ")}</p>
            <br />
            <p className="content__label">Dados pessoais sensíveis</p>
            <p className="content__text">
              {titular.dados
                ?.filter((d: any) => d.tipo === "sensivel")
                ?.map(
                  (dado: any) =>
                    moduloMapeamento.dados?.find(
                      (d: any) => d._id === dado.dado_id
                    )?.nome
                )
                ?.join(" | ") || "-"}
            </p>
            <br />
            <p className="content__label">Dados pessoais comuns</p>
            <p className="content__text">
              {" "}
              {titular.dados
                ?.map(
                  (dado: any) =>
                    moduloMapeamento.dados
                      ?.filter((d: any) => d.tipo !== "sensivel")
                      ?.find((d: any) => d._id === dado.dado_id)?.nome
                )
                ?.join(" | ") || "-"}
            </p>
            <br />
            <p className="content__label">Origem dos dados</p>
            <p className="content__text">
              {Array.from(
                new Set(titular.dados?.flatMap((dado: any) => dado.origem))
              )?.join(" | ")}
            </p>
            <br />
            <p className="content__label">Finalidade e Necessidade</p>
            <p className="content__text">{titular.finalidade_coleta || "-"}</p>
            <br />
            <p className="content__label">Medidas de Segurança</p>
            <p className="content__text"> {titular.medidas_coleta || "-"}</p>
            <br className="content__br" />
            <p className="content__label">Observações</p>
            <p className="content__text">{titular.obs_coleta || "-"}</p>
          </div>
        ))}

        {/* número da página */}
        {/* <div className="relatorio__seusdados-page">2</div> */}
      </div>

      <div className="page3" style={{ pageBreakAfter: "always" }}>
        <h1 className="relatorio__rot--itemtitle">2. PROCESSAMENTO</h1>
        <Progress
          size={[181, 6]}
          steps={4}
          showInfo={false}
          percent={50}
          strokeColor={["#5F29CC", "#33CCFF"]}
        />
        {titulares?.map((titular) => (
          <div className="relatorio__rot--content">
            <p className="content__titular">
              {titular.categoria?.toUpperCase()}
            </p>
            <hr className="content__hr" />
            <p className="content__label">Frequência</p>
            <p className="content__text">
              {Array.from(
                new Set(
                  titular.dados
                    ?.flatMap((dado: any) => dado.frequencia)
                    .filter((f: any) => f)
                )
              )?.join(" | ")}
            </p>
            <br />
            <p className="content__label">Bases legais</p>
            <p className="content__text">
              {(titular.dados &&
                Array.from(
                  new Set([
                    ...titular.dados?.flatMap(
                      (dado: any) => dado.bases_legais_comuns
                    ),
                    ...titular.dados?.flatMap(
                      (dado: any) => dado.bases_legais_sensiveis
                    ),
                  ])
                )
                  ?.filter((i: any) => i)
                  ?.join(" | ")) ||
                "-"}
            </p>
            <br />
            <p className="content__label">Finalidade e Necessidade</p>
            <p className="content__text">
              {" "}
              {titular.finalidade_processamento || "-"}
            </p>
            <br />
            <p className="content__label">Medidas de Segurança</p>
            <p className="content__text">
              {titular.medidas_processamento || "-"}
            </p>
            <br className="content__br" />
            <p className="content__label">Observações</p>
            <p className="content__text"> {titular.obs_processamento || "-"}</p>
          </div>
        ))}
        {/* número da página */}
        {/* <div className="relatorio__seusdados-page">2</div> */}
      </div>

      <div className="page4" style={{ pageBreakAfter: "always" }}>
        <h1 className="relatorio__rot--itemtitle">3. COMPARTILHAMENTO</h1>
        <Progress
          size={[181, 6]}
          steps={4}
          showInfo={false}
          percent={75}
          strokeColor={["#5F29CC", "#5F29CC", "#33CCFF"]}
        />
        {titulares?.map((titular) => (
          <div className="relatorio__rot--content">
            <p className="content__titular">
              {titular.categoria?.toUpperCase()}
            </p>
            <hr className="content__hr" />
            <p className="content__label">Departamentos</p>
            <p className="content__text">
              {" "}
              {Array.from(
                new Set(
                  titular.dados?.flatMap((dado: any) =>
                    dado.areas_compartilhadas?.map(
                      (area: any) =>
                        moduloProcessos.areas?.find(
                          (a: any) => a._id === area.area_id
                        )?.description
                    )
                  )
                )
              ).join(" | ")}
            </p>
            <br />
            <p className="content__label">Formas de compartilhamento</p>
            <p className="content__text">
              {Array.from(
                new Set(
                  titular.dados?.flatMap((dado: any) =>
                    dado.areas_compartilhadas?.map((area: any) => area.forma)
                  )
                )
              ).join(" | ")}
            </p>
            <br />
            <p className="content__label">Externos</p>
            <p className="content__text">
              {" "}
              {Array.from(
                new Set(
                  titular.dados?.flatMap((dado: any) =>
                    dado.externos
                      ?.map(
                        (ext: any) =>
                          fornecedores?.find(
                            (f: any) => f._id === ext.fornecedor_id
                          )?.nome +
                          ": " +
                          ext.forma
                      )
                      .join(" | ")
                  )
                )
              ).join(" | ") || "-"}
            </p>
            <br />
            <p className="content__label">Transferência internacional</p>
            <p className="content__text">
              {titular.dados?.find((dado: any) => dado.paises)
                ? titular.dados
                    .filter((dado: any) => dado.paises)
                    .map((dado: any) => dado.paises)
                    .join(" | ")
                : "Não"}
            </p>
            <br />
            <p className="content__label">Finalidade e Necessidade</p>
            <p className="content__text">
              {titular.finalidade_compartilhamento || "-"}
            </p>
            <br />
            <p className="content__label">Medidas de Segurança</p>
            <p className="content__text">
              {" "}
              {titular.medidas_compartilhamento || "-"}
            </p>
            <br className="content__br" />
            <p className="content__label">Observações</p>
            <p className="content__text">
              {" "}
              {titular.obs_compartilhamento || "-"}
            </p>
          </div>
        ))}
        {/* número da página */}
        {/* <div className="relatorio__seusdados-page">2</div> */}
      </div>

      <div className="page5" style={{ pageBreakAfter: "always" }}>
        <h1 className="relatorio__rot--itemtitle">
          4. ARMAZENAMENTO E RETENÇão
        </h1>
        <Progress
          size={[181, 6]}
          steps={4}
          showInfo={false}
          percent={100}
          strokeColor={["#5F29CC", "#5F29CC", "#5F29CC", "#33CCFF"]}
        />

        {titulares?.map((titular) => (
          <div className="relatorio__rot--content">
            <p className="content__titular">
              {titular.categoria?.toUpperCase()}
            </p>
            <hr className="content__hr" />
            <p className="content__label">Local do armazenamento</p>
            <p className="content__text">
              {" "}
              {Array.from(
                new Set(
                  titular.dados?.flatMap((dado: any) =>
                    dado.local?.map((local: any) => local).join(" | ")
                  )
                )
              ).join(" | ") || "-"}
            </p>
            <br />
            <p className="content__label">Prazo de guarda</p>
            <p className="content__text">
              {Array.from(
                new Set(titular.dados?.flatMap((dado: any) => dado.prazo))
              )?.join(" | ") || "Não definido"}
            </p>
            <br />
            <p className="content__label">Finalidade e Necessidade</p>
            <p className="content__text">
              {titular.finalidade_armazenamento || "-"}
            </p>
            <br />
            <p className="content__label">Medidas de Segurança</p>
            <p className="content__text">
              {titular.medidas_armazenamento || "-"}
            </p>
            <br className="content__br" />
            <p className="content__label">Observações</p>
            <p className="content__text">{titular.obs_armazenamento || "-"}</p>
          </div>
        ))}
        {/* número da página */}
        {/* <div className="relatorio__seusdados-page">2</div> */}
      </div>

      {/* <div className="page6" style={{ pageBreakAfter: "always" }}>
        <h1 className="relatorio__rot--itemtitle">Pontos de Atenção</h1>
        <br />
        <Row className="relatorio__rot--table">
          <Col style={{ fontWeight: "bold" }} className="table__cell" span={16}>
            Descrição
          </Col>
          <Col
            style={{ fontWeight: "bold" }}
            className="table__cell table__cellleftborder"
            span={8}
          >
            Situação
          </Col>
          {data.map((item, index) => (
            <>
              <Col
                className={
                  index === data.length - 1
                    ? "table__cellnoborder"
                    : "table__cell"
                }
                span={16}
              >
                {item.description}
              </Col>
              <Col
                className={
                  index === data.length - 1
                    ? `table__cellnoborder table__cellleftborder ${item.status}`
                    : `table__cell table__cellleftborder ${item.status}`
                }
                span={8}
              >
                <div>
                  {(
                    item.status.charAt(0).toUpperCase() + item.status.slice(1)
                  )?.replace("-", " ")}
                </div>
              </Col>
            </>
          ))}
        </Row>
      </div> */}

      <div className="page7" style={{ pageBreakAfter: "always" }}>
        <h1 className="relatorio__rot--itemtitle">Histórico</h1>
        <br />

        <div className="relatorio__rot--bordercontainer">
          <TimelineFicha
            logs={logs}
            mapeamentoId={currentMapeamento?._id}
            loadingLogs={loadingLogs}
          />
        </div>
      </div>
    </div>
  );
};

export default RelatorioROT;
