import { useContext } from "react";
import { GlobalContext } from "./globalContext";
import api from "../services/api";
import { message } from "antd";

// Importe as interfaces e enums necessárias aqui...

export interface NotificationType {
  _id: string;
  title: string;
  description: string;
  is_read: boolean;
  user_id: string;
  cliente_id?: string;
  modulo?: string;
  modelo_referencia_id?: string;
  modelo_referencia_nome?: string;
  type: string;
  created_at: Date;
  updated_at: Date;
}

export interface NotificationContextType {
  obterNotificacoesNaoLidas: (userInfo: any) => Promise<NotificationType[]>;
  marcarNotificacaoComoLida: (
    notificationId: string
  ) => Promise<NotificationType | null>;
}

export const useNotificationContext = (): NotificationContextType => {
  const globalContext = useContext(GlobalContext);

  const obterNotificacoesNaoLidas = async (userInfo: any) => {
    globalContext?.incrementLoadingCount();
    try {
      const response = await api.get<NotificationType[]>(
        `/notificacoes-nao-lidas/${userInfo._id}`
      );
      return response.data;
    } catch (error) {
      return []; // Retorna um array vazio em caso de erro
    } finally {
      globalContext?.decrementLoadingCount();
    }
  };

  const marcarNotificacaoComoLida = async (notificationId: string) => {
    globalContext?.incrementLoadingCount();
    try {
      const response = await api.put<NotificationType>(
        `/marcar-notificacao-lida/${notificationId}`
      );
      return response.data;
    } catch (error) {
      message.error("Não foi possível marcar a notificação como lida.");
      return null;
    } finally {
      globalContext?.decrementLoadingCount();
    }
  };

  return {
    obterNotificacoesNaoLidas,
    marcarNotificacaoComoLida,
  };
};
