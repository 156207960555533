import React, { useState } from "react";
import { Button, Table, Select, Radio, Modal } from "antd";
import { GlobalContext } from "../../../context/globalContext";
import { GlobalContextType } from "../../../@types/global";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import BackHeader from "../../../components/BackHeader/BackHeader";
import "./styles.css";

const FeedbackList: React.FC = () => {
  const { feedbacks, updateFeedback } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [filteredFeedbacks, setFilteredFeedbacks] = useState(feedbacks); // Estado para armazenar os feedbacks filtrados
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentFeedback, setCurrentFeedback] = useState<any>(null);

  const showModal = (feedback: any) => {
    setCurrentFeedback(feedback);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // Componente para exibir o ícone de estrela favoritada ou não
  const StarIcon: React.FC<{ filled: boolean }> = ({ filled }) => {
    return filled ? (
      <StarFilled style={{ color: "#ffc107" }} />
    ) : (
      <StarOutlined />
    );
  };

  // Função para renderizar o ícone de estrela favoritada ou não
  const renderStarIcon = (record: any) => {
    return (
      <Button type="text" onClick={() => handleFavorite(record)}>
        <StarIcon filled={record.favorite} />
      </Button>
    );
  };

  const columns = [
    {
      title: "Mensagem",
      dataIndex: "message",
      key: "message",
      render: (text: any, record: any) => (
        <Button
          type="link"
          onClick={() => showModal(record)}
          style={{ padding: 0 }}
        >
          <div
            className="feedback_title"
            dangerouslySetInnerHTML={{
              __html: text.length > 30 ? `${text.substring(0, 30)}...` : text,
            }}
          />
        </Button>
      ),
    },
    {
      title: "Criado em",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Favorito",
      dataIndex: "favorite",
      key: "favorite",
      render: (_: any, record: any) => renderStarIcon(record),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, record: any) => (
        <Radio.Group
          defaultValue={status}
          options={[
            { label: "Aplicado", value: "aplicado" },
            { label: "Em análise", value: "em_analise" },
            { label: "Em andamento", value: "em_andamento" },
            { label: "Não definido", value: "nao_definido" },
            { label: "Descartado", value: "descartado" },
          ]}
          onChange={(e) => handleChangeStatus(record, e.target.value)}
          optionType="button"
          buttonStyle="solid"
        />
      ),
    },
  ];

  const handleFavorite = async (record: any) => {
    const updatedFields = { favorite: !record.favorite };
    await updateFeedback(record._id, updatedFields);
  };

  const handleChangeStatus = async (record: any, newStatus: string) => {
    const updatedFields = { status: newStatus };
    await updateFeedback(record._id, updatedFields);
  };

  const filterAndSortFeedbacks = (
    status: string | undefined,
    favoritesFirst: boolean
  ) => {
    let filteredFeedbacks = [...feedbacks];

    // Filtrar por status, se necessário
    if (status) {
      filteredFeedbacks = filteredFeedbacks.filter((feedback: any) =>
        status !== "all" ? feedback.status === status : true
      );
    }

    // Ordenar por created_at
    filteredFeedbacks.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

    setFilteredFeedbacks(filteredFeedbacks);
  };

  // Ao alterar o filtro de status ou favoritos primeiro, aplicar os filtros e ordenação
  const handleFilterChange = (
    status: string | undefined,
    favoritesFirst: boolean
  ) => {
    filterAndSortFeedbacks(status, favoritesFirst);
  };

  return (
    <>
      <BackHeader title="Feedbacks" />
      <Select
        defaultValue="all"
        onChange={(value) =>
          handleFilterChange(value === "all" ? undefined : value, false)
        }
      >
        <Select.Option value="all">Todos</Select.Option>
        <Select.Option value="aplicado">Aplicado</Select.Option>
        <Select.Option value="em_analise">Em análise</Select.Option>
        <Select.Option value="em_andamento">Em andamento</Select.Option>
        <Select.Option value="nao_definido">Não definido</Select.Option>
        <Select.Option value="descartado">Descartado</Select.Option>
      </Select>
      <Table
        columns={columns}
        dataSource={filteredFeedbacks
          ?.sort((a: any, b: any) =>
            a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
          )
          ?.sort((a: any, b: any) => a.created_at - b.created_at)}
        rowKey="_id"
      />
      <Modal
        title="Detalhes do Feedback"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1800}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: currentFeedback?.message?.replace(
              "<img",
              "<br /><br /> <img"
            ),
          }}
        />
        {currentFeedback?.screenshot && (
          <img
            src={currentFeedback.screenshot}
            alt="Screenshot"
            style={{ maxWidth: "100%", marginTop: "10px" }}
          />
        )}
      </Modal>
    </>
  );
};

export default FeedbackList;
