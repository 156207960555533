import { useContext, useState, Dispatch, SetStateAction } from "react";
import api from "../services/api";
import { GlobalContext } from "./globalContext";
import { message } from "antd";

export type AreaType = {
  _id: string;
  description: string;
  cliente_id?: string;
  padrao?: boolean;
  created_at: Date;
  updated_at: Date;
};

export type CapituloType = {
  _id: string;
  description: string;
  norma?: string;
  recomendacao?: string;
  tags?: string[];
  created_at: Date;
  dependencia?: string;
  updated_at: Date;
  areas_id?: string[]; // não esta no model
  introducao?: string;
  descricao_personalizada?: string;
  questionarioCapitulo?: any; // não esta no model
};
export type PerguntaType = {
  _id: string;
  description: string;
  norma?: string;
  recomendacao?: string;
  obs?: string;
  tipo: string;
  placeholder?: string;
  opcoes?: {
    alternativa?: string;
    grau_descricao?: string;
    grau?: number;
    probabilidade?: number;
    impacto?: number;
  }[];
  ordem: number;
  created_at: Date;
  updated_at: Date;
  required?: boolean;
  dependencia?: { pergunta_id: string; value: string; tipo: string }[];
  inativo: boolean;
};

export type ProcessosContextType = {
  getAreas: () => void;
  postArea: (descricao: string) => Promise<AreaType>;
  postCapitulo: (values: any) => Promise<CapituloType> | any;
  getCapituloPerguntas: () => void;
  getPerguntas: () => void;
  getCapitulos: () => void;
  saveRespostas: (v: any) => void;
  areas: AreaType[] | undefined;
  setAreas: Dispatch<SetStateAction<AreaType[] | undefined>>;
  capituloPerguntas: CapituloType[] | undefined;
  setCapituloPerguntas: Dispatch<SetStateAction<CapituloType[] | undefined>>;
  perguntas: PerguntaType[] | undefined;
  setPerguntas: Dispatch<SetStateAction<PerguntaType[] | undefined>>;
  capitulos: CapituloType[] | undefined;
  setCapitulos: Dispatch<SetStateAction<CapituloType[] | undefined>>;
  respostasAuth: any;
  setRespostasAuth: Dispatch<SetStateAction<any>>;
  respostas: any[];
  setRespostas: Dispatch<SetStateAction<any[]>>;
  getRespostas: (token: string) => void;
  getRespostasAuth: (versaoId: string) => void;
  getMultipleRespostasAuth: (versoes: any[]) => void;
  postPergunta: (values: any, cap_id: any) => void;
  patchPergunta: (values: any) => void;
  patchPerguntasOrdem: (values: any) => void;
  updateArea: (
    areaId: string,
    description: string | null,
    padrao: boolean
  ) => Promise<any>;
  updateDescricaoProcesso: (
    questionarioProcessoId: string,
    description: string | null
  ) => Promise<any>;
};

export const useProcessosContext = (): ProcessosContextType => {
  const globalContext = useContext(GlobalContext);

  const [areas, setAreas] = useState<AreaType[] | undefined>();
  const [capituloPerguntas, setCapituloPerguntas] = useState<
    CapituloType[] | undefined
  >();
  const [perguntas, setPerguntas] = useState<PerguntaType[] | undefined>();
  const [capitulos, setCapitulos] = useState<CapituloType[] | undefined>();
  const [respostasAuth, setRespostasAuth] = useState<any>(null);
  const [respostas, setRespostas] = useState<any[]>([]);

  const handleApiRequest = async <T,>(
    endpoint: string,
    onSuccess: (data: T) => void
  ) => {
    try {
      globalContext?.incrementLoadingCount();
      const response = await api.get<T>(endpoint);
      onSuccess(response.data);
    } catch (error) {
      console.error(`Erro ao obter dados de ${endpoint}`, error);
    } finally {
      globalContext?.decrementLoadingCount();
    }
  };

  const getAreas = () => {
    handleApiRequest<AreaType[]>("areas", setAreas);
  };

  const postArea = async (descricao: string) => {
    try {
      globalContext?.incrementLoadingCount();
      const response = await api.post<AreaType>("areas", {
        description: descricao,
      });
      getAreas();
      return response.data;
    } catch (error) {
      console.error("Erro ao criar área", error);
      throw error;
    } finally {
      globalContext?.decrementLoadingCount();
    }
  };

  const updateArea = async (
    areaId: string,
    description: string | null,
    padrao: boolean
  ) => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .put(`/areas/${areaId}`, {
          description,
          padrao,
        })
        .then((response) => {
          getAreas();

          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao atualizar área!");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const updateDescricaoProcesso = async (
    questionarioProcessoId: string,
    description: string | null
  ) => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .put(`/questionarioprocesso/${questionarioProcessoId}`, {
          descricao_personalizada: description,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao atualizar descrição do processo!");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const getCapituloPerguntas = () => {
    handleApiRequest<CapituloType[]>("capituloperguntas", setCapituloPerguntas);
  };

  const getPerguntas = () => {
    handleApiRequest<PerguntaType[]>("perguntas", setPerguntas);
  };

  const postPergunta = (values: any, cap_id: any) => {
    globalContext?.incrementLoadingCount();
    api
      .post("perguntas", { ...values, cap_id: cap_id })
      .then((response) => {
        getPerguntas();
        getCapituloPerguntas();
      })
      .catch((err) => {
        console.log("ops! ocorreu um erro" + err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  const patchPergunta = (values: any) => {
    globalContext?.incrementLoadingCount();
    api
      .patch("perguntas/" + values._id, values)
      .then((response) => {
        getPerguntas();
        getCapituloPerguntas();
      })
      .catch((err) => {
        console.log("ops! ocorreu um erro" + err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  const patchPerguntasOrdem = (perguntas: PerguntaType[]) => {
    globalContext?.incrementLoadingCount();
    api
      .patch("perguntasordem", {
        perguntas: perguntas,
      })
      .then((response) => {
        message.success("Ordem salva com sucesso!");
        getPerguntas();
      })
      .catch((err) => {
        message.error("Falha ao salvar ordem!");
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  const getCapitulos = () => {
    handleApiRequest<CapituloType[]>("capitulos", setCapitulos);
  };

  const postCapitulo = (values: any) => {
    globalContext?.incrementLoadingCount();
    if (values.id !== null) {
      api
        .patch("capitulos/" + values.id, {
          description: values.description,
          norma: values.norma,
          introducao: values.introducao,
          recomendacao: values.recomendacao,
          tags: values.tags,
        })
        .then((response) => {
          getCapitulos();
          globalContext?.showAlert({
            type: "success",
            description: "Capítulo atualizado com sucesso!",
            message: "Sucesso!",
          });
        })
        .catch((err) => {
          globalContext?.showAlert({
            type: "error",
            description: "Falha ao editar capítulo!",
            message: "Falha!",
          });
        })
        .finally(() => globalContext?.decrementLoadingCount());
    } else {
      return new Promise((resolve, reject) => {
        globalContext?.incrementLoadingCount();
        api
          .post<any>("capitulos", {
            description: values.description,
            norma: values.norma,
            recomendacao: values.recomendacao,
            tags: values.tags,
            introducao: values.introducao,
            dependencia: values.dependencia || null,
          })
          .then((response) => {
            getCapitulos(); // Supondo que getCapitulos() é uma função que atualiza a lista de capítulos
            globalContext?.showAlert({
              type: "success",
              description: "Capítulo criado com sucesso!",
              message: "Sucesso!",
            });
            resolve(response.data); // Resolve a Promise com os dados do novo capítulo
          })
          .catch((err) => {
            globalContext?.showAlert({
              type: "error",
              description: "Falha ao criar capítulo!",
              message: "Falha!",
            });
            reject(err); // Rejeita a Promise com o erro
          })
          .finally(() => globalContext?.decrementLoadingCount());
      });
    }
  };

  const saveRespostas = (v: any) => {
    globalContext?.incrementLoadingCount();
    api
      .post("respostas", {
        respostas: v.resp,
        user_id: v.user_id,
      })
      .then((response) => {
        getRespostas(v.user_id);
        globalContext?.showAlert({
          type: "success",
          description: "Respostas salvo com sucesso!",
          message: "Sucesso!",
        });
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  const getRespostas = (token: string) => {
    globalContext?.incrementLoadingCount();
    api
      .get("respostas/" + token)
      .then((response) => {
        setRespostas(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  const getRespostasAuth = (versaoId: string) => {
    globalContext?.incrementLoadingCount();
    api
      .get("allrespostas/" + versaoId)
      .then((response) => {
        setRespostasAuth(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  const getMultipleRespostasAuth = (versoes: any[]) => {
    globalContext?.incrementLoadingCount();
    api
      .post("multipleRespostasAuth", {
        versoes: versoes,
      })
      .then((response) => {
        setRespostasAuth(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
      .finally(() => globalContext?.decrementLoadingCount());
  };

  return {
    getAreas,
    postArea,
    getCapituloPerguntas,
    getPerguntas,
    getCapitulos,
    areas,
    setAreas,
    capituloPerguntas,
    setCapituloPerguntas,
    updateArea,
    perguntas,
    setPerguntas,
    capitulos,
    postCapitulo,
    saveRespostas,
    setCapitulos,
    respostasAuth,
    setRespostasAuth,
    respostas,
    setRespostas,
    getRespostas,
    getMultipleRespostasAuth,
    getRespostasAuth,
    postPergunta,
    patchPergunta,
    patchPerguntasOrdem,
    updateDescricaoProcesso,
  };
};
