import React from "react";
import BackHeader from "../../../components/BackHeader/BackHeader";
import { Alert } from "antd";

const Leads: React.FC = () => {
  return (
    <>
      <BackHeader title="Leads" />
      <Alert
        message="Esta funcionalidade será disponibilizada em breve."
        showIcon
        type="info"
      />
    </>
  );
};

export default Leads;
