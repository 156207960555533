import BackHeader from "../../../components/BackHeader/BackHeader";
import ListQuestinarios from "../../Apps/Questionarios/ListQuestionarios/ListQuestionarios";

const QuestionariosCPPD = () => {
  return (
    <div className="questionarios_CPPD">
      <BackHeader title="Questionários CPPD" />
      <ListQuestinarios editCPPD />
    </div>
  );
};

export default QuestionariosCPPD;
