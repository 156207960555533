import React from "react";
import BackHeader from "../../../components/BackHeader/BackHeader";
import Fornecedores from "../../Apps/AcaoFornecedores/Fornecedores/Fornecedores";

const Diagnosticos: React.FC = () => {
  return (
    <>
      <BackHeader title="Diagnósticos" />
      <Fornecedores type="diagnosticos" />
    </>
  );
};

export default Diagnosticos;
