import React from "react";
import "./styles.css";
interface ContainerFichaProps {
  children?: React.ReactNode;
}

const ContainerFicha: React.FC<ContainerFichaProps> = ({ children }) => {
  return <div className="container_ficha">{children}</div>;
};

export default ContainerFicha;
