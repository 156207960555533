import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./styles.css";
import { Button, Switch, Table } from "antd";
import Search from "antd/es/input/Search";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import moment from "moment";

const Templates = () => {
  const { moduloMapeamento } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [search, setSearch] = useState<string>("");

  const navigate = useNavigate();

  const onChange = () => {};

  const columns = [
    {
      title: "Nome",
      dataIndex: "nome",
    },

    {
      title: "Data criação",
      dataIndex: "created_at",
      render: (date: any) => moment(new Date(date)).format("DD/MM/YYYY"),
    },
    {
      title: "Ativo",
      dataIndex: "ativo",
      render: (v: any) => <Switch checked={false} onChange={onChange} />,
    },
  ];

  const onSearch = (value: string) => setSearch(value);

  return (
    <div className="listQuestionarios">
      <BackHeader title="Questionários existentes" />
      <div className="clientes__actions">
        <Search
          placeholder="Buscar questionários"
          className="clientes__actions--search"
          allowClear
          enterButton="Buscar"
          onSearch={onSearch}
        />

        <Button
          className="clientes__actions--new"
          type="primary"
          style={{ marginLeft: "1rem" }}
          onClick={() => navigate("/templates/novo")}
        >
          Novo Questionário
        </Button>
      </div>

      <Table
        className="clientes__list"
        dataSource={moduloMapeamento.templates
          ?.filter((template: any) =>
            template.nome.toUpperCase().includes(search.toUpperCase())
          )
          .filter((template: any) => !template.cliente_id)
          .filter((template: any) => template.nome !== "Assessment Process")
          .sort(
            (a: any, b: any) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )}
        columns={columns}
        style={{ cursor: "pointer" }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate("/templates/" + record._id);
            },
          };
        }}
      />
    </div>
  );
};

export default Templates;
