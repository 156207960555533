import { useParams, useNavigate } from "react-router-dom";
import "./styles.css";
import { Button } from "antd";
import background from "../../../../../assets/images/background_pattern_rosa.png";
import qr1 from "../../../../../assets/images/qr_seusdados.png";
import qr2 from "../../../../../assets/images/qr_instagram.png";
import { BarChartOutlined, InfoCircleOutlined } from "@ant-design/icons";

const Introducao4 = () => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div
      className="intro-5"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <h1 style={{ marginTop: "5%", marginBottom: "3rem" }}>
        Agradecemos a sua resposta!
      </h1>
      <div className="intro_qrs">
        <div className="intro__img--containner">
          <img src={qr1} alt="Seusdados" />
          <a href="https://seusdados.com" target="_blank" rel="noreferrer">
            seusdados.com
          </a>
        </div>
        <div className="intro__img--containner">
          <img src={qr2} alt="Seusdados Instagram" />
          <a
            href="https://instagram.com/seusdados_com"
            target="_blank"
            rel="noreferrer"
          >
            instagram.com/seusdados_com
          </a>
        </div>
      </div>
      {params.id === "1" &&
        params.fornecedorid === "1" &&
        params.questionarioid === "1" && (
          <p style={{ opacity: 0.5, fontSize: "0.9rem", marginTop: "2rem" }}>
            <InfoCircleOutlined /> Esta janela já pode ser fechada.
          </p>
        )}
      {params.id !== "1" &&
        params.fornecedorid !== "1" &&
        params.questionarioid !== "1" && (
          <Button
            icon={<BarChartOutlined />}
            className="btn-next"
            onClick={() =>
              navigate(
                `/analisefornecedor/` +
                  params.id +
                  "/" +
                  params.fornecedorid +
                  "/" +
                  params.questionarioid
              )
            }
          >
            Ver Resultados
          </Button>
        )}
    </div>
  );
};

export default Introducao4;
