import "./styles.css";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import TaskList from "../../../../components/Tarefas/TaskList/TaskList";

const PlanoAcaoCVE = () => {
  return (
    <div className="csa">
      <BackHeader title="CSA" />
      <TaskList csa />
    </div>
  );
};

export default PlanoAcaoCVE;
