import React, { useContext, useEffect, useState } from "react";
import { StepProps, Steps, Tag, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import moment from "moment";

const { Text } = Typography;

interface Reuniao {
  _id: string;
  titulo: string;
  data_agendamento: string;
  status: string;
  ordem_reuniao: number;
  subordem_reuniao?: number;
  subject?: string;
}

const ReunioesCPPD = ({
  dataCriacao,
  cppdId,
}: {
  dataCriacao: string;
  cppdId: string;
}) => {
  const navigate = useNavigate();
  const { obterReunioesPorCppd } = useContext(
    GlobalContext
  ) as GlobalContextType;
  const [reunioes, setReunioes] = useState<Reuniao[]>([]);

  useEffect(() => {
    const fetchReunioes = async () => {
      if (cppdId) {
        const response = await obterReunioesPorCppd(cppdId);
        if (response) {
          setReunioes(response.reunioes);
        }
      }
    };

    fetchReunioes();
  }, [cppdId, obterReunioesPorCppd]);

  const handleStepClick = (reuniaoId: string) => {
    navigate(`/cppd/${cppdId}/reunioes`, {
      state: { activeReuniaoId: reuniaoId },
    });
  };

  const getStatusStyles = (reuniao: Reuniao) => {
    const status = reuniao.status;

    if (
      reunioes.filter(
        (r) => r.status !== "Realizada" && r.status !== "Cancelada"
      )?.[0]?._id === reuniao._id
    ) {
      return { color: "#1890ff" };
    }

    switch (status) {
      case "Realizada":
        return { color: "#52c41a" };
      // case "em andamento":
      //   return { color: "#1890ff" };
      default:
        return { color: "#6e6e6e" };
    }
  };

  const getStepStatus = (reuniao: Reuniao) => {
    const status = reuniao.status;

    if (
      reunioes.filter((r) => r.status !== "Realizada")?.[0]?._id === reuniao._id
    ) {
      return "process";
    }

    switch (status) {
      case "Realizada":
        return "finish";
      // case "Pendende":
      // return "process";
      default:
        return "wait";
    }
  };

  const stepsItems = reunioes
    ?.sort(
      (a: any, b: any) =>
        new Date(a.data_agendamento).getTime() -
        new Date(b.data_agendamento).getTime()
    )
    ?.map((reuniao) => ({
      title: (
        <Tooltip title={reuniao.subject}>
          <b>
            {reuniao.ordem_reuniao ? (
              <>
                {`R${reuniao.ordem_reuniao}${
                  reuniao.subordem_reuniao ? "." + reuniao.subordem_reuniao : ""
                }`}
                <br />
                {reuniao.ordem_reuniao === 1 &&
                  !reuniao.subordem_reuniao &&
                  "Instauração"}
                {reuniao.ordem_reuniao === 1 &&
                  reuniao.subordem_reuniao === 1 &&
                  "Instauração"}
              </>
            ) : (
              "R. Extr."
            )}
          </b>
        </Tooltip>
      ),
      description: (
        <Tooltip title={reuniao.subject}>
          <div className="flex flex-col items-center gap-1">
            <Text style={getStatusStyles(reuniao)}>
              {moment(new Date(reuniao.data_agendamento)).format(
                "DD/MM/YYYY HH:mm"
              )}
            </Text>
            <Tag style={getStatusStyles(reuniao)}>{reuniao.status}</Tag>
          </div>
        </Tooltip>
      ),
      status: getStepStatus(reuniao),
      onClick: () => handleStepClick(reuniao._id),
      className: "cursor-pointer",
    }));

  return (
    <div>
      <div className="overflow-x-auto">
        <Steps
          current={
            reunioes.filter((r) => r.status !== "Realizada")?.[0]
              ? reunioes.findIndex(
                  (r) =>
                    r === reunioes.filter((r) => r.status !== "Realizada")?.[0]
                )
              : -1
          }
          size="small"
          labelPlacement="vertical"
          items={stepsItems as StepProps[]}
        />
      </div>
    </div>
  );
};

export default ReunioesCPPD;
