import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./styles.css";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Input,
  Modal,
  Progress,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleFilled,
  CompassOutlined,
  EyeOutlined,
  InfoCircleTwoTone,
  MailOutlined,
  PhoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";
import SaveBtn from "../../../../components/SaveBtn/SaveBtn";
import Indice from "../../../../components/Questionario/Indice/Indice";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import PdfDownload from "../../../../components/PdfDownload/PdfDownload";
import moment from "moment";
import CapaRelBlock from "../../../../assets/images/CapaRelBlock.jpeg";
import LogoSeusdados from "../../../../assets/images/LogoSeusDados.png";
import NotaMaturidade from "../../../../assets/images/nota_maturidade.png";
import NotaMaturidadeExemplo from "../../../../assets/images/nota_maturidade_exemplo.png";
import { Scatter, ScatterChart, XAxis, YAxis } from "recharts";
interface TabelaProps {
  numeroColunas: number;
  numeroLinhas: number;
}

moment.locale("pt-br");

const { TextArea } = Input;

const Responder = () => {
  const {
    moduloMapeamento,
    moduloProcessos,
    windowWidth,
    postResposta,
    accessToken,
    getAll,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const params = useParams();

  const [formValues, setFormValues] = useState<any>({});

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalFinalizar, setShowModalFinalizar] = useState<boolean>(false);
  const [showModalIntro, setShowModalIntro] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  const [finish, setFinish] = useState(false);
  const [disabled, setDisabled] = useState<string[]>([]);
  const [questionario, setQuestionario] = useState<any>(null);
  const [activeCat, setActiveCat] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [porcentagemConforme, setPorcentagemConforme] = useState<any>(null);

  const [pontuacaoObtida, setPontuacaoObtida] = useState<any>(0);
  const [pontuacaoMaxima, setPontuacaoMaxima] = useState<any>(0);
  const [currentRespostas, setCurrentRespostas] = useState<any>([]);

  const [matrizRisco, setMatrizRisco] = useState<any>(null);
  const formQuestionarioRef = useRef<HTMLDivElement>(null);
  const analiseContentRef = useRef<HTMLDivElement>(null);

  const [perguntaInfo, setPerguntaInfo] = useState<any>(null);
  const [perguntasRelacao, setPerguntasRelacao] = useState<any>(null);

  useEffect(() => {
    if (questionario && questionario?.perguntasRelacao) {
      setPerguntasRelacao(
        questionario?.perguntasRelacao
          ?.sort((a: any, b: any) => {
            // Se ambos 'ordem' estão definidos, ordena por 'ordem'
            if (a.pergunta.ordem && b.pergunta.ordem) {
              return a.pergunta.ordem - b.pergunta.ordem;
            }

            // Se 'ordem' não está definido, ordena por 'created_at'
            return (
              new Date(a.pergunta.created_at).getTime() -
              new Date(b.pergunta.created_at).getTime()
            );
          })
          ?.filter((item: any) => item.capitulo_id === activeCat)
          // caso uma das dependencias seja cumprida
          ?.filter((relPergunta: any) => {
            if (
              relPergunta.pergunta.dependencia &&
              relPergunta.pergunta.dependencia.length > 0
            ) {
              return relPergunta.pergunta.dependencia.some(
                (dependencia: any) => {
                  return (
                    formValues[dependencia.pergunta_id] === dependencia.value
                  );
                }
              );
            } else {
              return true;
            }
          })
        // caso todas as dependencias sejam cumpridas
        // ?.filter((relPergunta: any) => {
        //   if (relPergunta.pergunta.dependencia && relPergunta.pergunta.dependencia.length > 0) {
        //     return relPergunta.pergunta.dependencia.every((dependencia: any) => {
        //       // Verifica se o valor da resposta corresponde ao esperado na dependência
        //       return formValues[dependencia.pergunta_id] === dependencia.value;
        //     });
        //   } else {
        //     // Caso não haja dependências, a pergunta é exibida normalmente
        //     return true;
        //   }
        // })
      );
    }
  }, [questionario, activeCat, formValues]);

  useEffect(() => {
    if (!accessToken) {
      getAll();
    }
  }, [accessToken]);

  const fetchQuestionario = async () => {
    if (
      params.token &&
      accessToken &&
      questionario === null &&
      moduloProcessos.perguntas?.[0]
    ) {
      try {
        await moduloMapeamento
          .getQuestionarioRespondente(params.token, accessToken)
          .then((r: any) => {
            if (r) {
              setQuestionario(r);
              moduloProcessos.setAreas(r.areasCompletas);
              setLoading(false);
            }
          });
      } catch (err) {}
    } else if (
      params.token &&
      questionario === null &&
      moduloProcessos.perguntas?.[0]
    ) {
      try {
        await moduloMapeamento
          .getQuestionarioRespondente(params.token, accessToken)
          .then((r: any) => {
            if (r) {
              setQuestionario(r);

              moduloProcessos.setAreas(r.areasCompletas);
              setLoading(false);
            }
          });
      } catch (err) {}
    }
  };

  useEffect(() => {
    fetchQuestionario();
  }, [moduloProcessos.perguntas]);

  useEffect(() => {
    if (params.token) {
      moduloProcessos.getRespostas(params.token);
    }
  }, [params.token]);

  useEffect(() => {
    if (moduloProcessos.respostas) {
      moduloProcessos.respostas.forEach((r: any) => {
        setFormValues((prevValues: any) => ({
          ...prevValues,
          [r.pergunta_id]: r.value,
        }));

        setDisabled((prevState: any) => [...prevState, r.pergunta_id]);
      });
    }
  }, [moduloProcessos.respostas]);

  useEffect(() => {
    if (
      questionario &&
      questionario?.perguntasRelacao &&
      moduloProcessos.respostas
    ) {
      const respostasComPergunta = moduloProcessos.respostas.map(
        (resposta: any) => {
          return {
            ...resposta,
            pergunta: questionario?.perguntasRelacao.find(
              (pergRel: any) => pergRel.pergunta._id === resposta.pergunta_id
            )?.pergunta,
          };
        }
      );
      let respostas = respostasComPergunta.map((resposta: any) => {
        return resposta.value.map(
          (value: any) =>
            resposta.pergunta.opcoes.find((opt: any) =>
              resposta.value.includes(opt.alternativa)
            )?.grau
        );
      });

      respostas = respostas.flat().filter((item: any) => item !== undefined);

      setPontuacaoObtida(
        respostas.reduce(
          (accumulator: number, currentValue: number) =>
            accumulator + currentValue,
          0
        )
      );

      const calcularPontuacaoMaxima = (perguntasRelacao: any) => {
        return perguntasRelacao?.reduce((soma: number, pergRel: any) => {
          // Encontra a pontuação máxima entre as opções da pergunta
          let maxGrau = Math.max(
            ...pergRel?.pergunta?.opcoes?.map((opcao: any) => opcao.grau || 0)
          );
          return soma + maxGrau;
        }, 0);
      };

      setPontuacaoMaxima(
        calcularPontuacaoMaxima(questionario?.perguntasRelacao)
      );

      setPorcentagemConforme((pontuacaoObtida * 100) / pontuacaoMaxima);

      // definir matriz de risco
      const probabilidadeImpacto = respostasComPergunta.map((resposta: any) => {
        return resposta.value.map((value: any) => {
          return {
            probabilidade: resposta.pergunta.opcoes.find((opt: any) =>
              resposta.value.includes(opt.alternativa)
            )?.probabilidade,
            impacto: resposta.pergunta.opcoes.find((opt: any) =>
              resposta.value.includes(opt.alternativa)
            )?.impacto,
            risco:
              (resposta.pergunta.opcoes.find((opt: any) =>
                resposta.value.includes(opt.alternativa)
              )?.impacto *
                resposta.pergunta.opcoes.find((opt: any) =>
                  resposta.value.includes(opt.alternativa)
                )?.probabilidade) /
              9,
            ...resposta,
          };
        });
      });

      setMatrizRisco(probabilidadeImpacto.flat());

      const respostasPerguntasGrau = respostasComPergunta.map(
        (resposta: any) => {
          return {
            ...resposta,
            pergunta: resposta.pergunta,
            respostas: resposta.value,
            graus: resposta.pergunta.opcoes.filter((opt: any) =>
              resposta.value.includes(opt.alternativa)
            ),
          };
        }
      );
      setCurrentRespostas(respostasPerguntasGrau);
    }
  }, [questionario, moduloProcessos.respostas]);

  const handleInputChange = (fieldName: string, value: any) => {
    setFormValues((prevValues: any) => ({ ...prevValues, [fieldName]: value }));
  };

  // Função para formatar os ticks e exibir apenas inteiros nos rótulos dos eixos
  const formatTick = (tick: any) => {
    if (tick % 1 === 0) {
      // Exibir apenas inteiros
      return tick.toString();
    }
    return ""; // Ocultar valores fracionários
  };

  function encontrarIndicePorValor(valorProcurado: any) {
    // Utilize a função map para percorrer o array
    // A função map retorna um novo array com os índices dos objetos que correspondem ao valor procurado
    const indicesEncontrados = questionario?.perguntasRelacao
      ?.filter((r: any) => r.pergunta?.description)
      .sort(
        (a: any, b: any) =>
          new Date(a.pergunta.created_at).getTime() -
          new Date(b.pergunta.created_at).getTime()
      )
      .map((objeto: any, indice: number) => {
        if (objeto === valorProcurado) {
          return indice + 1;
        }
        return null; // Retorna null para os objetos que não correspondem ao valor procurado
      });

    // Filtra os índices válidos (diferentes de null)
    const indicesValidos = indicesEncontrados.filter(
      (indice: number) => indice !== null
    );

    return indicesValidos;
  }

  const CustomizedShape = (props: any) => {
    const { cx, cy, fill, payload } = props;
    const { risco } = payload;

    const itensMatriz = matrizRisco
      ?.filter((item: any) => item.risco === risco)
      ?.map(
        (item: any) =>
          questionario?.perguntasRelacao
            ?.sort(
              (a: any, b: any) =>
                new Date(a.pergunta.created_at).getTime() -
                new Date(b.pergunta.created_at).getTime()
            )
            ?.findIndex((r: any) => r.pergunta._id === item.pergunta._id) + 1
      );
    return (
      <g>
        <g transform={`translate(${cx},${cy})`}>
          <text x={0} y={5} dy={0} textAnchor="middle" fontSize={12}>
            {itensMatriz.length > 7 ? (
              <>
                {}
                <tspan x={0} y={-5} dy={0}>
                  {itensMatriz
                    .slice(0, Math.ceil(itensMatriz.length / 2))
                    .join(", ")}
                  ,
                </tspan>
                <tspan x={0} y={10} dy={0}>
                  {itensMatriz
                    .slice(Math.ceil(itensMatriz.length / 2))
                    .join(", ")}
                </tspan>
              </>
            ) : (
              itensMatriz.join(", ")
            )}
          </text>
        </g>
      </g>
    );
  };

  const GridMatrizrisco: React.FC<TabelaProps> = ({
    numeroColunas,
    numeroLinhas,
  }) => {
    // Função para verificar se a célula deve ter background verde
    const shouldHaveGreenBackground = (
      linha: number,
      coluna: number
    ): boolean => {
      // Defina aqui as coordenadas das células que devem ter background verde
      const celulasVerdes = [
        { linha: 2, coluna: 1 },
        { linha: 3, coluna: 1 },
        { linha: 3, coluna: 2 },
        { linha: 4, coluna: 1 },
        { linha: 4, coluna: 2 },
        { linha: 4, coluna: 3 },
      ];
      return celulasVerdes.some(
        (c) => c.linha === linha && c.coluna === coluna
      );
    };

    // Função para verificar se a célula deve ter background laranja
    const shouldHaveOrangeBackground = (
      linha: number,
      coluna: number
    ): boolean => {
      // Defina aqui as coordenadas das células que devem ter background laranja
      const celulasLaranjas = [
        { linha: 1, coluna: 1 },
        { linha: 2, coluna: 2 },
        { linha: 3, coluna: 3 },
        { linha: 4, coluna: 4 },
      ];
      return celulasLaranjas.some(
        (c) => c.linha === linha && c.coluna === coluna
      );
    };

    // Função para criar as células da tabela
    const renderCelulas = () => {
      let celulas: JSX.Element[] = [];
      for (let i = 0; i < numeroLinhas; i++) {
        let linha: JSX.Element[] = [];

        for (let j = 0; j < numeroColunas; j++) {
          const isCelulaVerde = shouldHaveGreenBackground(i + 1, j + 1); // Linha e coluna baseadas em 1-index
          const isCelulaLaranja = shouldHaveOrangeBackground(i + 1, j + 1); // Linha e coluna baseadas em 1-index

          linha.push(
            <div
              key={`celula-${i}-${j}`}
              className="celula"
              style={{
                backgroundColor: isCelulaVerde
                  ? "#33CCFF"
                  : isCelulaLaranja
                  ? "#4C72E3"
                  : "#5F29CC",
              }}
            />
          );
        }
        celulas.push(
          <div key={`linha-${i}`} className="linha">
            {linha}
          </div>
        );
      }
      return celulas;
    };

    return <div className="tabela">{renderCelulas()}</div>;
  };

  const handleSubmit = async (finalizar?: boolean) => {
    setIsLoading(true);
    const save = async () => {
      try {
        params.token &&
          postResposta(
            questionario._id,
            questionario.cliente_id,
            params.token,
            formValues,
            finalizar ? true : false
          ).then(
            () => params.token && moduloProcessos.getRespostas(params.token)
          );

        setIsLoading(false);

        finalizar && setShowModalFinalizar(true);
        // navigate(`/formulario/5/` + 1 + "/" + 1 + "/" + 1);
      } catch (error) {
        console.error("Error saving form data:", error);
      }
    };
    save();
  };

  const itemsCollapse = [
    {
      key: "1",
      label: <b style={{ color: "#5f29cc" }}>Questionário</b>,
      children: (
        <div className="form-questionario" ref={formQuestionarioRef}>
          <form
            className="form"
            // onSubmit={handleSubmit}
            style={{
              marginBottom: "3rem",
              marginTop: "1rem",
            }}
          >
            {moduloProcessos?.capitulos?.find((c) => c._id === activeCat)
              ?.introducao && (
              <Alert
                type="info"
                message="O porquê dessas perguntas. Clique em Ver"
                showIcon
                style={{ marginBottom: "1rem" }}
                action={
                  <Button
                    onClick={() => setShowModalIntro(true)}
                    type="primary"
                    icon={<EyeOutlined />}
                  >
                    Ver
                  </Button>
                }
              />
            )}

            {perguntasRelacao?.map((relPergunta: any, index: number) => (
              <div
                key={index}
                className="formItem"
                style={{ marginBottom: "2rem" }}
              >
                <label className="label">
                  <b>{index + 1}. </b>
                  {`${relPergunta.pergunta.description} `}
                  <InfoCircleTwoTone
                    className="info"
                    onClick={() => setPerguntaInfo(relPergunta.pergunta)}
                  />
                </label>

                {relPergunta.pergunta.tipo === "input" && (
                  <div className="testeInput">
                    <TextArea
                      value={formValues[relPergunta.pergunta._id] || ""}
                      onChange={(e) =>
                        handleInputChange(
                          relPergunta.pergunta._id,
                          e.target.value
                        )
                      }
                      disabled={
                        disabled.find(
                          (qId: string) => qId === relPergunta.pergunta._id
                        )
                          ? true
                          : false
                      }
                      placeholder={relPergunta.pergunta?.placeholder || ""}
                      autoSize={{ minRows: 1, maxRows: 20 }}
                    />
                  </div>
                )}

                {relPergunta.pergunta.tipo === "dropdown" && (
                  <Select
                    allowClear
                    value={formValues[relPergunta.pergunta._id]}
                    onChange={(e) =>
                      // handleInputChange([], e.target.value)
                      handleInputChange(relPergunta.pergunta._id, e)
                    }
                    options={relPergunta.pergunta.opcoes.map(
                      (opcao: any, i: number) => {
                        return {
                          value: opcao.alternativa,
                          label: opcao.alternativa,
                        };
                      }
                    )}
                    style={{ width: "50%" }}
                    placeholder="Selecione"
                    disabled={
                      disabled.find(
                        (qId: string) => qId === relPergunta.pergunta._id
                      )
                        ? true
                        : false
                    }
                  />
                )}

                {relPergunta.pergunta.tipo === "checkbox" && (
                  <div className="checkboxGroup">
                    {relPergunta.pergunta.opcoes.map((opcao: any) => {
                      const currentSelection =
                        formValues[relPergunta.pergunta._id] || [];

                      return (
                        <>
                          <Checkbox
                            disabled={
                              disabled.find(
                                (qId: string) =>
                                  qId === relPergunta.pergunta._id
                              )
                                ? true
                                : false
                            }
                            checked={currentSelection.includes(
                              opcao.alternativa
                            )}
                            onChange={(v) => {
                              if (
                                currentSelection.includes(opcao.alternativa)
                              ) {
                                handleInputChange(
                                  relPergunta.pergunta._id,
                                  formValues[relPergunta.pergunta._id].filter(
                                    (item: string) => item !== opcao.alternativa
                                  )
                                );
                              } else {
                                handleInputChange(relPergunta.pergunta._id, [
                                  ...(formValues[relPergunta.pergunta._id] ||
                                    []),
                                  opcao.alternativa,
                                ]);
                              }
                            }}
                          >
                            {opcao.alternativa}
                          </Checkbox>
                          <br />
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}

            {questionario &&
            questionario?.perguntasRelacao &&
            (questionario?.perguntasRelacao.filter(
              (relPerg: any) =>
                formValues[relPerg.pergunta._id.toString()] &&
                formValues[relPerg.pergunta._id.toString()][0]
            ).length *
              100) /
              questionario?.perguntasRelacao.filter(
                (relPerg: any) =>
                  relPerg.pergunta.required &&
                  !relPerg.pergunta.dependencia?.[0]
              ).length <
              100 ? (
              <SaveBtn
                text="Salvar progresso"
                onClick={() => handleSubmit(false)}
              />
            ) : (
              // <SaveBtn onClick={handleSubmit} />
              // <SaveBtn onClick={() => setShowModal(true)} />
              <SaveBtn text="Finalizar" onClick={() => handleSubmit(true)} />
            )}
          </form>
          {questionario &&
            questionario?.perguntasRelacao &&
            activeCat !==
              questionario?.perguntasRelacao[
                questionario?.perguntasRelacao.length - 1
              ]?.capitulo_id && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }}
              >
                {questionario.capitulos
                  .map((c: any) => c._id)
                  .indexOf(activeCat) !== -1 && (
                  <Button
                    size="large"
                    onClick={() => {
                      const posicao: number = questionario.capitulos
                        .map((c: any) => c._id)
                        .indexOf(activeCat);

                      setActiveCat(
                        questionario.capitulos.map((c: any) => c._id)[
                          posicao - 1
                        ]
                      );
                    }}
                    icon={<ArrowLeftOutlined />}
                  >
                    Anterior
                  </Button>
                )}

                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    const posicao: number = questionario.capitulos
                      .map((c: any) => c._id)
                      .indexOf(activeCat);

                    setActiveCat(
                      questionario.capitulos.map((c: any) => c._id)[posicao + 1]
                    );

                    if (formQuestionarioRef.current) {
                      formQuestionarioRef.current.scrollTo(0, 0);
                    }
                    if (analiseContentRef.current) {
                      analiseContentRef.current.scrollTo(0, 0);
                    }
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>Próximo</span>{" "}
                    <ArrowRightOutlined style={{ marginLeft: "8px" }} />
                  </div>
                </Button>
              </div>
            )}
        </div>
      ),
      showArrow: false,
    },
  ];

  const itemsCollapseObs = [
    {
      key: "1",
      label: <b style={{ color: "#5f29cc" }}>Observações</b>,
      children: (
        <div style={{ maxHeight: "250px", overflowY: "auto" }}>
          {perguntaInfo ? (
            <p
              style={{
                color: "#000",
                fontSize: "0.9rem",
                // marginLeft: "1.6rem",
                marginBottom: "0.6rem",
              }}
              dangerouslySetInnerHTML={{
                __html: perguntaInfo?.obs,
              }}
            />
          ) : (
            <p
              style={{
                color: "#000",
                fontSize: "0.9rem",
                marginBottom: "0.6rem",
              }}
            >
              Clique em <InfoCircleTwoTone className="info" /> para visualizar
              dados de uma pergunta
            </p>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: <b style={{ color: "#5f29cc" }}>Normas</b>,
      children: (
        <div style={{ maxHeight: "250px", overflowY: "auto" }}>
          {perguntaInfo ? (
            <p
              style={{
                color: "#000",
                fontSize: "0.9rem",
                // marginLeft: "1.6rem",
                marginBottom: "0.6rem",
              }}
              dangerouslySetInnerHTML={{
                __html: perguntaInfo?.norma,
              }}
            />
          ) : (
            <p
              style={{
                color: "#000",
                fontSize: "0.9rem",
                marginBottom: "0.6rem",
              }}
            >
              Clique em <InfoCircleTwoTone className="info" /> para visualizar
              normas de uma pergunta
            </p>
          )}
        </div>
      ),
    },
  ];

  return loading ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "70vh",
      }}
    >
      <Spin size="large" />
      <p style={{ marginTop: "0.5rem", fontSize: 14, opacity: 0.5 }}>
        carregando...
      </p>
    </div>
  ) : (
    <div className="responder_questionario">
      <>
        <BackHeader title={questionario?.nome || ""} />
        {questionario && questionario?.perguntasRelacao && (
          <Tooltip
          //  title="Azul: Obrigatórias; Verde: Não obrigatórias"
          >
            {questionario?.perguntasRelacao.filter(
              (relPerg: any) => relPerg.pergunta.required
            ).length === 0 ? (
              <Progress
                percent={parseInt(
                  // @ts-ignore
                  (questionario?.perguntasRelacao.filter(
                    (relPerg: any) =>
                      formValues[relPerg.pergunta._id.toString()] &&
                      formValues[relPerg.pergunta._id.toString()]?.[0]
                  ).length *
                    100) /
                    questionario?.perguntasRelacao.length
                  // questionario?.perguntasRelacao.length
                )} // todas
                className="analise__progress"
              />
            ) : (
              <Progress
                percent={parseInt(
                  // @ts-ignore
                  (questionario?.perguntasRelacao.filter(
                    (relPerg: any) =>
                      relPerg.pergunta.required &&
                      formValues[relPerg.pergunta._id.toString()] &&
                      formValues[relPerg.pergunta._id.toString()]?.[0]
                  ).length *
                    100) /
                    questionario?.perguntasRelacao.filter(
                      (relPerg: any) =>
                        relPerg.pergunta.required &&
                        !relPerg.pergunta.dependencia?.[0]
                    ).length
                  // questionario?.perguntasRelacao.length
                )} // todas
                className="analise__progress"
              />
            )}
          </Tooltip>
        )}

        <div className="analise">
          <Indice
            activeCat={activeCat}
            setActiveCat={setActiveCat}
            questionario={questionario}
          />

          <div className="analise__content" ref={analiseContentRef}>
            <Collapse
              collapsible={windowWidth > 800 ? "header" : "disabled"}
              ghost={windowWidth <= 800 ? true : false}
              items={itemsCollapse}
              defaultActiveKey={["1"]}
            />
          </div>

          <div className="responder_questionario_obs">
            <Collapse items={itemsCollapseObs} defaultActiveKey={["1"]} />
          </div>
        </div>
      </>

      <Modal
        title="Você deseja:"
        open={showModal}
        footer={[
          <Button
            onClick={() => {
              handleSubmit();
              setShowModal(false);
            }}
            type="primary"
            style={{ background: "green" }}
          >
            Salvar progresso
          </Button>,
          <Button
            onClick={() => {
              setFinish(true);
              handleSubmit(true);
              setShowModal(false);
            }}
            type="primary"
          >
            Concluir e enviar
          </Button>,
          <Button
            type="default"
            form="form-pergunta"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </Button>,
        ]}
        width={493}
        onCancel={() => setShowModal(false)}
      >
        <p style={{ padding: "1rem 0" }}>
          Você já respondeu todas as respostas obrigatórias, ao clicar em
          "Concluir e enviar", não será possível responder ou alterar respostas!
        </p>
      </Modal>

      <Modal
        title=""
        open={showModalFinalizar}
        footer={[]}
        width={493}
        onCancel={() => setShowModalFinalizar(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <br />
          <br />
          <CheckCircleFilled style={{ fontSize: "80px", color: "#32E6BE" }} />
          <br />
          <h2 style={{ textAlign: "center" }}>
            Parabéns!
            <br /> Você concluiu este questionário.
          </h2>
          <br />

          {pontuacaoMaxima !== 0 &&
          matrizRisco?.[0] &&
          typeof porcentagemConforme === "number" &&
          porcentagemConforme !== null &&
          porcentagemConforme !== 0 &&
          !isNaN(porcentagemConforme) &&
          questionario &&
          currentRespostas ? (
            <PdfDownload
              title={"Imprimir Análise"}
              content={
                <div className="relatorio_questionario">
                  <div className="capa" style={{ pageBreakAfter: "always" }}>
                    <h3>{"relatório de diagnóstico".toUpperCase()}</h3>
                    <div className="linha_capa" />
                    <h4 style={{ fontSize: "2.5rem", marginTop: "2rem" }}>
                      Maturidade da governança em proteção de dados
                    </h4>
                    <h1 className="capa__title">
                      {/* <br /> */}
                      <b>CPPD</b>
                    </h1>
                    <img alt="capa" src={CapaRelBlock} />
                    <p>
                      {moment(
                        new Date(
                          moduloMapeamento.questionarios
                            ?.find((q) => q._id === questionario._id)
                            ?.versoes?.find(
                              (v: any) => v._id === questionario.versoes?.[0]
                            ).updated_at
                        )
                      ).format("YY")}
                    </p>
                  </div>
                  <div className="page2" style={{ pageBreakAfter: "always" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <h1 className="title2">
                      Para
                      <br />
                      <b>Começar</b>
                    </h1>
                    <div className="rows">
                      <div className="col1">
                        <p className="col1_title">PREPARADO POR:</p>
                        <b>
                          {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
                        </b>
                        <p className="col1_title">RESPONSÁVEL:</p>
                        <b>MARCELO FATTORI</b>
                        <img
                          alt="logo"
                          className="relatorio__seusdados-logo"
                          src={LogoSeusdados}
                        />
                      </div>
                      <div className="col2">
                        <h4>Contexto:</h4>
                        <p>
                          A Lei Geral de Proteção de Dados Pessoais (LGPD) é uma
                          lei federal que regulamenta o tratamento de dados
                          pessoais de pessoas físicas no Brasil. Estar em
                          conformidade com a LGPD é importante por três motivos:
                        </p>
                        <ul>
                          <li>
                            Responsabilidade legal: empresas que não cumprem a
                            LGPD podem ser multadas em até 2% do faturamento
                            anual, limitadas a R$ 50 milhões.
                          </li>
                          <li>
                            Ética e transparência: as empresas têm a
                            responsabilidade de proteger a privacidade e a
                            segurança das pessoas ao coletar e processar dados
                            pessoais.
                          </li>
                          <li>
                            Imagem e reputação: empresas que demonstram
                            compromisso com a proteção de dados pessoais ganham
                            a confiança de seus clientes e parceiros.
                          </li>
                        </ul>
                        <p>
                          Para que a organização esteja em conformidade com a
                          LGPD necessária a adequação de processos (atividades)
                          e modificação da cultura organizacional sobre
                          tratamentos de dados, o que permitirá o gerenciamento
                          da atividade empresarial a partir da lógica da
                          proteçao de dados por padrão, com observância das
                          regras legais e normas internas por todas as partes
                          relacionadas com a empresa.
                        </p>
                        <br />
                        <p>
                          <b>
                            Não basta, portanto, criação de politicas, modelos
                            de documentos ou aquisição de softwares, mas, sim,
                            verdadeira transformação cultural, a partir da alta
                            gestão, com observância de boas práticas e
                            governança da proteção de dados pessoais.
                          </b>
                        </p>
                        <br />
                        <p>
                          <b>
                            Para desenvolver e garantir esse ambiente de
                            governança em proteção de dados pessoais é criado o
                            CPPD
                          </b>{" "}
                          - Comitê de Privacidade e Proteção de Dados Pessoais.
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            marginTop: "1rem",
                          }}
                        >
                          Palavras-chave: LGPD, conformidade, plano de
                          continuidade de negócios, diagnóstico
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="page3" style={{ pageBreakAfter: "always" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <h1 className="title2">
                      Porque isso é
                      <br />
                      <b>importante</b>
                    </h1>
                    <div className="rows">
                      <div className="col1">
                        <p className="col1_title">PREPARADO POR:</p>
                        <b>
                          {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
                        </b>
                        <p className="col1_title">RESPONSÁVEL:</p>
                        <b>MARCELO FATTORI</b>
                        <img
                          className="relatorio__seusdados-logo"
                          alt="logo"
                          src={LogoSeusdados}
                        />
                      </div>
                      <div className="col2">
                        <p>
                          A governança em proteção de dados pessoais é{" "}
                          <b>
                            essencial para cumprir as exigências legais da LGPD
                            e, também, para mitigar riscos, evitar penalidades
                            severas e obter condições favoráveis à conitnuidade
                            do negócio.
                          </b>
                        </p>
                        <br />
                        <p>
                          A <b>Resolução CD/ANPD nº 4</b>, de 24 de fevereiro de
                          2023, enfatiza a importância da implementação de boas
                          práticas de governança,{" "}
                          <b>
                            bonificando a organização diligente com uma
                            atenuante de multas e outras penalidades em casos de
                            violações.
                          </b>
                        </p>
                        <br />
                        <p>
                          A ausência de um sistema de governança implementado e
                          efetivo poderá se tornar, ainda, fator de{" "}
                          <b>agravamento da pena pela conduta negligente</b> da
                          organização.
                        </p>
                        <br />
                        <p>
                          Além disso, a governança robusta em proteção de dados
                          <b>
                            fortalece a confiança dos titulares de dados,
                            melhora a reputação da organização
                          </b>{" "}
                          e pode ser um diferencial competitivo no mercado.
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            marginTop: "1rem",
                          }}
                        >
                          Palavras-chave: LGPD, conformidade, plano de
                          continuidade de negócios, diagnóstico, governança,
                          proteção de dados, segurança da informação.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="page4" style={{ pageBreakAfter: "always" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <h1 className="title2">
                      A atuação do
                      <br />
                      <b>CPPD</b>
                    </h1>
                    <div className="rows">
                      <div className="col1">
                        <p className="col1_title">PREPARADO POR:</p>
                        <b>
                          {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
                        </b>
                        <p className="col1_title">RESPONSÁVEL:</p>
                        <b>MARCELO FATTORI</b>
                        <img
                          className="relatorio__seusdados-logo"
                          alt="logo"
                          src={LogoSeusdados}
                        />
                      </div>
                      <div className="col2">
                        <h4>Objetivo e Composição:</h4>
                        <p>
                          O Comitê de Proteção de Dados (CPPD) da
                          {/* {" "}
                      <b>
                        {
                          clientes.find(
                            (c: any) =>
                              c._id ===
                              moduloMapeamento.questionarios?.find(
                                (q) => q._id === questId
                              )?.cliente_id
                          )?.razao_social
                        }
                      </b>{" "} */}
                          é um órgão consultivo criado para assegurar a
                          conformidade com a LGPD na organização. A sua
                          composição inclui:
                        </p>
                        <ul>
                          <li>Coordenador</li>
                          <li>Secretário</li>
                          <li>Representantes das diversas áreas</li>
                          <li>Convidados indicados conforme necessidade</li>
                        </ul>
                        <h4>Indicação e Mandato</h4>
                        <p>
                          Os membros são indicados pela diretoria ou gestores de
                          áreas e devem possuir conhecimento sólido sobre a LGPD
                          e os riscos associados, participando de forma ativa e
                          diligente nas reuniões.
                        </p>
                        <h4>Funcionamento</h4>
                        <p>
                          O CPPD se reúne ordinariamente mensalmente e
                          extraordinariamente conforme necessário. As pautas são
                          organizadas pelo Coordenador, com possibilidade de
                          sugestões dos membros. As discussões são registradas
                          em atas e devem embasar decisões da Diretoria
                          Executiva.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="page5" style={{ pageBreakAfter: "always" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <h1 className="title2">
                      A atuação do
                      <br />
                      <b>CPPD</b>
                    </h1>
                    <div className="rows">
                      <div className="col1">
                        <p className="col1_title">PREPARADO POR:</p>
                        <b>
                          {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
                        </b>
                        <p className="col1_title">RESPONSÁVEL:</p>
                        <b>MARCELO FATTORI</b>
                        <img
                          className="relatorio__seusdados-logo"
                          alt="logo"
                          src={LogoSeusdados}
                        />
                      </div>
                      <div className="col2">
                        <h4>Principais atribuições</h4>
                        <ul>
                          <li>
                            Apoiar o Encarregado de Proteção de Dados (DPO)
                          </li>
                          <li>Monitorar a conformidade com a LGPD</li>
                          <li>
                            Implementar e atualizar o programa de proteção de
                            dados
                          </li>
                          <li>Identificar e mitigar riscos de privacidade</li>
                          <li>
                            Garantir a eficácia dos controles de segurança e
                            privacidade
                          </li>
                          <li>
                            Promover a cultura de proteção de dados na
                            organização
                          </li>
                        </ul>
                        <h4>Responsabilidades</h4>
                        <p>
                          Participar das reuniões e discussões, analisar
                          informações pertinentes e contribuir com sugestões,
                          sobretudo apoiar as áreas internas na continuidade do
                          processo de conformidade com a LGPD.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="page6" style={{ pageBreakAfter: "always" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <h1 className="title2">
                      <b>Objetivos</b>
                    </h1>
                    <div className="rows">
                      <div className="col1">
                        <p className="col1_title">PREPARADO POR:</p>
                        <b>
                          {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
                        </b>
                        <p className="col1_title">RESPONSÁVEL:</p>
                        <b>MARCELO FATTORI</b>
                        <img
                          className="relatorio__seusdados-logo"
                          alt="logo"
                          src={LogoSeusdados}
                        />
                      </div>
                      <div className="col2">
                        <h4>Objetivo desta análise:</h4>

                        <p>
                          Este relatório tem como objetivo avaliar o grau de
                          maturidade da organização em relação à governança de
                          proteção de dados pessoais, conforme as diretrizes da
                          Lei Geral de Proteção de Dados (LGPD) e as normas
                          internacionais relevantes.
                        </p>
                        <br />
                        <p>
                          Através de um questionário aplicado aos Comitês de
                          Privacidade e Proteção de Dados (CPPD) dos nossos
                          clientes, buscamos identificar pontos fortes e áreas
                          de melhoria para garantir a conformidade e a segurança
                          dos dados pessoais tratados pela organização.
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            marginTop: "1rem",
                          }}
                        >
                          Palavras-chave: LGPD, conformidade, plano de
                          continuidade de negócios, diagnóstico, governança,
                          proteção de dados, segurança da informação.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="page7" style={{ pageBreakAfter: "always" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <h1 className="title2">
                      <b>Fundamentos</b>
                    </h1>
                    <div className="rows">
                      <div className="col1">
                        <p className="col1_title">PREPARADO POR:</p>
                        <b>
                          {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
                        </b>
                        <p className="col1_title">RESPONSÁVEL:</p>
                        <b>MARCELO FATTORI</b>
                        <img
                          className="relatorio__seusdados-logo"
                          alt="logo"
                          src={LogoSeusdados}
                        />
                      </div>
                      <div className="col2">
                        <h4>Legais:</h4>
                        <ul>
                          <li>
                            Lei 13.709 de 14 de agosto de 2018 - LGPD
                            <ul>
                              <li>artigo 50, § 1º</li>
                              <li>artigo 52, § 1º, incisos IX, X</li>
                            </ul>
                          </li>
                          <li>
                            Resolução n. 4/2023 do Conselho Diretor da ANPD -
                            Autoridade Nacional de Proteção de Dados
                            <ul>
                              <li>artigo 7</li>
                              <li>artigo 13, inciso II</li>
                            </ul>
                          </li>
                        </ul>
                        <h4>Técnicos:</h4>
                        <ul>
                          <li>
                            ABNT. NBR <b>ISO/IEC 27001:2022</b> - Tecnologia da
                            Informação - Técnicas de segurança - Sistemas de
                            gestão de segurança da informação - Requisitos. Rio
                            de Janeiro: ABNT, 2022.;
                          </li>
                          <li>
                            RABNT. NBR <b>ISO/IEC 27002:2023</b> - Tecnologia da
                            Informação - Técnicas de segurança - Código de
                            prática para controles de segurança da informação.
                            Rio de Janeiro: ABNT, 2023.;
                          </li>
                          <li>
                            ABNT. NBR <b>ISO/IEC 27701:2022</b> - Tecnologia da
                            Informação - Técnicas de segurança - Extensão à
                            ISO/IEC 27001 e ISO/IEC 27002 para gestão de
                            privacidade da informação - Requisitos e diretrizes.
                            Rio de Janeiro: ABNT, 2022.;
                          </li>
                          <li>
                            <b>
                              NIST. Framework for Improving Critical
                              Infrastructure Cybersecurity
                            </b>
                            , Version 1.1. Gaithersburg, MD: National Institute
                            of Standards and Technology, 2018. Disponível em:
                            <br />
                            <a href="https://nvlpubs.nist.gov/nistpubs/CSWP/NIST.CSWP.04162018.pdf">
                              https://nvlpubs.nist.gov/nistpubs/CSWP/NIST.CSWP.04162018.pdf
                            </a>
                          </li>
                          <li>
                            <b>
                              NIST. NIST Privacy Framework: A Tool for Improving
                              Privacy
                            </b>{" "}
                            through Enterprise Risk Management, Version 1.0.
                            Gaithersburg, MD: National Institute of Standards
                            and Technology, 2020. Disponível em:
                            <br />
                            <a href="https://www.nist.gov/system/files/documents/2020/01/16/NIST%20Privacy%20Framework_V1.0.pdf">
                              https://www.nist.gov/system/files/documents
                              /2020/01/16/NIST%20Privacy%20Framework_V1.0.pdf
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="page8" style={{ pageBreakAfter: "always" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <h1 className="title2">
                      <b>Metodologia</b>
                    </h1>
                    <div className="rows">
                      <div className="col1">
                        <p className="col1_title">PREPARADO POR:</p>
                        <b>
                          {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
                        </b>
                        <p className="col1_title">RESPONSÁVEL:</p>
                        <b>MARCELO FATTORI</b>
                        <img
                          className="relatorio__seusdados-logo"
                          alt="logo"
                          src={LogoSeusdados}
                        />
                      </div>
                      <div className="col2">
                        <h4>Introdução:</h4>
                        <p>
                          O relatório apresenta os resultados do diagnóstico do
                          nível de maturidade da governança em proteção de dados
                          pessoais do CPPD - Comitê de Privacidade e Proteção de
                          Dados, pela{" "}
                          <b>
                            SEUSDADOS CONSULTORIA EM PROTEÇÃO DE DADOS LIMITADA
                          </b>{" "}
                          entre{" "}
                          <b>
                            {moment(
                              new Date(
                                moduloMapeamento.questionarios
                                  ?.find((q) => q._id === questionario._id)
                                  ?.versoes?.find(
                                    (v: any) =>
                                      v._id === questionario.versoes?.[0]
                                  ).created_at
                              )
                            ).format("DD [de] MMMM")}
                          </b>{" "}
                          e{" "}
                          <b>
                            {" "}
                            {moment(
                              new Date(
                                moduloMapeamento.questionarios
                                  ?.find((q) => q._id === questionario._id)
                                  ?.versoes?.find(
                                    (v: any) =>
                                      v._id === questionario.versoes?.[0]
                                  ).updated_at
                              )
                            ).format("DD [de] MMMM")}
                          </b>
                          , referente ao ano/exercício de{" "}
                          <b>
                            {moment(
                              new Date(
                                moduloMapeamento.questionarios
                                  ?.find((q) => q._id === questionario._id)
                                  ?.versoes?.find(
                                    (v: any) =>
                                      v._id === questionario.versoes?.[0]
                                  ).updated_at
                              )
                            ).format("YYYY")}
                          </b>
                          .
                        </p>
                        <h4>Metodologia:</h4>
                        <ul>
                          <li>
                            Estrutura das Perguntas e Respostas
                            <ul>
                              <li>
                                Alternativa A: Resposta totalmente errada (0
                                pontos)
                              </li>
                              <li>
                                Alternativa B: Baixo grau de maturidade (1
                                ponto)
                              </li>
                              <li>
                                Alternativa C: Médio grau de maturidade (2
                                pontos)
                              </li>
                              <li>
                                Alternativa D: Alto grau de maturidade (3
                                pontos)
                              </li>
                            </ul>
                          </li>
                          <li>
                            Pontuação Total
                            <ul>
                              <li>
                                Cada pergunta tem um valor máximo de 3 pontos.
                              </li>
                              <li>
                                O questionário completo tem um valor máximo de
                                90 pontos (30 perguntas x 3 pontos).
                              </li>
                            </ul>
                          </li>
                          <li>
                            Conversão de Pontuação para Nota de Maturidade
                            <ul>
                              <li>
                                Para converter a pontuação total em uma nota de
                                maturidade que varia de 0 a 100, utilizaremos a
                                seguinte fórmula:
                                <img
                                  style={{
                                    height: "2rem",
                                    marginTop: "1rem",
                                  }}
                                  src={NotaMaturidade}
                                  alt="exemplo"
                                />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="page9" style={{ pageBreakAfter: "always" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <h1 className="title2">
                      <b>Metodologia</b>
                    </h1>
                    <div className="rows">
                      <div className="col1">
                        <p className="col1_title">PREPARADO POR:</p>
                        <b>
                          {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
                        </b>
                        <p className="col1_title">RESPONSÁVEL:</p>
                        <b>MARCELO FATTORI</b>
                        <img
                          className="relatorio__seusdados-logo"
                          src={LogoSeusdados}
                          alt="logo"
                        />
                      </div>
                      <div className="col2">
                        <ul>
                          <li>
                            Exemplo de Pergunta e Alternativas:
                            <ul>
                              <li>
                                Pergunta: A sua organização possui um Data
                                Protection Officer (DPO) nomeado conforme os
                                requisitos da LGPD?
                                <ul>
                                  <li>
                                    Alternativa A: Não temos um DPO nomeado. (0
                                    pontos)
                                  </li>
                                  <li>
                                    Alternativa B: Temos uma pessoa responsável
                                    pela privacidade, mas sem um título oficial
                                    de DPO. (1 ponto)
                                  </li>
                                  <li>
                                    Alternativa C: Temos um DPO nomeado, mas ele
                                    não possui treinamento específico em LGPD.
                                    (2 pontos)
                                  </li>
                                  <li>
                                    Alternativa D: Temos um DPO nomeado com
                                    treinamento completo em LGPD e envolvimento
                                    ativo na gestão de privacidade. (3 pontos)
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>

                          <li>
                            Aplicação da Metodologia
                            <ul>
                              <li>
                                Administração do Questionário: Cada organização
                                responde às 30 perguntas, selecionando uma
                                alternativa para cada pergunta.
                              </li>
                              <li>
                                Cálculo da Pontuação Total: Soma-se a pontuação
                                de todas as respostas.
                              </li>
                              <li>
                                Conversão da Pontuação Total para Nota de
                                Maturidade: Aplica-se a fórmula para obter a
                                nota final.
                              </li>
                            </ul>
                          </li>
                          <li>
                            Exemplo de Cálculo
                            <ul>
                              <li>Pontuação Total Obtida: 75 pontos</li>
                              <li>
                                Pontuação Máxima: 90 pontos
                                <img
                                  style={{
                                    height: "2rem",
                                    marginTop: "1rem",
                                  }}
                                  src={NotaMaturidadeExemplo}
                                  alt="exemplo"
                                />
                              </li>
                            </ul>
                          </li>
                          <li>
                            Considerações finais sobre a metodologia
                            <ul>
                              <li>
                                Este método oferece uma forma estruturada e
                                objetiva de avaliar o grau de maturidade de uma
                                organização em relação à LGPD. As perguntas
                                cobrem aspectos chave da conformidade, como
                                governança, gestão de dados, segurança da
                                informação, entre outros. Essa abordagem permite
                                identificar o quanto efetivo vem sendo a atuação
                                do CPPD e do modelo de governança adotado na
                                organização.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="page10" style={{ pageBreakAfter: "always" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <h1 className="title2">
                      Nível de
                      <br />
                      <b>conformidade</b>
                    </h1>
                    <div className="rows">
                      <div className="col1">
                        <p className="col1_title">PREPARADO POR:</p>
                        <b>
                          {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
                        </b>
                        <p className="col1_title">RESPONSÁVEL:</p>
                        <b>MARCELO FATTORI</b>
                        <img
                          className="relatorio__seusdados-logo"
                          src={LogoSeusdados}
                          alt="logo"
                        />
                      </div>
                      <div className="col2">
                        <h4>Interpretação dos Resultados</h4>
                        <ul>
                          <li>
                            Nota de 0 a 30: Maturidade baixa (Organização em
                            estágio inicial de conformidade com a LGPD)
                          </li>
                          <li>
                            Nota de 40 a 60: Maturidade média (Organização com
                            práticas básicas de conformidade, mas com várias
                            áreas a melhorar)
                          </li>
                          <li>
                            Nota de 70 a 80: Maturidade alta (Organização com
                            boas práticas de conformidade, faltando apenas
                            alguns ajustes)
                          </li>
                          <li>
                            Nota de 90 a 100: Maturidade total (Organização em
                            plena conformidade com a LGPD, com processos bem
                            estabelecidos e monitorados)
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="page11" style={{ pageBreakAfter: "always" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <h1 className="title2">
                      Pilares &
                      <br />
                      <b>categorias</b>
                    </h1>
                    <div className="rows">
                      <div className="col1">
                        <p className="col1_title">PREPARADO POR:</p>
                        <b>
                          {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
                        </b>
                        <p className="col1_title">RESPONSÁVEL:</p>
                        <b>MARCELO FATTORI</b>
                        <img
                          className="relatorio__seusdados-logo"
                          src={LogoSeusdados}
                          alt="logo"
                        />
                      </div>
                      <div className="col2">
                        <p>A Análise está estruturada em 4 pilares:</p>
                        <ul>
                          <li>Cultura Organizacional</li>
                          <li>Processos de negócios</li>
                          <li>Gestão de TI</li>
                          <li>Segurança da Informação</li>
                        </ul>
                        <p>
                          E cada um deles possuem categorias específicas, onde
                          são tratadas pormenorizadamente cada um dos controles
                          aplicáveis.
                        </p>
                      </div>
                    </div>
                  </div>

                  {pontuacaoMaxima && pontuacaoMaxima !== 0 && (
                    <div
                      className="page12"
                      style={{ pageBreakBefore: "always" }}
                    >
                      <h1 className="title2">
                        Resultados &
                        <br />
                        <b>conformidade</b>
                      </h1>

                      <div className="rows_chart">
                        <div className="rows_chart--col1">
                          <p>
                            Baseado na análise das respostas apresentadas pelo
                            CPPD:
                          </p>
                          <div style={{ marginTop: "2rem" }}>
                            <p
                              style={{
                                fontSize: "14px !important",
                                textAlign: "left",
                                color: "#ed722f !important",
                              }}
                            >
                              BAIXO = Risco alto de incidentes e penalidades
                            </p>
                            <p
                              style={{
                                fontSize: "14px !important",
                                textAlign: "left",
                                color: "#ed722f !important",
                              }}
                            >
                              MÉDIO = Risco considerável de incidentes e
                              penalidades
                            </p>
                            <p
                              style={{
                                fontSize: "14px !important",
                                textAlign: "left",
                                color: "#ed722f !important",
                              }}
                            >
                              ALTO = Baixo risco de incidentes e penalidades
                            </p>
                          </div>
                        </div>
                        <div className="rows_chart--col2">
                          <div
                            className="rows_chart--col2__nivel"
                            style={{
                              width: 50,
                              height: 200,
                              background:
                                "rgb(0,255,8) linear-gradient(180deg, #5F29CC 0%, #4C72E3 50%,  #33CCFF 100%)",
                            }}
                            translate="no"
                          >
                            <p
                              style={{
                                position: "relative",
                                top: 90 + "px",
                                marginTop: "-29px",
                                left: "78px",
                                color:
                                  porcentagemConforme > 33 &&
                                  porcentagemConforme < 66
                                    ? "#4C72E3"
                                    : porcentagemConforme >= 66
                                    ? "#33CCFF"
                                    : "#5F29CC",
                                fontWeight: "bold",
                                fontSize: "1.2rem",
                                width: "8rem",
                              }}
                              translate="no"
                            >
                              {porcentagemConforme >= 66 && "ALTA"}
                              {porcentagemConforme > 33 &&
                                porcentagemConforme < 66 &&
                                "MÉDIO"}
                              {porcentagemConforme <= 33 && "BAIXA"}
                              <span
                                style={{
                                  position: "relative",
                                  fontSize: "0.8rem",
                                }}
                              >
                                <br />
                                {porcentagemConforme.toFixed(2)} pontos
                              </span>
                            </p>
                            <p
                              style={{
                                marginTop: "-82px",
                                marginLeft: "-20px",
                              }}
                            >
                              Maturidade
                            </p>
                            <div
                              style={{
                                width: "70px",
                                height: "2px",
                                marginTop: "-2px",
                                background: "#000",
                                opacity: 0.3,
                                marginLeft: "-10px",
                              }}
                            />
                            <div
                              style={{
                                width: "70px",
                                height: "2px",
                                background: "#000",
                                marginLeft: "-10px",
                                marginTop: `calc(${
                                  100 - porcentagemConforme
                                }px*2)`,
                              }}
                            >
                              <div
                                className="arrow right"
                                style={{ top: "-3.5px" }}
                              />
                              <div
                                className="arrow left"
                                style={{ top: "-12.9px", left: "63px" }}
                              />
                            </div>
                            <div
                              style={{
                                width: "70px",
                                height: "2px",
                                background: "#000",
                                opacity: 0.3,
                                marginLeft: "-10px",
                                marginTop: `calc(196px - calc(${porcentagemConforme}px*2))`,
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <p
                        style={{
                          fontSize: "24px",
                          textAlign: "center",
                          marginTop: "6rem",
                        }}
                      >
                        O nível de maturidade da governança de proteção de dados
                        é avaliado em uma escala de 0 a 100, com base nas
                        respostas fornecidas no questionário. Cada resposta
                        contribui com uma pontuação específica, que é somada
                        para determinar a nota final. Esta nota reflete a
                        posição da organização em relação às melhores práticas
                        de proteção de dados e ao cumprimento das exigências
                        legais.
                      </p>
                      <img
                        className="relatorio__seusdados-logo"
                        alt="logo"
                        src={LogoSeusdados}
                        style={{
                          bottom: "0",
                          marginTop: "12rem",
                        }}
                      />
                    </div>
                  )}

                  <div className="page7" style={{ pageBreakAfter: "always" }}>
                    <h1
                      className="title2"
                      style={{
                        paddingTop: "1.25rem !important",
                      }}
                    >
                      Análises &
                      <br />
                      <b>plano de ação</b>
                    </h1>
                    <div
                      style={{
                        width: "600px",
                        height: "300px",
                        position: "relative",
                        marginTop: "-30%",
                        // bottom: "-50%",
                        // left: "50%",
                        transform: "translate(0, 106.5%)",
                        zIndex: 0,
                      }}
                    >
                      <GridMatrizrisco numeroColunas={4} numeroLinhas={4} />
                    </div>
                    <ScatterChart
                      width={600}
                      height={300}
                      margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                      }}
                    >
                      <XAxis
                        label={{
                          value: "Probabilidade",
                          position: "bottom",
                          offset: 0,
                        }}
                        type="number"
                        dataKey="probabilidade"
                        name="Probabilidade"
                        ticks={[-0.5, 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5]}
                        domain={[0, 3]}
                        tickFormatter={formatTick}
                      />
                      <YAxis
                        label={{
                          value: "Impacto",
                          angle: -90,
                          position: "left",
                        }}
                        type="number"
                        dataKey="impacto"
                        ticks={[-0.5, 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5]}
                        domain={[0, 3]}
                        name="Impacto"
                        tickFormatter={formatTick}
                      />
                      <Scatter
                        fill="#000"
                        name="Matriz de Risco"
                        data={matrizRisco}
                        shape={<CustomizedShape />}
                        isAnimationActive={false}
                      />
                    </ScatterChart>

                    <div
                      style={{
                        marginLeft: "5rem",
                      }}
                    >
                      <br />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                            backgroundColor: "#33CCFF",
                            opacity: 0.65,
                          }}
                        />
                        <h5 style={{ margin: "0" }}>
                          Risco Baixo:{" "}
                          <span style={{ fontSize: "0.8rem" }}>
                            {matrizRisco
                              ?.filter((item: any) => item.risco <= 0.33)
                              ?.map(
                                (item: any) =>
                                  questionario?.perguntasRelacao
                                    .sort(
                                      (a: any, b: any) =>
                                        new Date(
                                          a.pergunta.created_at
                                        ).getTime() -
                                        new Date(
                                          b.pergunta.created_at
                                        ).getTime()
                                    )
                                    .findIndex(
                                      (r: any) =>
                                        r.pergunta._id === item.pergunta._id
                                    ) + 1
                              )
                              .join(", ")}
                          </span>
                        </h5>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                            backgroundColor: "#4C72E3",
                            opacity: 0.65,
                          }}
                        ></div>
                        <h5 style={{ margin: "0" }}>
                          Risco Médio:{" "}
                          <span style={{ fontSize: "0.8rem" }}>
                            {matrizRisco
                              ?.filter(
                                (item: any) =>
                                  item.risco > 0.33 && item.risco <= 0.66
                              )
                              ?.map(
                                (item: any) =>
                                  questionario?.perguntasRelacao
                                    .sort(
                                      (a: any, b: any) =>
                                        new Date(
                                          a.pergunta.created_at
                                        ).getTime() -
                                        new Date(
                                          b.pergunta.created_at
                                        ).getTime()
                                    )
                                    .findIndex(
                                      (r: any) =>
                                        r.pergunta._id === item.pergunta._id
                                    ) + 1
                              )
                              .join(", ")}
                          </span>
                        </h5>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                            backgroundColor: "#5F29CC",
                            opacity: 0.65,
                          }}
                        ></div>
                        <h5 style={{ margin: "0" }}>
                          Risco Alto:{" "}
                          <span style={{ fontSize: "0.8rem" }}>
                            {matrizRisco
                              ?.filter((item: any) => item.risco > 0.66)
                              ?.map(
                                (item: any) =>
                                  questionario?.perguntasRelacao
                                    .sort(
                                      (a: any, b: any) =>
                                        new Date(
                                          a.pergunta.created_at
                                        ).getTime() -
                                        new Date(
                                          b.pergunta.created_at
                                        ).getTime()
                                    )
                                    .findIndex(
                                      (r: any) =>
                                        r.pergunta._id === item.pergunta._id
                                    ) + 1
                              )
                              .join(", ")}
                          </span>
                        </h5>
                      </div>
                    </div>

                    <div style={{ height: "3rem" }} />
                    <div className="rows">
                      <div className="col1">
                        <p className="col1_title">PREPARADO POR:</p>
                        <b>
                          {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
                        </b>
                        <p className="col1_title">RESPONSÁVEL:</p>
                        <b>MARCELO FATTORI</b>
                        <img
                          className="relatorio__seusdados-logo"
                          alt="logo"
                          src={LogoSeusdados}
                        />
                      </div>
                      <div className="col2">
                        <p style={{ paddingTop: "4.2rem" }}>
                          Com base nos resultados obtidos, propomos um plano de
                          ação para aprimorar a governança de proteção de dados
                          pessoais na organização. Este plano inclui medidas
                          práticas e recomendações específicas para abordar as
                          áreas de melhoria identificadas, implementação de
                          novos controles de segurança, programas de treinamento
                          e conscientização para colaboradores, dentre outras
                          medidas.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    {questionario?.perguntasRelacao
                      ?.map((r: any) => r.capitulo_id)
                      ?.filter((id: any, index: number) => {
                        return (
                          questionario?.perguntasRelacao
                            ?.map((r: any) => r.capitulo_id)
                            ?.indexOf(id) === index
                        );
                      })
                      ?.map((idCap: any) => (
                        <div style={{ pageBreakAfter: "always" }}>
                          <div className="relatorio__headerCap">
                            <h2>
                              {
                                moduloProcessos.capitulos?.find(
                                  (cap: any) => cap._id === idCap
                                )?.description
                              }
                            </h2>
                          </div>
                          <div className="">
                            {questionario?.perguntasRelacao
                              ?.filter(
                                (r: any) =>
                                  r.capitulo_id === idCap &&
                                  r.pergunta?.description
                              )
                              ?.sort(
                                (a: any, b: any) =>
                                  new Date(a.pergunta.created_at).getTime() -
                                  new Date(b.pergunta.created_at).getTime()
                              )
                              .map((relPergunta: any) => {
                                const currentResposta = currentRespostas.filter(
                                  (r: any) =>
                                    r.pergunta_id === relPergunta.pergunta_id
                                );
                                return (
                                  <div
                                    key={relPergunta._id + "analiseMultiResp"}
                                  >
                                    <div
                                      className="respostas__pergunta--label"
                                      style={{ fontSize: 16 }}
                                    >
                                      <b>
                                        {encontrarIndicePorValor(relPergunta)}.
                                      </b>{" "}
                                      {relPergunta.pergunta.description}{" "}
                                    </div>
                                    <div style={{ marginLeft: "1rem" }}>
                                      <Space direction="vertical">
                                        {relPergunta.pergunta.tipo !==
                                        "input" ? (
                                          relPergunta.pergunta.opcoes.map(
                                            (opt: any) => {
                                              let allRespostas: any = [];
                                              currentResposta.forEach(
                                                (r: any) =>
                                                  allRespostas.push(...r.value)
                                              );

                                              const currentGrau =
                                                currentResposta?.[0]?.graus?.filter(
                                                  (g: any) =>
                                                    g.alternativa?.includes(
                                                      opt?.alternativa
                                                    )
                                                )?.[0];

                                              return (
                                                <p>
                                                  <Checkbox
                                                    checked={
                                                      currentResposta.filter(
                                                        (resp: any) =>
                                                          resp.respostas.includes(
                                                            opt.alternativa
                                                          )
                                                      )?.[0]
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    {opt.alternativa}{" "}
                                                    {currentResposta.filter(
                                                      (resp: any) =>
                                                        resp.respostas.includes(
                                                          opt.alternativa
                                                        )
                                                    ).length > 1 && (
                                                      <>
                                                        {" "}
                                                        -{" "}
                                                        <b
                                                          className={
                                                            currentGrau.grau_descricao ===
                                                              "Conforme" ||
                                                            currentGrau.grau_descricao ===
                                                              "Baixo risco"
                                                              ? "respostas__pergunta--label__conforme"
                                                              : currentGrau.grau_descricao ===
                                                                  "Não conforme" ||
                                                                currentGrau.grau_descricao ===
                                                                  "Alto risco"
                                                              ? "respostas__pergunta--label__inconforme"
                                                              : currentGrau.grau_descricao ===
                                                                "Médio risco"
                                                              ? "respostas__pergunta--label__orange"
                                                              : "respostas__pergunta--label__blue"
                                                          }
                                                        >
                                                          {currentResposta.filter(
                                                            (resp: any) =>
                                                              resp.respostas.includes(
                                                                opt.alternativa
                                                              )
                                                          ).length !== 1 && (
                                                            <>
                                                              {
                                                                currentResposta.filter(
                                                                  (resp: any) =>
                                                                    resp.respostas.includes(
                                                                      opt.alternativa
                                                                    )
                                                                ).length
                                                              }{" "}
                                                              resposta
                                                              {currentResposta.filter(
                                                                (resp: any) =>
                                                                  resp.respostas.includes(
                                                                    opt.alternativa
                                                                  )
                                                              ).length !== 1 &&
                                                                "s"}{" "}
                                                            </>
                                                          )}

                                                          {currentGrau.grau_descricao && (
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "0.6rem",
                                                              }}
                                                            >
                                                              (
                                                              {
                                                                currentGrau.grau_descricao
                                                              }
                                                              )
                                                            </span>
                                                          )}
                                                        </b>
                                                      </>
                                                    )}
                                                  </Checkbox>
                                                </p>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p className="respostas__pergunta--label__blue">
                                            {/* Conhecimento de processo */}
                                            {currentResposta[0]?.value[0]}
                                          </p>
                                        )}
                                      </Space>
                                    </div>
                                    <br />
                                    <h4>Boas práticas</h4>
                                    <br />

                                    <div
                                      style={{ marginLeft: "1rem" }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          relPergunta.pergunta.recomendacao,
                                      }}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="page14" style={{ pageBreakAfter: "always" }}>
                    <h1 className="title2">
                      Um pouco
                      <br />
                      <b>sobre nós</b>
                    </h1>
                    <div style={{ marginTop: "4rem" }}>
                      <p>
                        Na Seusdados Consultoria, acreditamos que a proteção de
                        dados é mais do que uma obrigação legal; é uma missão
                        transformadora. Somos uma consultoria multidisciplinar
                        que combina expertise jurídica, tecnológica, de
                        segurança da informação, gestão de processos e
                        comunicação para criar soluções inovadoras e disruptivas
                        em conformidade com a Lei Geral de Proteção de Dados
                        (LGPD).
                      </p>
                      <br />
                      <h4>Nossa Visão</h4>
                      <p>
                        Somos pioneiros na proteção de dados no Brasil,
                        impulsionando a transformação digital com segurança e
                        privacidade. Nosso compromisso é não apenas assegurar a
                        conformidade legal, mas também promover uma cultura de
                        proteção de dados que fortaleça a confiança e a
                        reputação de nossos clientes.
                      </p>
                      <br />

                      <h4>Nossa Missão</h4>
                      <p>
                        Capacitar empresas e organizações a navegarem pelo
                        complexo cenário de proteção de dados, oferecendo
                        soluções personalizadas que garantam a conformidade com
                        a LGPD e a segurança dos dados pessoais. Atuamos como
                        DPO as a service, proporcionando suporte técnico e
                        estratégico contínuo para a gestão de dados pessoais.
                      </p>
                      <br />

                      <h4>Nossos Valores</h4>
                      <ul>
                        <li>
                          <b>Inovação:</b> Utilizamos as mais recentes
                          tecnologias e metodologias para oferecer soluções
                          eficientes e eficazes.
                        </li>
                        <li>
                          <b>Transparência: </b>Mantemos uma comunicação clara e
                          aberta com nossos clientes, garantindo que todas as
                          etapas do processo de conformidade sejam compreendidas
                          e acompanhadas de perto.
                        </li>
                        <li>
                          <b>Multidisciplinaridade:</b> Nossa equipe é composta
                          por profissionais de diversas áreas, garantindo uma
                          abordagem abrangente e integrada.
                        </li>
                        <li>
                          <b>Excelência:</b> Comprometemo-nos com a qualidade e
                          a excelência em todos os nossos serviços, buscando
                          sempre superar as expectativas dos nossos clientes.
                        </li>
                      </ul>
                      <br />
                      <h4>Nossa Abordagem</h4>
                      <p>
                        Nossa consultoria segue uma lógica de atuação que passa
                        pela análise completa e transversal das necessidades de
                        cada cliente. Consideramos todos os aspectos envolvidos
                        na proteção de dados pessoais, desde a análise jurídica
                        e regulatória até a implementação de medidas técnicas de
                        segurança da informação.
                      </p>
                      <br />
                    </div>
                    <img
                      className="relatorio__seusdados-logo"
                      alt="logo"
                      src={LogoSeusdados}
                      style={{
                        bottom: "0",
                        marginTop: "2rem",
                      }}
                    />
                  </div>
                  <div className="page15" style={{ pageBreakAfter: "always" }}>
                    <h1 className="title2">
                      Um pouco
                      <br />
                      <b>sobre nós</b>
                    </h1>
                    <div style={{ marginTop: "4rem" }}>
                      <h4>Nossos Serviços</h4>
                      <ul>
                        <li>
                          <b>Adequação à LGPD:</b> Realizamos auditorias e
                          implementamos medidas para garantir a conformidade com
                          a LGPD.
                        </li>
                        <li>
                          <b>DPO as a Service: </b>Assumimos a função de
                          Encarregado de Proteção de Dados Pessoais, oferecendo
                          suporte contínuo.
                        </li>
                        <li>
                          <b>Treinamentos e Capacitações: </b>Oferecemos
                          programas de treinamento para capacitar equipes na
                          gestão e proteção de dados.
                        </li>
                        <li>
                          <b>Avaliação de Impacto:</b> Conduzimos avaliações de
                          impacto à proteção de dados (DPIA) para identificar e
                          mitigar riscos.
                        </li>
                      </ul>
                      <br />
                      <h4>Nossa Equipe</h4>
                      <p>
                        Nossa equipe é formada por especialistas em direito,
                        tecnologia, segurança da informação, gestão de processos
                        e comunicação, todos comprometidos com a missão de
                        proteger os dados pessoais de nossos clientes. Com uma
                        abordagem colaborativa e integrada, estamos preparados
                        para enfrentar os desafios mais complexos e proporcionar
                        soluções eficazes e inovadoras.
                      </p>
                    </div>
                    <img
                      className="relatorio__seusdados-logo"
                      alt="logo"
                      src={LogoSeusdados}
                      style={{
                        bottom: "0",
                        marginTop: "26rem",
                      }}
                    />
                  </div>
                  <div className="page16" style={{ pageBreakAfter: "always" }}>
                    <h1 className="title2">
                      Próximos
                      <br />
                      <b>passos:</b>
                    </h1>
                    <div className="rows">
                      <div className="col1"></div>
                      <div className="col2">
                        <p>
                          A <b>continuidade do negócio</b>, do ponto de vista da{" "}
                          <b>gestão estratégica de riscos</b> da organização,
                          passa pela conformidade com a{" "}
                          <b>Lei Geral de Proteção de Dados</b>. Dentre outras,
                          a obtenção e evidências de que parceiros comerciais e
                          fornecedores estejam em conformidade com a LGPD, é{" "}
                          <b>obrigatória</b> para garantir a sua conformidade.
                        </p>
                        <div className="proximos-passos__containner">
                          <p>
                            01<b>Avaliar o resultado</b>
                          </p>
                          <div className="proximos-passos__hr" />
                          <p>
                            02<b>Adequar o plano de ação </b>
                          </p>
                          <div className="proximos-passos__hr" />
                          <p>
                            03<b>Estruturar</b>
                          </p>
                          <div className="proximos-passos__hr" />
                          <p>
                            04<b>Implementar </b>
                          </p>
                          <div className="proximos-passos__hr" />
                          <p>
                            05<b>Monitorar </b>
                          </p>
                          <div className="proximos-passos__hr" />
                        </div>
                      </div>
                    </div>
                    <img
                      className="relatorio__seusdados-logo"
                      alt="logo"
                      src={LogoSeusdados}
                      style={{
                        bottom: "0",
                        marginTop: "-2rem",
                        // paddingBottom: "2rem",
                      }}
                    />
                  </div>
                  <div className="page17" style={{ pageBreakAfter: "always" }}>
                    <h4 className="title2">Obrigado</h4>
                    <div style={{ marginTop: "2rem" }}>
                      <br />
                      <br />
                      <h4>
                        O time da <b>Seusdados Consultoria</b> está a sua
                        disposição para esclarecer dúvidas e também ajudar, não
                        deixe de nos chamar.
                      </h4>
                      <div className="address-info">
                        <p>
                          <CompassOutlined className="address-info--icon" />
                          <span>Rua Afonso Arinos, 24, Vila Nova Espéria,</span>
                          <br />
                          <span style={{ marginLeft: "5.6rem" }}>
                            Jundiaí-SP, 13.211-030
                          </span>
                        </p>
                        <p>
                          <PhoneOutlined className="address-info--icon" />
                          <span>+55 11 4040 5552</span>
                        </p>
                        <p>
                          <MailOutlined className="address-info--icon" />
                          <span>marcelo@seusdados.com</span>
                        </p>
                        <p>
                          <SearchOutlined className="address-info--icon" />
                          <span>
                            <a href="www.seusdados.com">www.seusdados.com</a>
                          </span>
                        </p>
                      </div>
                    </div>
                    <img
                      className="relatorio__seusdados-logo"
                      alt="logo"
                      src={LogoSeusdados}
                      style={{
                        marginTop: "24rem",
                        // paddingBottom: "2rem",
                      }}
                    />
                  </div>
                </div>
              }
            />
          ) : (
            <div style={{ display: "flex" }}>
              <Spin />
              <p style={{ marginTop: "0.5rem", fontSize: 14, opacity: 0.5 }}>
                Gerando seu relatório...
              </p>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        title={
          "Introdução: " +
          moduloProcessos?.capitulos?.find((c) => c._id === activeCat)
            ?.description
        }
        open={showModalIntro}
        footer={[]}
        width={1000}
        onCancel={() => setShowModalIntro(false)}
      >
        <hr />
        <p
          style={{
            color: "#000",
            fontSize: "0.9rem",
            // marginLeft: "1.6rem",
            marginBottom: "0.6rem",
          }}
          dangerouslySetInnerHTML={{
            __html:
              moduloProcessos?.capitulos?.find((c) => c._id === activeCat)
                ?.introducao || "",
          }}
        />
      </Modal>
    </div>
  );
};

export default Responder;
