import * as React from "react";
import { SaveBtnTypes } from "../../@types/buttom";
import "./styles.css";
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { Spin, Tooltip } from "antd";

const SaveBtn: React.FC<SaveBtnTypes> = ({ onClick, type, text, loading }) => {
  return (
    <Tooltip title="Salvar progresso">
      <button
        type={type || "button"}
        onClick={onClick}
        className={`saveBtn ${text && "saveBtn-text"}`}
      >
        {text && <p style={{ color: "#fff" }}>{text}</p>}
        {loading ? (
          <Spin
            indicator={<LoadingOutlined spin style={{ color: "#fff" }} />}
          />
        ) : (
          <SaveOutlined rev={undefined} />
        )}
      </button>
    </Tooltip>
  );
};
export default SaveBtn;
