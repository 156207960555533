import React, { useState, useEffect } from "react";
import {
  Avatar,
  Space,
  Tooltip,
  Modal,
  Tag,
  Dropdown,
  Checkbox,
  Divider,
  Button,
  theme,
  Select,
  Input,
  Spin,
} from "antd";
import {
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { GlobalContextType } from "../../../@types/global";
import { GlobalContext } from "../../../context/globalContext";
import "./styles.css";
import Comments from "../../Comments/Comments";
import { ModalVerTarefaTypes } from "../../../@types/modalVerTarefa";
import NewUser from "../../NewUser/NewUser";
import { useParams } from "react-router-dom";
import { TaskType } from "../../../context/taskContext";

const { useToken } = theme;
const { Search } = Input;

const Task: React.FC<ModalVerTarefaTypes> = ({ showModal, setShowModal }) => {
  const { userInfo, usuarios, moduloTask } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [searchValue, setSearchValue] = useState("");
  const [filteredUsuarios, setFilteredUsuarios] = useState([]);
  const [showModalNewUser, setShowModalNewUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const handleSearch = (value: any) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (usuarios) {
      setFilteredUsuarios(
        usuarios.filter((usr: any) =>
          usr.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  }, [searchValue, usuarios]);
  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: "300px",
    maxHeight: "800px",
    overflowY: "auto",
    overflowX: "hidden",
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };

  const handleShowModal = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };

  const taskCorretas = (taskCli: TaskType[], other: TaskType[]) => {
    const tasksCliente = taskCli?.filter(
      (task: any) =>
        task.tipo_dependencia === "checklist" &&
        task.dependencia === moduloTask.selectedTask?._id
    );

    const otherTasks = other?.filter(
      (task: any) =>
        task.tipo_dependencia === "checklist" &&
        task.dependencia === moduloTask.selectedTask?._id
    );

    if (tasksCliente?.[0]) {
      return tasksCliente;
    } else {
      return otherTasks;
    }
  };

  const handleMenuClick = (e: any) => {
    // Supondo que 'moduloTask.selectedTask' é a tarefa que você deseja atualizar
    moduloTask.selectedTask &&
      moduloTask.updateTask({ ...moduloTask.selectedTask, status: e.key });
  };

  const items: any = [
    {
      key: "Pendente",
      label: "Pendente",
      onClick: handleMenuClick,
    },
    {
      key: "Em andamento",
      label: "Em andamento",
      onClick: handleMenuClick,
    },
    {
      key: "Concluída",
      label: "Concluída",
      onClick: handleMenuClick,
    },
  ];

  return (
    <>
      <Modal
        title={
          moduloTask.selectedTask
            ? moduloTask.selectedTask.tipo === "pauta"
              ? moduloTask.selectedTask.title
              : "Tarefa: " + moduloTask.selectedTask.title
            : ""
        }
        // title={selectedTask ? "Tarefa: Capitulo " + selectedTask.title : ""}
        open={showModal}
        style={{ maxWidth: 900 }}
        footer={[]}
        width={"90%"}
        onCancel={handleShowModal}
      >
        {userInfo?.grupos?.find(
          (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
        ) ? (
          <Dropdown
            menu={{
              items,
            }}
          >
            <Space style={{ cursor: "pointer" }}>
              <Tag
                style={{
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                }}
                color={
                  moduloTask.selectedTask?.status === "Em andamento"
                    ? "blue"
                    : moduloTask.selectedTask?.status === "Concluída"
                    ? "green"
                    : "grey"
                }
              >
                {moduloTask.selectedTask?.status}
              </Tag>
              <EditOutlined />
            </Space>
          </Dropdown>
        ) : (
          <Tag
            style={{
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
            }}
            color={
              moduloTask.selectedTask?.status === "Em andamento"
                ? "blue"
                : moduloTask.selectedTask?.status === "Concluída"
                ? "green"
                : "grey"
            }
          >
            {moduloTask.selectedTask?.status}
          </Tag>
        )}
        {moduloTask?.selectedTask?.tipo !== "pauta" && (
          <>
            <h3
              style={{
                marginTop: "0rem",
              }}
            >
              Atribuído
            </h3>
            {/* <Tooltip title="Alterar atribuíção"> */}
            <div
              className="microtask_user"
              style={{
                // cursor: "pointer",
                width: "fit-content",
              }}
            >
              <Avatar
                className="avatar-task"
                src={`https://ui-avatars.com/api/?name=${moduloTask.selectedTask?.assignment_name?.replaceAll(
                  " ",
                  "+"
                )}?size=128?background=random`}
              />
              <p>{moduloTask.selectedTask?.assignment_name || ""}</p>
              {/* <EditOutlined style={{ marginLeft: "0.5rem" }} /> */}
            </div>
            {/* </Tooltip> */}
          </>
        )}

        <h3
          style={{
            marginTop: "1rem",
          }}
        >
          Gerenciamento a cargo de:
        </h3>
        <div className="microtask_user">
          <Avatar
            className="avatar-task"
            src={`https://ui-avatars.com/api/?name=${moduloTask.selectedTask?.owner_name?.replaceAll(
              " ",
              "+"
            )}?background=random`}
          />
          <p>{moduloTask.selectedTask?.owner_name || ""}</p>
        </div>
        <h3
          style={{
            marginTop: "2rem",
          }}
        >
          Descrição
        </h3>
        <p
          style={{
            maxHeight: "20rem",
            marginTop: "0.5rem",
            marginBottom: "2rem",
            overflowY: "auto",
            background: "#F3F2F1",
            borderRadius: "10px",
            padding: "1rem",
          }}
          dangerouslySetInnerHTML={{
            __html:
              moduloTask?.selectedTask?.description.replaceAll(
                "\n",
                "<br />"
              ) || "",
          }}
        >
          {/* {selectedTask?.description
            .split("\n")
            .map((item: any, key: number) => (
              <React.Fragment key={key}>
                {item}
                <br />
                <br />
              </React.Fragment>
            ))} */}
        </p>

        {/* <p>
          Prazo:{" "}
          <b>
            {selectedTask?.prazo_conclusao} dia
            {selectedTask?.prazo_conclusao
              ? selectedTask?.prazo_conclusao > 1
                ? "s"
                : ""
              : "s"}
          </b>
        </p> */}

        {(moduloTask.tasksCliente?.filter(
          (task: any) =>
            task.tipo_dependencia === "checklist" &&
            task.dependencia === moduloTask.selectedTask?._id
        )[0] ||
          moduloTask.tasks?.filter(
            (task: any) =>
              task.tipo_dependencia === "checklist" &&
              task.dependencia === moduloTask.selectedTask?._id
          )[0]) && (
          <h3
            style={{
              marginTop: "2rem",
              marginBottom: "0.5rem",
            }}
          >
            Lista de verificação
          </h3>
        )}

        {taskCorretas(moduloTask.tasksCliente, moduloTask.tasks)
          ?.filter(
            (task: any) =>
              task.tipo_dependencia === "checklist" &&
              task.dependencia === moduloTask.selectedTask?._id
          )
          ?.map((task: any) => {
            return (
              <div className="microtask_container">
                <Tooltip title="Ver tarefa">
                  <EyeOutlined
                    onClick={() => {
                      moduloTask.setSelectedTask(task);
                    }}
                    style={{
                      cursor: "pointer",
                      marginRight: "0.5rem",
                      color: "#1677ff",
                    }}
                  />
                </Tooltip>

                {/* <Tooltip title="Concluir tarefa">
                  <Checkbox>{task.title}</Checkbox>
                </Tooltip> */}
                <p style={{ maxWidth: "600px" }}>{task.title}</p>
                <Tooltip title="Alterar atribuíção">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "auto",
                    }}
                  >
                    {loading && (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 16 }} spin />
                        }
                      />
                    )}
                    <Dropdown
                      placement="bottom"
                      disabled={loading}
                      trigger={["click"]}
                      menu={{
                        items: [
                          {
                            key: "1",
                            type: "group",
                            label: "Sponsors",
                            children: usuarios
                              ?.filter((usr: any) =>
                                usr.grupos.find(
                                  (g: any) => g.name === "sponsor"
                                )
                              )
                              ?.filter((usr: any) =>
                                params.id ? usr.cliente_id === params.id : true
                              )
                              ?.map((usr: any) => {
                                return {
                                  key: usr._id,
                                  label: usr.name,
                                  onClick: (v: any) => {
                                    setLoading(true);
                                    moduloTask
                                      .updateTaskAssignment(task._id, usr._id)
                                      .finally(() => setLoading(false));
                                  },
                                };
                              }),
                          },
                          {
                            key: "2",
                            type: "group",
                            label: "Outros",
                            children: usuarios
                              ?.filter((usr: any) =>
                                usr.grupos.find(
                                  (g: any) => g.name !== "sponsor"
                                )
                              )
                              ?.filter((usr: any) =>
                                params.id ? usr.cliente_id === params.id : true
                              )
                              ?.map((usr: any) => {
                                return {
                                  key: usr._id,
                                  label: usr.name,
                                  onClick: (v: any) => {
                                    setLoading(true);
                                    moduloTask
                                      .updateTaskAssignment(task._id, usr._id)
                                      .finally(() => setLoading(false));
                                  },
                                };
                              }),
                          },
                        ],
                      }}
                      dropdownRender={(menu) => (
                        <div style={contentStyle}>
                          <Search
                            style={{
                              margin: "10px 18px",
                              width: "264px",
                            }}
                            placeholder="Buscar"
                            onSearch={handleSearch}
                            enterButton
                          />
                          <Divider style={{ margin: 0 }} />
                          {React.cloneElement(menu as React.ReactElement, {
                            style: menuStyle,
                          })}
                          <Divider style={{ margin: 0 }} />
                          <Space
                            style={{
                              padding: 8,
                              justifyContent: "flex-end",
                              display: "flex",
                            }}
                          >
                            <Button
                              type="primary"
                              icon={<UserAddOutlined />}
                              onClick={() => setShowModalNewUser(true)}
                            >
                              Novo usuário
                            </Button>
                          </Space>
                        </div>
                      )}
                    >
                      <div
                        className="microtask_user"
                        style={{ cursor: "pointer", marginLeft: "auto" }}
                      >
                        <Avatar
                          className="avatar-task"
                          src={`https://ui-avatars.com/api/?name=${task?.assignment_name?.replaceAll(
                            " ",
                            "+"
                          )}?background=random`}
                        />
                        <p>{task?.assignment_name || ""}</p>
                        <EditOutlined style={{ marginLeft: "0.5rem" }} />
                      </div>
                    </Dropdown>
                  </div>
                </Tooltip>
              </div>
            );
          })}

        {!moduloTask.selectedTask?.title.includes("pauta") && (
          <>
            <h3
              style={{
                marginTop: "2rem",
                marginBottom: "-2.5rem",
              }}
            >
              {moduloTask.selectedTask?.tipo === "pauta" && "Comentários"}
              {moduloTask.selectedTask?.tipo !== "pauta" && "Ações rápidas"}
            </h3>
            {moduloTask.selectedTask && moduloTask.selectedTask._id && (
              <Comments task_id={moduloTask.selectedTask._id} />
            )}
          </>
        )}
      </Modal>
      <NewUser
        showModal={showModalNewUser}
        setShowModal={setShowModalNewUser}
      />
    </>
  );
};

export default Task;
