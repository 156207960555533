import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  DownCircleOutlined,
  ExclamationCircleOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  RightCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { GlobalContextType } from "../../../../../../@types/global";
import { GlobalContext } from "../../../../../../context/globalContext";
import React, { ReactNode, useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import { InfoCircle } from "iconsax-react";
import { Titular } from "../../../../../../@types/titular";
import EditFornecedor from "../../../Lider/components/EditFornecedor/EditFornecedor";
import { Questionario } from "../../../../../..//context/mapeamentoContext";

const { TextArea } = Input;
const { Option, OptGroup } = Select;

interface CompartilhamentoGuiaProps {
  titular: Titular;
  setSelectedDados: (
    v: any[] | null | ((prev: any[] | null) => any[] | null)
  ) => void;
  selectedDados: any[] | null;
  icon: ReactNode;
  setIcon: (icon: ReactNode) => void;
  loadingTitulares: boolean;
  setLoadingTitulares: (v: boolean) => void;
  setActiveTab: (v: string) => void;
  activeTab: string;
  mapeamento: Questionario | null | undefined;
}

const CompartilhamentoGuia: React.FC<CompartilhamentoGuiaProps> = ({
  titular,
  setSelectedDados,
  selectedDados,
  icon,
  setIcon,
  loadingTitulares,
  setLoadingTitulares,
  setActiveTab,
  activeTab,
  mapeamento,
}) => {
  const { moduloProcessos, moduloMapeamento, fornecedores } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [showCreateFornecedor, setShowCreateFornecedor] =
    useState<boolean>(false);

  useEffect(() => {
    // Seleciona a div com a classe `ant-tabs-content-holder` e reseta o scroll
    const tabsContent = document.querySelector(".ant-tabs-content-holder");
    if (tabsContent) {
      tabsContent.scrollTop = 0;
    }
  }, [activeTab]);

  return (
    <>
      <h4>Compartilhamento</h4>
      <hr />
      {/* Listagem de dos dados pessoais */}
      <>
        <>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            1. Selecione os dados para definir as informações de
            compartilhamento.{" "}
          </p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="Selecionar todos">
              <Switch
                unCheckedChildren="Não"
                checkedChildren="Sim"
                onClick={(v) => {
                  if (v && titular.dados) {
                    setSelectedDados(titular.dados);
                  } else {
                    setSelectedDados(null);
                  }
                }}
                checked={
                  selectedDados?.length === titular.dados?.length ? true : false
                }
              />
            </Tooltip>
            <p style={{ marginLeft: "0.5rem" }}>
              As informações de compartilhamento se aplicam a todos os dados
              pessoais cadastrados?
            </p>
          </div>
          <div></div>
        </>
        <br />
        <>
          {titular.dados?.map((dado) => (
            <Button
              size="small"
              style={{
                background:
                  titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.frequencia &&
                  (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.bases_legais_comuns ||
                    titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                      ?.bases_legais_sensiveis)
                    ? selectedDados?.find((d) => d.dado_id === dado.dado_id)
                      ? "#5F29CC"
                      : "#32E6BE"
                    : "",
                color:
                  titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.frequencia &&
                  (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.bases_legais_comuns ||
                    titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                      ?.bases_legais_sensiveis)
                    ? selectedDados?.find(
                        (d: any) => d.dado_id === dado.dado_id
                      )
                      ? "#fff"
                      : "#333"
                    : selectedDados?.find(
                        (d: any) => d.dado_id === dado.dado_id
                      )
                    ? "#fff"
                    : "#333",
                marginRight: "0.5rem",
                marginBottom: "0.5rem",
                opacity:
                  selectedDados?.length === titular.dados?.length
                    ? titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                        ?.frequencia &&
                      (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                        ?.bases_legais_comuns ||
                        titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                          ?.bases_legais_sensiveis)
                      ? 1
                      : 0.4
                    : 1,
                cursor:
                  selectedDados?.length === titular.dados?.length
                    ? "default"
                    : "pointer",
              }}
              icon={
                titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                  ?.frequencia &&
                (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                  ?.bases_legais_comuns ||
                  titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.bases_legais_sensiveis) ? (
                  selectedDados?.find(
                    (d: any) => d.dado_id === dado.dado_id
                  ) ? (
                    <DownCircleOutlined />
                  ) : (
                    <CheckCircleOutlined />
                  )
                ) : selectedDados?.find(
                    (d: any) => d.dado_id === dado.dado_id
                  ) ? (
                  <RightCircleOutlined />
                ) : (
                  <PlusCircleOutlined />
                )
              }
              type={
                selectedDados?.find((d: any) => d.dado_id === dado.dado_id)
                  ? "primary"
                  : titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                      ?.frequencia &&
                    (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                      ?.bases_legais_comuns ||
                      titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                        ?.bases_legais_sensiveis)
                  ? "primary"
                  : "default"
              }
              onClick={() => {
                setSelectedDados((prev) => {
                  if (
                    selectedDados?.find((d: any) => d.dado_id === dado.dado_id)
                  ) {
                    // remove
                    if (prev) {
                      return [
                        ...prev.filter((d) => d.dado_id !== dado.dado_id),
                      ];
                    } else {
                      return null;
                    }
                  } else {
                    // adiciona
                    return [dado];
                  }
                });
              }}
            >
              {
                moduloMapeamento.dados?.find((d: any) => d._id === dado.dado_id)
                  ?.nome
              }
            </Button>
          ))}
        </>
        <div></div>
      </>
      <br />
      {/* Preenchimento das informações */}
      {selectedDados?.[0] && (
        <>
          {/* Compartilhamento de dados */}
          <>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              2. Compartilhamento dos dados{" "}
              <Tooltip title="Você deve cadastrar as áreas e/ou terceiros com quem os dados pessoais são compartilhados nesta atividade">
                <InfoCircle
                  size={16}
                  style={{
                    marginLeft: "0.5rem",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </p>

            <Space direction="vertical">
              <Button
                onClick={() => {
                  moduloMapeamento.setCurrentTitulares(
                    (prevTitulares: Titular[]) => {
                      let currentTitular = titular;
                      selectedDados?.forEach((dado) => {
                        const dadoIndex: number | undefined =
                          currentTitular.dados?.findIndex(
                            (d) => d.dado_id === dado.dado_id
                          );

                        const valor = "Departamento interno";

                        if (
                          dadoIndex !== undefined &&
                          currentTitular?.dados?.[dadoIndex]
                        ) {
                          let currentCompartilhamento: any = currentTitular
                            .dados[dadoIndex].compartilhamento?.[0]
                            ? currentTitular.dados[dadoIndex].compartilhamento
                            : [];

                          currentTitular.dados[dadoIndex] = {
                            ...currentTitular.dados[dadoIndex],
                            compartilhamento: currentCompartilhamento.find(
                              (item: string) => item === valor
                            )
                              ? currentCompartilhamento.filter(
                                  (item: string) => item !== valor
                                )
                              : [
                                  ...currentCompartilhamento.filter(
                                    (c: string) =>
                                      c !== "Não há compartilhamento"
                                  ),
                                  valor,
                                ],
                          };
                        }
                      });
                      return [
                        ...prevTitulares.filter((t) => t.key !== titular.key),
                        currentTitular,
                      ];
                    }
                  );
                }}
                size="small"
                type={
                  titular?.dados
                    ?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )
                    ?.find((dado) =>
                      dado.compartilhamento?.find(
                        (item: any) => item === "Departamento interno"
                      )
                    )
                    ? "primary"
                    : "default"
                }
                icon={
                  titular?.dados
                    ?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )
                    ?.find((dado) =>
                      dado.compartilhamento?.find(
                        (item: any) => item === "Departamento interno"
                      )
                    ) ? (
                    <CheckCircleFilled />
                  ) : (
                    <PlusCircleOutlined />
                  )
                }
                style={
                  titular?.dados
                    ?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )
                    ?.find((dado) =>
                      dado.compartilhamento?.find(
                        (item: any) => item === "Departamento interno"
                      )
                    )
                    ? {
                        background: "#3333FF",
                        color: "#fff",
                      }
                    : {}
                }
              >
                Departamento interno{" "}
                <Tooltip title="É a área da sua organização em que os dados são compartilhados">
                  <ExclamationCircleOutlined />
                </Tooltip>
              </Button>
              <Button
                onClick={() => {
                  moduloMapeamento.setCurrentTitulares(
                    (prevTitulares: Titular[]) => {
                      let currentTitular = titular;
                      selectedDados?.forEach((dado) => {
                        const dadoIndex: number | undefined =
                          currentTitular.dados?.findIndex(
                            (d) => d.dado_id === dado.dado_id
                          );

                        const valor = "Pessoa jurídica externa";

                        if (
                          dadoIndex !== undefined &&
                          currentTitular?.dados?.[dadoIndex]
                        ) {
                          let currentCompartilhamento: any = currentTitular
                            .dados[dadoIndex].compartilhamento?.[0]
                            ? currentTitular.dados[dadoIndex].compartilhamento
                            : [];

                          currentTitular.dados[dadoIndex] = {
                            ...currentTitular.dados[dadoIndex],
                            compartilhamento: currentCompartilhamento.find(
                              (item: string) => item === valor
                            )
                              ? currentCompartilhamento.filter(
                                  (item: string) => item !== valor
                                )
                              : [
                                  ...currentCompartilhamento.filter(
                                    (c: string) =>
                                      c !== "Não há compartilhamento"
                                  ),
                                  valor,
                                ],
                          };
                        }
                      });
                      return [
                        ...prevTitulares.filter((t) => t.key !== titular.key),
                        currentTitular,
                      ];
                    }
                  );
                }}
                size="small"
                type={
                  titular?.dados
                    ?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )
                    ?.find((dado) =>
                      dado.compartilhamento?.find(
                        (item: any) => item === "Pessoa jurídica externa"
                      )
                    )
                    ? "primary"
                    : "default"
                }
                icon={
                  titular?.dados
                    ?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )
                    ?.find((dado) =>
                      dado.compartilhamento?.find(
                        (item: any) => item === "Pessoa jurídica externa"
                      )
                    ) ? (
                    <CheckCircleFilled />
                  ) : (
                    <PlusCircleOutlined />
                  )
                }
                style={
                  titular?.dados
                    ?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )
                    ?.find((dado) =>
                      dado.compartilhamento?.find(
                        (item: any) => item === "Pessoa jurídica externa"
                      )
                    )
                    ? {
                        background: "#3333FF",
                        color: "#fff",
                      }
                    : {}
                }
              >
                Pessoa jurídica externa{" "}
                <Tooltip title="É um terceiro PJ (fornecedor, parceiro ou prestador) com quem os dados são compartilhados">
                  <ExclamationCircleOutlined />
                </Tooltip>
              </Button>
              <Button
                onClick={() => {
                  moduloMapeamento.setCurrentTitulares(
                    (prevTitulares: Titular[]) => {
                      let currentTitular = titular;
                      selectedDados?.forEach((dado) => {
                        const dadoIndex: number | undefined =
                          currentTitular.dados?.findIndex(
                            (d) => d.dado_id === dado.dado_id
                          );

                        const valor = "Pessoa física externa";

                        if (
                          dadoIndex !== undefined &&
                          currentTitular?.dados?.[dadoIndex]
                        ) {
                          let currentCompartilhamento: any = currentTitular
                            .dados[dadoIndex].compartilhamento?.[0]
                            ? currentTitular.dados[dadoIndex].compartilhamento
                            : [];

                          currentTitular.dados[dadoIndex] = {
                            ...currentTitular.dados[dadoIndex],
                            compartilhamento: currentCompartilhamento.find(
                              (item: string) => item === valor
                            )
                              ? currentCompartilhamento.filter(
                                  (item: string) => item !== valor
                                )
                              : [
                                  ...currentCompartilhamento.filter(
                                    (c: string) =>
                                      c !== "Não há compartilhamento"
                                  ),
                                  valor,
                                ],
                          };
                        }
                      });
                      return [
                        ...prevTitulares.filter((t) => t.key !== titular.key),
                        currentTitular,
                      ];
                    }
                  );
                }}
                size="small"
                type={
                  titular?.dados
                    ?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )
                    ?.find((dado) =>
                      dado.compartilhamento?.find(
                        (item: any) => item === "Pessoa física externa"
                      )
                    )
                    ? "primary"
                    : "default"
                }
                icon={
                  titular?.dados
                    ?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )
                    ?.find((dado) =>
                      dado.compartilhamento?.find(
                        (item: any) => item === "Pessoa física externa"
                      )
                    ) ? (
                    <CheckCircleFilled />
                  ) : (
                    <PlusCircleOutlined />
                  )
                }
                style={
                  titular?.dados
                    ?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )
                    ?.find((dado) =>
                      dado.compartilhamento?.find(
                        (item: any) => item === "Pessoa física externa"
                      )
                    )
                    ? {
                        background: "#3333FF",
                        color: "#fff",
                      }
                    : {}
                }
              >
                Pessoa física externa{" "}
                <Tooltip title="É um terceiro PF (fornecedor, parceiro ou prestador) com quem os dados são compartilhados">
                  <ExclamationCircleOutlined />
                </Tooltip>
              </Button>
              <Button
                onClick={() => {
                  moduloMapeamento.setCurrentTitulares(
                    (prevTitulares: Titular[]) => {
                      let currentTitular = titular;
                      selectedDados?.forEach((dado) => {
                        const dadoIndex: number | undefined =
                          currentTitular.dados?.findIndex(
                            (d) => d.dado_id === dado.dado_id
                          );

                        const valor = "Não há compartilhamento";

                        if (
                          dadoIndex !== undefined &&
                          currentTitular?.dados?.[dadoIndex]
                        ) {
                          let currentCompartilhamento: any = currentTitular
                            .dados[dadoIndex].compartilhamento?.[0]
                            ? currentTitular.dados[dadoIndex].compartilhamento
                            : [];

                          currentTitular.dados[dadoIndex] = {
                            ...currentTitular.dados[dadoIndex],
                            compartilhamento: currentCompartilhamento.includes(
                              "Não há compartilhamento"
                            )
                              ? []
                              : [valor],
                          };
                        }
                      });
                      return [
                        ...prevTitulares.filter((t) => t.key !== titular.key),
                        currentTitular,
                      ];
                    }
                  );
                }}
                size="small"
                type={
                  titular?.dados
                    ?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )
                    ?.find((dado) =>
                      dado.compartilhamento?.find(
                        (item: any) => item === "Não há compartilhamento"
                      )
                    )
                    ? "primary"
                    : "default"
                }
                icon={
                  titular?.dados
                    ?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )
                    ?.find((dado) =>
                      dado.compartilhamento?.find(
                        (item: any) => item === "Não há compartilhamento"
                      )
                    ) ? (
                    <CheckCircleFilled />
                  ) : (
                    <PlusCircleOutlined />
                  )
                }
                style={
                  titular?.dados
                    ?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )
                    ?.find((dado) =>
                      dado.compartilhamento?.find(
                        (item: any) => item === "Não há compartilhamento"
                      )
                    )
                    ? {
                        background: "#3333FF",
                        color: "#fff",
                      }
                    : {}
                }
              >
                Não há compartilhamento{" "}
                <Tooltip title="Se você não compartilha dados, selecione essa opção">
                  <ExclamationCircleOutlined />
                </Tooltip>
              </Button>
            </Space>
            <div></div>
            <br />
            <Row gutter={24}>
              {titular?.dados
                ?.filter((dado: any) =>
                  selectedDados?.find((d) => dado.dado_id === d.dado_id)
                )
                ?.find((dado) =>
                  dado.compartilhamento?.find(
                    (item: any) =>
                      item === "Pessoa jurídica externa" ||
                      item === "Pessoa física externa"
                  )
                ) && (
                <Col span={12}>
                  <Collapse
                    defaultActiveKey={"1"}
                    size="small"
                    items={[
                      {
                        key: "1",
                        label: "Externos",
                        children: (
                          <>
                            <Button
                              onClick={() => {
                                setShowCreateFornecedor(true);
                              }}
                              type={"text"}
                              style={{
                                borderColor: "#5F29CC",
                                color: "#5F29CC",
                              }}
                            >
                              Cadastrar pessoa física ou jurídica{" "}
                              <PlusCircleFilled />
                            </Button>
                            {mapeamento?.cliente_id && (
                              <EditFornecedor
                                setShowAddFornecedor={setShowCreateFornecedor}
                                showAddFornecedor={showCreateFornecedor}
                                clientId={mapeamento?.cliente_id}
                                titular={titular}
                                selectedDados={selectedDados}
                              />
                            )}

                            {fornecedores
                              ?.filter((f: any) =>
                                titular?.dados
                                  ?.filter((d: any) =>
                                    selectedDados?.find(
                                      (dado) => dado.dado_id === d.dado_id
                                    )
                                  )
                                  ?.map((d) => d.externos)
                                  ?.reduce(
                                    (acc: any, curr: any) => acc.concat(curr),
                                    []
                                  )
                                  ?.map(
                                    (externo: any) => externo?.fornecedor_id
                                  )
                                  ?.includes(f._id)
                              )
                              ?.map((fornecedor: any) => {
                                const currentExterno: any = titular?.dados
                                  ?.filter((dado: any) =>
                                    selectedDados?.find(
                                      (d) => dado.dado_id === d.dado_id
                                    )
                                  )
                                  ?.find((dado) =>
                                    dado.compartilhamento?.find(
                                      (item: any) =>
                                        item === "Departamento interno"
                                    )
                                  )
                                  ?.externos?.find(
                                    (ext: any) =>
                                      ext.fornecedor_id === fornecedor._id
                                  );

                                return (
                                  <div
                                    style={{
                                      marginTop: "2rem",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#5F29CC",
                                        fontSize: 16,
                                        fontWeight: 600,
                                      }}
                                    >
                                      {fornecedor.nome}
                                    </p>
                                    <p
                                      style={{
                                        color: "#333333",
                                        fontSize: 16,
                                      }}
                                    >
                                      {fornecedor.cnpj}
                                    </p>
                                    <p
                                      style={{
                                        color: "#333333",
                                        fontSize: 16,
                                      }}
                                    >
                                      {fornecedor.email}
                                    </p>
                                    <Select
                                      size="small"
                                      style={{
                                        width: 240,
                                        marginTop: "0.25rem",
                                      }}
                                      placeholder="Forma de compartilhamento"
                                      value={currentExterno?.forma}
                                      onChange={(value: string) => {
                                        moduloMapeamento.setCurrentTitulares(
                                          (prevTitulares: Titular[]) => {
                                            let currentTitular = titular;
                                            selectedDados?.forEach((dado) => {
                                              const dadoIndex:
                                                | number
                                                | undefined =
                                                currentTitular.dados?.findIndex(
                                                  (d) =>
                                                    d.dado_id === dado.dado_id
                                                );

                                              if (
                                                dadoIndex !== undefined &&
                                                currentTitular?.dados?.[
                                                  dadoIndex
                                                ]
                                              ) {
                                                let currentExternoCompartilhado: any =
                                                  currentTitular.dados[
                                                    dadoIndex
                                                  ].externos;

                                                currentTitular.dados[
                                                  dadoIndex
                                                ] = {
                                                  ...currentTitular.dados[
                                                    dadoIndex
                                                  ],
                                                  externos: [
                                                    ...currentExternoCompartilhado.filter(
                                                      (e: any) =>
                                                        e.fornecedor_id !==
                                                        fornecedor._id
                                                    ),
                                                    {
                                                      ...currentExternoCompartilhado?.find(
                                                        (e: any) =>
                                                          e.fornecedor_id ===
                                                          fornecedor._id
                                                      ),
                                                      forma: value,
                                                    },
                                                  ],
                                                };
                                              }
                                            });
                                            return [
                                              ...prevTitulares.filter(
                                                (t) => t.key !== titular.key
                                              ),
                                              currentTitular,
                                            ];
                                          }
                                        );
                                      }}
                                    >
                                      <OptGroup label="Físico">
                                        <Option value="Papel">Papel</Option>
                                        <Option value="CD/DVD">CD/DVD</Option>
                                        <Option value="Pen-drive">
                                          Pen-drive
                                        </Option>
                                        <Option value="HD Externo">
                                          HD Externo
                                        </Option>
                                        <Option value="Cartão SD">
                                          Cartão SD
                                        </Option>
                                        <Option value="MicroSD">MicroSD</Option>
                                      </OptGroup>
                                      <OptGroup label="Digital">
                                        <Option value="Drive de armazenamento em nuvem (Google Drive; Dropbox; Sharepoint; Onedrive; Icloud)">
                                          Drive de armazenamento em nuvem
                                          (Google Drive; Dropbox; Sharepoint;
                                          Onedrive; Icloud)
                                        </Option>
                                        <Option value="Transferência por sistemas (STFP)">
                                          Transferência por sistemas (STFP)
                                        </Option>
                                        <Option value="Plataformas de Comunicação Licenciadas (Teams; Google Workplace ou Similiar)">
                                          Plataformas de Comunicação Licenciadas
                                          (Teams; Google Workplace ou Similiar)
                                        </Option>
                                        <Option value="E-mail">E-mail</Option>
                                        <Option value="Serviço de Transferência de Arquivos de Terceiros (Ex: Wetransfer)">
                                          Serviço de Transferência de Arquivos
                                          de Terceiros (Ex: Wetransfer)
                                        </Option>
                                        <Option value="Redes Sociais (Facebook; Linkedin)">
                                          Redes Sociais (Facebook; Linkedin)
                                        </Option>
                                        <Option value="Aplicativo de Mensagens (WhatsApp, Telegram, WeChat)">
                                          Aplicativo de Mensagens (WhatsApp,
                                          Telegram, WeChat)
                                        </Option>
                                      </OptGroup>
                                    </Select>
                                  </div>
                                );
                              })}
                          </>
                        ),
                      },
                    ]}
                  />
                </Col>
              )}
              {titular?.dados
                ?.filter((dado: any) =>
                  selectedDados?.find((d) => dado.dado_id === d.dado_id)
                )
                ?.find((dado) =>
                  dado.compartilhamento?.find(
                    (item: any) => item === "Departamento interno"
                  )
                ) && (
                <Col span={12}>
                  <Collapse
                    size="small"
                    defaultActiveKey={"1"}
                    items={[
                      {
                        key: "1",
                        label: "Áreas compartilhadas",
                        children: (
                          <Space direction="vertical">
                            {mapeamento?.areas?.map((areaId) => {
                              const currentFormaArea: any = titular?.dados
                                ?.filter((dado: any) =>
                                  selectedDados?.find(
                                    (d) => dado.dado_id === d.dado_id
                                  )
                                )
                                ?.find((dado) =>
                                  dado.areas_compartilhadas?.find(
                                    (item: any) => item.area_id === areaId
                                  )
                                )
                                ?.areas_compartilhadas?.find(
                                  (ac) => ac.area_id === areaId
                                );

                              return (
                                <div
                                  style={{
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <div>
                                    <Button
                                      onClick={() => {
                                        moduloMapeamento.setCurrentTitulares(
                                          (prevTitulares: Titular[]) => {
                                            let currentTitular = titular;
                                            selectedDados?.forEach((dado) => {
                                              const dadoIndex:
                                                | number
                                                | undefined =
                                                currentTitular.dados?.findIndex(
                                                  (d) =>
                                                    d.dado_id === dado.dado_id
                                                );

                                              if (
                                                dadoIndex !== undefined &&
                                                currentTitular?.dados?.[
                                                  dadoIndex
                                                ]
                                              ) {
                                                let currentAreaCompartilhada: any =
                                                  currentTitular.dados[
                                                    dadoIndex
                                                  ].areas_compartilhadas || [];

                                                currentTitular.dados[
                                                  dadoIndex
                                                ] = {
                                                  ...currentTitular.dados[
                                                    dadoIndex
                                                  ],
                                                  areas_compartilhadas:
                                                    currentAreaCompartilhada?.find(
                                                      (a: any) =>
                                                        a.area_id === areaId
                                                    )
                                                      ? currentAreaCompartilhada.filter(
                                                          (a: any) =>
                                                            a.area_id !== areaId
                                                        )
                                                      : [
                                                          ...currentAreaCompartilhada,
                                                          {
                                                            area_id: areaId,
                                                          },
                                                        ],
                                                };
                                              }
                                            });
                                            return [
                                              ...prevTitulares.filter(
                                                (t) => t.key !== titular.key
                                              ),
                                              currentTitular,
                                            ];
                                          }
                                        );
                                      }}
                                      size="small"
                                      type={
                                        titular?.dados
                                          ?.filter((dado: any) =>
                                            selectedDados?.find(
                                              (d) => dado.dado_id === d.dado_id
                                            )
                                          )
                                          ?.find((dado) =>
                                            dado.areas_compartilhadas?.find(
                                              (item: any) =>
                                                item.area_id === areaId
                                            )
                                          )
                                          ? "primary"
                                          : "default"
                                      }
                                      icon={
                                        titular?.dados
                                          ?.filter((dado: any) =>
                                            selectedDados?.find(
                                              (d) => dado.dado_id === d.dado_id
                                            )
                                          )
                                          ?.find((dado) =>
                                            dado.areas_compartilhadas?.find(
                                              (item: any) =>
                                                item.area_id === areaId
                                            )
                                          ) ? (
                                          <CheckCircleFilled />
                                        ) : (
                                          <PlusCircleOutlined />
                                        )
                                      }
                                      style={
                                        titular?.dados
                                          ?.filter((dado: any) =>
                                            selectedDados?.find(
                                              (d) => dado.dado_id === d.dado_id
                                            )
                                          )
                                          ?.find((dado) =>
                                            dado.areas_compartilhadas?.find(
                                              (item: any) =>
                                                item.area_id === areaId
                                            )
                                          )
                                          ? {
                                              background: "#3333FF",
                                              color: "#fff",
                                            }
                                          : {}
                                      }
                                    >
                                      {
                                        moduloProcessos.areas?.find(
                                          (a) => a._id === areaId
                                        )?.description
                                      }
                                    </Button>
                                  </div>
                                  <div>
                                    {titular?.dados
                                      ?.filter((dado: any) =>
                                        selectedDados?.find(
                                          (d) => dado.dado_id === d.dado_id
                                        )
                                      )
                                      ?.find((dado) =>
                                        dado.areas_compartilhadas?.find(
                                          (item: any) => item.area_id === areaId
                                        )
                                      ) && (
                                      <Select
                                        size="small"
                                        style={{
                                          width: 240,
                                          marginTop: "0.5rem",
                                        }}
                                        placeholder="Forma de compartilhamento"
                                        value={currentFormaArea?.forma}
                                        onChange={(value: string) => {
                                          moduloMapeamento.setCurrentTitulares(
                                            (prevTitulares: Titular[]) => {
                                              let currentTitular = titular;
                                              selectedDados?.forEach((dado) => {
                                                const dadoIndex:
                                                  | number
                                                  | undefined =
                                                  currentTitular.dados?.findIndex(
                                                    (d) =>
                                                      d.dado_id === dado.dado_id
                                                  );

                                                if (
                                                  dadoIndex !== undefined &&
                                                  currentTitular?.dados?.[
                                                    dadoIndex
                                                  ]
                                                ) {
                                                  let currentAreaCompartilhada: any =
                                                    currentTitular.dados[
                                                      dadoIndex
                                                    ].areas_compartilhadas;

                                                  currentTitular.dados[
                                                    dadoIndex
                                                  ] = {
                                                    ...currentTitular.dados[
                                                      dadoIndex
                                                    ],
                                                    areas_compartilhadas: [
                                                      ...currentAreaCompartilhada.filter(
                                                        (a: any) =>
                                                          a.area_id !== areaId
                                                      ),
                                                      {
                                                        ...currentAreaCompartilhada?.find(
                                                          (a: any) =>
                                                            a.area_id === areaId
                                                        ),
                                                        forma: value,
                                                      },
                                                    ],
                                                  };
                                                }
                                              });
                                              return [
                                                ...prevTitulares.filter(
                                                  (t) => t.key !== titular.key
                                                ),
                                                currentTitular,
                                              ];
                                            }
                                          );
                                        }}
                                      >
                                        <OptGroup label="Físico">
                                          <Option value="Papel">Papel</Option>
                                          <Option value="CD/DVD">CD/DVD</Option>
                                          <Option value="Pen-drive">
                                            Pen-drive
                                          </Option>
                                          <Option value="HD Externo">
                                            HD Externo
                                          </Option>
                                          <Option value="Cartão SD">
                                            Cartão SD
                                          </Option>
                                          <Option value="MicroSD">
                                            MicroSD
                                          </Option>
                                        </OptGroup>
                                        <OptGroup label="Digital">
                                          <Option value="Drive de armazenamento em nuvem (Google Drive; Dropbox; Sharepoint; Onedrive; Icloud)">
                                            Drive de armazenamento em nuvem
                                            (Google Drive; Dropbox; Sharepoint;
                                            Onedrive; Icloud)
                                          </Option>
                                          <Option value="Transferência por sistemas (STFP)">
                                            Transferência por sistemas (STFP)
                                          </Option>
                                          <Option value="Plataformas de Comunicação Licenciadas (Teams; Google Workplace ou Similiar)">
                                            Plataformas de Comunicação
                                            Licenciadas (Teams; Google Workplace
                                            ou Similiar)
                                          </Option>
                                          <Option value="E-mail">E-mail</Option>
                                          <Option value="Serviço de Transferência de Arquivos de Terceiros (Ex: Wetransfer)">
                                            Serviço de Transferência de Arquivos
                                            de Terceiros (Ex: Wetransfer)
                                          </Option>
                                          <Option value="Redes Sociais (Facebook; Linkedin)">
                                            Redes Sociais (Facebook; Linkedin)
                                          </Option>
                                          <Option value="Aplicativo de Mensagens (WhatsApp, Telegram, WeChat)">
                                            Aplicativo de Mensagens (WhatsApp,
                                            Telegram, WeChat)
                                          </Option>
                                        </OptGroup>
                                      </Select>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </Space>
                        ),
                      },
                    ]}
                  />
                </Col>
              )}
            </Row>
            <div></div>
          </>
          <br />
          {/* Serão transferidos para outro país */}
          <>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              3. Os dados serão transferidos para outro país?{" "}
            </p>

            <>
              <Radio.Group
                onChange={(event) => {
                  moduloMapeamento.setCurrentTitulares(
                    (prevTitulares: Titular[]) => {
                      let currentTitular = titular;
                      selectedDados?.forEach((dado) => {
                        const dadoIndex: number | undefined =
                          currentTitular.dados?.findIndex(
                            (d) => d.dado_id === dado.dado_id
                          );
                        if (
                          dadoIndex !== undefined &&
                          currentTitular?.dados?.[dadoIndex]
                        ) {
                          currentTitular.dados[dadoIndex] = {
                            ...currentTitular.dados[dadoIndex],
                            internacional: event.target.value,
                          };
                        }
                      });
                      return [
                        ...prevTitulares.filter((t) => t.key !== titular.key),
                        currentTitular,
                      ];
                    }
                  );
                }}
                value={
                  titular?.dados?.find(
                    (d) => d.dado_id === selectedDados?.[0]?.dado_id
                  )?.internacional
                }
              >
                <Space direction="vertical">
                  <Radio value={true}>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Space>
              </Radio.Group>
              {titular?.dados?.find(
                (d) => d.dado_id === selectedDados?.[0]?.dado_id
              )?.internacional && (
                <Input
                  style={{ marginTop: "1rem" }}
                  onChange={(v) => {
                    moduloMapeamento.setCurrentTitulares(
                      (prevTitulares: Titular[]) => {
                        let currentTitular = titular;
                        selectedDados?.forEach((dado) => {
                          const dadoIndex: number | undefined =
                            currentTitular.dados?.findIndex(
                              (d) => d.dado_id === dado.dado_id
                            );

                          if (
                            dadoIndex !== undefined &&
                            currentTitular?.dados?.[dadoIndex]
                          ) {
                            currentTitular.dados[dadoIndex] = {
                              ...currentTitular.dados[dadoIndex],
                              paises: v.target.value,
                            };
                          }
                        });

                        return [
                          ...prevTitulares.filter((t) => t.key !== titular.key),
                          currentTitular,
                        ];
                      }
                    );
                  }}
                  placeholder="Digite os país(es)"
                  value={
                    titular?.dados?.filter((dado: any) =>
                      selectedDados?.find((d) => dado.dado_id === d.dado_id)
                    )?.[0]?.paises
                  }
                />
              )}
            </>
            <div></div>
          </>
          <br />
          {/* Finalidade */}
          <>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              4. Finalidade e Necessidade{" "}
              <Tooltip title="Descrever por quais motivos os dados são compartilhados e identificar quais dados pessoais são desnecessários para alcançar essa finalidade.">
                <InfoCircle
                  size={16}
                  style={{
                    marginLeft: "0.5rem",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </p>
            <TextArea
              onChange={(value) => {
                moduloMapeamento.setCurrentTitulares(
                  (prevTitulares: Titular[]) => {
                    let currentTitular = titular;
                    currentTitular.finalidade_compartilhamento =
                      value.target.value;

                    return [
                      ...prevTitulares.filter((t) => t.key !== titular.key),
                      currentTitular,
                    ];
                  }
                );
              }}
              value={titular.finalidade_compartilhamento}
              autoSize={{ minRows: 3 }}
              showCount
              maxLength={5000}
            />
            <div></div>
          </>
          <br />
          {/* Medidas de segurança */}
          <>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              5. Medidas de Segurança{" "}
              <Tooltip title="Indicar quais medidas garantem maior proteção aos dados pessoais compartilhados.">
                <InfoCircle
                  size={16}
                  style={{
                    marginLeft: "0.5rem",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </p>
            <TextArea
              onChange={(value) => {
                moduloMapeamento.setCurrentTitulares(
                  (prevTitulares: Titular[]) => {
                    let currentTitular = titular;
                    currentTitular.medidas_compartilhamento =
                      value.target.value;

                    return [
                      ...prevTitulares.filter((t) => t.key !== titular.key),
                      currentTitular,
                    ];
                  }
                );
              }}
              value={titular.medidas_compartilhamento}
              autoSize={{ minRows: 3 }}
              showCount
              maxLength={5000}
            />
            <div></div>
          </>
          <br />
          {/* Observações */}
          <>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              6. Observações{" "}
              <Tooltip title="Caso esta etapa do processo tenha alguma particularidade, deixe-a aqui">
                <InfoCircle
                  size={16}
                  style={{
                    marginLeft: "0.5rem",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </p>
            <TextArea
              onChange={(value) => {
                moduloMapeamento.setCurrentTitulares(
                  (prevTitulares: Titular[]) => {
                    let currentTitular = titular;
                    currentTitular.obs_compartilhamento = value.target.value;

                    return [
                      ...prevTitulares.filter((t) => t.key !== titular.key),
                      currentTitular,
                    ];
                  }
                );
              }}
              value={titular.obs_compartilhamento}
              // onChange={(e) => setValue(e.target.value)}
              autoSize={{ minRows: 3 }}
              showCount
              maxLength={5000}
            />
            <div></div>
          </>
          <br />
          {/* Salvar e avançar*/}
          <>
            <Button
              style={{
                background: "#32E6BE",
                color: "#333333",
                marginTop: "1rem",
              }}
              icon={icon}
              type="primary"
              loading={loadingTitulares}
              onClick={() => {
                setLoadingTitulares(true);
                setIcon(<SaveOutlined />);

                moduloMapeamento
                  .saveTitulares(moduloMapeamento.currentTitulares)
                  .then(() => {
                    setIcon(<CheckOutlined />);
                  })
                  .catch(() => {
                    setIcon(<CloseOutlined />);
                  })
                  .finally(() => {
                    setLoadingTitulares(false);
                    setActiveTab("Armazenamento");
                  });
              }}
            >
              Salvar e avançar
            </Button>
            <div></div>
            <br />
          </>
        </>
      )}
    </>
  );
};

export default CompartilhamentoGuia;
