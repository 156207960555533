import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  DownCircleOutlined,
  PlusCircleOutlined,
  RightCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { GlobalContextType } from "../../../../../../@types/global";
import { GlobalContext } from "../../../../../../context/globalContext";
import React, { ReactNode, useEffect } from "react";
import { Button, Input, Radio, Select, Space, Switch, Tooltip } from "antd";
import { InfoCircle } from "iconsax-react";
import { Titular } from "../../../../../../@types/titular";
const { TextArea } = Input;

interface ProcessamentoGuiaProps {
  titular: Titular;
  setSelectedDados: (
    v: any[] | null | ((prev: any[] | null) => any[] | null)
  ) => void;
  selectedDados: any[] | null;
  icon: ReactNode;
  setIcon: (icon: ReactNode) => void;
  loadingTitulares: boolean;
  setLoadingTitulares: (v: boolean) => void;
  setActiveTab: (v: string) => void;
  activeTab: string;
}

const ProcessamentoGuia: React.FC<ProcessamentoGuiaProps> = ({
  titular,
  setSelectedDados,
  selectedDados,
  icon,
  setIcon,
  loadingTitulares,
  setLoadingTitulares,
  setActiveTab,
  activeTab,
}) => {
  const { moduloMapeamento } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  useEffect(() => {
    // Seleciona a div com a classe `ant-tabs-content-holder` e reseta o scroll
    const tabsContent = document.querySelector(".ant-tabs-content-holder");
    if (tabsContent) {
      tabsContent.scrollTop = 0;
    }
  }, [activeTab]);

  return (
    <>
      <h4>Processamento</h4>
      <hr />
      {/* Listagem de dos dados pessoais */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          1. Selecione os dados para definir as informações de processamento.{" "}
          {/* <Tooltip title="Selecione os dados para definir as informações de processamento">
        <InfoCircle
          size={16}
          style={{
            marginLeft: "0.5rem",
            cursor: "pointer",
          }}
        />
      </Tooltip> */}
        </p>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip title="Selecionar todos">
            <Switch
              unCheckedChildren="Não"
              checkedChildren="Sim"
              onClick={(v) => {
                if (v && titular.dados) {
                  setSelectedDados(titular.dados);
                } else {
                  setSelectedDados(null);
                }
              }}
              checked={
                selectedDados?.length === titular.dados?.length ? true : false
              }
            />
          </Tooltip>
          <p style={{ marginLeft: "0.5rem" }}>
            As informações de processamento se aplicam a todos os dados pessoais
            cadastrados?
          </p>
        </div>
        <div></div>
        <br />

        {titular.dados?.map((dado) => (
          <Button
            size="small"
            style={{
              background:
                titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                  ?.frequencia &&
                (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                  ?.bases_legais_comuns ||
                  titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.bases_legais_sensiveis)
                  ? selectedDados?.find((d) => d.dado_id === dado.dado_id)
                    ? "#5F29CC"
                    : "#32E6BE"
                  : "#5F29CC",
              color:
                titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                  ?.frequencia &&
                (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                  ?.bases_legais_comuns ||
                  titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.bases_legais_sensiveis)
                  ? selectedDados?.find((d: any) => d.dado_id === dado.dado_id)
                    ? "#fff"
                    : "#333"
                  : selectedDados?.find((d: any) => d.dado_id === dado.dado_id)
                  ? "#fff"
                  : "#333",
              marginRight: "0.5rem",
              marginBottom: "0.5rem",
              opacity:
                selectedDados?.length === titular.dados?.length
                  ? titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                      ?.frequencia &&
                    (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                      ?.bases_legais_comuns ||
                      titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                        ?.bases_legais_sensiveis)
                    ? 1
                    : 0.4
                  : 1,
              cursor:
                selectedDados?.length === titular.dados?.length
                  ? "default"
                  : "pointer",
            }}
            icon={
              titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                ?.frequencia &&
              (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                ?.bases_legais_comuns ||
                titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                  ?.bases_legais_sensiveis) ? (
                selectedDados?.find((d: any) => d.dado_id === dado.dado_id) ? (
                  <DownCircleOutlined />
                ) : (
                  <CheckCircleOutlined />
                )
              ) : selectedDados?.find(
                  (d: any) => d.dado_id === dado.dado_id
                ) ? (
                <RightCircleOutlined />
              ) : (
                <PlusCircleOutlined />
              )
            }
            type={
              selectedDados?.find((d: any) => d.dado_id === dado.dado_id)
                ? "primary"
                : titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.frequencia &&
                  (titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                    ?.bases_legais_comuns ||
                    titular?.dados?.find((d) => d.dado_id === dado.dado_id)
                      ?.bases_legais_sensiveis)
                ? "primary"
                : "default"
            }
            onClick={() => {
              setSelectedDados((prev) => {
                if (
                  selectedDados?.find((d: any) => d.dado_id === dado.dado_id)
                ) {
                  // remove
                  if (prev) {
                    return [
                      ...prev.filter(
                        (d: { dado_id: string }) => d.dado_id !== dado.dado_id
                      ),
                    ];
                  } else {
                    return null;
                  }
                } else {
                  // adiciona
                  if (prev) {
                    return [
                      ...prev.filter(
                        (d: { dado_id: string }) => d.dado_id !== dado.dado_id
                      ),
                      dado,
                    ];
                  } else {
                    return [dado];
                  }
                }
              });
            }}
          >
            {
              moduloMapeamento.dados?.find((d: any) => d._id === dado.dado_id)
                ?.nome
            }
          </Button>
        ))}
        <div></div>
      </>
      <br />
      {/* Frequência do processamento */}
      <>
        {selectedDados?.[0] && (
          <>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              2. Frequência do processamento{" "}
              <Tooltip title="É a periodicidade com que os dados pessoas serão acessados e processados">
                <InfoCircle
                  size={16}
                  style={{
                    marginLeft: "0.5rem",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </p>
            <Radio.Group
              onChange={(event) => {
                moduloMapeamento.setCurrentTitulares(
                  (prevTitulares: Titular[]) => {
                    let currentTitular = titular;
                    selectedDados?.forEach((dado) => {
                      const dadoIndex: number | undefined =
                        currentTitular.dados?.findIndex(
                          (d) => d.dado_id === dado.dado_id
                        );
                      if (
                        dadoIndex !== undefined &&
                        currentTitular?.dados?.[dadoIndex]
                      ) {
                        currentTitular.dados[dadoIndex] = {
                          ...currentTitular.dados[dadoIndex],
                          frequencia: event.target.value,
                        };
                      }
                    });
                    return [
                      ...prevTitulares.filter((t) => t.key !== titular.key),
                      currentTitular,
                    ];
                  }
                );
              }}
              value={
                titular?.dados?.find(
                  (d) => d.dado_id === selectedDados?.[0]?.dado_id
                )?.frequencia
              }
            >
              <Space direction="vertical">
                <Radio value={"Contínuo ou em tempo real"}>
                  <Tooltip title="Você acessa ou utiliza os dados a todo momento ou em tempo real.">
                    Contínuo ou em tempo real
                  </Tooltip>
                </Radio>
                <Radio value={"Semanal"}>
                  <Tooltip title="Você acessa ou utiliza os dados semanalmente.">
                    Semanal
                  </Tooltip>
                </Radio>
                <Radio value={"Mensal"}>
                  <Tooltip title="Você acessa ou utiliza os dados cadastrados mensalmente.">
                    Mensal
                  </Tooltip>
                </Radio>
                <Radio value={"Semestral ou Anual"}>
                  <Tooltip title="Você geralmente acessa ou utiliza os dados a cada 6 meses a 1 ano.">
                    Semestral ou Anual
                  </Tooltip>
                </Radio>
                <Radio value={"Uma única vez"}>
                  <Tooltip title="Você acessa ou utiliza os dados apenas uma vez.">
                    Uma única vez
                  </Tooltip>
                </Radio>
              </Space>
            </Radio.Group>
          </>
        )}
        <div></div>
      </>
      <br />
      {/* Bases legais */}
      <>
        {selectedDados?.[0] && (
          <>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              3. Bases Legais{" "}
              <Tooltip title="São as justificativas legais previstas pela LGPD que permitem que sua empresa processe dados pessoais de indivíduos.">
                <InfoCircle
                  size={16}
                  style={{
                    marginLeft: "0.5rem",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </p>

            <div>
              <p
                style={{
                  marginBottom: "0.5rem",
                }}
              >
                Dados comuns{" "}
                <Tooltip
                  overlayStyle={{
                    maxWidth: "500px",
                  }}
                  title={
                    <>
                      <ul>
                        <li>Consentimento pelo titular (i) Art. 7º, I, LGPD</li>
                        <li>
                          Cumprimento de obrigação legal ou regulatória (i) Art.
                          7º, II, LGPD
                        </li>
                        <li>
                          Para execução de Políticas Públicas previstas em leis
                          e regulamentos (i) Art. 7º, III, LGPD
                        </li>
                        <li>
                          Realização de Estudos por órgãos de pesquisa (i) Art.
                          7º, IV, LGPD
                        </li>
                        <li>
                          Execução de contratos e procedimentos preliminares (i)
                          Art. 7º, V, LGPD
                        </li>
                        <li>
                          Exercício regular de direitos em processo judicial,
                          administrativo ou arbitral (i) Art. 7º, VI, LGPD
                        </li>
                        <li>
                          Proteção da vida e da incolumidade física do titular
                          ou de terceiro (i) Art. 7º, VII, LGPD
                        </li>
                        <li>
                          Tutela de Saúde exclusivamente ... (i) Art. 7º, VIII,
                          LGPD
                        </li>
                        <li>Legítimo Interesse (i) Art. 7º, XI, LGPD</li>
                        <li>Proteção ao crédito (i) Art. 7º, X, LGPD</li>
                      </ul>
                    </>
                  }
                >
                  <InfoCircle
                    size={16}
                    style={{
                      marginLeft: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </p>
              <Select
                style={{ width: "100%" }}
                placeholder="Selecione a base legal"
                allowClear
                value={
                  titular?.dados?.filter((dado: any) =>
                    selectedDados.find((d) => dado.dado_id === d.dado_id)
                  )?.[0]?.bases_legais_comuns
                }
                onChange={(value: string) => {
                  moduloMapeamento.setCurrentTitulares(
                    (prevTitulares: Titular[]) => {
                      let currentTitular = titular;
                      selectedDados?.forEach((dado) => {
                        const dadoIndex: number | undefined =
                          currentTitular.dados?.findIndex(
                            (d) => d.dado_id === dado.dado_id
                          );

                        if (
                          dadoIndex !== undefined &&
                          currentTitular?.dados?.[dadoIndex]
                        ) {
                          const dado = currentTitular.dados[dadoIndex];
                          dado.bases_legais_comuns = value;
                        }
                      });

                      return [
                        ...prevTitulares.filter((t) => t.key !== titular.key),
                        currentTitular,
                      ];
                    }
                  );
                }}
                options={[
                  {
                    value: "Consentimento pelo titular",
                    label: "Consentimento pelo titular",
                  },
                  {
                    value: "Cumprimento de obrigação legal ou regulatória",
                    label: "Cumprimento de obrigação legal ou regulatória",
                  },
                  {
                    value:
                      "Para execução de políticas públicas previstas em leis ou regulamentos",
                    label:
                      "Para execução de políticas públicas previstas em leis ou regulamentos",
                  },
                  {
                    value: "Realização de estudos por órgão de pesquisa ",
                    label: "Realização de estudos por órgão de pesquisa ",
                  },
                  {
                    value:
                      "Execução de contrato ou de procedimentos preliminares ",
                    label:
                      "Execução de contrato ou de procedimentos preliminares ",
                  },
                  {
                    value:
                      "Exercício regular de direitos em processo judicial, administrativo ou arbitral",
                    label:
                      "Exercício regular de direitos em processo judicial, administrativo ou arbitral",
                  },
                  {
                    value:
                      "Proteção da vida ou da incolumidade física do titular ou de terceiro",
                    label:
                      "Proteção da vida ou da incolumidade física do titular ou de terceiro",
                  },
                  {
                    value:
                      "Tutela da saúde, exclusivamente, em procedimento realizado por profissionais da saúde, serviços de saúde ou autoridade sanitária.",
                    label:
                      "Tutela da saúde, exclusivamente, em procedimento realizado por profissionais da saúde, serviços de saúde ou autoridade sanitária.",
                  },
                  {
                    value: "Legítimo Interesse",
                    label: "Legítimo Interesse",
                  },
                  {
                    value: "Proteção do crédito",
                    label: "Proteção do crédito",
                  },
                ]}
              />
              <p
                style={{
                  marginBottom: "0.5rem",
                  marginTop: "1rem",
                }}
              >
                Dados sensíveis{" "}
                <Tooltip
                  overlayStyle={{
                    maxWidth: "500px",
                  }}
                  title={
                    <>
                      <ul>
                        <li>Consentimento pelo titular (i) Art. 11, I, LGPD</li>
                        <li>
                          Cumprimento de obrigação legal ou regulatória (i) Art.
                          11, II, “a” LGPD
                        </li>
                        <li>
                          Para execução de Políticas Públicas previstas em leis
                          e regulamentos (i) Art. 11, II, “b” LGPD
                        </li>
                        <li>
                          Realização de Estudos por órgãos de pesquisa (i) Art.
                          11, II, “c” LGPD
                        </li>
                        <li>
                          Exercício regular de direitos em processo judicial,
                          administrativo ou arbitral (i) Art. 11, II, “d” LGPD
                        </li>
                        <li>
                          Proteção da vida e da incolumidade física do titular
                          ou de terceiro (i) Art. 11, II, “e” LGPD
                        </li>
                        <li>
                          Tutela de Saúde exclusivamente ... (i) Art. 11, II,
                          “f” LGPD
                        </li>
                        <li>
                          Garantia da prevenção à fraude e à segurança do
                          titular (i) Art. 11, II, “g” LGPD
                        </li>
                      </ul>
                    </>
                  }
                >
                  <InfoCircle
                    size={16}
                    style={{
                      marginLeft: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </p>
              <Select
                style={{ width: "100%" }}
                allowClear
                placeholder="Selecione a base legal"
                value={
                  titular?.dados?.filter((dado: any) =>
                    selectedDados.find((d) => dado.dado_id === d.dado_id)
                  )?.[0]?.bases_legais_sensiveis
                }
                onChange={(value: string) => {
                  moduloMapeamento.setCurrentTitulares(
                    (prevTitulares: Titular[]) => {
                      let currentTitular = titular;
                      selectedDados?.forEach((dado) => {
                        const dadoIndex: number | undefined =
                          currentTitular.dados?.findIndex(
                            (d) => d.dado_id === dado.dado_id
                          );

                        if (
                          dadoIndex !== undefined &&
                          currentTitular?.dados?.[dadoIndex]
                        ) {
                          const dado = currentTitular.dados[dadoIndex];
                          dado.bases_legais_sensiveis = value;
                        }
                      });

                      return [
                        ...prevTitulares.filter((t) => t.key !== titular.key),
                        currentTitular,
                      ];
                    }
                  );
                }}
                options={[
                  {
                    value: "Consentimento",
                    label: "Consentimento",
                  },
                  {
                    value:
                      "Cumprimento de obrigação legal ou regulatória pelo controlador;",
                    label:
                      "Cumprimento de obrigação legal ou regulatória pelo controlador;",
                  },
                  {
                    value:
                      "Compartilhamento de dados necessários à execução, pela administração pública, de políticas públicas previstas em leis ou regulamentos;",
                    label:
                      "Compartilhamento de dados necessários à execução, pela administração pública, de políticas públicas previstas em leis ou regulamentos;",
                  },
                  {
                    value: "Realização de estudos por órgão de pesquisa; ",
                    label: "Realização de estudos por órgão de pesquisa; ",
                  },
                  {
                    value:
                      "Exercício regular de direitos, inclusive em contrato e em processo judicial, administrativo e arbitral;",
                    label:
                      "Exercício regular de direitos, inclusive em contrato e em processo judicial, administrativo e arbitral;",
                  },
                  {
                    value:
                      "Proteção da vida ou da incolumidade física do titular ou de terceiros;",
                    label:
                      "Proteção da vida ou da incolumidade física do titular ou de terceiros;",
                  },
                  {
                    value:
                      "Tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias;",
                    label:
                      "Tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias;",
                  },
                  {
                    value:
                      "Garantia da prevenção à fraude e à segurança do titular, nos processos de identificação e autenticação de cadastro em sistemas eletrônicos",
                    label:
                      "Garantia da prevenção à fraude e à segurança do titular, nos processos de identificação e autenticação de cadastro em sistemas eletrônicos",
                  },
                ]}
              />
            </div>
          </>
        )}
      </>
      <br />
      {/* Finalidade */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          4. Finalidade e Necessidade{" "}
          <Tooltip title="Descrever por quais motivos os dados são processados e identificar quais dados pessoais são desnecessários para alcançar essa finalidade.">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <TextArea
          onChange={(value) => {
            moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
              let currentTitular = titular;
              currentTitular.finalidade_processamento = value.target.value;

              return [
                ...prevTitulares.filter((t) => t.key !== titular.key),
                currentTitular,
              ];
            });
          }}
          value={titular.finalidade_processamento}
          autoSize={{ minRows: 3 }}
          showCount
          maxLength={5000}
        />
        <div></div>
      </>
      <br />
      {/* Medidas de segurança */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          5. Medidas de Segurança{" "}
          <Tooltip title="Indicar quais medidas garantem maior proteção aos dados pessoais processados.">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <TextArea
          onChange={(value) => {
            moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
              let currentTitular = titular;
              currentTitular.medidas_processamento = value.target.value;

              return [
                ...prevTitulares.filter((t) => t.key !== titular.key),
                currentTitular,
              ];
            });
          }}
          value={titular.medidas_processamento}
          autoSize={{ minRows: 3 }}
          showCount
          maxLength={5000}
        />
        <div></div>
      </>
      <br />
      {/* Observações */}
      <>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          6. Observações{" "}
          <Tooltip title="Caso esta etapa do processo tenha alguma particularidade, deixe-a aqui">
            <InfoCircle
              size={16}
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </p>
        <TextArea
          onChange={(value) => {
            moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
              let currentTitular = titular;
              currentTitular.obs_processamento = value.target.value;

              return [
                ...prevTitulares.filter((t) => t.key !== titular.key),
                currentTitular,
              ];
            });
          }}
          value={titular.obs_processamento}
          // onChange={(e) => setValue(e.target.value)}
          autoSize={{ minRows: 3 }}
          showCount
          maxLength={5000}
        />
        <div></div>
      </>
      <br />
      {/* Salvar e avançar*/}
      <>
        <Button
          style={{
            background: "#32E6BE",
            color: "#333333",
            marginTop: "1rem",
          }}
          icon={icon}
          type="primary"
          loading={loadingTitulares}
          onClick={() => {
            setLoadingTitulares(true);
            setIcon(<SaveOutlined />);

            moduloMapeamento
              .saveTitulares(moduloMapeamento.currentTitulares)
              .then(() => {
                setIcon(<CheckOutlined />);
              })
              .catch(() => {
                setIcon(<CloseOutlined />);
              })
              .finally(() => {
                setLoadingTitulares(false);
                setActiveTab("Compartilhamento");
              });
          }}
        >
          Salvar e avançar
        </Button>
        <div></div>
        <br />
      </>
    </>
  );
};

export default ProcessamentoGuia;
