import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  Form,
  Input,
  InputRef,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  theme,
  Tooltip,
} from "antd";
import Modal from "antd/es/modal/Modal";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContextType } from "../../../../@types/global";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContext } from "../../../../context/globalContext";
import ModalEditPergunta from "./EditPergunta/ModalEditPergunta";
import "./styles.css";
import { PerguntaType } from "../../../../context/processosContext";
import { AnyObject } from "antd/es/_util/type";

const { TextArea } = Input;

interface EditCapituloProps {
  novo: boolean;
}

const EditCapitulo: React.FC<EditCapituloProps> = ({ novo }) => {
  const { moduloProcessos } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [newCap, setNewCap] = React.useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [verAlternativas, setVerAlternativas] = useState<boolean>(false);

  const params = useParams();

  const navigate = useNavigate();

  const onFinish = () => {
    if (params.id) {
      moduloProcessos.postCapitulo({
        id: params.id || null,
        description:
          newCap.description ||
          moduloProcessos.capitulos?.filter((c: any) => c._id === params.id)[0]
            .description,
        norma:
          newCap.norma ||
          moduloProcessos.capitulos?.filter((c: any) => c._id === params.id)[0]
            .norma,
        recomendacao:
          newCap.recomendacao ||
          moduloProcessos.capitulos?.filter((c: any) => c._id === params.id)[0]
            .recomendacao,
        tags: tags,
        introducao: newCap.introducao,
      });
    } else {
      moduloProcessos.postCapitulo({
        id: null,
        description: newCap.description,
        norma: newCap.norma,
        recomendacao: newCap.recomendacao,
        introducao: newCap.introducao,
        tags: tags,
        dependencia: params.id,
      });
    }

    navigate("/capitulos");
    setShowModal(false);
    setNewCap({});
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpenSub, setIsModalOpenSub] = useState<boolean>(false);

  const handleShowModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleShowModalSub = () => {
    setIsModalOpenSub(!isModalOpenSub);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 24 },
  };

  const { token } = theme.useToken();
  const [tags, setTags] = useState<any>([]);
  const [inputVisible, setInputVisible] = useState<any>(false);
  const [inputValue, setInputValue] = useState<any>("");
  const [editInputIndex, setEditInputIndex] = useState<any>(-1);
  const [editInputValue, setEditInputValue] = useState<any>("");
  const [perguntasListadas, setPerguntasListadas] = useState<
    PerguntaType | any
  >([]);
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);
  const [selectedPergunta, setSelectedPergunta] = useState<any>(null);

  useEffect(() => {
    if (!novo) {
      moduloProcessos.capitulos &&
        moduloProcessos.capitulos[0] &&
        moduloProcessos.capitulos.filter((c: any) => c._id === params.id)[0] &&
        setTags(
          moduloProcessos.capitulos.filter((c: any) => c._id === params.id)[0]
            .tags
        );
    } else {
      setTags([]);
    }
  }, [moduloProcessos.capitulos]);

  useEffect(() => {
    if (!novo) {
      const perguntasRelacionadas = moduloProcessos.capituloPerguntas?.filter(
        (relacao: any) => relacao.capitulo_id.includes(params.id)
      );

      const perguntasListadas = perguntasRelacionadas?.map((relacao: any) => {
        const perguntaId = relacao.pergunta_id;
        return moduloProcessos.perguntas?.find(
          (pergunta: any) => pergunta._id === perguntaId
        );
      });
      setPerguntasListadas(perguntasListadas?.filter((p: any) => p));
    } else {
      setPerguntasListadas([]);
    }
  }, [moduloProcessos.capituloPerguntas, moduloProcessos.perguntas]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag: any) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && tags.indexOf(e) === -1) {
      setTags([...tags, e]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const tagInputStyle: React.CSSProperties = {
    width: "800px",
    verticalAlign: "top",
  };

  const tagPlusStyle: React.CSSProperties = {
    background: token.colorBgContainer,
    borderStyle: "dashed",
  };

  const columns = [
    {
      title: "Ordem",
      key: "action",
      render: (text: any, record: any, index: number) => (
        <div>
          <Button
            type="link"
            size="small"
            icon={<UpOutlined />}
            onClick={() => moveUp(index)}
            disabled={index === 0} // Desabilita se for o primeiro
            style={{ marginLeft: "0.5rem" }}
          />
          <Button
            type="link"
            size="small"
            icon={<DownOutlined />}
            onClick={() => moveDown(index)}
            disabled={index === perguntasListadas.length - 1} // Desabilita se for o último
            style={{ marginLeft: "0.5rem" }}
          />
        </div>
      ),
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
    },
    {
      title: "Alternativas",
      dataIndex: "opcoes",
      key: "opcoes",
      render: (opcoes: any, record: any) => (
        <>
          {record.tipo !== "input" ? (
            <ul>
              {opcoes?.map((opt: any) => (
                <li style={{ fontSize: 8, marginBottom: 10 }}>
                  {opt.alternativa}
                </li>
              ))}
            </ul>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: "Ação",
      key: "action",
      render: (text: any, record: any, index: number) => (
        <div>
          <Button
            icon={<EditOutlined />}
            type="primary"
            onClick={() => {
              setSelectedPergunta(record);
              handleShowModal();
            }}
          >
            Editar
          </Button>
          <Button
            style={{ color: "red", borderColor: "red", marginLeft: "1rem" }}
            icon={<DeleteOutlined />}
            onClick={() => {
              moduloProcessos?.patchPergunta({
                _id: record._id,
                inativo: true,
              });
            }}
          >
            Excluir
          </Button>
        </div>
      ),
    },
  ];

  const columnsSubProcessos = [
    { title: "Descrição", dataIndex: "description", key: "description" },
  ];

  const moveUp = (index: number) => {
    if (index === 0) return; // Não pode mover para cima se já for o primeiro
    const newPerguntas = [...perguntasListadas];
    const [movedPergunta] = newPerguntas.splice(index, 1); // Remove a pergunta do índice atual
    newPerguntas.splice(index - 1, 0, movedPergunta); // Adiciona a pergunta no índice acima

    // Atualiza o campo 'ordem'
    const updatedPerguntas = newPerguntas.map((p, i) => ({
      ...p,
      ordem: i + 1,
    }));

    setPerguntasListadas(updatedPerguntas);

    saveOrderToDatabase(updatedPerguntas);
  };

  const moveDown = (index: number) => {
    if (index === perguntasListadas.length - 1) return; // Não pode mover para baixo se já for o último
    const newPerguntas = [...perguntasListadas];
    const [movedPergunta] = newPerguntas.splice(index, 1); // Remove a pergunta do índice atual
    newPerguntas.splice(index + 1, 0, movedPergunta); // Adiciona a pergunta no índice abaixo

    // Atualiza o campo 'ordem'
    const updatedPerguntas = newPerguntas.map((p, i) => ({
      ...p,
      ordem: i + 1,
    }));

    setPerguntasListadas(updatedPerguntas);

    saveOrderToDatabase(updatedPerguntas);
  };

  // Função para salvar a novas ordens na base de dados
  const saveOrderToDatabase = (perguntas: any[]) => {
    moduloProcessos?.patchPerguntasOrdem(perguntas);
  };

  const items = [
    {
      key: "1",
      label: "Introdução",
      children: (
        <Form.Item name={["capitulo", "introducao"]} label="Introdução">
          <div>
            <ReactQuill
              placeholder="Escreva a introdução"
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
              ]}
              modules={{
                toolbar: [
                  [{ font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image"],
                  ["clean"],
                ],
                clipboard: {
                  matchVisual: false,
                },
              }}
              defaultValue={
                params.id && !novo
                  ? moduloProcessos.capitulos?.filter(
                      (c: any) => c._id === params.id
                    )[0]?.introducao || newCap.introducao
                  : newCap.introducao
              }
              onChange={(v: any) => setNewCap({ ...newCap, introducao: v })}
            />
          </div>
        </Form.Item>
      ),
    },
    {
      key: "2",
      label: "Normas",
      children: (
        <Form.Item name={["capitulo", "norma"]} label="Norma">
          <TextArea
            rows={5}
            onChange={(v: any) =>
              setNewCap({ ...newCap, norma: v.target.value })
            }
            value={
              params.id && !novo
                ? moduloProcessos.capitulos?.filter(
                    (c: any) => c._id === params.id
                  )[0]?.norma || newCap.norma
                : newCap.norma
            }
            defaultValue={
              params.id && !novo
                ? moduloProcessos.capitulos?.filter(
                    (c: any) => c._id === params.id
                  )[0]?.norma || newCap.norma
                : newCap.norma
            }
          />
        </Form.Item>
      ),
    },
    {
      key: "3",
      label: "Recomendações",
      children: (
        <Form.Item name={["capitulo", "recomendacao"]} label="Recomendação">
          <div>
            <ReactQuill
              placeholder="Escreva a recomendação"
              // value={
              //   capitulos.filter((c: any) => c._id === params.id)[0]
              //     .recomendacao || newCap.recomendacao
              // }
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
              ]}
              modules={{
                toolbar: [
                  [{ font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image"],
                  ["clean"],
                ],
                clipboard: {
                  // toggle to add extra line breaks when pasting HTML:
                  matchVisual: false,
                },
                // imageResize: {
                //   parchment: Quill.import("parchment"),
                //   modules: ["Resize", "DisplaySize"],
                // },
              }}
              defaultValue={
                params.id && !novo
                  ? moduloProcessos.capitulos?.filter(
                      (c: any) => c._id === params.id
                    )[0]?.recomendacao || newCap.recomendacao
                  : newCap.recomendacao
              }
              onChange={(v: any) => setNewCap({ ...newCap, recomendacao: v })}
            />
          </div>

          {/* <TextArea
          rows={5}
          onChange={(v: any) =>
            setNewCap({ ...newCap, recomendacao: v.target.value })
          }
          value={
            capitulos.filter((c: any) => c._id === params.id)[0]
              .recomendacao || newCap.recomendacao
          }
          defaultValue={
            capitulos.filter((c: any) => c._id === params.id)[0]
              .recomendacao || newCap.recomendacao
          }
        /> */}
        </Form.Item>
      ),
    },
  ];

  return (
    <div className="editCapitulo">
      {!novo && (
        <BackHeader
          title={params.id ? "Editar Processo" : "Novo Processo"}
          route="/capitulos"
        />
      )}

      <Form
        {...layout}
        layout="vertical"
        name="edit-users-form"
        onFinish={onFinish}
        // style={{ maxWidth: 600 }}
      >
        <Form.Item name={["capitulo", "description"]} label="Nome do processo">
          <Input
            value={
              params.id && !novo
                ? moduloProcessos.capitulos?.filter(
                    (c: any) => c._id === params.id
                  )[0]?.description || newCap.description
                : newCap.description
            }
            defaultValue={
              params.id && !novo
                ? moduloProcessos.capitulos?.filter(
                    (c: any) => c._id === params.id
                  )[0]?.description || newCap.description
                : newCap.description
            }
            onChange={(v: any) =>
              setNewCap({ ...newCap, description: v.target.value })
            }
          />
        </Form.Item>
        {!novo && (
          <>
            <h2>Áreas associadas</h2>
            <Space size={[0, 8]} wrap style={{ margin: "1rem 0 1rem 0" }}>
              <Space size={[0, 8]} wrap>
                {tags.map((tag: any, index: number) => (
                  <Tag
                    color="magenta"
                    key={tag}
                    closable={true}
                    style={{ userSelect: "none" }}
                    onClose={() => handleClose(tag)}
                  >
                    <span
                      onDoubleClick={(e) => {
                        if (index !== 0) {
                          setEditInputIndex(index);
                          setEditInputValue(tag);
                          e.preventDefault();
                        }
                      }}
                    >
                      {
                        moduloProcessos.areas?.filter(
                          (a: any) => a._id === tag
                        )[0].description
                      }
                    </span>
                  </Tag>
                ))}
              </Space>
              {inputVisible ? (
                <Select
                  options={moduloProcessos.areas}
                  // ref={inputRef}
                  value={inputValue}
                  style={tagInputStyle}
                  fieldNames={{ label: "description", value: "_id" }}
                  onChange={handleInputConfirm}
                />
              ) : (
                <Tag style={tagPlusStyle} onClick={showInput} color="green">
                  <PlusOutlined rev={undefined} /> Nova área
                </Tag>
              )}
            </Space>
            {params.id && !novo && (
              <>
                <h2 style={{ marginTop: "1rem" }}>
                  Subprocessos{" "}
                  <Tooltip title="Será utilizado no módulo Assessment Process">
                    <InfoCircleOutlined style={{ fontSize: 18 }} />
                  </Tooltip>
                </h2>
                <br />
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  icon={<PlusOutlined />}
                  type="primary"
                >
                  Adicionar subprocesso
                </Button>
                {moduloProcessos.capitulos?.find(
                  (cap: any) => cap.dependencia && cap.dependencia === params.id
                ) && (
                  <Table
                    style={{ marginTop: "1rem" }}
                    size="small"
                    columns={columnsSubProcessos}
                    expandable={{
                      expandedRowRender: (record) => {
                        const perguntasRelacionadasSub =
                          moduloProcessos.capituloPerguntas?.filter(
                            (relacao: any) =>
                              relacao.capitulo_id.includes(record._id)
                          );
                        const perguntasListadasSub = perguntasRelacionadasSub
                          ?.map((relacao: any) => {
                            const perguntaId = relacao.pergunta_id;
                            return moduloProcessos.perguntas?.find(
                              (pergunta: any) => pergunta._id === perguntaId
                            );
                          })
                          .filter(
                            (
                              pergunta: PerguntaType | undefined
                            ): pergunta is PerguntaType =>
                              pergunta !== undefined
                          ) as AnyObject[];

                        return (
                          <div style={{ marginLeft: "3rem" }}>
                            <h3>Norma</h3>
                            <p>{record.norma}</p>
                            <br />
                            <h3>Recomendação</h3>
                            <p>{record.recomendacao}</p>
                            <br />

                            <div
                              className="j-b d-f a-c"
                              style={{ margin: "0.5rem 0" }}
                            >
                              <h3>Perguntas</h3>
                              <Button
                                icon={<PlusOutlined />}
                                size="small"
                                type="primary"
                                onClick={handleShowModalSub}
                              >
                                Nova pergunta
                              </Button>
                            </div>
                            <Table
                              size="small"
                              columns={columns}
                              dataSource={perguntasListadasSub}
                              pagination={{ pageSize: 5 }}
                              locale={{
                                emptyText: "Nenhuma pergunta localizada",
                              }}
                            />
                            <ModalEditPergunta
                              handleShowModal={handleShowModalSub}
                              showModal={isModalOpenSub}
                              capId={record._id}
                              perguntasListadas={perguntasListadasSub}
                            />
                          </div>
                        );
                      },
                      rowExpandable: (record) =>
                        record.name !== "Not Expandable",
                    }}
                    dataSource={moduloProcessos.capitulos
                      ?.filter(
                        (cap: any) =>
                          cap.dependencia && cap.dependencia === params.id
                      )
                      .map((cap: any) => {
                        return {
                          ...cap,
                          key: cap._id,
                        };
                      })}
                  />
                )}

                <hr />
                <div className="j-b d-f a-c" style={{ margin: "0.5rem 0" }}>
                  <h2>Perguntas do processo</h2>
                  <div>
                    <Switch
                      checkedChildren="Alternativas"
                      unCheckedChildren="Alternativas"
                      onChange={(checked) => setVerAlternativas(checked)}
                    />
                    <Button
                      icon={<PlusOutlined />}
                      style={{ marginLeft: "1rem" }}
                      size="small"
                      type="primary"
                      onClick={handleShowModal}
                    >
                      Nova pergunta
                    </Button>
                  </div>
                </div>

                <Table
                  size="small"
                  columns={columns.filter((c) =>
                    verAlternativas ? true : c.title !== "Alternativas"
                  )}
                  dataSource={perguntasListadas
                    ?.sort((a: PerguntaType, b: PerguntaType) => {
                      // Garantindo que 'ordem' é um número
                      const ordemA = a.ordem !== undefined ? a.ordem : Infinity; // Se 'ordem' não estiver definida, coloca um valor alto
                      const ordemB = b.ordem !== undefined ? b.ordem : Infinity;

                      // Se ambos 'ordem' estão definidos, ordena por 'ordem'
                      if (ordemA !== Infinity && ordemB !== Infinity) {
                        return ordemA - ordemB;
                      }

                      // Se 'ordem' não está definido, ordena por 'created_at'
                      return (
                        new Date(a.created_at).getTime() -
                        new Date(b.created_at).getTime()
                      );
                    })
                    ?.map((p: any, index: number) => ({
                      ...p,
                      ordem: p.ordem !== undefined ? p.ordem : index + 1, // Se 'ordem' não estiver definido, usa a nova ordem
                    }))}
                  pagination={false}
                  locale={{ emptyText: "Nenhuma pergunta localizada" }}
                />
              </>
            )}
          </>
        )}
        <Collapse
          style={{ marginTop: "2rem" }}
          size="small"
          ghost
          items={items}
        />

        <hr style={{ marginTop: "1.5rem" }} />
        <Form.Item>
          <Button
            icon={<SaveOutlined />}
            type="primary"
            onClick={() => onFinish()}
          >
            Salvar
          </Button>
        </Form.Item>
      </Form>
      {params.id && (
        <ModalEditPergunta
          handleShowModal={handleShowModal}
          showModal={isModalOpen}
          capId={params.id}
          perguntasListadas={perguntasListadas}
          selectedPergunta={selectedPergunta}
        />
      )}

      {!novo && (
        <Modal
          title="Cadastrar subprocesso"
          open={showModal}
          footer={[]}
          width={1000}
          onCancel={() => setShowModal(false)}
        >
          <EditCapitulo novo />
        </Modal>
      )}
    </div>
  );
};

export default EditCapitulo;
