import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./styles.css";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Empty,
  Input,
  Modal,
  Progress,
  Select,
  Spin,
  Tooltip,
  Upload,
  UploadFile,
  message,
} from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FileFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import api from "../../../../../services/api";
import SaveBtn from "../../../../../components/SaveBtn/SaveBtn";
import { GlobalContext } from "../../../../../context/globalContext";
import { GlobalContextType } from "../../../../../@types/global";
import Anexos from "../../../../../components/Anexos/Anexos";

const { Dragger } = Upload;

interface DependenciaType {
  type: string;
  value: string;
  depende: string;
}
interface Response {
  questao_id: string;
  resposta: string[];
}

interface Questao {
  _id: string;
  questao: string;
  opcoes: string[];
  tipo: string;
  formate?: string;
  dependencia?: DependenciaType[];
  ordem: number;
  required?: boolean;
  description?: string;
}

const Quetionario = () => {
  const { windowWidth } = React.useContext(GlobalContext) as GlobalContextType;
  const navigate = useNavigate();
  const params = useParams();
  const [questoes, setQuestoes] = useState<Questao[]>([]);
  const [expired, setExpired] = useState<any>(null);
  const [currentAnexos, setCurrentAnexos] = useState<any[]>([]);
  const [anexos, setAnexos] = useState<any[]>([]);
  const [questoesObrigatorias, setQuestoesObrigatorias] = useState<
    any[] | null
  >(null);
  const [categorias, setCategorias] = useState([
    "Informações gerais da empresa",
    "Encarregado de Proteção de dados (DPO)",
    "Identificação dos tratamentos de dados pessoais",
    "Conformidade à Privacidade e Proteção de dados (LGPD)",
    "Atendimento aos direitos do titular de dados",
    "Tecnologia da Informação",
    "Segurança da Informação",
    "Treinamento e conscientização",
    "Cultura de Segurança da informação",
    "Histórico de incidentes de segurança envolvendo dados pessoais",
    "Gerenciamento de Banco de dados",
    "Transferência internacional de dados",
  ]);

  let cliente_id = params.id;
  let fornecedor_id = params.fornecedorid;

  useEffect(() => {
    api.get("/questoes").then((response) => {
      setQuestoes(response.data);
    });
  }, []);

  useEffect(() => {
    if (questoes[0]) {
      setQuestoesObrigatorias(
        questoes.filter(
          (q: any) => q.required && !q.inativo && !q.dependencia[0]
        )
      );
    }
  }, [questoes]);

  const [isModalVisible, setIsModalVisible] = useState(true);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const [formValues, setFormValues] = useState<any>({});

  const [cnpj, setCnpj] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState<string[]>([]);
  const [questionario, setQuestionario] = useState<any>();

  const [activeCat, setActiveCat] = useState<string>(
    "Informações gerais da empresa"
  );

  useEffect(() => {
    if (formValues["660fee5de65c9279bff20fa8"] === "Sim") {
      setCategorias([
        "Informações gerais da empresa",
        "Encarregado de Proteção de dados (DPO)",
        "Identificação dos tratamentos de dados pessoais",
      ]);
    }
    if (formValues["660fee5de65c9279bff20fa8"] === "Não") {
      setCategorias([
        "Informações gerais da empresa",
        "Encarregado de Proteção de dados (DPO)",
        "Identificação dos tratamentos de dados pessoais",
        "Conformidade à Privacidade e Proteção de dados (LGPD)",
        "Atendimento aos direitos do titular de dados",
        "Tecnologia da Informação",
        "Segurança da Informação",
        "Treinamento e conscientização",
        "Cultura de Segurança da informação",
        "Histórico de incidentes de segurança envolvendo dados pessoais",
        "Gerenciamento de Banco de dados",
        "Transferência internacional de dados",
      ]);
    }
  }, [formValues]);

  const verificaPorcentagem = () => {
    // quantas questoes de questoesObrigatorias esto em formValues
    if (questoesObrigatorias !== null) {
      if (formValues["660fee5de65c9279bff20fa8"] === "Sim") {
        return 100;
      }

      let qtdRespondidas = 0;
      const qtdObrigatorias = questoesObrigatorias.length;

      questoesObrigatorias.forEach(
        (q: any) => formValues[q._id.toString()] && qtdRespondidas++
      );

      const porcentagemRespondidas = (qtdRespondidas / qtdObrigatorias) * 100;

      return porcentagemRespondidas;
    } else {
      return 0;
    }
  };

  function encontrarIndicePorValor(valorProcurado: any) {
    // Utilize a função map para percorrer o array
    // A função map retorna um novo array com os índices dos objetos que correspondem ao valor procurado
    const indicesEncontrados = questoes
      .filter((q: Questao) => {
        if (!q.dependencia) {
          return true;
        } else if (!q.dependencia[0]) {
          return true;
        } else if (
          q.dependencia.filter((dep: any) => {
            if (dep.type === "input" && formValues[dep.depende]) {
              if (
                formValues[dep.depende][0] === dep.value ||
                formValues[dep.depende] === dep.value
              ) {
                return true;
              } else {
                return false;
              }
            } else if (
              dep.type === "checkbox" &&
              formValues[dep.depende] &&
              formValues[dep.depende].length > 0
            ) {
              return true;
            } else {
              return false;
            }
          })[0]
        ) {
          return true;
        } else {
          return false;
        }
      })
      .sort((a, b) => a.ordem - b.ordem)
      .map((objeto, indice) => {
        if (objeto === valorProcurado) {
          return indice + 1;
        }
        return null; // Retorna null para os objetos que não correspondem ao valor procurado
      });

    // Filtra os índices válidos (diferentes de null)
    const indicesValidos = indicesEncontrados.filter(
      (indice) => indice !== null
    );

    return indicesValidos;
  }

  useEffect(() => {
    api.get(`/fornecedorcnpj/${fornecedor_id}`).then((response) => {
      const fornecedor = response.data;
      setCnpj(fornecedor.cnpj);
      setExpired(fornecedor.expired);
    });
  }, [fornecedor_id]);

  useEffect(() => {
    if (questionario && questionario.status === "respondido") {
      navigate(
        `/formulario/5/` +
          params.id +
          "/" +
          params.fornecedorid +
          "/" +
          params.questionarioid
      );
    }
  }, [questionario]);

  const fetchData = async () => {
    await api
      .get(`/respostasforn/${params.questionarioid}`)
      .then((response: any) => {
        response.data.respostas.forEach((res: Response) => {
          setFormValues((prevState: any) => {
            return {
              ...prevState,
              [res.questao_id]: res.resposta,
            };
          });
          setDisabled((prevState: any) => [...prevState, res.questao_id]);
        });
        setQuestionario(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [params.questionarioid]);

  const handleInputChange = (fieldName: string, value: any) => {
    setFormValues((prevValues: any) => ({ ...prevValues, [fieldName]: value }));
  };

  const handleSubmit = async (fim: boolean) => {
    setIsLoading(true);

    const save = async () => {
      try {
        const formData = new FormData();
        if (currentAnexos) {
          for (const arquivo of currentAnexos) {
            formData.append("files", arquivo);
          }
        }
        const itens: any = {
          questionario_id: params.questionarioid,
          cliente_id: cliente_id,
          fornecedor_id: fornecedor_id, // Assuming you have fornecedor_id available
          cnpj: cnpj, // Assuming you have cnpj available
          respostas: JSON.stringify(formValues), // The answers to the questions
          finalizar: fim,
        };

        Object.keys(itens).forEach((key: any) => {
          formData.append(key, itens[key]);
        });

        try {
          await api.post("/salvarQuestionario", formData, {
            params: formValues,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          // Se a chamada API foi bem sucedida, exibe mensagem de sucesso.
          message.success("Respostas salvas com sucesso!");
        } catch (error: any) {
          // Se a chamada API falhou, verifica se tem uma resposta com status conhecido e mensagem personalizada.
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message.error(
              `Erro ao salvar respostas: ${error.response.data.message}`
            );
          } else {
            // Se o erro não é uma resposta da API ou não tem mensagem personalizada, exibe mensagem genérica.
            message.error(
              "Erro ao salvar respostas. Por favor, tente novamente."
            );
          }
        }
        setCurrentAnexos([]);

        if (windowWidth < 800) {
          navigate(
            `/analisefornecedor/` +
              params.id +
              "/" +
              params.fornecedorid +
              "/" +
              params.questionarioid
          );
        } else {
          fim &&
            navigate(
              `/formulario/5/` +
                params.id +
                "/" +
                params.fornecedorid +
                "/" +
                params.questionarioid
            );
        }
        fetchData();
      } catch (error) {
        console.error("Error saving form data:", error);
      }
    };
    // salva progresso
    save();
  };

  const getFieldName = (questionId: any) => `Questao_${questionId}`;

  const SmartphoneLayout = () => {
    return (
      <div
        className="form-questionario"
        style={{
          padding: "0 2rem",
          height: "max-content",
          marginBottom: "-25rem",
          paddingBottom: "3rem",
          overflow: "hidden",
        }}
      >
        {activeCat === "Tecnologia da Informação" && (
          <Alert
            message="Caso você não seja do setor do TI, solicite auxílio do profissional responsável pelo departamento."
            type="warning"
            showIcon
          />
        )}
        <form
          className="form"
          // onSubmit={handleSubmit}
          style={{
            marginBottom: "6rem",
            marginTop: "1rem",
            marginLeft: "-2rem",
            width: "calc(100% + 4rem)",
          }}
        >
          {questoes
            // .filter((item: any) => item.categoria === activeCat)
            .filter((q: Questao) => {
              if (!q.dependencia) {
                return true;
              } else if (!q.dependencia[0]) {
                return true;
              } else if (
                q.dependencia.filter((dep: any) => {
                  if (dep.type === "input" && formValues[dep.depende]) {
                    if (
                      formValues[dep.depende][0] === dep.value ||
                      formValues[dep.depende] === dep.value
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  } else if (
                    dep.type === "checkbox" &&
                    formValues[dep.depende] &&
                    formValues[dep.depende].length > 0
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                })[0]
              ) {
                return true;
              } else {
                return false;
              }
            })
            .sort((a, b) => a.ordem - b.ordem)
            .map((questaoEOpcoes: Questao, index: number) => (
              <div key={index} className="formItem">
                <label className="label">
                  <b>{encontrarIndicePorValor(questaoEOpcoes)}. </b>
                  {`${questaoEOpcoes.questao}`}
                </label>
                {questaoEOpcoes.description && (
                  <p
                    style={{
                      color: "#000",
                      opacity: 0.4,
                      fontSize: "0.9rem",
                      // marginLeft: "1.6rem",
                      marginBottom: "0.6rem",
                    }}
                  >
                    *{questaoEOpcoes.description}
                  </p>
                )}

                {questaoEOpcoes.tipo === "input" && (
                  <div className="testeInput">
                    <Input
                      value={formValues[questaoEOpcoes._id] || ""}
                      onChange={(e) =>
                        handleInputChange(questaoEOpcoes._id, e.target.value)
                      }
                      disabled={
                        disabled.find(
                          (qId: string) => qId === questaoEOpcoes._id
                        )
                          ? true
                          : false
                      }
                    />
                  </div>
                )}

                {questaoEOpcoes.tipo === "dropdown" && (
                  <Select
                    allowClear
                    value={formValues[questaoEOpcoes._id]}
                    onChange={(e) =>
                      // handleInputChange([], e.target.value)
                      handleInputChange(questaoEOpcoes._id, e)
                    }
                    options={questaoEOpcoes.opcoes.map((opcao, i) => {
                      return {
                        value: opcao,
                        label: opcao,
                      };
                    })}
                    style={{ width: "50%" }}
                    placeholder="Selecione"
                    disabled={
                      disabled.find((qId: string) => qId === questaoEOpcoes._id)
                        ? true
                        : false
                    }
                  />
                )}

                {questaoEOpcoes.tipo === "checkbox" && (
                  <div className="checkboxGroup">
                    {questaoEOpcoes.dependencia &&
                    questaoEOpcoes.dependencia[0] ? (
                      questaoEOpcoes.dependencia.filter((dep: any) => {
                        if (dep.type === "input" && formValues[dep.depende]) {
                          if (
                            formValues[dep.depende][0] === dep.value ||
                            formValues[dep.depende] === dep.value
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        } else if (
                          dep.type === "checkbox" &&
                          formValues[dep.depende] &&
                          formValues[dep.depende].length > 0
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      })[0] && (
                        <>
                          {questaoEOpcoes.opcoes
                            .filter((opcao) => {
                              if (
                                questaoEOpcoes.dependencia &&
                                questaoEOpcoes.dependencia[0].type ===
                                  "checkbox"
                              ) {
                                if (
                                  formValues[
                                    questaoEOpcoes.dependencia[0].depende
                                  ].includes(opcao)
                                ) {
                                  return true;
                                } else {
                                  return false;
                                }
                              } else {
                                return true;
                              }
                            })
                            .map((opcao) => {
                              const currentSelection =
                                formValues[questaoEOpcoes._id] || [];

                              return (
                                <>
                                  <Checkbox
                                    disabled={
                                      disabled.find(
                                        (qId: string) =>
                                          qId === questaoEOpcoes._id
                                      )
                                        ? true
                                        : false
                                    }
                                    checked={currentSelection.includes(opcao)}
                                    onChange={(v) => {
                                      if (currentSelection.includes(opcao)) {
                                        handleInputChange(
                                          questaoEOpcoes._id,
                                          formValues[questaoEOpcoes._id].filter(
                                            (item: string) => item !== opcao
                                          )
                                        );
                                      } else {
                                        handleInputChange(questaoEOpcoes._id, [
                                          ...(formValues[questaoEOpcoes._id] ||
                                            []),
                                          opcao,
                                        ]);
                                      }
                                    }}
                                  >
                                    {opcao}
                                  </Checkbox>
                                  <br />
                                </>
                              );
                            })}
                        </>
                      )
                    ) : (
                      <>
                        {questaoEOpcoes.opcoes.map((opcao) => {
                          const currentSelection =
                            formValues[questaoEOpcoes._id] || [];

                          return (
                            <>
                              <Checkbox
                                disabled={
                                  disabled.find(
                                    (qId: string) => qId === questaoEOpcoes._id
                                  )
                                    ? true
                                    : false
                                }
                                checked={currentSelection.includes(opcao)}
                                onChange={(v) => {
                                  if (currentSelection.includes(opcao)) {
                                    handleInputChange(
                                      questaoEOpcoes._id,
                                      formValues[questaoEOpcoes._id].filter(
                                        (item: string) => item !== opcao
                                      )
                                    );
                                  } else {
                                    handleInputChange(questaoEOpcoes._id, [
                                      ...(formValues[questaoEOpcoes._id] || []),
                                      opcao,
                                    ]);
                                  }
                                }}
                              >
                                {opcao}
                              </Checkbox>
                              <br />
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}

                {questaoEOpcoes.tipo === "attachment" && (
                  <Dragger
                    name={questaoEOpcoes._id}
                    action="/criar e mudar rota"
                    beforeUpload={(file) => {
                      const isPDF = file.type === "application/pdf";
                      if (!isPDF) {
                        message.error("You can only upload PDF file!");
                      }
                      return isPDF ? true : Upload.LIST_IGNORE;
                    }}
                    onChange={(info: UploadChangeParam<UploadFile<any>>) => {
                      if (info.file.status === "done") {
                        handleInputChange(
                          getFieldName(index),
                          info.file.response
                        );
                      } else if (info.file.status === "error") {
                      }
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <FileFilled style={{ color: "grey" }} rev={undefined} />
                    </p>
                    <p className="ant-upload-text">
                      Clique para enviar os documentos
                    </p>
                    <p className="ant-upload-hint">Em formato PDF</p>
                  </Dragger>
                )}
              </div>
            ))}
          {/* <div>
        {activeCat === "Transferência internacional de dados" && (
          <button
            type="submit"
            className="submitButton"
            style={{ backgroundColor: "green" }}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ color: "#fff", fontSize: "1rem" }}
                    spin
                  />
                }
              />
            ) : (
              <SaveOutlined />
            )}{" "}
            Enviar Respostas
          </button>
        )}
      </div> */}
          <Button
            size="large"
            style={{
              marginTop: "1rem",
              marginBottom: "3rem",
            }}
            type="primary"
            onClick={() => {
              handleSubmit(true);
            }}
          >
            Finalizar e Avançar <ArrowRightOutlined />
          </Button>
        </form>
      </div>
    );
  };

  const itemsCollapse = [
    {
      key: "1",
      label: "Questionário",
      children: (
        <div className="form-questionario">
          {activeCat === "Tecnologia da Informação" && (
            <Alert
              message="Caso você não seja do setor do TI, solicite auxílio do profissional responsável pelo departamento."
              type="warning"
              showIcon
            />
          )}
          <form
            className="form"
            // onSubmit={handleSubmit}
            style={{
              marginBottom: "3rem",
              marginTop: "1rem",
            }}
          >
            {questoes
              .filter((item: any) => item.categoria === activeCat)
              .filter((q: Questao) => {
                if (!q.dependencia) {
                  return true;
                } else if (!q.dependencia[0]) {
                  return true;
                } else if (
                  q.dependencia.filter((dep: any) => {
                    if (dep.type === "input" && formValues[dep.depende]) {
                      if (
                        formValues[dep.depende][0] === dep.value ||
                        formValues[dep.depende] === dep.value
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    } else if (
                      dep.type === "checkbox" &&
                      formValues[dep.depende] &&
                      formValues[dep.depende].length > 0
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  })[0]
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              .sort((a, b) => a.ordem - b.ordem)
              .filter((q: any) =>
                q.tipo === "anexo"
                  ? cliente_id !== "656de00ca80c7c99f88fd1fb"
                    ? true
                    : false
                  : true
              )
              .map((questaoEOpcoes: Questao, index: number) => (
                <div key={index} className="formItem">
                  <label className="label">
                    <b>{encontrarIndicePorValor(questaoEOpcoes)}. </b>
                    <span style={{ whiteSpace: "pre-wrap" }}>
                      {questaoEOpcoes.questao}
                    </span>
                  </label>
                  {questaoEOpcoes.description && (
                    <p
                      style={{
                        color: "#000",
                        opacity: 0.4,
                        fontSize: "0.9rem",
                        // marginLeft: "1.6rem",
                        marginBottom: "0.6rem",
                      }}
                    >
                      *{questaoEOpcoes.description}
                    </p>
                  )}

                  {questaoEOpcoes.tipo === "anexo" &&
                    cliente_id !== "656de00ca80c7c99f88fd1fb" && (
                      <>
                        <Anexos
                          currentAnexos={[...currentAnexos, ...anexos]}
                          onChange={(v) =>
                            setCurrentAnexos(
                              v.fileList.map((f: any) => f.originFileObj)
                            )
                          }
                          setCurrentAnexos={setCurrentAnexos}
                        />
                      </>
                    )}

                  {questaoEOpcoes.tipo === "input" && (
                    <div className="testeInput">
                      <Input
                        value={formValues[questaoEOpcoes._id] || ""}
                        onChange={(e) =>
                          handleInputChange(questaoEOpcoes._id, e.target.value)
                        }
                        disabled={
                          disabled.find(
                            (qId: string) => qId === questaoEOpcoes._id
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                  {questaoEOpcoes.tipo === "dropdown" && (
                    <Select
                      allowClear
                      value={formValues[questaoEOpcoes._id]}
                      onChange={(e) =>
                        // handleInputChange([], e.target.value)
                        handleInputChange(questaoEOpcoes._id, e)
                      }
                      options={questaoEOpcoes.opcoes.map((opcao, i) => {
                        return {
                          value: opcao,
                          label: opcao,
                        };
                      })}
                      style={{ width: "50%" }}
                      placeholder="Selecione"
                      disabled={
                        disabled.find(
                          (qId: string) => qId === questaoEOpcoes._id
                        )
                          ? true
                          : false
                      }
                    />
                  )}

                  {questaoEOpcoes.tipo === "checkbox" && (
                    <div className="checkboxGroup">
                      {questaoEOpcoes.dependencia &&
                      questaoEOpcoes.dependencia[0] ? (
                        questaoEOpcoes.dependencia.filter((dep: any) => {
                          if (dep.type === "input" && formValues[dep.depende]) {
                            if (
                              formValues[dep.depende][0] === dep.value ||
                              formValues[dep.depende] === dep.value
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          } else if (
                            dep.type === "checkbox" &&
                            formValues[dep.depende] &&
                            formValues[dep.depende].length > 0
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        })[0] && (
                          <>
                            {questaoEOpcoes.opcoes
                              .filter((opcao) => {
                                if (
                                  questaoEOpcoes.dependencia &&
                                  questaoEOpcoes.dependencia[0].type ===
                                    "checkbox"
                                ) {
                                  if (
                                    formValues[
                                      questaoEOpcoes.dependencia[0].depende
                                    ].includes(opcao)
                                  ) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                } else {
                                  return true;
                                }
                              })
                              .map((opcao) => {
                                const currentSelection =
                                  formValues[questaoEOpcoes._id] || [];

                                return (
                                  <>
                                    <Checkbox
                                      disabled={
                                        disabled.find(
                                          (qId: string) =>
                                            qId === questaoEOpcoes._id
                                        )
                                          ? true
                                          : false
                                      }
                                      checked={currentSelection.includes(opcao)}
                                      onChange={(v) => {
                                        if (currentSelection.includes(opcao)) {
                                          handleInputChange(
                                            questaoEOpcoes._id,
                                            formValues[
                                              questaoEOpcoes._id
                                            ].filter(
                                              (item: string) => item !== opcao
                                            )
                                          );
                                        } else {
                                          handleInputChange(
                                            questaoEOpcoes._id,
                                            [
                                              ...(formValues[
                                                questaoEOpcoes._id
                                              ] || []),
                                              opcao,
                                            ]
                                          );
                                        }
                                      }}
                                    >
                                      {opcao}
                                    </Checkbox>
                                    <br />
                                  </>
                                );
                              })}
                          </>
                        )
                      ) : (
                        <>
                          {questaoEOpcoes.opcoes.map((opcao) => {
                            const currentSelection =
                              formValues[questaoEOpcoes._id] || [];

                            return (
                              <>
                                <Checkbox
                                  disabled={
                                    disabled.find(
                                      (qId: string) =>
                                        qId === questaoEOpcoes._id
                                    )
                                      ? true
                                      : false
                                  }
                                  checked={currentSelection.includes(opcao)}
                                  onChange={(v) => {
                                    if (currentSelection.includes(opcao)) {
                                      handleInputChange(
                                        questaoEOpcoes._id,
                                        formValues[questaoEOpcoes._id].filter(
                                          (item: string) => item !== opcao
                                        )
                                      );
                                    } else {
                                      handleInputChange(questaoEOpcoes._id, [
                                        ...(formValues[questaoEOpcoes._id] ||
                                          []),
                                        opcao,
                                      ]);
                                    }
                                  }}
                                >
                                  {opcao}
                                </Checkbox>
                                <br />
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                  )}

                  {questaoEOpcoes.tipo === "attachment" && (
                    <Dragger
                      name={questaoEOpcoes._id}
                      action="/criar e mudar rota"
                      beforeUpload={(file) => {
                        const isPDF = file.type === "application/pdf";
                        if (!isPDF) {
                          message.error("You can only upload PDF file!");
                        }
                        return isPDF ? true : Upload.LIST_IGNORE;
                      }}
                      onChange={(info: UploadChangeParam<UploadFile<any>>) => {
                        if (info.file.status === "done") {
                          console.log(info.file.response);
                          handleInputChange(
                            getFieldName(index),
                            info.file.response
                          );
                        } else if (info.file.status === "error") {
                          console.log("Upload failed:", info.file.error);
                        }
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <FileFilled style={{ color: "grey" }} rev={undefined} />
                      </p>
                      <p className="ant-upload-text">
                        Clique para enviar os documentos
                      </p>
                      <p className="ant-upload-hint">Em formato PDF</p>
                    </Dragger>
                  )}
                </div>
              ))}
            {activeCat === "Transferência internacional de dados" ||
            formValues["660fee5de65c9279bff20fa8"] === "Sim" ? (
              <p>
                <InfoCircleOutlined /> Você concluiu o questionário. Clique no
                botão "Salvar progresso" no canto inferior direito para enviar
                suas respostas.
              </p>
            ) : (
              <></>
            )}
            {/* <div>
              {activeCat === "Transferência internacional de dados" && (
                <button
                  type="submit"
                  className="submitButton"
                  style={{ backgroundColor: "green" }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ color: "#fff", fontSize: "1rem" }}
                          spin
                        />
                      }
                    />
                  ) : (
                    <SaveOutlined />
                  )}{" "}
                  Enviar Respostas
                </button>
              )}
            </div> */}

            {verificaPorcentagem() < 100 ? (
              <SaveBtn
                text="Salvar progresso"
                onClick={() => handleSubmit(false)}
              />
            ) : (
              <SaveBtn
                text="Salvar progresso"
                onClick={() => setShowModal(true)}
              />
            )}
          </form>

          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            {activeCat !== "Informações gerais da empresa" && (
              <button
                onClick={() => {
                  const posicao: number = categorias.indexOf(activeCat);

                  setActiveCat(categorias[posicao - 1]);
                }}
                className="submitButton"
                style={{
                  marginTop: "0rem",
                  marginBottom: "5rem",
                  marginRight: "2rem",
                }}
              >
                <ArrowLeftOutlined /> Anterior
              </button>
            )}

            {activeCat !== "Transferência internacional de dados" &&
              formValues["660fee5de65c9279bff20fa8"] !== "Sim" && (
                <button
                  onClick={() => {
                    const posicao: number = categorias.indexOf(activeCat);

                    setActiveCat(categorias[posicao + 1]);
                  }}
                  className="submitButton"
                  style={{ marginTop: "0rem", marginBottom: "5rem" }}
                >
                  Próximo <ArrowRightOutlined />
                </button>
              )}
          </div>
        </div>
      ),
      showArrow: false,
    },
  ];
  return expired !== null ? (
    expired ? (
      <div className="questionario_fornecedor_expirado">
        <div className="message">
          <Empty
            description={false}
            style={{
              transform: "scale(1.5)",
              marginBottom: "3rem",
              marginTop: "-2rem",
            }}
          />
          <h2>Acesso ao questionário expirado</h2>
          <p
            style={{
              marginTop: "1rem",
              maxWidth: "90%",
              textAlign: "center",
            }}
          >
            <span style={{ opacity: 0.6 }}>
              Tenha em mente que o prazo pode ser estendido no futuro. Caso
              surjam dúvidas, por favor, entre em contato diretamente com sua
              empresa parceira e busque o responsável pela ação em colaboração
              com a equipe Seusdados.
            </span>{" "}
          </p>
        </div>
      </div>
    ) : (
      <>
        <Tooltip
        //  title="Azul: Obrigatórias; Verde: Não obrigatórias"
        >
          <Progress
            percent={verificaPorcentagem()} // todas
            style={windowWidth <= 800 ? { marginTop: "0rem" } : {}}
            className="analise__progress"
          />
        </Tooltip>

        {windowWidth <= 800 ? (
          <SmartphoneLayout />
        ) : (
          <div className="analise">
            <div
              className="analise__indice"
              style={{
                maxWidth: "300px",
                margin: "2%",
                height: "76%",
              }}
            >
              <p className="analise__indice--title">Índice</p>
              {categorias.map((cat: any) => {
                return (
                  <p
                    key={cat}
                    className={`${
                      activeCat === cat
                        ? "analise__indice--cap__active"
                        : "analise__indice--cap"
                    }`}
                    onClick={() => setActiveCat(cat)}
                  >
                    {cat}
                  </p>
                );
              })}
            </div>
            <div
              style={{
                margin: "2% 2% 2% 0",
                width: "74%",
                // height: "300px",
              }}
            >
              <Collapse items={itemsCollapse} defaultActiveKey={["1"]} />
            </div>
          </div>
        )}

        <Modal
          title="Você deseja:"
          open={showModal}
          footer={[
            <Button
              onClick={() => {
                handleSubmit(false);
                setShowModal(false);
              }}
              type="primary"
              style={{ background: "green" }}
            >
              Salvar progresso
            </Button>,
            <Button
              onClick={() => {
                handleSubmit(true);
                setShowModal(false);
              }}
              type="primary"
            >
              Concluir e enviar
            </Button>,
            <Button
              type="default"
              form="form-pergunta"
              onClick={() => setShowModal(false)}
            >
              Cancelar
            </Button>,
          ]}
          width={493}
          onCancel={() => setShowModal(false)}
        >
          <p style={{ padding: "1rem 0" }}>
            Você já respondeu todas as respostas obrigatórias, ao clicar em
            "Concluir e enviar", não será possível responder ou alterar
            respostas!
          </p>
        </Modal>
        <Modal
          title="Coleta de informações"
          open={isModalVisible}
          onOk={handleOk}
          footer={[
            <Button key="submit" type="primary" onClick={handleOk}>
              Confirmar
            </Button>,
          ]}
        >
          <p>
            A coleta de informações através do presente formulário é feita sob a
            mais absoluta confidencialidade e segundo diretrizes da LGPD,
            Resoluções da ANPD e normas ABNT ISO, sendo que apenas os dados de
            contato serão utilizados para eventuais comunicações a serem
            realizadas pelo DPO.
          </p>
        </Modal>
      </>
    )
  ) : (
    <Spin
      size="large"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    />
  );
};

export default Quetionario;
